import { format, subDays } from 'date-fns'

export const NOW = new Date()
export const YESTERDAY = subDays(new Date(), 1)

const DATEFORMAT = 'yyyy-MM-dd'
const START = format(subDays(YESTERDAY, 29), DATEFORMAT)
const END = format(YESTERDAY, DATEFORMAT)

export const usageGlobalParamsDefault = {
  start: START,
  end: END,
  graph_interval: 'daily',
  graph_interval_patientusage: 'weekly'
}

export const usageTodayParamsDefault = {
  graph_interval: 'daily',
  graph_interval_patientusage: 'daily'
}

export default {
  usageGlobalParams: {
    ...usageGlobalParamsDefault
  },
  usageTodayParams: {
    ...usageTodayParamsDefault
  },
  activePatients: {
    data: [],
    loading: false,
    hasErrors: false
  },
  activePatientsToday: {
    data: [],
    loading: false,
    hasErrors: false
  },
  measureActivity: {
    data: [],
    loading: false,
    hasErrors: false
  },
  measureActivityToday: {
    data: [],
    loading: false,
    hasErrors: false
  },
  measureCompletionToday: {
    data: [],
    loading: false,
    hasErrors: false
  },
  clinicalSignIns: {
    data: [],
    loading: false,
    hasErrors: false
  },
  measures: {
    data: [],
    loading: false,
    hasErrors: false
  }
}
