import axios from 'axios'
import {
  SIGN_OUT as ANALYTICS_SIGN_OUT
} from '@/store/modules/analytics/outcomes-explorer/constants.js'
import {
  ENDPOINTS as SETTINGS_ENDPOINTS
} from '@/store/modules/settings/constants.js'
import {
  EXPORTS_LOGOUT
} from '@/store/modules/exports/constants.js'
import {
  unsetAccessTokens,
  setAccessTokens
} from './sessionHandler'
import {
  PATIENT_USERS_LOGOUT
} from '@/store/modules/patientUsers/constants.js'
import {
  LOCATIONS_LOGOUT
} from '@/store/modules/locations/constants.js'
import {
  MUTATION_SET_MODAL_CONFIG,
  MUTATION_SET_MODAL_OPENED
} from '@/store/modules/applicationState/constants'
import {
  LOCAL_STORAGE_USER_TOKEN,
  LOCAL_STORAGE_USER_KEY_NAME,
  SIGN_OUT
} from './constants'

export function loginSuccessCallback (response, commit, method) {
  const token = response.data.access_token
  setAccessTokens(token, response.data.refresh_token)
  const user = {
    id: response.data.id,
    username: response.data.username,
    firstName: response.data.first_name,
    lastName: response.data.last_name,
    initials: response.data.initials,
    isPatient: response.data.is_patient,
    user_type: response.data.user_type,
    roles: response.data.roles,
    status: response.data.status,
    relationToPatient: response.data.patient_relation,
    isAccountActive: response.data.account_active,
    hippaAgreement: response.data.hippaAgreement,
    loggedAsRespondent: response.data.logged_as_respondent,
    uuid: response.data.umf_uuid
  }
  localStorage.setItem(LOCAL_STORAGE_USER_KEY_NAME, JSON.stringify(user))

  if (commit) {
    commit(method, { token, user })
  }
  return { token, user }
}

export const getRelations = (patients, user) => user.relations.map(relation => ({
  relation_id: relation.relation_id,
  patient: relation.relation_id === '1'
    ? null
    : patients.length > 1 ? relation.patient.phi_safe_name : null
}))

export function singOutCallback (error, commit) {
  unsetAccessTokens()
  commit(SIGN_OUT, {})
  commit(MUTATION_SET_MODAL_CONFIG, {})
  commit(MUTATION_SET_MODAL_OPENED, false)
  commit('settings/SIGN_OUT')
  commit('user/SIGN_OUT')
  commit('measures/SIGN_OUT')
  commit('programs/SIGN_OUT')
  commit('patients/SIGN_OUT')
  commit('lastSession/SIGN_OUT')
  commit('appointments/SIGN_OUT')
  commit('respondents/SIGN_OUT')
  commit(LOCATIONS_LOGOUT)
  commit(PATIENT_USERS_LOGOUT)
  commit(ANALYTICS_SIGN_OUT)
  commit(EXPORTS_LOGOUT)
  return error ? error.response : null
}

export function initialState () {
  return {
    token: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN) || '',
    sign_in_user: {},
    loggedInUser: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY_NAME)) || {
      initials: []
    },
    myAccountData: {},
    accounts: [],
    accessCodeUUID: null,
    accessCode: null,
    accessCodeUsers: [],
    resetToken: null,
    loginUsername: null,
    ssoError: false,
    showSelectAccount: false
  }
}

export function removeUISettingsNotPersistingBetweenLogins (currentSettings) {
  const settings = { ...currentSettings }
  settings.accountStatusSelector = null
  settings.noContactInfoselector = null
  axios.post(SETTINGS_ENDPOINTS.UI_SETTINGS, settings)
}
