import {
  getLoggedUserHasAnyOfPermissions,
  getLoggedUserHasAnyOfRoles,
  loggedUserIsRespondent,
  loggedUserIsAdmin,
  loggedUserIsDirector,
  loggedUserIsClinician,
  loggedUserIsStaff,
  loggedUserIsSupervisor
} from './UserRoles'

const DEFAULT_ROUTE_FOR_AUTHENTICATED_RESPONDENT = '/welcome'
const DEFAULT_ROUTE_FOR_OTHER = '/'
const DEFAULT_HOME_FOR_CLINICIAN = 'ClinicianHomepage'
const DEFAULT_HOME_FOR_STAFF = 'ClinicianHomepage'
const DEFAULT_HOME_FOR_SUPERVISOR = 'ClinicianPatientsList'
const DEFAULT_HOME_FOR_ADMIN = 'SettingsLayout'
const DEFAULT_HOME_FOR_DIRECTOR = 'AnalyticsLayout'
const DEFAULT_HOME_FOR_RESPONDENT = 'Session'
const ROUTE_LOGIN = 'Login'
const ROUTE_RESET_PASSWORD_RESPONDENT = '/my-account/change-password'
const ROUTE_RESET_PASSWORD_CLINICAL_USER = '/force-reset-password'
const DEFAULT_SETTING_HOME_FOR_STAFF = 'SettingsPatientUsers'
const DEFAULT_SETTING_HOME_FOR_ADMIN = 'SettingsClinicalUsers'

/**
 * Determine Next Route that the user must Go When recovering session
 * From Hard Reset
 *
 * @returns {string}
 */
export function getNextRouteWhenForceResetPassword () {
  if (loggedUserIsRespondent()) {
    return ROUTE_RESET_PASSWORD_RESPONDENT
  }
  return ROUTE_RESET_PASSWORD_CLINICAL_USER
}
/**
 * Determine Next Route that the user must Go When recovering session
 * From Hard Reset
 *
 * @returns {string}
 */
export function getNextRouteWhenAuthenticated () {
  if (loggedUserIsRespondent()) {
    return DEFAULT_ROUTE_FOR_AUTHENTICATED_RESPONDENT
  }
  return DEFAULT_ROUTE_FOR_OTHER
}

/**
 * Return next Route Path Name When Going Home
 *
 * @returns {string}
 */
export function getNextRouteWhenGoingHome () {
  if (loggedUserIsClinician()) {
    return DEFAULT_HOME_FOR_CLINICIAN
  }

  if (loggedUserIsStaff()) {
    return DEFAULT_HOME_FOR_STAFF
  }

  if (loggedUserIsSupervisor()) {
    return DEFAULT_HOME_FOR_SUPERVISOR
  }

  if (loggedUserIsAdmin()) {
    return DEFAULT_HOME_FOR_ADMIN
  }

  if (loggedUserIsDirector()) {
    return DEFAULT_HOME_FOR_DIRECTOR
  }

  if (loggedUserIsRespondent()) {
    return DEFAULT_HOME_FOR_RESPONDENT
  }

  return ROUTE_LOGIN
}

export function getNextSettingsHomeRoute () {
  if (loggedUserIsAdmin()) {
    return DEFAULT_SETTING_HOME_FOR_ADMIN
  }

  if (loggedUserIsStaff()) {
    return DEFAULT_SETTING_HOME_FOR_STAFF
  }

  return getNextRouteWhenGoingHome()
}

/**
 * Determine If The current logged user has access to certain Route.
 *
 * @param routeAccessData
 * @returns {boolean|*}
 */
export function userHasAccessToRoute (routeAccessData) {
  const roleAllowToExecuteRoute = userRolesAllowToExecuteRoute(routeAccessData)
  const permissionAllowToExecuteRoute = userPermissionAllowToExecuteRoute(routeAccessData)
  return roleAllowToExecuteRoute || permissionAllowToExecuteRoute
}

/**
 * Determine if the current logged can execute Route by certain permission
 *
 * @param routeAccessData
 * @returns {boolean}
 */
function userPermissionAllowToExecuteRoute (routeAccessData) {
  const invalid = (!routeAccessData || !routeAccessData.permissions || !routeAccessData.permissions.length)
  return invalid ? false : getLoggedUserHasAnyOfPermissions(routeAccessData.permissions)
}

/**
 * Determine if the current logged can execute Route by certain Role
 *
 * @param routeAccessData
 * @returns {boolean}
 */
function userRolesAllowToExecuteRoute (routeAccessData) {
  const invalid = (!routeAccessData || !routeAccessData.roles || !routeAccessData.roles.length)
  return invalid ? false : getLoggedUserHasAnyOfRoles(routeAccessData.roles)
}
