import axios from 'axios'
import {
  SIGN_OUT,
  LOCAL_STORAGE_USER_KEY_NAME,
  RESET_RESPONDENT_USER_PASSWORD,
  RENEW_TOKEN,
  SIGN_IN,
  GET_ACCESS_CODE,
  VALIDATE_ACCESS_CODE,
  ACCOUNT_SETUP,
  FIND_USERNAME,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_PASSWORD,
  RECOVER_USERNAME,
  MY_ACCOUNT,
  MY_ACCOUNT_TOGGLE_PRYMARY,
  CONTACT_INFO,
  CONTACT_INFO_DELETE,
  SAVE_HIPPA_AGREEMENT,
  LOGIN_AS,
  KEEP_ALIVE,
  SECURITY_COMMIT_LOGIN_USERNAME,
  SET_ACCESS_CODE,
  SET_ACCESS_CODE_UUID,
  SET_ACCESS_CODE_USERS,
  SET_RESET_TOKEN,
  SET_LOGIN_SSO_ERROR,
  SET_SHOW_SELECT_ACCOUNT,
  ENDPOINTS
} from './constants'
import {
  unsetAccessTokens
} from './sessionHandler'
import {
  loginSuccessCallback,
  getRelations,
  singOutCallback,
  removeUISettingsNotPersistingBetweenLogins,
  initialState
} from './functions'

// initial state
const state = initialState()

// actions
const actions = {
  RESET_CLINICAL_USER_PASSWORD (actions, userId) {
    return axios.post(ENDPOINTS.RESET_CLINICAL_USER_PASSWORD, { id: userId })
  },
  FIND_USERNAME ({ commit }, username) {
    return axios.get(`${ENDPOINTS.FIND_USERNAME}/${escape(username)}`).then((response) => {
      commit(FIND_USERNAME, response.data)
    }).catch((error) => {
      return error.response
    })
  },
  SIGN_IN ({ commit }, credentials) {
    return axios.post(ENDPOINTS.SIGN_IN, credentials).then((response) => {
      if (response.data && response.data.force_reset_password) {
        commit(SET_ACCESS_CODE_UUID, response.data.uuid)
        commit(VALIDATE_ACCESS_CODE, { resetToken: response.data.token })
      } else {
        loginSuccessCallback(response, commit, SIGN_IN)
      }
      return response
    }).catch((error) => {
      return error.response
    })
  },

  RENEW_TOKEN ({ commit }, token) {
    return axios.post(ENDPOINTS.RENEW_TOKEN, { refreshToken: token }).then(response => {
      loginSuccessCallback(response, commit, RENEW_TOKEN)
    }).catch((error) => {
      singOutCallback(error, commit)
      return error.response
    })
  },

  GET_SESSION ({ commit }) {
    const promise = axios.get(ENDPOINTS.GET_SESSION)
    promise.then(response => {
      loginSuccessCallback(response, commit, SIGN_IN)
    }).catch((error) => {
      singOutCallback(error, commit)
      return error.response
    })
    return promise
  },

  SIGN_OUT ({ commit, getters }) {
    removeUISettingsNotPersistingBetweenLogins(getters.getUiSettings)
    return new Promise((resolve) => {
      axios.post(ENDPOINTS.SIGN_OUT).finally(() => {
        singOutCallback(null, commit)
        resolve()
      })
    })
  },

  KEEP_ALIVE ({ commit }) {
    commit(KEEP_ALIVE)
    return axios.get(ENDPOINTS.KEEP_ALIVE)
  },

  SIGN_OUT_CLIENT_SIDE ({ commit }) {
    unsetAccessTokens()
    commit(SIGN_OUT)
  },

  GET_ACCESS_CODE ({ commit }, data) {
    return axios.post(ENDPOINTS.GET_ACCESS_CODE, data).then((response) => {
      commit(GET_ACCESS_CODE, response.data)
      commit(SET_ACCESS_CODE_USERS, response.data.data)

      const accessCodeUsers = response.data.data

      if (accessCodeUsers.length > 1) {
        commit(SET_SHOW_SELECT_ACCOUNT, true)
      } else if (accessCodeUsers[0].relations && accessCodeUsers[0].relations.length > 1) {
        commit(SET_SHOW_SELECT_ACCOUNT, true)
      }

      if (response.data.data.length === 1) {
        commit(SET_ACCESS_CODE_UUID, response.data.data[0].uuid)
      }

      return response
    }).catch((error) => {
      return error.response
    })
  },

  SECURE_GET_ACCESS_CODE ({ commit }, data) {
    return axios.post(ENDPOINTS.SECURE_GET_ACCESS_CODE, data).then((response) => {
      commit(GET_ACCESS_CODE, response.data)
      commit(SET_ACCESS_CODE_UUID, response.data.data[0].uuid)
    }).catch((error) => {
      return error.response
    })
  },

  VALIDATE_TOKEN ({ commit }, data) {
    return new Promise((resolve, reject) => {
      return axios.post(ENDPOINTS.VALIDATE_TOKEN, data)
        .then((response) => {
          commit(GET_ACCESS_CODE, response.data)
          commit(SET_ACCESS_CODE_USERS, response.data.data)
          commit(VALIDATE_ACCESS_CODE, {
            resetToken: response.data.token
          })

          if (response.data.data.length === 1) {
            commit(SET_ACCESS_CODE_UUID, response.data.data[0].uuid)
          } else if (response.data.data.length > 1) {
            commit(SET_SHOW_SELECT_ACCOUNT, true)
          }

          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  VALIDATE_ACCESS_CODE ({ commit }, data) {
    return axios.post(ENDPOINTS.VALIDATE_ACCESS_CODE, data)
      .then((response) => {
        commit(VALIDATE_ACCESS_CODE, { resetToken: response.data.token })
        return response
      }).catch((error) => {
        return error.response
      })
  },

  ACCOUNT_SETUP ({ commit }, data) {
    return axios.post(ENDPOINTS.ACCOUNT_SETUP, data)
      .then((response) => {
        loginSuccessCallback(response, commit, ACCOUNT_SETUP)
      })
      .catch((error) => {
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY_NAME)
        unsetAccessTokens()
        return error.response
      })
  },

  RESET_PASSWORD ({ commit }, data) {
    return axios.post(ENDPOINTS.RESET_PASSWORD, data)
      .then((response) => commit(RESET_PASSWORD, response.data))
  },

  CHANGE_PASSWORD ({ commit }, data) {
    return axios.post(ENDPOINTS.CHANGE_PASSWORD, data)
      .then((response) => commit(CHANGE_PASSWORD, response.data))
      .catch((error) => {
        return error.response
      })
  },

  VALIDATE_PASSWORD ({ commit }, data) {
    return axios.post(ENDPOINTS.VALIDATE_PASSWORD, data)
      .then((response) => commit(VALIDATE_PASSWORD, response.data))
      .catch((error) => {
        return error.response
      })
  },

  RECOVER_USERNAME ({ commit }, data) {
    return axios.post(ENDPOINTS.RECOVER_USERNAME, data)
      .then((response) => commit(RECOVER_USERNAME, response.data))
      .catch((error) => {
        return error.response
      })
  },

  MY_ACCOUNT ({ commit }, data) {
    return axios.get(ENDPOINTS.MY_ACCOUNT.replace('{id}', data.id))
      .then((response) => commit(MY_ACCOUNT, response.data))
      .catch((error) => {
        return error.response
      })
  },

  MY_ACCOUNT_UPDATE ({ commit }, data) {
    return axios.put(ENDPOINTS.MY_ACCOUNT.replace('{id}', data.id), data)
      .then((response) => commit(MY_ACCOUNT, response.data))
      .catch((error) => {
        return error.response
      })
  },

  CONTACT_INFO_CREATE ({ commit }, data) {
    data.editable = 1
    return axios.post(ENDPOINTS.CONTACT_INFO_CREATE.replace('{user_id}', data.user_id), data)
      .then((response) => commit(CONTACT_INFO, response.data))
      .catch((error) => {
        return error.response
      })
  },

  CONTACT_INFO_UPDATE ({ commit }, data) {
    return axios.put(ENDPOINTS.CONTACT_INFO.replace('{user_id}', data.user_id).replace('{id}', data.id), data)
      .then((response) => commit(CONTACT_INFO, response.data))
      .catch((error) => {
        return error.response
      })
  },

  CONTACT_INFO_DELETE ({ commit }, data) {
    return axios.delete(ENDPOINTS.CONTACT_INFO.replace('{user_id}', data.user_id).replace('{id}', data.id), data)
      .then((response) => commit(CONTACT_INFO_DELETE, response.data))
      .catch((error) => {
        return error.response
      })
  },
  MY_ACCOUNT_TOGGLE_PRYMARY ({ commit }, params) {
    commit(MY_ACCOUNT_TOGGLE_PRYMARY, params)
    return axios.put(ENDPOINTS.CONTACT_INFO.replace('{user_id}', params.data.user_id).replace('{id}', params.data.id), params.data)
      .catch((error) => {
        return error.response
      })
  },

  [RESET_RESPONDENT_USER_PASSWORD]: async (actions, params) => {
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.RESET_RESPONDENT_USER_PASSWORD, params).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  SET_SHOW_SELECT_ACCOUNT ({ commit }, value) {
    commit(SET_SHOW_SELECT_ACCOUNT, value)
  }
}

// getters
const getters = {
  loginUsername: state => state.loginUsername,
  isAuthenticated: state => !!state.token,
  loggedInUser: state => state.loggedInUser,
  signInUser: state => state.sign_in_user,
  myAccountData: state => state.myAccountData,
  accounts: state => state.accounts,
  getAccessCodeUUID: state => state.accessCodeUUID,
  getAccessCode: state => state.accessCode,
  getAccessCodeUsers: state => state.accessCodeUsers,
  getResetToken: state => state.resetToken,
  getLoginSSOError: state => state.ssoError,
  getShowSelectAccount: state => state.showSelectAccount
}

// mutations
const mutations = {

  [SIGN_IN] (state, data) {
    const { token, user } = data
    state.token = token
    state.refreshToken = data.refresh_token
    state.loggedInUser = user
  },

  [RENEW_TOKEN] (state, data) {
    const { token, user } = data
    state.token = token
    state.refreshToken = data.refresh_token
    state.loggedInUser = user
  },

  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },

  [KEEP_ALIVE] () {

  },

  [GET_ACCESS_CODE] (state, data) {
    const accounts = data.data

    if (accounts.length === 1 && accounts[0].relations.length === 0) {
      state.accounts = accounts
    } else {
      const patients = accounts
        .filter(account => account.relations.filter(relation => relation.relation_id === '1').length)
        .map(account => account.id)

      const hydratedAccounts = accounts.map(account => ({
        ...account,
        relations: getRelations(patients, account)
      }))

      const patientUsers = hydratedAccounts.filter(user => user.relations.filter(relation => relation.relation_id === '1').length)
      // If is already an user don't add it to the repondents list
      const respondentUsers = hydratedAccounts.filter(user => patientUsers.find(pu => pu.uuid === user.uuid) ? false : user.relations.filter(relation => relation.relation_id !== '1').length)
      // Order patient users first
      state.accounts = [...patientUsers, ...respondentUsers]
    }
  },

  [SET_ACCESS_CODE] (state, accessCode) {
    state.accessCode = accessCode
  },

  [SET_ACCESS_CODE_UUID] (state, uuid) {
    state.accessCodeUUID = uuid
  },

  [SET_ACCESS_CODE_USERS] (state, accessCodeUsers) {
    state.accessCodeUsers = accessCodeUsers
  },

  [VALIDATE_ACCESS_CODE] (state, data) {
    state.resetToken = data.resetToken
  },

  [ACCOUNT_SETUP] (state, data) {
    const { token, user } = data
    state.token = token
    state.refreshToken = data.refresh_token
    state.loggedInUser = user
  },

  [FIND_USERNAME] (state, data) {
    state.sign_in_user = data
  },

  [RESET_PASSWORD] () {
    // console.log('save token in global state')
  },

  [CHANGE_PASSWORD] () {
  },

  [VALIDATE_PASSWORD] () {
    // Do nothing
  },

  [RECOVER_USERNAME] () {
    // Do nothing

  },

  [MY_ACCOUNT] (state, data) {
    const primaryEmailArray = data.contact.filter(contact => contact.primary === 1 && contact.channel === 1 && contact.emr === 0)
    const primaryPhone = data.contact.filter(contact => contact.primary === 1 && contact.channel === 2 && contact.emr === 0)
    const secondaryEmailArray = data.contact.filter(contact => contact.primary === 0 && contact.channel === 1 && contact.emr === 0)
    const secondaryPhone = data.contact.filter(contact => contact.primary === 0 && contact.channel === 2 && contact.emr === 0)

    const removeDuplicates = (duplicates) => {
      const flag = {}
      const unique = []
      duplicates.forEach(elem => {
        if (!flag[elem.value]) {
          flag[elem.value] = true
          unique.push(elem)
        }
      })
      return unique
    }
    const primaryEmail = removeDuplicates(primaryEmailArray)
    const uniqueSecondaryEmails = removeDuplicates(secondaryEmailArray)
    const secondaryEmail = uniqueSecondaryEmails.filter(email => email.value !== primaryEmail[0].value)

    data.contact_primary = {
      email: (primaryEmail.length && primaryEmail[0].value.length) ? primaryEmail[0] : null,
      phone: (primaryPhone.length && primaryPhone[0].value.length) ? primaryPhone[0] : null
    }

    data.contact_secondary = {
      email: (secondaryEmail.length && secondaryEmail[0].value.length) ? secondaryEmail[0] : null,
      phone: (secondaryPhone.length && secondaryPhone[0].value.length) ? secondaryPhone[0] : null
    }

    state.myAccountData = data
    state.loggedInUser.username = data.username
  },

  [CONTACT_INFO] (state, data) {
    const contactIndex = data.primary === 1 ? 'contact_primary' : 'contact_secondary'
    const channelIndex = data.channel === 1 ? 'email' : 'phone'
    state.myAccountData[contactIndex][channelIndex] = data
  },

  [CONTACT_INFO_DELETE] (state, data) {
    const id = parseInt(data.id)
    state.myAccountData.contact = state.myAccountData.contact.filter(item => {
      return item.id !== id
    })

    if (state.myAccountData.contact_secondary.email && state.myAccountData.contact_secondary.email.id === id) {
      state.myAccountData.contact_secondary.email = null
    }

    if (state.myAccountData.contact_secondary.phone && state.myAccountData.contact_secondary.phone.id === id) {
      state.myAccountData.contact_secondary.phone = null
    }
  },

  [MY_ACCOUNT_TOGGLE_PRYMARY] (state, params) {
    if (state.myAccountData.contact_primary[params.type]) {
      state.myAccountData.contact_primary[params.type].primary = state.myAccountData.contact_primary[params.type].primary === 1 ? 0 : 1
    }
    if (state.myAccountData.contact_secondary[params.type]) {
      state.myAccountData.contact_secondary[params.type].primary = state.myAccountData.contact_secondary[params.type].primary === 1 ? 0 : 1
    }
  },

  [SAVE_HIPPA_AGREEMENT] (state, data) {
    state.loggedInUser.hippaAgreement = data
  },

  [SET_RESET_TOKEN] (state, value) {
    state.resetToken = value
  },

  [LOGIN_AS] (state, response) {
    const data = loginSuccessCallback(response, null, null)
    const { token, user } = data
    state.token = token
    state.refreshToken = data.refresh_token
    state.loggedInUser = user
  },

  [SECURITY_COMMIT_LOGIN_USERNAME] (state, username) {
    state.loginUsername = username
  },

  [SET_LOGIN_SSO_ERROR] (state, value) {
    state.ssoError = value
  },

  [SET_SHOW_SELECT_ACCOUNT] (state, showSelectAccount) {
    state.showSelectAccount = showSelectAccount
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
}
