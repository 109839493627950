import axios from 'axios'
import {
  ENDPOINTS,
  GET_EXPORTS,
  GET_EXPORTS_LOADING,
  CREATE_EXPORT,
  CREATE_EXPORT_LOADING,
  DELETE_EXPORT,
  SHOW_EXPORT_TOOLTIP,
  EXPORTS_STATUSES,
  DOWNLOAD_EXPORT
} from './constants'
import {
  downloadFile
} from '@/mixins/analytics/Queries/AnalyticsExportHelper.js'

export default {
  [GET_EXPORTS] ({ commit }) {
    commit(GET_EXPORTS_LOADING, true)
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_EXPORTS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_EXPORTS, response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(GET_EXPORTS_LOADING, false)
      })
    })
  },
  [CREATE_EXPORT] ({ commit }, params) {
    commit(CREATE_EXPORT_LOADING, true)
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.CREATE_EXPORT, params).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(CREATE_EXPORT, response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(CREATE_EXPORT_LOADING, false)
      })
    })
  },
  [DELETE_EXPORT] ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(ENDPOINTS.DELETE_EXPORT.replace('{id}', id)).then((response) => {
        if (response.data.error || (response.data.status && EXPORTS_STATUSES.includes(response.data.status))) {
          reject(response.data.error)
          return false
        }
        commit(DELETE_EXPORT, id)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [DOWNLOAD_EXPORT] (actions, { id, filename }) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.DOWNLOAD_EXPORT.replace('{id}', id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }

        downloadFile(response.data.url, filename)
          .then(() => resolve(response))
          .catch(error => reject(error))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [SHOW_EXPORT_TOOLTIP] ({ commit }) {
    commit(SHOW_EXPORT_TOOLTIP, true)
    setTimeout(() => {
      commit(SHOW_EXPORT_TOOLTIP, false)
    }, 4000)
  }
}
