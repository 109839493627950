import actions from './actions'
import mutations from './mutations'
import getters from './getters'

function initialState () {
  return {
    clinicalUsers: [],
    supervisorsLoading: false,
    supervisors: []
  }
}
const state = initialState()

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
