import store from '../../store'
import {
  ROLE_COMBO_ANY_THAN_RESPONDENT,
  ROLE_COMBO_STAFF_OR_CLINICIAN,
  ROLE_COMBO_STAFF_OR_CLINICIAN_OR_SUPERVISOR,
  ROLE_COMBO_CLINICIAN_OR_SUPERVISOR,
  USER_ROLE_ADMIN,
  USER_ROLE_DIRECTOR,
  USER_ROLE_CLINICIAN, USER_ROLE_RESPONDENT,
  USER_ROLE_STAFF,
  USER_ROLE_SUPERVISOR
} from './Roles'

import {
  PATIENT_SELECTOR_ALL,
  PATIENT_SELECTOR_MY_PATIENTS,
  PATIENT_SELECTOR_SUPERVISED_PATIENTS
} from '@/data/patientSelectorOptions'

/**
 * Determine if the user is admin
 *
 * @returns {boolean}
 */
export function loggedUserIsDirector () {
  return getLoggedUserHasRole(USER_ROLE_DIRECTOR)
}

/**
 * Determine if the user is admin
 *
 * @returns {boolean}
 */
export function loggedUserIsAdmin () {
  return getLoggedUserHasRole(USER_ROLE_ADMIN)
}

/**
 * Determine if the user is admin
 *
 * @returns {boolean}
 */
export function loggedUserIsOnlyAdmin () {
  return getLoggedUserHasOnlyRole(USER_ROLE_ADMIN)
}

/**
 * Determine if a user is a staff
 *
 * @returns {boolean}
 */
export function loggedUserIsStaff () {
  return getLoggedUserHasRole(USER_ROLE_STAFF)
}

/**
 * Determine if a user is a staff
 *
 * @returns {boolean}
 */
export function loggedUserIsOnlyStaff () {
  return getLoggedUserHasOnlyRole(USER_ROLE_STAFF)
}

/**
 * Determine if user is a Clinician
 *
 * @returns {boolean}
 */
export function loggedUserIsClinician () {
  return getLoggedUserHasRole(USER_ROLE_CLINICIAN)
}

/**
 * Determine if user is a Clinician
 *
 * @returns {boolean}
 */
export function loggedUserIsOnlyClinician () {
  return getLoggedUserHasOnlyRole(USER_ROLE_CLINICIAN)
}

/**
 * Determine user is a Supervisor
 *
 * @returns {boolean}
 */
export function loggedUserIsSupervisor () {
  return getLoggedUserHasRole(USER_ROLE_SUPERVISOR)
}

/**
 * Determine user is a Supervisor
 *
 * @returns {boolean}
 */
export function loggedUserIsOnlySupervisor () {
  return getLoggedUserHasOnlyRole(USER_ROLE_SUPERVISOR)
}

/**
 * Determine User is Respondent
 *
 * @returns {boolean}
 */
export function loggedUserIsRespondent () {
  return getLoggedUserHasRole(USER_ROLE_RESPONDENT)
}

/**
 * Determine if logged user is Clinician or Staff Person.
 *
 * @returns {boolean}
 */
export function loggedUserIsClinicianOrStaff () {
  return getLoggedUserHasAnyOfRoles(ROLE_COMBO_STAFF_OR_CLINICIAN)
}

/**
 * Determine if logged user is Clinician or supervisor Person.
 *
 * @returns {boolean}
 */
export function loggedUserIsClinicianOrSupervisor () {
  return getLoggedUserHasAnyOfRoles(ROLE_COMBO_CLINICIAN_OR_SUPERVISOR)
}

/**
 * Determine if the logged user is Clinician or Staff or Supervisor.
 *
 * @returns {boolean}
 */
export function loggedUserIsStaffOrClinicianOrSupervisor () {
  return getLoggedUserHasAnyOfRoles(ROLE_COMBO_STAFF_OR_CLINICIAN_OR_SUPERVISOR)
}

/**
 * Determine if logged User is any other than respondent.
 *
 * @returns {boolean}
 */
export function loggedUserIsAnyOtherThanRespondent () {
  return getLoggedUserHasAnyOfRoles(ROLE_COMBO_ANY_THAN_RESPONDENT)
}

/**
 * Check if current logged user has specific role
 *
 * @param roleName
 * @returns {boolean}
 */
export function getLoggedUserHasRole (roleName) {
  const roles = getLoggedUserRoles()
  return (!roles || !roles.length) ? false : roles.includes(roleName)
}

/**
 * Get Logged User Roles
 *
 * @returns {Array}
 */
export function getLoggedUserRoles () {
  const user = store.getters.loggedInUser
  const roleObject = user ? user.roles : null
  return roleObject ? roleObject.roles : []
}

/**
 * Get Logged User Roles
 *
 * @returns {Array}
 */
export function getLoggedUserPermissions () {
  const user = store.getters.loggedInUser
  const roleObject = user ? user.roles : null
  return roleObject ? roleObject.permissions : []
}

/**
 * Determine if logged user has certain permission.
 *
 * @param permissionName
 * @returns {boolean}
 */
export function getLoggedUserHasPermission (permissionName) {
  const permissions = getLoggedUserPermissions()
  const invalidPermissions = (!permissions || !permissions.length)
  return invalidPermissions ? false : permissions.includes(permissionName)
}

/**
 * Check if Current logged user has any of the roles given in an array.
 *
 * @param roleNames
 * @returns {boolean}
 */
export function getLoggedUserHasAnyOfRoles (roleNames) {
  let hasAccess = false
  for (let i = 0, len = roleNames.length; i < len; i++) {
    if (getLoggedUserHasRole(roleNames[i])) {
      hasAccess = true
      break
    }
  }
  return hasAccess
}

/**
 * Check if Current logged user has only the desired role.
 *
 * @param roleName
 * @returns {boolean}
 */
export function getLoggedUserHasOnlyRole (roleName) {
  const roles = getLoggedUserRoles()
  return (!roles || roles.length !== 1) ? false : roles[0] === roleName
}

/**
 * Check if logged user has any of Permissions
 *
 * @param permissionNames
 * @returns {boolean}
 */
export function getLoggedUserHasAnyOfPermissions (permissionNames) {
  let hasAccess = false
  for (let i = 0, len = permissionNames.length; i < len; i++) {
    if (getLoggedUserHasPermission(permissionNames.roles[i])) {
      hasAccess = true
      break
    }
  }
  return hasAccess
}

/**
 * Determine Default Patient Selector when none stored in user preferences.
 *
 * @returns string
 */
export function getDefaultPatientSelector () {
  if (loggedUserIsClinician()) {
    return PATIENT_SELECTOR_MY_PATIENTS
  }

  if (loggedUserIsSupervisor()) {
    return PATIENT_SELECTOR_SUPERVISED_PATIENTS
  }

  if (loggedUserIsStaff()) {
    return PATIENT_SELECTOR_ALL
  }
}
