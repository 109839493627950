const Roles = [
  {
    id: 'staff',
    text: 'Staff',
    visible: true
  }, {
    id: 'clinician',
    text: 'Clinician',
    visible: true
  }, {
    id: 'supervisor',
    text: 'Supervisor',
    visible: true
  }, {
    id: 'respondent',
    text: 'Respondent',
    visible: false
  }, {
    id: 'admin',
    text: 'Admin',
    visible: true
  }
]

export default Roles.filter(r => r.visible)
