import axios from 'axios'
import {
  GET_ANALYTICS_PROGRAMS,
  GET_ANALYTICS_LOCATIONS,
  ANALYTICS_GET_RECORDS,
  ANALYTICS_GET_RECORDS_LOADING,
  ANALYTICS_GET_TOTAL_RECORDS,
  ANALYTICS_GET_TOTAL_RECORDS_LOADING,
  ENDPOINTS,
  ANALYTICS_GET_SAVED_QUERIES,
  ANALYTICS_DELETE_SAVED_QUERY,
  ANALYTICS_UPDATE_SAVED_QUERY,
  ANALYTICS_SAVE_QUERY,
  ANALYTICS_GET_PROGRAMS_FILTERED,
  ANALYTICS_GET_PROGRAMS_FILTERED_LOADING,
  ANALYTICS_UPDATE_CURRENT_QUERY_CHART_INFO,
  ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE,
  ANALYTICS_SET_SELECTED_PROGRAM_CHART,
  ANALYTICS_UNASSIGNED_UUID,
  ANALYTICS_GET_SELECTED_SERVICE_LINE,
  ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS,
  ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS
} from './constants'
import {
  getAvailableProgramsOrLocations,
  getChartData,
  getAvailableMeasures,
  getUUIDType,
  getPatientType,
  getMeasureChartSettings
} from '@/mixins/analytics/Queries/AnalyticsQueryHelper'
import { AnalyticsProgramReportModel } from '@/mixins/analytics/Reports/AnalyticsProgramReportModel'
import {
  createNewCancelTokenRequest
} from '@/store/modules/patients/index.js'
import cloneDeep from 'lodash/cloneDeep'

export default {
  [GET_ANALYTICS_PROGRAMS] ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_ANALYTICS_PROGRAMS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_ANALYTICS_PROGRAMS, response.data)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [GET_ANALYTICS_LOCATIONS] ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_ANALYTICS_LOCATIONS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_ANALYTICS_LOCATIONS, response.data)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [ANALYTICS_GET_TOTAL_RECORDS] ({ commit }, params) {
    commit(ANALYTICS_GET_TOTAL_RECORDS_LOADING, true)
    params.count_only = true
    // params.mocked = true
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.ANALYTICS_GET_TOTAL_RECORDS, transformParamsForQuery(params), {
        cancelToken: createNewCancelTokenRequest('analyticsGetTotalRecords')
      }).then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        commit(ANALYTICS_GET_TOTAL_RECORDS_LOADING, false)
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(ANALYTICS_GET_TOTAL_RECORDS, response.data.count)
          resolve(response)
        }
      }).catch((error) => {
        commit(ANALYTICS_GET_TOTAL_RECORDS_LOADING, false)
        reject(error)
      })
    })
  },

  [ANALYTICS_GET_RECORDS] ({ commit, getters }, params) {
    commit(ANALYTICS_GET_RECORDS_LOADING, true)
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.ANALYTICS_GET_RECORDS, transformParamsForQuery(params)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(ANALYTICS_GET_RECORDS, Object.keys(response.data).length ? response.data.results : [])
          commit(ANALYTICS_GET_SELECTED_SERVICE_LINE, getters.getAnalyticsCurrentQuery.program_or_location_filter)
          commit(ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS, getters.getAnalyticsProgramsSelected)
          commit(ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS, getters.getAnalyticsTotalRecords)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(ANALYTICS_GET_RECORDS_LOADING, false)
      })
    })
  },

  [ANALYTICS_GET_SAVED_QUERIES] ({ commit }) {
    return axios.get(ENDPOINTS.ANALYTICS_QUERIES).then(response => {
      commit(ANALYTICS_GET_SAVED_QUERIES, response.data)
    })
  },

  [ANALYTICS_DELETE_SAVED_QUERY] ({ commit }, savedQueryId) {
    const url = ENDPOINTS.ANALYTICS_QUERY_RESOURCE.replace('{id}', savedQueryId)
    return axios.delete(url).then((result) => {
      if (result.data.result === true) {
        commit(ANALYTICS_DELETE_SAVED_QUERY, savedQueryId)
      }
    })
  },

  [ANALYTICS_UPDATE_SAVED_QUERY] ({ commit, state }, data) {
    if (data.hasOwnProperty('from_state') && !data.from_state) { // eslint-disable-line no-prototype-builtins
      data.chart_info = getChartingCustomizationInformation(state)
    }

    const url = ENDPOINTS.ANALYTICS_QUERY_RESOURCE.replace('{id}', data.id)
    return axios.put(url, data).then((result) => {
      commit(ANALYTICS_UPDATE_SAVED_QUERY, result.data)
    })
  },

  [ANALYTICS_SAVE_QUERY] ({ commit, state }, data) {
    data.chart_info = getChartingCustomizationInformation(state)
    return axios.post(ENDPOINTS.ANALYTICS_QUERIES, data).then((result) => {
      commit(ANALYTICS_SAVE_QUERY, result.data)
    })
  },

  /**
   * This works for current Saved query loaded in memory.
   *
   * @param commit
   * @param state
   * @param dispatch
   */
  [ANALYTICS_UPDATE_CURRENT_QUERY_CHART_INFO] ({ commit, state, dispatch }) {
    // There is no a saved query, abort.
    if (state.analyticsSavedQueryLoaded) {
      addChartInfoToQuery(commit, state)
      dispatch(ANALYTICS_UPDATE_SAVED_QUERY, state.analyticsSavedQueryLoaded)
    }
  },

  /**
   * Get Information about Programs with Data stored in the analytics service.
   *
   * @param commit
   * @param criteria
   * @returns {Promise<unknown>}
   */
  async [ANALYTICS_GET_PROGRAMS_FILTERED] ({ commit, state, getters }, criteria) {
    const transformedQuery = { ...transformCriteriaForQuery(criteria) }

    const uuidType = getUUIDType(transformedQuery)
    const patientType = getPatientType(transformedQuery)

    commit(ANALYTICS_GET_PROGRAMS_FILTERED_LOADING, true)
    const availableProgramsOrLocationsQueryResult = await getAvailableProgramsOrLocations(transformedQuery, uuidType)
    const availableMeasuresQueryResult = await getAvailableMeasures(transformedQuery)
    const uuids = availableProgramsOrLocationsQueryResult.results ? availableProgramsOrLocationsQueryResult.results.map(a => a[uuidType]) : []
    const measuresUUIDS = availableMeasuresQueryResult.results ? availableMeasuresQueryResult.results.map(a => a.owl_measure_uuid) : []
    const measureChartSettings = await getMeasureChartSettings(measuresUUIDS)
    const measuresWithGraphs = getters.getScorableAnalyticMeasures ? getters.getScorableAnalyticMeasures.filter(item => item.analytics_graph === '1').map(i => i.uuid) : []
    const filteredMeasures = measuresWithGraphs.filter(item => transformedQuery.measure.includes(item))
    const resultsByPatientType = await getChartData(uuids, transformedQuery, uuidType, filteredMeasures)
    const analyticsProgramCollection = []

    uuids.forEach(uuid => {
      const programData = uuidType === 'program_uuid'
        ? state.analyticPrograms.find(item => item.uuid === uuid)
        : state.analyticLocations.find(item => item.uuid === uuid)

      if (programData) {
        const programOrLocationQueriesResults = resultsByPatientType.hasOwnProperty(uuid) ? resultsByPatientType[uuid] : {} // eslint-disable-line no-prototype-builtins
        const measuresWithResults = programData ? programData.measures.filter(m => measuresUUIDS.includes(m.uuid)) : []
        if (programOrLocationQueriesResults && Object.keys(programOrLocationQueriesResults).length) {
          const analyticsProgram = new AnalyticsProgramReportModel(programData, programOrLocationQueriesResults, state.analyticsSavedQueryLoaded, measuresWithResults, measureChartSettings, patientType, state.analyticsCustomDateFilterLabel) // remove uuidType if not using
          analyticsProgramCollection.push(analyticsProgram)
        }
      }
    })

    commit(ANALYTICS_GET_PROGRAMS_FILTERED_LOADING, false)
    commit(ANALYTICS_GET_PROGRAMS_FILTERED, analyticsProgramCollection)
  },

  async [ANALYTICS_SET_SELECTED_PROGRAM_CHART] ({ commit }, newValue) {
    commit(ANALYTICS_SET_SELECTED_PROGRAM_CHART, newValue)
  }
}
/**
 * Transform query params for the API call only
 *
 * @param {Object} params
 * @returns {Object}
 */
const transformCriteriaForQuery = (criteria) => {
  const transformedCriteria = cloneDeep(criteria)

  if (transformedCriteria.location_uuid) {
    const locationIndex = transformedCriteria.location_uuid.indexOf(ANALYTICS_UNASSIGNED_UUID)

    if (locationIndex > -1) {
      transformedCriteria.location_uuid[locationIndex] = { condition: 'is_null' }
    }
  }

  if (transformedCriteria.clinicians) {
    const clinicianIndex = transformedCriteria.clinicians.indexOf(ANALYTICS_UNASSIGNED_UUID)

    if (clinicianIndex > -1) {
      transformedCriteria.clinicians[clinicianIndex] = { condition: 'is_null' }
    }
  }

  if (transformedCriteria.completedType === 'allTime') {
    if (transformedCriteria.completed) {
      delete transformedCriteria.completed.less_than
      delete transformedCriteria.completed.greater_than
      transformedCriteria.completed = Object.assign(transformedCriteria.completed, {
        condition: 'not',
        value: null
      })
    } else {
      transformedCriteria.completed = {
        condition: 'not',
        value: null
      }
    }
  }

  delete transformedCriteria.completedType
  delete transformedCriteria.customRangeDate
  delete transformedCriteria.dateFilterMode

  return transformedCriteria
}

/**
 * Transform query params for the API call only
 *
 * @param {Object} params
 * @returns {Object}
 */
const transformParamsForQuery = (params) => {
  return {
    ...params,
    criteria: transformCriteriaForQuery(params.criteria)
  }
}

/**
 * Add a chart_info property to the given object
 *
 * @param state
 * @param savedQuery
 * @returns {*}
 */
function addChartInfoToQuery (commit, state) {
  const chartInfo = getChartingCustomizationInformation(state)
  return commit(ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE, {
    attribute: 'chart_info',
    value: chartInfo,
    objectName: 'analyticsSavedQueryLoaded'
  })
}

/**
 * Get all charts information
 *
 * @param state
 * @returns {{}|boolean}
 */
function getChartingCustomizationInformation (state) {
  // There is no programs to get data from.
  if (!state.analyticsProgramsFiltered || !state.analyticsProgramsFiltered.length) {
    return false
  }

  const chartInfo = {}
  for (let i = 0, len = state.analyticsProgramsFiltered.length; i < len; i++) {
    const programReportModel = state.analyticsProgramsFiltered[i]
    chartInfo[programReportModel.uuid] = programReportModel.getConfigurationForExport()
  }
  return chartInfo
}
