import {
  SIGN_OUT,
  TOGGLE_SIDEBAR_STATUS,
  HIDE_SIDEBAR,
  SET_DETAIL_VIEW_OPENED,
  SET_SIDEBAR_ADD_STATUS,
  TOGGLE_MY_ACCOUNT_STATUS,
  SET_ACTIVE_FORM_DIRTY,
  SET_CURRENT_BROWSER,
  SET_HOME_TABS,
  DATE_SELECTOR_DATE,
  HOME_TABS,
  SET_COMING_FROM_PROGRAM,
  SET_DETAIL_TO_OPEN,
  SET_HOME_TAB_SCROLL_POSITION,
  SET_SCROLL_POSITION_BY_TAB,
  SET_PATIENTS_TABLE_PARAMS,
  SETTINGS_OPEN,
  SET_SEARCH_STRING,
  SET_SORT_OPTIONS

} from './constants'

function initialState () {
  return {
    dateSelectorDate: new Date(),
    currentBrowser: {},
    homeTab: '',
    homeTabsScrollPosition: [],
    patientTableParams: null,
    openedByClick: false,
    settingsOpen: true,
    comingFromProgram: null,
    detailToOpen: null,
    sidebarAddIsOpen: false,
    sidebarIsOpen: false,
    myAccountIsOpen: false,
    detailViewOpened: false,
    activeFormDirty: false,
    searchString: '',
    savedScrollPositionByTab: [
      { tab: 'today', index: 0 },
      { tab: 'week', index: 0 },
      { tab: 'programs', index: 0 },
      { tab: 'new-results', index: 0 }
    ],
    sortOptions: {
      tab: '',
      data: [
        {
          colId: null,
          sort: null
        }
      ]
    }
  }
}
// initial state
const state = initialState()

// actions
const actions = {
  SET_SIDEBAR_ADD_STATUS ({ commit }, event) {
    commit(SET_SIDEBAR_ADD_STATUS, event)
  },
  TOGGLE_SIDEBAR_STATUS ({ commit }) {
    commit(TOGGLE_SIDEBAR_STATUS)
  },
  HIDE_SIDEBAR ({ commit }) {
    commit(HIDE_SIDEBAR)
  },
  TOGGLE_MY_ACCOUNT_STATUS ({ commit }) {
    commit(TOGGLE_MY_ACCOUNT_STATUS)
  },
  SET_CURRENT_BROWSER ({ commit }) {
    let isChrome = navigator.userAgent.indexOf('Chrome') > -1
    const isExplorer = navigator.userAgent.indexOf('MSIE') > -1 || (!!window.MSInputMethodContext && !!document.documentMode)
    const isFirefox = navigator.userAgent.indexOf('Firefox') > -1
    let isSafari = navigator.userAgent.indexOf('Safari') > -1
    const isOpera = navigator.userAgent.toLowerCase().indexOf('op') > -1
    if ((isChrome) && (isSafari)) {
      isSafari = false
    }
    if ((isChrome) && (isOpera)) {
      isChrome = false
    }

    commit(SET_CURRENT_BROWSER, { isChrome, isExplorer, isFirefox, isSafari, isOpera })
  },
  SET_ACTIVE_FORM_DIRTY ({ commit }, value) {
    commit(SET_ACTIVE_FORM_DIRTY, value)
  }
}

// getters
const getters = {
  getCurrentBrowser: state => state.currentBrowser,
  getDateSelectorDate: state => state.dateSelectorDate,
  getSidebarAddIsOpen: state => state.sidebarAddIsOpen,
  getSettingsOpen: state => state.settingsOpen,
  getHomeTab: state => state.homeTab,
  getTabsScrollPosition: state => state.homeTabsScrollPosition,
  getPatientTableParams: state => state.patientTableParams,
  getDetailToOpen: state => state.detailToOpen,
  getSidebarIsOpen: state => state.sidebarIsOpen,
  getMyAccountIsOpen: state => state.myAccountIsOpen,
  getDetailVewOpened: state => state.detailViewOpened,
  getComingFromProgram: state => state.comingFromProgram,
  getActiveFormDirty: state => state.activeFormDirty,
  getSearchString: state => state.searchString,
  getSavedScrollPositionByTab: state => state.savedScrollPositionByTab,
  getSortOptions: state => state.sortOptions
}

// mutations
const mutations = {
  [SET_SIDEBAR_ADD_STATUS] (state, event) {
    const found = event.target && event.target.classList && event.target.classList.contains('button-add-target')
    if (event.type === 'click') {
      const isItem = event.target && event.target.classList && event.target.classList.contains('dropdown-item')
      if ((state.openedByClick && !found) || isItem) {
        state.openedByClick = state.sidebarAddIsOpen = false
        return
      }
      state.openedByClick = !state.openedByClick && state.sidebarAddIsOpen
      return
    }

    if (event.type === 'mouseover') {
      state.sidebarAddIsOpen = true
      return
    }
    if (event.type === 'mouseout' && !state.openedByClick) {
      const contextualDropdown = event.relatedTarget && event.relatedTarget.classList && event.relatedTarget.classList.contains('contextual-dropdown')
      const dropdownItem = event.relatedTarget && event.relatedTarget.classList && event.relatedTarget.classList.contains('dropdown-item')
      if (!contextualDropdown && !dropdownItem) {
        state.sidebarAddIsOpen = false
      }
    }
  },
  [TOGGLE_MY_ACCOUNT_STATUS] (state) {
    state.myAccountIsOpen = !state.myAccountIsOpen
  },
  [TOGGLE_SIDEBAR_STATUS] (state) {
    state.sidebarIsOpen = !state.sidebarIsOpen
  },
  [HIDE_SIDEBAR] (state) {
    state.sidebarIsOpen = false
  },
  [SET_ACTIVE_FORM_DIRTY] (state, value) {
    state.activeFormDirty = !!value
  },

  [SET_DETAIL_VIEW_OPENED] (state, value) {
    state.detailViewOpened = value
  },

  [SET_CURRENT_BROWSER] (state, value) {
    state.currentBrowser = value
  },

  [SET_HOME_TABS] (state, tab) {
    if (HOME_TABS.includes(tab)) {
      state.homeTab = tab
    }
  },

  [DATE_SELECTOR_DATE] (state, date) {
    state.dateSelectorDate = date
  },

  [SET_COMING_FROM_PROGRAM] (state, value) {
    state.comingFromProgram = value
  },

  [SET_DETAIL_TO_OPEN] (state, value) {
    state.detailToOpen = value
  },

  [SETTINGS_OPEN] (state) {
    state.settingsOpen = !state.settingsOpen
  },

  [SET_HOME_TAB_SCROLL_POSITION] (state, data) {
    state.homeTabsScrollPosition[data.tab] = data.value
  },

  [SET_SCROLL_POSITION_BY_TAB] (state, data) {
    state.savedScrollPositionByTab = state.savedScrollPositionByTab.map(obj => {
      if (obj.tab === data.tab) {
        return { ...obj, index: data.index }
      }
      return obj
    })
  },

  [SET_PATIENTS_TABLE_PARAMS] (state, data) {
    state.patientTableParams = data
  },

  [SIGN_OUT] (state) {
    const s = initialState()
    state.isAuthenticated = false
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },

  [SET_SEARCH_STRING] (state, string) {
    state.searchString = string
  },

  [SET_SORT_OPTIONS] (state, data) {
    state.sortOptions = data
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
}
