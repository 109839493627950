export const GENERAL_SETTINGS = 'settings/GENERAL_SETTINGS'
export const GET_UI_SETTINGS = 'settings/GET_UI_SETTINGS'
export const UI_SETTINGS_LOADED = 'settings/UI_SETTINGS_LOADED'
export const UPDATE_PATIENT_LIST_VIEW = 'settings/UPDATE_PATIENT_LIST_VIEW'
export const TOGGLE_MEASURE_LIST_ITEM_OPEN = 'settings/TOGGLE_MEASURE_LIST_ITEM_OPEN'
export const SIGN_OUT = 'settings/SIGN_OUT'
export const UPDATE_UI_SETTING_VALUE = 'settings/UPDATE_UI_SETTING_VALUE'

export const ENDPOINTS = {
  GENERAL_SETTINGS: 'settings/general',
  UI_SETTINGS: 'settings/ui-settings'
}
