import axios from 'axios'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function initialState () {
  return {
    activeTab: 'measures',
    activeAssignment: {},
    assignments: [],
    assignmentsLoading: false,
    loadingGetPatient: false,
    measureQuestions: {},
    multiMeasureQuestions: {},
    multiMeasureQuestionsLoading: false,
    patientInsurance: {},
    getPatientInsuranceLoading: {},
    setPatientInsuranceLoading: {},
    patients: [],
    patientsByProgram: {},
    patientsCount: 0,
    patientsLoading: false,
    patientsNewResults: [],
    pdfStatus: {},
    scheduledPatientsByDate: {},
    scheduledPatientsByDateLoading: false,
    scheduledPatientsBySingleDate: [],
    singlePatient: [],
    singlePatientMeasuresList: [],
    singlePatientProgram: {},
    singlePatientPrograms: [],
    singlePatientProgramsLoading: false,
    singlePatientReferrerRoute: '',
    teamId: false,
    clinicianAssignments: [],
    clinicianAssignmentsLoading: false
  }
}

/**
 * Create new request cancel token.
 *
 * @param alias
 * @returns {CancelToken}
 */
const CancelToken = axios.CancelToken
const cancelRequestObjects = []
export function createNewCancelTokenRequest (alias) {
  return new CancelToken(function executor (c) {
    cancelRequestObjects[alias] = c
  })
}

const state = initialState()
export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
