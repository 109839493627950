export const ACTION_OPEN_AUDITION_ENHANCE_MODAL = 'ACTION_OPEN_AUDITION_ENHANCE_MODAL'
export const ACTION_CLOSE_AUDITION_ENHANCE_MODAL = 'ACTION_CLOSE_AUDITION_ENHANCE_MODAL'
export const MUTATION_SET_MODAL_OPENED = 'MUTATION_SET_MODAL_OPEN'
export const MUTATION_SET_MODAL_TEMPLATE = 'MUTATION_SET_MODAL_TEMPLATE'
export const MUTATION_SET_ENHANCE_AUDITION_DATA = 'MUTATION_SET_ENHANCE_AUDITION_MODAL_DATA'
export const MUTATION_SET_ENHANCE_AUDITION_VARIABLES = 'MUTATION_SET_ENHANCE_AUDITION_VARIABLES'
export const CONTACT_INFO_DETAIL = 'applicationState/CONTACT_INFO_DETAIL'
export const UPDATE_CONTACT_INFO_DETAIL = 'applicationState/UPDATE_CONTACT_INFO_DETAIL'
export const UPDATE_RECENTLY_SELECTED = 'applicationState/MULTI_SELECT_RECENTLY_SELECTED'
export const MUTATION_SET_MODAL_CONFIG = 'applicationState/MUTATION_SET_MODAL_CONFIG'
export const UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA = 'applicationState/UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA'
export const PATIENT_ROW_ACTIVE = 'applicationState/PATIENT_ROW_ACTIVE'
export const HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT = 'applicationState/HIDE_CONTACT_INFO_DETAIL'
export const CHANGE_SETUP_DONE_PROMISE_VALUE = 'applicationState/CHANGE_SETUP_DONE_PROMISE_VALUE'
