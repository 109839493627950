import { parseISO, getUnixTime, getTime } from 'date-fns'
import sum from 'lodash/sum'

const makeUnixTimestampFromISO = (date) => date === '1970-01-01' ? 0 : getUnixTime(parseISO(date))

const makeJSTimestampFromISO = (date) => getTime(parseISO(date))

export default {
  getAnalyticsUsageGlobalParams: state => state.usageGlobalParams,
  getAnalyticsUsageTodayParams: state => state.usageTodayParams,
  getAnalyticsUsageActivePatientsData: state => state.activePatients.data,
  getAnalyticsUsageActivePatientsLoadingState: state => state.activePatients.loading,
  getAnalyticsUsageActivePatientsHasErrors: state => state.activePatients.hasErrors,
  getAnalyticsUsageActivePatientsParams: state => ({
    criteria: {
      date_range: {
        greater_than: makeUnixTimestampFromISO(state.usageGlobalParams.start),
        less_than: makeUnixTimestampFromISO(state.usageGlobalParams.end)
      },
      graph_interval: state.usageGlobalParams.graph_interval_patientusage
    }
  }),
  getAnalyticsUsageActivePatientsSeries: state => [
    {
      data: state.activePatients.data.map(item => [makeJSTimestampFromISO(item.end_date), item.active_respondents]),
      name: 'totalActive',
      start_dates: state.activePatients.data.map(item => makeJSTimestampFromISO(item.start_date))
    },
    {
      data: state.activePatients.data.map(item => [makeJSTimestampFromISO(item.end_date), item.new_respondents]),
      name: 'new',
      start_dates: state.activePatients.data.map(item => makeJSTimestampFromISO(item.start_date))
    }
  ],

  getAnalyticsUsageActivePatientsTodayData: state => state.activePatientsToday.data,
  getAnalyticsUsageActivePatientsTodayLoadingState: state => state.activePatientsToday.loading,
  getAnalyticsUsageActivePatientsTodayHasErrors: state => state.activePatientsToday.hasErrors,
  getAnalyticsUsageActivePatientsTodaySeries: state => ([
    {
      data: state.activePatientsToday.data.map(item => [
        {
          name: 'notInvitedToSignIn',
          y: item.percent_not_invited
        },
        {
          name: 'needInviteFollowup',
          y: item.percent_invited
        },
        {
          name: 'canSignInThemselves',
          y: item.percent_finished_account_setup
        }
      ]).flat(1),
      name: 'activePatientsToday'
    }
  ]),

  getAnalyticsUsageMeasureActivityData: state => state.measureActivity.data,
  getAnalyticsUsageMeasureActivityLoadingState: state => state.measureActivity.loading,
  getAnalyticsUsageMeasureActivityHasErrors: state => state.measureActivity.hasErrors,
  getAnalyticsUsageMeasureActivityParams: state => ({
    criteria: {
      due_date_range: {
        greater_than: makeUnixTimestampFromISO(state.usageGlobalParams.start),
        less_than: makeUnixTimestampFromISO(state.usageGlobalParams.end)
      },
      graph_interval: state.usageGlobalParams.graph_interval_patientusage
    },
    query: 'measure_completion'
  }),
  getAnalyticsUsageMeasureActivitySeries: state => [
    {
      data: state.measureActivity.data.map(item => [makeJSTimestampFromISO(item.end_date), item.measures_due]),
      name: 'measuresDue',
      start_dates: state.measureActivity.data.map(item => makeJSTimestampFromISO(item.start_date))
    },
    {
      data: state.measureActivity.data.map(item => [makeJSTimestampFromISO(item.end_date), item.measures_completed]),
      name: 'measuresCompleted',
      start_dates: state.measureActivity.data.map(item => makeJSTimestampFromISO(item.start_date))
    }
  ],

  getAnalyticsUsageMeasureActivityTodayData: state => state.measureActivityToday.data,
  getAnalyticsUsageMeasureActivityTodayLoadingState: state => state.measureActivityToday.loading,
  getAnalyticsUsageMeasureActivityTodayHasErrors: state => state.measureActivityToday.hasErrors,
  getAnalyticsUsageMeasureActivityTodayParams: state => ({
    query: 'measures_due'
  }),
  getAnalyticsUsageMeasureActivityTodaySeries: state => {
    const activeRespondents = state.measureActivityToday.data.active_respondents
    const patientsWithMeasuresDue = state.measureActivityToday.data.patients_with_measures_due
    const percentWithMeasuresDue = activeRespondents > 0 ? Math.round((patientsWithMeasuresDue / activeRespondents) * 100) : 0

    return [{
      data: [
        {
          name: 'percentWithMeasuresDue',
          y: percentWithMeasuresDue
        },
        {
          name: 'percentWithMeasuresCompleted',
          y: 100 - percentWithMeasuresDue
        }
      ],
      name: 'measureActivityToday'
    }]
  },
  getAnalyticsUsageMeasureCompletionRateSeries: state => [
    {
      data: state.measureActivity.data.map(item => [makeJSTimestampFromISO(item.end_date), Math.round(item.percent_completed)]),
      name: 'measureCompletionRate',
      start_dates: state.measureActivity.data.map(item => makeJSTimestampFromISO(item.start_date))
    }
  ],
  getAnalyticsUsageMeasureCompletionTodayLoadingState: state => state.measureCompletionToday.loading,
  getAnalyticsUsageMeasureCompletionTodayHasErrors: state => state.measureCompletionToday.hasErrors,
  getAnalyticsUsageMeasureCompletionTodayParams: state => ({
    criteria: {
      graph_interval: state.usageTodayParams.graph_interval_patientusage
    },
    query: 'measure_completion'
  }),
  getAnalyticsUsageMeasureCompletionTodaySeries: state => {
    const measuresDue = sum(state.measureCompletionToday.data.map(item => item.measures_due))
    const measuresCompleted = sum(state.measureCompletionToday.data.map(item => item.measures_completed))
    const percentCompleted = measuresDue > 0 ? Math.round((measuresCompleted / measuresDue) * 100) : 0
    const percentDue = 100 - percentCompleted

    return [{
      data: [
        {
          name: 'percentIncomplete',
          y: percentDue
        },
        {
          name: 'percentCompleted',
          y: percentCompleted
        }
      ],
      name: 'measureCompletionToday'
    }]
  },
  getAnalyticsUsageClinicalSignInsData: state => state.clinicalSignIns.data,
  getAnalyticsUsageClinicalSignInsLoadingState: state => state.clinicalSignIns.loading,
  getAnalyticsUsageClinicalSignInsHasErrors: state => state.clinicalSignIns.hasErrors,
  getAnalyticsUsageClinicalSignInsParams: state => ({
    criteria: {
      date_range: {
        greater_than: makeUnixTimestampFromISO(state.usageGlobalParams.start),
        less_than: makeUnixTimestampFromISO(state.usageGlobalParams.end)
      },
      graph_interval: state.usageGlobalParams.graph_interval
    }
  }),
  getAnalyticsUsageClinicalSignInsSeries: state => [
    {
      name: 'signinsClinicians',
      data: state.clinicalSignIns.data.map(item => [makeJSTimestampFromISO(item.start_date), item.clinician_logins]),
      end_dates: state.clinicalSignIns.data.map(item => makeJSTimestampFromISO(item.end_date))
    },
    {
      name: 'signinsSupervisors',
      data: state.clinicalSignIns.data.map(item => [makeJSTimestampFromISO(item.start_date), item.supervisor_logins]),
      end_dates: state.clinicalSignIns.data.map(item => makeJSTimestampFromISO(item.end_date))
    },
    {
      name: 'signinsStaff',
      data: state.clinicalSignIns.data.map(item => [makeJSTimestampFromISO(item.start_date), item.staff_logins + item.admin_logins]),
      end_dates: state.clinicalSignIns.data.map(item => makeJSTimestampFromISO(item.end_date))
    }
  ],
  getAnalyticsUsageMeasuresData: state => state.measures.data,
  getAnalyticsUsageMeasuresLoadingState: state => state.measures.loading,
  getAnalyticsUsageMeasuresHasErrors: state => state.measures.hasErrors,
  getAnalyticsUsageMeasuresParams: state => ({
    criteria: {
      results_to_include: 'all_results',
      due_date: {
        greater_than: makeUnixTimestampFromISO(state.usageGlobalParams.start),
        less_than: makeUnixTimestampFromISO(state.usageGlobalParams.end)
      },
      graph_interval: state.usageGlobalParams.graph_interval
    },
    aggregate: {
      column: 'completed',
      function: 'count'
    },
    group_by: [
      {
        column: 'owl_measure_uuid',
        function: 'count',
        include_column: true
      },
      {
        column: 'measure_name',
        function: 'group_only',
        include_column: true
      },
      {
        column: 'measure_abbreviation',
        function: 'group_only',
        include_column: true
      }
    ]
  }),
  getAnalyticsUsageMeasuresSeries: state => [
    {
      name: 'measuresDue',
      data: state.measures.data.map((item, i) => ({
        element_id: i,
        category: item.measure_abbreviation,
        y: item.count_owl_measure_uuid
      }))
    },
    {
      name: 'measuresCompleted',
      data: state.measures.data.map((item, i) => ({
        element_id: i,
        category: item.measure_abbreviation,
        percentage: item.count_completed === 0 ? 0 : Math.round((item.count_completed / item.count_owl_measure_uuid) * 100),
        y: item.count_completed
      }))
    }
  ]
}
