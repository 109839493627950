export const LOCAL_STORAGE_USER_TOKEN = 'user-token'
export const LOCAL_STORAGE_USER_TOKEN_REFRESH = 'user-token-refresh'
export const LOCAL_STORAGE_USER_KEY_NAME = 'owl-user'
export const RESET_RESPONDENT_USER_PASSWORD = 'security/RESET_RESPONDENT_USER_PASSWORD'
export const SIGN_OUT = 'security/SIGN_OUT'
export const RENEW_TOKEN = 'security/RENEW_TOKEN'
export const SIGN_IN = 'security/SIGN_IN'
export const GET_ACCESS_CODE = 'security/GET_ACCESS_CODE'
export const VALIDATE_ACCESS_CODE = 'security/VALIDATE_ACCESS_CODE'
export const VALIDATE_TOKEN = 'security/VALIDATE_TOKEN'
export const ACCOUNT_SETUP = 'security/ACCOUNT_SETUP'
export const FIND_USERNAME = 'security/FIND_USERNAME'
export const RESET_PASSWORD = 'security/RESET_PASSWORD'
export const CHANGE_PASSWORD = 'security/CHANGE_PASSWORD'
export const VALIDATE_PASSWORD = 'security/VALIDATE_PASSWORD'
export const RECOVER_USERNAME = 'security/RECOVER_USERNAME'
export const MY_ACCOUNT = 'security/MY_ACCOUNT'
export const MY_ACCOUNT_TOGGLE_PRYMARY = 'security/MY_ACCOUNT_TOGGLE_PRYMARY'
export const CONTACT_INFO = 'security/CONTACT_INFO'
export const CONTACT_INFO_DELETE = 'security/CONTACT_INFO_DELETE'
export const SAVE_HIPPA_AGREEMENT = 'security/SAVE_HIPPA_AGREEMENT'
export const LOGIN_AS = 'security/LOGIN_AS'
export const KEEP_ALIVE = 'security/KEEP_ALIVE'
export const SECURITY_COMMIT_LOGIN_USERNAME = 'security/SET_LOGIN_USERNAME'
export const SET_ACCESS_CODE = 'security/SET_ACCESS_CODE'
export const SET_ACCESS_CODE_UUID = 'security/SET_ACCESS_CODE_UUID'
export const SET_ACCESS_CODE_USERS = 'security/SET_ACCESS_CODE_USERS'
export const SET_RESET_TOKEN = 'security/SET_RESET_TOKEN'
export const SET_LOGIN_SSO_ERROR = 'security/SET_LOGIN_SSO_ERROR'
export const URL_TO_REDIRECT_AFTER_LOGIN = 'urlToRedirectAfterLogin'
export const SET_SHOW_SELECT_ACCOUNT = 'security/SET_SHOW_SELECT_ACCOUNT'

export const ENDPOINTS = {
  FIND_USERNAME: 'security/username',
  SIGN_IN: 'security/login',
  SIGN_OUT: 'security/logout',
  GET_ACCESS_CODE: 'security/generate-access-code',
  SECURE_GET_ACCESS_CODE: 'security/secure-generate-access-code',
  VALIDATE_ACCESS_CODE: 'security/validate-access-code',
  VALIDATE_TOKEN: 'security/validate-token',
  ACCOUNT_SETUP: 'security/account-setup',
  RESET_PASSWORD: 'security/reset-password',
  RECOVER_USERNAME: 'security/recover-username',
  VALIDATE_PASSWORD: 'security/validate-password',
  CHANGE_PASSWORD: 'security/change-password',
  MY_ACCOUNT: 'users/{id}',
  RENEW_TOKEN: 'security/renew-token',
  GET_SESSION: 'security/get-session',
  CONTACT_INFO: 'users/{user_id}/contact-info/{id}',
  CONTACT_INFO_CREATE: 'users/{user_id}/contact-info',
  KEEP_ALIVE: 'security/keep-alive',
  RESET_CLINICAL_USER_PASSWORD: 'security/reset-clinical-user-password',
  RESET_RESPONDENT_USER_PASSWORD: 'security/reset-user-password'
}
