import { DateTimeHelper } from '@/mixins/DateTimeHelper'
/**
 * Array sort Helper, sorts elements by recentlySelected and Name attribute.
 * @param a
 * @param b
 * @returns {number}
 */
export function sortByRecentlyUpdatedCallback (a, b) {
  if (a.recentlySelected && b.recentlySelected) {
    return a.last_name.localeCompare(b.last_name)
  }

  if (a.recentlySelected) {
    return -1
  }

  if (b.recentlySelected) {
    return 1
  }

  return a.last_name.localeCompare(b.last_name)
}

/**
 * Array sort Helper, sorts elements by last name first and then for first name
 * @param a
 * @param b
 * @returns {number}
 */
export function sortByLastNameAndFirstName (a, b) {
  const aSort = `${a.last_name} ${a.first_name}`
  const bSort = `${b.last_name} ${b.first_name}`
  return aSort.localeCompare(bSort)
}

/**
 * Object sort Helper, sorts elements by key
 * @param a
 * @param b
 * @returns {Array}
 */
export function sortObjectByKey (unordered) {
  const ordered = []
  Object.keys(unordered).sort((a, b) => {
    return fixMonth(a).localeCompare(fixMonth(b))
  }).forEach(key => {
    ordered[key] = unordered[key]
  })
  return ordered
}

function fixMonth (date) {
  if (!date) return ''
  const t = date.split('-')
  return t[0] + '-' + ('0' + t[1]).slice(-2)
}

/**
 * Object sort Helper, sorts elements by key
 * @param a
 * @param b
 * @returns {Array}
 */
export function sortByFieldAndFullName (a, b, field, direction = 'asc') {
  const aSort = DateTimeHelper.methods.$toLocal(a[field], 'YYYYMMDD')
  const bSort = DateTimeHelper.methods.$toLocal(b[field], 'YYYYMMDD')
  const dateSort = !aSort || !bSort ? 1 : direction === 'asc' ? aSort.localeCompare(bSort) : bSort.localeCompare(aSort)
  // if same date then sort by full name
  return !dateSort ? sortByLastNameAndFirstName(a, b) : dateSort
}
