import {
  GET_PATIENT_USERS,
  GET_PATIENT_USER,
  PATIENT_USERS_LOGOUT,
  CHANGE_PATIENT_USER_STATUS,
  SAVE_PATIENT_USER,
  UPDATE_PATIENT_USER
} from './constants'
import { initialState } from './index'

export default {

  [GET_PATIENT_USERS]: (state, patientUsers) => {
    state.patientUsers = patientUsers
  },

  [CHANGE_PATIENT_USER_STATUS]: (state, data) => {
    const i = state.patientUsers.findIndex(p => Number(p.id) === Number(data.id))
    if (i !== -1) {
      state.patientUsers[i].account_information.status = data.status
      state.patientUsers[i].account_information.status_changed_at = data.status_changed_at
      state.patientUsers[i].account_information.last_activity = data.last_activity
    }
  },

  [SAVE_PATIENT_USER]: (state, patientUser) => {
    state.patientUsers.unshift(patientUser)
  },

  [UPDATE_PATIENT_USER]: (state, patientUser) => {
    const i = state.patientUsers.findIndex(l => Number(l.id) === Number(patientUser.id))
    if (i !== -1) {
      const oldpatientUser = state.patientUsers[i]
      for (const k in oldpatientUser) {
        if (oldpatientUser.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          oldpatientUser[k] = patientUser[k]
        }
      }
    }
  },

  [GET_PATIENT_USER]: (state, patientUser) => {
    if (patientUser.contact_information.length && patientUser.contact_information[0].channel === '2') {
      const tmp = patientUser.contact_information[0]
      patientUser.contact_information[0] = patientUser.contact_information[1]
      patientUser.contact_information[1] = tmp
    }
    if (patientUser.emr_contact_information.length && patientUser.emr_contact_information[0].channel === '2') {
      const tmp = patientUser.emr_contact_information[0]
      patientUser.emr_contact_information[0] = patientUser.emr_contact_information[1]
      patientUser.emr_contact_information[1] = tmp
    }
    state.singlePatientUser = patientUser
  },

  [PATIENT_USERS_LOGOUT]: (state) => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}
