import axios from 'axios'
import moment from 'moment'
import Helpers from '../../../mixins/Helpers'
import {
  UPDATE_PAGE_QUESTION_VALUE,
  GET_SESSIONS,
  FINISH_SESSION,
  JUST_FINISHED_SESSION,
  GET_SINGLE_MEASURE,
  GET_MEASURE_LIBRARY,
  GET_MEASURE_LIBRARY_DETAIL,
  GET_SCHEDULING_OPTIONS,
  LOADING_SCHEDULING_OPTIONS,
  RESET_SCHEDULING_OPTIONS,
  REMOVE_SCHEDULED_MEASURE,
  RESET_MEASURES_ASSIGNED_DURING_SESSION,
  REMOVE_MEASURE_ASSIGNED_DURING_SESSION,
  SCHEDULE_MEASURE,
  SET_CUSTOM_MEASURE,
  TOGGLE_LIBRARY_STARRED,
  TOGGLE_LIBRARY_SELECTED,
  SAVE_HIPPA_AGREEMENT,
  SIGN_OUT,
  SET_CHECKBOX_QUESTION_VALUE,
  SET_QUESTION_DEFAULT_OPEN,
  SAVE_MEASURE_QUESTION,
  RESET_ACTIVE_MEASURE,
  CLINICIAN_SAVE_MEASURE_QUESTION,
  SAVE_AUDITION_PAGE,
  GET_AUDITION_MEASURE_REPORT,
  RESET_AUDITION_REPORT,
  SET_AUDITION_MODE,
  ENDPOINTS
} from './constants'

const CancelToken = axios.CancelToken

let cancelRequestObjects = []

function getSaveQuestionAllPromises (url, params) {
  const promises = []

  if (cancelRequestObjects && cancelRequestObjects.length && typeof cancelRequestObjects[params.questionId] === 'function') {
    // only cancel if the question saving is the same that we are trying to save ( checkboxes )
    cancelRequestObjects[params.questionId]()
    cancelRequestObjects = []
  }

  if (params.value instanceof Array && params.value.length && typeof params.value[0] === 'object') {
    const questions = params.value.filter(question => !question.readOnly)
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i]
      const cancelFunction = new CancelToken(function executor (c) {
        cancelRequestObjects[question.id] = c
      })
      promises.push(axios.put(url.replace('{questionId}', question.id), { value: question.value }, { cancelToken: cancelFunction }))
    }
  } else {
    const cancelFunction = new CancelToken(function executor (c) {
      cancelRequestObjects[params.questionId] = c
    })
    promises.push(axios.put(
      url.replace('{questionId}', params.questionId),
      { value: params.value, measureData: params.measureData },
      { cancelToken: cancelFunction }
    ))
  }
  return promises
}

function initialState () {
  return {
    hippaAgreement: false,
    finishSession: false,
    auditionData: [],
    auditionMeasureReport: [],
    justFinishedSession: false,
    sessions: [],
    measureAssignedDuringSession: [],
    measureLibrary: [],
    measureLibraryDetail: {},
    loadingSchedulingOptions: false,
    schedulingOptions: {
      available_measures: [],
      frequency_options: []
    },
    activeMeasure: {
      page: {
        questions: []
      }
    },
    frequencies: [
      { value: 1, text: 'One Time' },
      { value: 2, text: 'Daily' },
      { value: 3, text: 'Weekly' },
      { value: 4, text: 'Every 2 Weeks' },
      { value: 5, text: 'Monthly' },
      { value: 6, text: 'Every 2 Months' },
      { value: 7, text: 'Every 3 Months' },
      { value: 8, text: 'Every 6 Months' },
      { value: 9, text: 'Every 6 Weeks' },
      { value: 10, text: 'Every 4 Months' },
      { value: 11, text: 'Annually' }
    ],
    customMeasure: null,
    incompleteMeasure: false
  }
}

function getMeasure (url, commit, method, params = {}, isAudition = false) {
  return new Promise((resolve, reject) => {
    axios[method](url, params).then((response) => {
      response.data.isAudition = isAudition
      commit(GET_SINGLE_MEASURE, response.data)
      resolve()
    }).catch((error) => {
      const defaultSchedulingOptions = {
        available_measures: [],
        frequency_options: []
      }
      commit(GET_SINGLE_MEASURE, defaultSchedulingOptions)
      reject(error.response)
    })
  })
}
function saveMeasurePageQuestion (url, params, commit) {
  return new Promise((resolve, reject) => {
    let error = ''
    const promise = axios.all(getSaveQuestionAllPromises(url, params))
    promise.then((responses) => {
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i]
        if (response && response.data) {
          if (response && response.data && response.data.error) {
            error = response.data.error
            break
          } else {
            commit(SAVE_MEASURE_QUESTION, response.data)
          }
        }
      }
      return error ? reject(error) : resolve(responses[0])
    })
    promise.catch((error) => {
      resolve(false)
      return error
    })
  })
}
function saveMeasurePage (url, params, commit) {
  return new Promise((resolve, reject) => {
    const questionAnswers = params.hasOwnProperty('questionAnswers') ? params.questionAnswers : [] // eslint-disable-line no-prototype-builtins
    const postParams = {
      pageId: params.pageId,
      questionAnswers: questionAnswers,
      completed: params.complete,
      last_answered_question: params.last_answered_question,
      measureData: params.measureData
    }
    axios.post(url, postParams).then((response) => {
      if (response.data && response.data.measureData) {
        commit(SAVE_AUDITION_PAGE, response.data.measureData)
      }
      resolve(response.data)
    }).catch((error) => {
      reject(error.response)
    })
  })
}
function getParentQuestion (id, questions) {
  let r = null
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i]
    if (question.id === id) {
      r = question.text
    }
  }
  return r
}

// initial state
const state = initialState()

// actions
const actions = {
  CANCEL_SAVE_QUESTION_REQUEST (actions, object) {
    if (cancelRequestObjects && cancelRequestObjects.length && typeof cancelRequestObjects[object.questionId] === 'function') {
      cancelRequestObjects[object.questionId]()
      cancelRequestObjects = []
    }
  },
  SAVE_HIPPA_AGREEMENT ({ commit }) {
    return axios.post(`${ENDPOINTS.SAVE_HIPPA_AGREEMENT}`)
      .then((response) => {
        commit(SAVE_HIPPA_AGREEMENT, response.data)
      }).catch((error) => {
        return error.response
      })
  },
  GET_SESSIONS ({ commit }) {
    return axios.get(`${ENDPOINTS.GET_SESSIONS}`)
      .then((response) => {
        commit(GET_SESSIONS, response.data.sessions)
      })
      .catch((error) => {
        commit(GET_SESSIONS, [])
        return error.response
      })
  },
  FINISH_SESSION ({ commit }, status) {
    return commit(FINISH_SESSION, status)
  },
  JUST_FINISHED_SESSION ({ commit }, status) {
    return commit(JUST_FINISHED_SESSION, status)
  },
  [GET_MEASURE_LIBRARY] ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${ENDPOINTS.GET_MEASURE_LIBRARY}`, { params })
        .then((response) => {
          commit(GET_MEASURE_LIBRARY, response.data.rows)
          resolve(response)
        })
        .catch((error) => {
          commit(GET_MEASURE_LIBRARY, [])
          reject(error.response)
        })
    })
  },
  GET_MEASURE_LIBRARY_DETAIL ({ commit }, id) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_MEASURE_LIBRARY_DETAIL
        .replace('{measureLibraryId}', id)
      axios.get(url)
        .then((response) => {
          commit(GET_MEASURE_LIBRARY_DETAIL, response.data)
          resolve()
        })
        .catch((error) => {
          commit(GET_MEASURE_LIBRARY_DETAIL, {})
          reject(error.response)
        })
    })
  },
  TOGGLE_LIBRARY_STARRED ({ commit }, data) {
    return commit(TOGGLE_LIBRARY_STARRED, data)
  },
  TOGGLE_LIBRARY_SELECTED ({ commit }, data) {
    return commit(TOGGLE_LIBRARY_SELECTED, data)
  },
  GET_AUDITION_MEASURE ({ commit }, measure) {
    const pageId = measure.pageId ? measure.pageId : 0
    const url = ENDPOINTS.GET_AUDITION_MEASURE.replace('{measureId}', measure.id).replace('{pageId}', pageId)
    return getMeasure(url, commit, 'post', { measureData: measure.measureData }, true)
  },
  GET_SINGLE_MEASURE ({ commit }, measure) {
    const pageId = measure.pageId ? measure.pageId : 0
    const url = ENDPOINTS.GET_SINGLE_MEASURE.replace('{clientMeasureId}', measure.id).replace('{pageId}', pageId)
    return getMeasure(url, commit, 'get')
  },
  SAVE_MEASURE_QUESTION ({ commit }, params) {
    const url = ENDPOINTS.SAVE_MEASURE_QUESTION.replace('{clientMeasureId}', params.clientMeasureId).replace('{pageId}', params.pageId)
    return saveMeasurePageQuestion(url, params, commit)
  },
  SAVE_MEASURE_QUESTION_AUDITION ({ commit }, params) {
    const url = ENDPOINTS.SAVE_MEASURE_QUESTION_AUDITION.replace('{measureId}', params.measureId).replace('{pageId}', params.pageId)
    return saveMeasurePageQuestion(url, params, commit)
  },
  SAVE_MEASURE_PAGE ({ commit }, params) {
    const url = ENDPOINTS.SAVE_MEASURE_PAGE.replace('{clientMeasureId}', params.clientMeasureId)
    return saveMeasurePage(url, params, commit)
  },
  SAVE_AUDITION_PAGE ({ commit }, params) {
    const url = ENDPOINTS.SAVE_AUDITION_PAGE.replace('{measureId}', params.measureId)
    return saveMeasurePage(url, params, commit)
  },

  GET_SCHEDULING_OPTIONS ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const queryParams = params.getTeamMeasures ? { team_measures: true } : {}
      commit(LOADING_SCHEDULING_OPTIONS, true)
      axios.get(ENDPOINTS.GET_SCHEDULING_OPTIONS.replace('{id}', params.ccauId), { params: queryParams })
        .then((response) => {
          commit(GET_SCHEDULING_OPTIONS, response.data)
          resolve()
        })
        .catch((error) => {
          commit(GET_SCHEDULING_OPTIONS, {})
          reject(error.response)
        }).finally(() => {
          commit(LOADING_SCHEDULING_OPTIONS, false)
        })
    })
  },

  SET_CUSTOM_MEASURE ({ commit }, measureId) {
    commit(SET_CUSTOM_MEASURE, measureId)
  },

  SCHEDULE_MEASURE ({ commit }, schedule) {
    schedule.due_date = moment(schedule.due_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.SCHEDULE_MEASURE, schedule)
        .then((response) => {
          const params = {
            schedule,
            measure_storage: response.data.measure_storage,
            id: response.data.id
          }
          commit(SCHEDULE_MEASURE, params)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  SET_CHECKBOX_QUESTION_VALUE ({ commit }, params) {
    commit(SET_CHECKBOX_QUESTION_VALUE, params)
  },

  RESET_SCHEDULING_OPTIONS ({ commit }) {
    const resetState = initialState().schedulingOptions
    commit(RESET_SCHEDULING_OPTIONS, resetState)
  },

  GET_AUDITION_MEASURE_REPORT ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_AUDITION_MEASURE_REPORT.replace('{measureId}', params.measureId)
      axios.post(url, params).then((response) => {
        commit(GET_AUDITION_MEASURE_REPORT, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_AUDITION_MEASURE_REPORT, [])
        reject(error.response)
      })
    })
  },

  SET_AUDITION_MODE ({ commit }, auditionMode) {
    commit(SET_AUDITION_MODE, auditionMode)
  }
}

// getters
const getters = {
  userSessions: state => state.sessions,
  getAuditionData: state => state.auditionData,
  getAuditionAnswersReport: state => state.auditionAnswersReport,
  getAuditionMeasureReport: state => state.auditionMeasureReport,
  finishSession: state => state.finishSession,
  getJustFinishedSession: state => state.justFinishedSession,
  activeMeasure: state => state.activeMeasure,
  measureLibrary: state => state.measureLibrary,
  getFrequencies: state => state.frequencies,
  getLoadingSchedulingOptions: state => state.loadingSchedulingOptions,
  measureLibraryDetail: state => state.measureLibraryDetail,
  getSchedulingOptions: state => state.schedulingOptions,
  hippaAgreement (state, getters, rootState) {
    return rootState.security.loggedInUser.hippaAgreement
  },
  getCustomMeasure: state => state.customMeasure,
  getMeasureAssignedDuringSession: state => state.measureAssignedDuringSession,
  getIncompleteMeasure: state => state.incompleteMeasure,
  getAuditionMode: state => state.auditionMode
}

// mutations
const mutations = {
  [GET_SESSIONS] (state, data) {
    state.sessions = data
  },

  [FINISH_SESSION] (state, status) {
    state.finishSession = status
  },

  [JUST_FINISHED_SESSION] (state, status) {
    state.justFinishedSession = status
  },

  [GET_SINGLE_MEASURE] (state, data) {
    const isMobile = Helpers.$isOnlyMobile()
    if (data.page && data.page.questions) {
      let prompt = data.page.prompt
      let defaultOpen = !!prompt
      let id = null
      let questionAllowsPrompt = false

      for (let i = 0; i < data.page.questions.length; i++) {
        const question = data.page.questions[i]
        // For auditioning a measures al question should strt with skipped false
        if (data.isAudition) {
          question.skipped = false
        }
        if (question.showNext && id === null) {
          id = i
          question.multiQuestion = []
          question.isMultiQuestion = true
        }
        if (id !== null) {
          const questionToAdd = { ...question }
          if (questionToAdd.id === id) {
            delete questionToAdd.multiQuestion
            delete questionToAdd.isMultiQuestion
          }
          data.page.questions[id].multiQuestion.push(questionToAdd)
          question.keepHiden = id !== i
        }
        // keep id for future recerence if needed
        id = question.showNext ? id : null

        // if question has parent get parent question text
        if (typeof question.parent === 'number') {
          question.parentQuestion = getParentQuestion(question.parent, data.page.questions)
        }
        if (question.type === 'prompt') {
          const lastQuestion = (i > 0) ? data.page.questions[i - 1] : null
          defaultOpen = true
          // Concatenate propts that are one following each other
          prompt = lastQuestion && lastQuestion.type === 'prompt' ? lastQuestion.text + question.text : question.text
        }
        questionAllowsPrompt = question.showPrompt || (!question.hidePrompt && question.type !== 'prompt' && question.type !== 'text' && question.type !== 'title')

        if (prompt && questionAllowsPrompt) {
          question.prompt = {
            text: prompt,
            defaultOpen: defaultOpen
          }
          // If is not boble set default open to all prompts
          defaultOpen = !isMobile
        }
      }
    }
    state.activeMeasure = data
  },
  [RESET_ACTIVE_MEASURE] (state) {
    state.activeMeasure = null
  },

  [GET_MEASURE_LIBRARY] (state, data) {
    if (data.length) {
      data.forEach(item => {
        item.starred = false
        item.selected = false
      })
    }
    state.measureLibrary = data
  },
  [GET_MEASURE_LIBRARY_DETAIL] (state, data) {
    state.measureLibraryDetail = data
  },
  [SAVE_AUDITION_PAGE] (state, data) {
    state.auditionData = data
    if (!data) {
      localStorage.removeItem('auditionData')
    }
    localStorage.setItem('auditionData', JSON.stringify(data))
  },
  [LOADING_SCHEDULING_OPTIONS] (state, val) {
    state.loadingSchedulingOptions = val
  },
  [GET_SCHEDULING_OPTIONS] (state, data) {
    if (data.available_measures) {
      data.available_measures.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation))
    }
    state.schedulingOptions = data
  },
  [RESET_SCHEDULING_OPTIONS] (state, data) {
    state.schedulingOptions = data
  },
  [REMOVE_SCHEDULED_MEASURE] (state, clientMeasureId) {
    if (state.schedulingOptions.measures_assigned) {
      const index = state.schedulingOptions.measures_assigned.findIndex(m => m.id === clientMeasureId)
      if (index > -1) {
        state.schedulingOptions.measures_assigned.splice(index, 1)
      }
    }
  },
  [RESET_MEASURES_ASSIGNED_DURING_SESSION] (state) {
    state.measureAssignedDuringSession = []
  },
  [REMOVE_MEASURE_ASSIGNED_DURING_SESSION] (state, measureId) {
    state.measureAssignedDuringSession = state.measureAssignedDuringSession.filter(m => m.id !== measureId)
  },
  [SET_CUSTOM_MEASURE] (state, measureId) {
    state.customMeasure = measureId
  },
  [SCHEDULE_MEASURE] (state, data) {
    const measureData = {
      measure_name: data.schedule.measure_name || data.measure_storage.name,
      abbreviation: data.schedule.abbreviation || data.measure_storage.abbreviation,
      frequency: data.schedule.frequency,
      due_date: data.schedule.due_date,
      clinical_administered: parseInt(data.measure_storage.clinical_administered),
      is_pre_populate: parseInt(data.measure_storage.pre_populate),
      id: data.id,
      completed: 0
    }

    if (data.schedule.schedule_type) {
      measureData.schedule_type = data.schedule.schedule_type
    }

    if (data.schedule.isAddMeasure) {
      const addedInSession = Object.assign({}, measureData, { clinical_administered: data.schedule.clinical_administered })
      state.measureAssignedDuringSession.push(addedInSession)
    }
    if (!state.schedulingOptions.measures_assigned) {
      return
    }
    state.schedulingOptions.measures_assigned.push(measureData)
  },
  [TOGGLE_LIBRARY_STARRED] (state, data) {
    const index = state.measureLibrary.findIndex((measureLibraryItem) => {
      return data.id === measureLibraryItem.id
    })
    state.measureLibrary[index].starred = !state.measureLibrary[index].starred
  },
  [TOGGLE_LIBRARY_SELECTED] (state, data) {
    state.measureLibrary.forEach(row => {
      row.selected = row.id === data.id
    })
  },
  [SET_QUESTION_DEFAULT_OPEN] (state, params) {
    try {
      state.activeMeasure.page.questions[params.question].prompt.defaultOpen = params.value
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  },
  [SET_CHECKBOX_QUESTION_VALUE] (state, params) {
    const index = state.activeMeasure.page.questions.findIndex((q) => q.id === params.questionId)
    state.activeMeasure.page.questions[index].value = params.value
  },
  [SAVE_MEASURE_QUESTION] (state, question) {
    const stateQuestion = state.activeMeasure.page.questions.find(q => q.id === question.id)
    if (stateQuestion.type !== 'checkbox') {
      stateQuestion.value = question.value
    }
    stateQuestion.skipped = false

    const multiQuestionContainer = state.activeMeasure.page.questions.find(q => q.isMultiQuestion && q.multiQuestion.find(mq => mq.id === question.id))
    if (multiQuestionContainer) {
      const stateMultiQuestion = multiQuestionContainer.multiQuestion.find(q => q.id === question.id)
      if (stateMultiQuestion.type !== 'checkbox') {
        stateMultiQuestion.value = question.value
      }
      stateMultiQuestion.skipped = false
    }
    state.activeMeasure = { ...state.activeMeasure }
  },
  [GET_AUDITION_MEASURE_REPORT] (state, data) {
    data.completedDataWithScores = 1
    state.auditionMeasureReport = data
  },
  [RESET_AUDITION_REPORT] (state) {
    state.auditionMeasureReport = null
  },
  [CLINICIAN_SAVE_MEASURE_QUESTION] (state, params) {
    let questions = []
    if (params.value instanceof Array && params.value.length && typeof params.value[0] === 'object') {
      questions = params.value
    } else {
      questions.push({
        id: params.questionId,
        value: params.value
      })
    }
    questions.forEach(question => {
      const stateQuestion = state.activeMeasure.page.questions.find(q => q.id === question.id)
      stateQuestion.value = question.value
    })
    state.activeMeasure = { ...state.activeMeasure }
  },
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [SET_AUDITION_MODE] (state, value) {
    state.auditionMode = value
  },
  [UPDATE_PAGE_QUESTION_VALUE] (state, params) {
    const question = state.activeMeasure.page.questions.find(q => q.id === params.questionId)
    question.value = params.newValue
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
}
