import {
  GET_EXPORTS,
  GET_EXPORTS_LOADING,
  CREATE_EXPORT,
  CREATE_EXPORT_LOADING,
  DELETE_EXPORT,
  SHOW_EXPORT_TOOLTIP,
  EXPORTS_LOGOUT
} from './constants'
import { initialState } from './index'

export default {

  [GET_EXPORTS]: (state, exports) => {
    state.owlExports = exports && exports.requests ? Object.values(exports.requests) : []
  },

  [CREATE_EXPORT]: (state, data) => {
    if (data) {
      state.owlExports.push(data)
    }
  },

  [GET_EXPORTS_LOADING]: (state, val) => {
    state.owlExportsLoading = val
  },
  [DELETE_EXPORT]: (state, id) => {
    const i = state.owlExports.findIndex(e => e.id === id)
    if (i !== -1) {
      state.owlExports.splice(i, 1)
    }
  },
  [CREATE_EXPORT_LOADING]: (state, val) => {
    state.createExportLoading = val
  },
  [SHOW_EXPORT_TOOLTIP]: (state, val) => {
    state.showExportTooltip = val
  },
  [EXPORTS_LOGOUT]: (state) => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}
