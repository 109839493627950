// Mutation types
export const SIGN_OUT = 'appointments/SIGN_OUT'
export const GET_APPOINTMENTS_BY_DATE = 'appointments/GET_APPOINTMENTS_BY_DATE'
export const GET_PATIENTS_BY_DUE_DATE = 'patients/GET_PATIENTS_BY_DUE_DATE'
export const UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON = 'patients/UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON'
export const UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON = 'patients/UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON'

export const ENDPOINTS = {
  GET_APPOINTMENTS_BY_DATE: 'appointments',
  GET_PATIENTS_BY_DUE_DATE: 'patients/by-due-date'
}
