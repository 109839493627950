export default {
  getClinicians: state => state.clinicians,
  getCliniciansLoading: state => state.cliniciansLoading,
  getSingleClinicianTeam: state => state.clinician_team,
  getPatientMeasures: state => state.patientMeasures,
  getPatientMeasuresPDF: state => state.patientMeasuresPDF,
  getPatientMeasuresPDFLoading: state => state.patientMeasuresPDFLoading,
  getPatientMeasuresPDFData: state => (
    {
      measureIds: state.patientMeasuresPDF.map(item => item.id),
      sessionAbbreviations: state.patientMeasuresPDF.map(item => item.abbreviation),
      questionIds: {
        clientMeasureIds: state.patientMeasuresPDF.map(item => item.id)
      }
    }
  ),
  getPDFMeasureIds: state => {
    return state.patientMeasuresPDF.map(item => item.id)
  },
  getClinicianTeams: state => state.teams,
  getAlerts: state => state.alerts,
  getDeletingScheduleMeasure: state => state.deletingScheduleMeasure,
  getAlertsCount: state => state.alertsCount,
  getAlertsCountLoading: state => state.alertsCountLoading,
  getPatientSingleMeasureCompletedSessions: state => state.patientSingleMeasureCompletedSessions,
  getPatientSingleMeasure: state => state.patientSingleMeasure,
  getPatientSingleMeasureLoading: state => state.patientSingleMeasureLoading,
  getPatientSingleMeasurePDF: state => state.patientSingleMeasurePDF,
  getPatientSingleMeasurePDFLoading: state => state.patientSingleMeasurePDFLoading
}
