import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'
const RESULT_KEY = 'week_' || 'day_'
/**
 * Handle construction of Outpatient Weeks To Below Cutoff Chart Model.
 */
export class AnalyticsOutpatientWeeksToBelowCutoffChartModel extends AnalyticsChartModel {
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    super(queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure)
    this.title = this.getChartTitleOrDefault(queryChartConfiguration, programData, queryResult, measure)
  }

  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'outpatientWeeksToBelowCutoff'
  }

  getChartSubdivisionKey () {
    return 'outcomesOutpatient'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program, queryResult, measure) {
    const intervalType = Object.hasOwn(queryResult.scores, 'day_0') ? 'days' : 'weeks'
    const daysOrWeeks = intervalType === 'days' ? 'outpatientDaysToBelowCutoff' : 'outpatientWeeksToBelowCutoff'
    const measureName = measure ? measure.abbreviation : ''
    const setting = this.getMeasureChartSettings(queryResult)
    const cutOff = setting && this.getYAxisCutOffFromMeasureConfig(setting) ? `(${this.getYAxisCutOffFromMeasureConfig(setting)})` : ''
    return `${program.name}: ${measureName} ${i18n.messages[i18n.locale][daysOrWeeks]} ${cutOff}`
  }

  /**
   * Get Default Chart X Axis.
   */
  getXAxis (queryResult) {
    const categories = this.getXAxisCategories(queryResult.scores, RESULT_KEY, true)
    const labels = this.getXAxisLabels()
    const tickInterval = Object.keys(categories).length > 10 ? 5 : 1
    return {
      categories,
      labels,
      tickInterval
    }
  }

  /**
  * Get Default Chart Y Axis.
  */
  getYAxis (queryResult) {
    const max = this.getYAxisMax(queryResult.scores)
    return {
      max: max,
      min: 0,
      tickPositions: this.getYAxisTickPositions(max),
      title: {
        text: i18n.messages[i18n.locale].numberOfPatients,
        margin: 20,
        style: this.getDefaultStyleObject(16)
      }
    }
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult, program) {
    const intervalType = Object.hasOwn(queryResult.scores, 'day_0') ? 'days' : 'weeks'
    const name = i18n.messages[i18n.locale][intervalType]
    const data = []

    for (const key in queryResult.scores) {
      data.push(queryResult.scores[key])
    }
    return [{ name, data }]
  }
}
