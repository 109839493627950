import axios from 'axios'
import moment from 'moment'
import throttle from 'lodash/debounce'
import {
  ENDPOINTS
} from '@/store/modules/exports/constants.js'

const WARNING_TRESHOLD = 'warningTreshold'
const URL_IGNORED_BY_KEEP_ALIVE = [ENDPOINTS.GET_EXPORTS]
export const LogoutTimer = {
  created () {
    // added this condition to prevent redundant keep-alive checks and redirects
    // added throttler to reduce the number of mousemove event triggers
    if (this.$route.name !== null && this.$route.name !== 'Login') {
      const throttler = throttle(this.handleMouseMove, 1000)
      document.addEventListener('mousemove', throttler)
    }
    this.setup()
  },
  data: () => {
    return {
      seconds: 10,
      timer: null,
      timeoutHandle: null,
      targetTimeOutDate: null
    }
  },
  methods: {
    handleMouseMove () {
      if (this.targetTimeOutDate && moment.now() >= this.targetTimeOutDate) {
        this.logOut()
        return true
      }

      // Otherwise close the prompt and reset the timer and session token in order to keep session alive.
      if (this.isPromptOpen) {
        this.closePrompt()
        this.$store.dispatch('KEEP_ALIVE')
      }

      if (this.$store.getters.isAuthenticated) {
        this.resetTimer()
        this.storeWarningThreshold()
      }
    },
    storeWarningThreshold () {
      const warningDate = Date.now()
      // add warning threshold to current date for expiration
      localStorage.setItem(WARNING_TRESHOLD, warningDate + this.$store.getters.generalSettings.session_configuration.warningThreshhold)
    },
    logOut () {
      this.closePrompt()
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch('SIGN_OUT').then(() => {
          this.$router.push({
            name: 'Login'
          })
        }).catch((e) => {
          this.resetTimer()
          this.$handleApiError(e)
        })
      }
    },
    setup () {
      // To sync with API expiration we reset the timer each time we make a request
      axios.interceptors.response.use((response) => {
        let strippedUrl = ''
        try {
          const replace = response.config && response.config.baseURL ? `${response.config.baseURL}/` : ''
          strippedUrl = response.config && response.config.url ? response.config.url.replace(replace, '') : ''
        } catch (error) {
          console.log(error) // eslint-disable-line no-console
        }

        if (URL_IGNORED_BY_KEEP_ALIVE.includes(strippedUrl)) {
          return response
        }
        // close any existing prompt
        this.closePrompt()
        // If we have a previous handler lets reset it
        if (this.timeoutHandle) {
          window.clearInterval(this.timeoutHandle)
        }

        // only trigger counter for logged in users
        if (this.$store.getters.isAuthenticated) {
          this.storeWarningThreshold()
          this.timeoutHandle = setInterval(() => {
            const now = moment.now()
            const checkDate = localStorage.getItem(WARNING_TRESHOLD)

            // Show The prompt
            if (now >= checkDate) {
              if (this.$store.getters.isAuthenticated) {
                this.initPromptCounter()
                this.showPrompt()
              }
              window.clearInterval(this.timeoutHandle)
            }
          }, 1000)
        }
        return response
      })
    },
    initPromptCounter () {
      this.timer = setInterval(() => {
        this.seconds--
        if (!this.seconds || moment.now() >= this.targetTimeOutDate.valueOf()) {
          this.logOut()
        }
      }, 1000)
    },
    closePrompt () {
      clearInterval(this.timer)
      const toast = document.querySelector('#sessionClose')
      if (toast) {
        this.$toast.hide({
          transitionOut: 'fadeOut'
        }, toast)
      }
      this.resetTimer()
    },
    resetTimer () {
      this.targetTimeOutDate = moment().add(this.gSettings.session_configuration.timeout / 1000, 'seconds').valueOf()
      this.seconds = this.gSettings.session_configuration.secondsToShow
    },
    showPrompt () {
      const options = {
        message: this.sessionTimeoutMessage,
        timeout: 0,
        close: false,
        overlay: true,
        toastOnce: false,
        icon: '',
        id: 'sessionClose',
        zindex: 999,
        class: 'singleButton owl',
        position: 'center',
        buttons: [
          [`<button class="exit">${this.$t('close')}</button>`, (instance, toast) => {
            this.$store.dispatch('KEEP_ALIVE')
            instance.hide({
              transitionOut: 'fadeOut'
            }, toast, 'button')
          }]
        ]
      }
      // Conditional check to bypass warning prompt if check rate is negative
      const checkRate = this.$store.getters.generalSettings.session_configuration.checkRate
      if (!checkRate.startsWith('-')) {
        this.$toast.info(options)
      }
    }
  },
  computed: {
    isPromptOpen () {
      return this.seconds !== this.gSettings.session_configuration.secondsToShow
    },
    sessionTimeoutMessage () {
      const timeObj = moment.utc(this.seconds * 1000)
      let time = timeObj.format('ss')
      let unit = 'seconds'

      const hours = timeObj.format('H')
      const minutes = timeObj.format('mm')

      if (Number(hours) > 0) {
        unit = hours > 1 ? 'hours' : 'hour'
        time = hours

        if (Number(minutes) > 0) {
          time = `${hours}:${minutes}`
        }
      }

      if (Number(hours) <= 0 && Number(minutes) > 0) {
        unit = Number(minutes) > 1 ? 'minutes' : 'minute'
        time = Number(minutes)
      }

      return this.$t('sessionTimeout', {
        time: time,
        unit: unit
      })
    }
  },
  watch: {
    sessionTimeoutMessage () {
      const toast = document.querySelector('#sessionClose .iziToast-message')
      if (toast) {
        toast.innerHTML = this.sessionTimeoutMessage
      }
    }
  }
}
