// Mutation types
export const GET_SESSIONS = 'measures/GET_SESSIONS'
export const FINISH_SESSION = 'measures/FINISH_SESSION'
export const JUST_FINISHED_SESSION = 'measures/JUST_FINISHED_SESSION'
export const GET_SINGLE_MEASURE = 'measures/GET_SINGLE_MEASURE'
export const GET_MEASURE_LIBRARY = 'measures/GET_MEASURE_LIBRARY'
export const GET_MEASURE_LIBRARY_DETAIL = 'measures/GET_MEASURE_LIBRARY_DETAIL'
export const GET_SCHEDULING_OPTIONS = 'measures/GET_SCHEDULING_OPTIONS'
export const LOADING_SCHEDULING_OPTIONS = 'measures/LOADING_SCHEDULING_OPTIONS'
export const RESET_SCHEDULING_OPTIONS = 'measures/RESET_SCHEDULING_OPTIONS'
export const REMOVE_SCHEDULED_MEASURE = 'measures/REMOVE_SCHEDULED_MEASURE'
export const RESET_MEASURES_ASSIGNED_DURING_SESSION = 'measures/RESET_MEASURES_ASSIGNED_DURING_SESSION'
export const REMOVE_MEASURE_ASSIGNED_DURING_SESSION = 'measures/REMOVE_MEASURE_ASSIGNED_DURING_SESSION'
export const SCHEDULE_MEASURE = 'measures/SCHEDULE_MEASURE'
export const SET_CUSTOM_MEASURE = 'measures/SET_CUSTOM_MEASURE'
export const TOGGLE_LIBRARY_STARRED = 'measures/TOGGLE_LIBRARY_STARRED'
export const TOGGLE_LIBRARY_SELECTED = 'measures/TOGGLE_LIBRARY_SELECTED'
export const SAVE_HIPPA_AGREEMENT = 'security/SAVE_HIPPA_AGREEMENT'
export const SIGN_OUT = 'measures/SIGN_OUT'
export const SET_CHECKBOX_QUESTION_VALUE = 'measures/SET_CHECKBOX_QUESTION_VALUE'
export const SET_QUESTION_DEFAULT_OPEN = 'measures/SET_QUESTION_DEFAULT_OPEN'
export const SAVE_MEASURE_QUESTION = 'measures/SAVE_MEASURE_QUESTION'
export const RESET_ACTIVE_MEASURE = 'measures/RESET_ACTIVE_MEASURE'
export const CLINICIAN_SAVE_MEASURE_QUESTION = 'measures/CLINICIAN_SAVE_MEASURE_QUESTION'
export const SAVE_AUDITION_PAGE = 'measures/SAVE_AUDITION_PAGE'
export const GET_AUDITION_MEASURE_REPORT = 'measures/GET_AUDITION_MEASURE_REPORT'
export const RESET_AUDITION_REPORT = 'measures/RESET_AUDITION_REPORT'
export const SET_AUDITION_MODE = 'measures/SET_AUDITION_MODE'
export const UPDATE_PAGE_QUESTION_VALUE = 'measures/UPDATE_PAGE_QUESTION_VALUE'

export const ENDPOINTS = {
  GET_SESSIONS: '/questionnaires/session',
  GET_MEASURE_LIBRARY: '/library/list',
  GET_MEASURE_LIBRARY_DETAIL: '/library/{measureLibraryId}',
  GET_SINGLE_MEASURE: '/questionnaires/{clientMeasureId}/page/{pageId}',
  GET_ANSWERS_REPORT: '/questionnaires-audition/answers-report/{measureId}',
  GET_MEASURE_REPORT: '/questionnaires-audition/measure-report/{measureId}',
  GET_AUDITION_MEASURE: '/questionnaires-audition/{measureId}/page/{pageId}',
  GET_AUDITION_MEASURE_REPORT: '/questionnaires-audition/measure-report/{measureId}',
  SAVE_MEASURE_PAGE: '/questionnaires/{clientMeasureId}',
  SAVE_AUDITION_PAGE: '/questionnaires-audition/{measureId}',
  SAVE_MEASURE_QUESTION: '/questionnaires/{clientMeasureId}/page/{pageId}/question/{questionId}',
  SAVE_MEASURE_QUESTION_AUDITION: '/questionnaires-audition/{measureId}/page/{pageId}/question/{questionId}',
  SAVE_HIPPA_AGREEMENT: '/questionnaires/hippa-agreement',
  GET_SCHEDULING_OPTIONS: '/scheduling/{id}',
  SCHEDULE_MEASURE: '/scheduling',
  GET_MEASURE_CHART_SETTINGS: '/library/chart-settings'
}
