import axios from 'axios'

import {
  ENDPOINTS,
  FETCH_ACTIVE_PATIENTS_TODAY,
  FETCH_ACTIVE_PATIENTS,
  FETCH_CLINICAL_SIGNINS,
  FETCH_MEASURE_ACTIVITY_TODAY,
  FETCH_MEASURE_ACTIVITY,
  FETCH_MEASURE_COMPLETION_TODAY,
  FETCH_MEASURES,
  RESET_USAGE_GLOBAL_PARAMS,
  RESET_USAGE_TODAY_PARAMS,
  SET_ACTIVE_PATIENTS_DATA,
  SET_ACTIVE_PATIENTS_HAS_ERRORS,
  SET_ACTIVE_PATIENTS_LOADING,
  SET_ACTIVE_PATIENTS_TODAY_DATA,
  SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS,
  SET_ACTIVE_PATIENTS_TODAY_LOADING,
  SET_CLINICAL_SIGNINS_DATA,
  SET_CLINICAL_SIGNINS_HAS_ERRORS,
  SET_CLINICAL_SIGNINS_LOADING,
  SET_MEASURE_ACTIVITY_DATA,
  SET_MEASURE_ACTIVITY_HAS_ERRORS,
  SET_MEASURE_ACTIVITY_LOADING,
  SET_MEASURE_ACTIVITY_TODAY_DATA,
  SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS,
  SET_MEASURE_ACTIVITY_TODAY_LOADING,
  SET_MEASURE_COMPLETION_TODAY_DATA,
  SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS,
  SET_MEASURE_COMPLETION_TODAY_LOADING,
  SET_MEASURES_DATA,
  SET_MEASURES_HAS_ERRORS,
  SET_MEASURES_LOADING,
  UPDATE_USAGE_GLOBAL_PARAMS,
  UPDATE_USAGE_TODAY_PARAMS
} from './constants'

const errorMessage = (response) => {
  return response.data && response.data.error ? response.data.error : 'There was a problem loading this data'
}

export default {
  [UPDATE_USAGE_GLOBAL_PARAMS] ({ commit }, params) {
    commit(UPDATE_USAGE_GLOBAL_PARAMS, params)
  },

  [RESET_USAGE_GLOBAL_PARAMS] ({ commit }) {
    commit(RESET_USAGE_GLOBAL_PARAMS)
  },

  [UPDATE_USAGE_TODAY_PARAMS] ({ commit }, params) {
    commit(UPDATE_USAGE_TODAY_PARAMS, params)
  },

  [RESET_USAGE_TODAY_PARAMS] ({ commit }) {
    commit(RESET_USAGE_TODAY_PARAMS)
  },

  [FETCH_ACTIVE_PATIENTS] ({ commit, getters }) {
    commit(SET_ACTIVE_PATIENTS_LOADING, true)
    commit(SET_ACTIVE_PATIENTS_HAS_ERRORS, false)
    const params = getters.getAnalyticsUsageActivePatientsParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE_RESPONDENTS, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_ACTIVE_PATIENTS_DATA, response.data.results)
          commit(SET_ACTIVE_PATIENTS_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_ACTIVE_PATIENTS_HAS_ERRORS, true)
        commit(SET_ACTIVE_PATIENTS_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_ACTIVE_PATIENTS_TODAY] ({ commit, getters }) {
    commit(SET_ACTIVE_PATIENTS_TODAY_LOADING, true)
    commit(SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS, false)

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE_INVITES).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_ACTIVE_PATIENTS_TODAY_DATA, response.data.results)
          commit(SET_ACTIVE_PATIENTS_TODAY_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS, true)
        commit(SET_ACTIVE_PATIENTS_TODAY_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_MEASURE_ACTIVITY] ({ commit, getters }) {
    commit(SET_MEASURE_ACTIVITY_LOADING, true)
    commit(SET_MEASURE_ACTIVITY_HAS_ERRORS, false)
    const params = getters.getAnalyticsUsageMeasureActivityParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_MEASURE_ACTIVITY_DATA, response.data.results)
          commit(SET_MEASURE_ACTIVITY_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_MEASURE_ACTIVITY_HAS_ERRORS, true)
        commit(SET_MEASURE_ACTIVITY_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_MEASURE_ACTIVITY_TODAY] ({ commit, getters }) {
    commit(SET_MEASURE_ACTIVITY_TODAY_LOADING, true)
    commit(SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS, true)
    const params = getters.getAnalyticsUsageMeasureActivityTodayParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_MEASURE_ACTIVITY_TODAY_DATA, response.data.results)
          commit(SET_MEASURE_ACTIVITY_TODAY_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS, true)
        commit(SET_MEASURE_ACTIVITY_TODAY_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_MEASURE_COMPLETION_TODAY] ({ commit, getters }) {
    commit(SET_MEASURE_COMPLETION_TODAY_LOADING, true)
    commit(SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS, false)
    const params = getters.getAnalyticsUsageMeasureCompletionTodayParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_MEASURE_COMPLETION_TODAY_DATA, response.data.results)
          commit(SET_MEASURE_COMPLETION_TODAY_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS, true)
        commit(SET_MEASURE_COMPLETION_TODAY_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_CLINICAL_SIGNINS] ({ commit, getters }) {
    commit(SET_CLINICAL_SIGNINS_LOADING, true)
    commit(SET_CLINICAL_SIGNINS_HAS_ERRORS, false)
    const params = getters.getAnalyticsUsageClinicalSignInsParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE_SIGNINS, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_CLINICAL_SIGNINS_DATA, response.data.results)
          commit(SET_CLINICAL_SIGNINS_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_CLINICAL_SIGNINS_HAS_ERRORS, true)
        commit(SET_CLINICAL_SIGNINS_LOADING, false)
        errorMessage(error.response)
      })
    })
  },

  [FETCH_MEASURES] ({ commit, getters }) {
    commit(SET_MEASURES_LOADING, true)
    commit(SET_MEASURES_HAS_ERRORS, false)
    const params = getters.getAnalyticsUsageMeasuresParams

    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.USAGE, params).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit(SET_MEASURES_DATA, response.data.results)
          commit(SET_MEASURES_LOADING, false)
          resolve(response)
        } else {
          reject(errorMessage(response))
        }
      }).catch((error) => {
        commit(SET_MEASURES_HAS_ERRORS, true)
        commit(SET_MEASURES_LOADING, false)
        errorMessage(error.response)
      })
    })
  }
}
