import axios from 'axios'
import store from '@/store/'
import { CHANGE_PATIENT_USER_STATUS } from '@/store/modules/patientUsers/constants'
import {
  GET_CLINICAL_USERS,
  GET_ACTIVE_SUPERVISORS_LOADING,
  DELETE_CLINICAL_USER,
  UPDATE_CLINICAL_USER,
  CHANGE_STATUS,
  SAVE_CLINICAL_USER,
  GET_SUPERVISORS,
  GET_ACTIVE_SUPERVISORS,
  VALIDATE_PROVIDER_ID,
  UPDATE_USER_SUPERIORS_AND_SUBORDINATES,
  ENDPOINTS
} from './constants'
import { createNewCancelTokenRequest } from '@/store/modules/patients/index.js'

export default {
  [GET_CLINICAL_USERS]: async ({ commit }, params) => {
    if (!params) {
      params = {}
    }
    const uiSettings = store.getters.getUiSettings
    const accountStatusSelector = uiSettings.accountStatusSelector ? uiSettings.accountStatusSelector.selected : null
    const clinicalUseerRole = uiSettings.clinicalUserRole ? uiSettings.clinicalUserRole.selected : null
    const noContactInfoselector = uiSettings.noContactInfoselector ? uiSettings.noContactInfoselector.selected : null
    params.no_contact_information = noContactInfoselector
    params.roles = clinicalUseerRole
    if (accountStatusSelector) {
      params.status = accountStatusSelector
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_CLINICAL_USERS, {
        params,
        cancelToken: createNewCancelTokenRequest('loadItems')
      }).then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_CLINICAL_USERS, response.data.rows)
        resolve(response)
      }).catch((error) => {
        commit(GET_CLINICAL_USERS, [])
        reject(error)
      })
    })
  },

  [GET_SUPERVISORS]: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_SUPERVISORS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_SUPERVISORS, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_SUPERVISORS, [])
        reject(error)
      })
    })
  },

  [GET_ACTIVE_SUPERVISORS]: async ({ commit }) => {
    commit(GET_ACTIVE_SUPERVISORS_LOADING, true)
    return new Promise((resolve, reject) => {
      axios.get(`${ENDPOINTS.GET_SUPERVISORS}?onlyActive=1`).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_SUPERVISORS, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_SUPERVISORS, [])
        reject(error)
      }).finally(() => {
        commit(GET_ACTIVE_SUPERVISORS_LOADING, false)
      })
    })
  },

  [SAVE_CLINICAL_USER]: async ({ commit }, data) => {
    const parameters = {
      first_name: data.first_name,
      last_name: data.last_name,
      status: data.status,
      email: data.email,
      invite: data.invite,
      locations: data.locations,
      provider_id: data.provider_id,
      roles: data.roles,
      superiors: data.superiors,
      subordinates: data.subordinates
    }
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.SAVE_CLINICAL_USER, parameters).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(SAVE_CLINICAL_USER, response.data)
        commit(UPDATE_USER_SUPERIORS_AND_SUBORDINATES, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [UPDATE_CLINICAL_USER]: async ({ commit }, data) => {
    const parameters = {
      first_name: data.first_name,
      last_name: data.last_name,
      status: data.status,
      invite: data.invite,
      locations: data.locations,
      provider_id: data.provider_id,
      roles: data.roles,
      superiors: data.superiors,
      subordinates: data.subordinates,
      email: data.email
    }

    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.UPDATE_CLINICAL_USER.replace('{id}', data.id), parameters).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(UPDATE_CLINICAL_USER, response.data)
        commit(UPDATE_USER_SUPERIORS_AND_SUBORDINATES, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [CHANGE_STATUS]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.CHANGE_STATUS.replace('{id}', data.id), data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(CHANGE_STATUS, response.data)
        commit(CHANGE_PATIENT_USER_STATUS, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [VALIDATE_PROVIDER_ID]: async (actions, params) => {
    const data = { provider_id: params.value }
    if (params.clinicianId) {
      data.clinician_id = params.clinicianId
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.VALIDATE_PROVIDER_ID, { params: data })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },

  [DELETE_CLINICAL_USER]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.delete(ENDPOINTS.DELETE_CLINICAL_USER.replace('{id}', data.id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(DELETE_CLINICAL_USER, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
