export const SIGN_OUT = 'analytics/SIGN_OUT'
export const GET_ANALYTICS_PROGRAMS = 'analytics/GET_ANALYTICS_PROGRAMS'
export const GET_ANALYTICS_LOCATIONS = 'analytics/GET_ANALYTICS_LOCATIONS'
export const ANALYTICS_SELECT_PROGRAMS = 'analytics/ANALYTICS_SELECT_PROGRAMS'
export const ANALYTICS_UPDATE_CURRENT_QUERY = 'analytics/ANALYTICS_UPDATE_CURRENT_QUERY'
export const ANALYTICS_DELETE_PROPERTY_FROM_QUERY = 'analytics/ANALYTICS_DELETE_PROPERTY_FROM_QUERY'
export const ANALYTICS_LOAD_SAVED_QUERY = 'analytics/ANALYTICS_LOAD_SAVED_QUERY'
export const ANALYTICS_GET_TOTAL_RECORDS = 'analytics/ANALYTICS_GET_TOTAL_RECORDS'
export const ANALYTICS_GET_RECORDS = 'analytics/ANALYTICS_GET_RECORDS'
export const ANALYTICS_GET_RECORDS_LOADING = 'analytics/ANALYTICS_GET_RECORDS_LOADING'
export const ANALYTICS_GET_TOTAL_RECORDS_LOADING = 'analytics/ANALYTICS_GET_TOTAL_RECORDS_LOADING'
export const ANALYTICS_API_QUERY = 'analytics/ANALYTICS_API_QUERY'
export const ANALYTICS_GET_SAVED_QUERIES = 'analytics/ANALYTICS_GET_SAVED_QUERIES'
export const ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION = 'analytics/SET_SELECTED_QUERY'
export const ANALYTICS_RESET_SELECTED_QUERY_FOR_EDITION = 'analytics/RESET_SELECTED_QUERY'
export const ANALYTICS_DELETE_SAVED_QUERY = 'analytics/DELETED_SAVED_QUERY'
export const ANALYTICS_SAVE_QUERY = 'analytics/CREATE_QUERY'
export const ANALYTICS_UPDATE_SAVED_QUERY = 'analytics/UPDATE_QUERY'
export const ANALYTICS_SAVED_QUERY_LOADED = 'analytics/SAVED_QUERY_LOADED'
export const ANALYTICS_GET_PROGRAMS_FILTERED = 'analytics/ANALYTICS_GET_PROGRAMS_FILTERED'
export const ANALYTICS_GET_PROGRAMS_FILTERED_LOADING = 'analytics/ANALYTICS_GET_PROGRAMS_FILTERED_LOADING'
export const ANALYTICS_CHANGE_CHART_ATTRIBUTE = 'analytics/CHANGE_CHART_ATTRIBUTE'
export const ANALYTICS_UPDATE_CURRENT_QUERY_CHART_INFO = 'analytics/UPDATE_CURRENT_QUERY_CHART_INFO'
export const ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE = 'analytics/ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE'
export const ANALYTICS_SET_SELECTED_PROGRAM_CHART = 'analytics/SET_SELECTED_PROGRAM_CHART'
export const ANALYTICS_SET_SELECTED_MEASURES_CHART = 'analytics/ANALYTICS_SET_SELECTED_MEASURES_CHART'
export const ANALYTICS_UNASSIGNED_UUID = { condition: 'is_null' }
export const ANALYTICS_GET_AVAILABLE_SERVICE_LINES = 'analytics/ANALYTICS_GET_AVAILABLE_SERVICE_LINES'
export const ANALYTICS_GET_VISIBLE_CHART_ITEMS = 'analytics/ANALYTICS_GET_VISIBLE_CHART_ITEMS'
export const ANALYTICS_GET_VISIBLE_CHART_ITEMS_CATCHALL = 'analytics/ANALYTICS_GET_VISIBLE_CHART_ITEMS_CATCHALL'
export const ANALYTICS_GET_SELECTED_SERVICE_LINE = 'analytics/ANALYTICS_GET_SELECTED_SERVICE_LINE'
export const ANALYTICS_GET_MEASURES_FOR_SERVICE_LINES = 'analytics/ANALYTICS_GET_MEASURES_FOR_SERVICE_LINES'
export const ANALYTICS_SET_CHART_COLLECTION = 'analytics/ANALYTICS_SET_CHART_COLLECTION'
export const ANALYTICS_SET_CHART_COLLECTION_LOADING = 'analytics/ANALYTICS_SET_CHART_COLLECTION_LOADING'
export const ANALYTICS_SET_CUSTOM_BUTTON_TEXT = 'analytics/ANALYTICS_SET_CUSTOM_BUTTON_TEXT'
export const ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS = 'analytics/ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS'
export const ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS = 'analytics/ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS'

export const ENDPOINTS = {
  GET_ANALYTICS_PROGRAMS: 'analytics/programs',
  GET_ANALYTICS_LOCATIONS: 'analytics/locations',
  ANALYTICS_GET_TOTAL_RECORDS: 'analytics/outcomes-explorer',
  ANALYTICS_GET_RECORDS: 'analytics/outcomes-explorer',
  ANALYTICS_QUERIES: 'analytics/queries',
  ANALYTICS_QUERY_RESOURCE: 'analytics/queries/{id}'
}
