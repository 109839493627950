import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function initialState () {
  return {
    owlExports: [],
    owlExportsLoading: false,
    createExportLoading: false,
    showExportTooltip: false
  }
}
const state = initialState()

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
