export const PATIENTS_NO_PROGRAM_ID = 'patientsnoprogram'
export const LOCAL_STORAGE_USER_PROGRAM_OPENED = 'clinicianProgramsOpened'
export const LOCAL_STORAGE_CLINICIAN_MY_PROGRAMS = 'patientsByMyPrograms'
export const LOCAL_STORAGE_CLINICIAN_ALL_PROGRAMS = 'patientsByAllPrograms'

export const ACTIONS = {
  getMyPrograms: 'programList/GET_MY_PROGRAMS',
  getAllPrograms: 'programList/GET_ALL_PROGRAMS',
  getVisibleProgramPatients: 'programList/GET_VISIBLE_PROGRAM_PATIENTS',
  getProgramActivePatients: 'programList/GET_PROGRAM_PATIENTS',
  toggleProgramAccordion: 'programList/TOGGLE_PROGRAM_ACCORDION',
  highlightPatient: 'programList/HIGHLIGHT_PATIENT',
  getCollapsedProgramPatients: 'programList/GET_COLLAPSED_PROGRAM_PATIENTS',
  abortPendingRequest: 'programList/ABORT_PENDING_REQUESTS',
  getUpdatedPatientData: 'programList/GET_LIST_DATA_FOR_SINGLE_PATIENT'
}

export const MUTATIONS = {
  setMyPrograms: 'programList/SET_MY_PROGRAMS',
  setAllPrograms: 'programList/SET_ALL_PROGRAMS',
  resetState: 'programList/RESET_STATE',
  toggleProgramAccordion: 'programList/TOGGLE_PROGRAM_ACCORDION',
  setProgramActivePatients: 'programList/SET_PROGRAM_ACTIVE_PATIENTS',
  setPatientsBatchToLoad: 'programList/SET_PATIENTS_BATCH_TO_LOAD',
  increasePatientsToShow: 'programList/PROGRAM_INCREASE_PATIENTS_TO_SHOW',
  highlightPatient: 'programList/HIGHLIGHT_PATIENT',
  markProgramAsLoading: 'programList/SET_PROGRAM_LOADING',
  setJustAddedPatientData: 'programList/SET_JUST_ADDED_PATIENT',
  updateProgramsPatientFromSignInButton: 'programList/UPDATE_PROGRAMS_PATIENT_FROM_SIGN_IN_BUTTON',
  updatePatientInProgram: 'programList/UPDATE_PROGRAM_PATIENT_DATA',
  addJustAddedToActiveProgram: 'programList/ADD_JUST_ADDED_PATIENT_TO_ACTUAL_PROGRAM_RECORDS',
  removePatientFromProgram: 'programList/REMOVE_PATIENT_FROM_PROGRAM_LIST_DATA',
  changeObjectAttributes: 'programList/CHANGE_STORE_OBJECT_ATTRIBUTES',
  reloadPatientsInAllPrograms: 'programList/RELOAD_PATIENTS_IN_ALL_PROGRAMS'
}

export const ENDPOINTS = {
  getMyPrograms: 'programs/my',
  getAllPrograms: 'programs/all',
  getActiveProgramPatients: 'programs/{id}/active-patients'
}
