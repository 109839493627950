import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'

/**
 * Handle constructions of Analytics Gender Breakdown Chart Model.
 */
export class AnalyticsDischargeDispositionChartModel extends AnalyticsChartModel {
  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'dischargeDisposition'
  }

  getChartSubdivisionKey () {
    return 'patientStatus'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program) {
    return `${program.name} ${i18n.messages[i18n.locale].dischargeDisposition}`
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    if (!queryResult || !queryResult.length) {
      return []
    }

    const totalRecords = queryResult.reduce((total, r) => total + r.count_program_assignment_uuid, 0)
    const data = queryResult.map(r => {
      const status = i18n.messages[i18n.locale][r.program_status]
      return {
        name: !status ? r.program_status : status,
        y: this.getPercentage(totalRecords, r.count_program_assignment_uuid)
      }
    })
    return [{ data }]
  }
}
