import { AnalyticsOutpatientScoreOverTimeChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsOutpatientScoreOverTimeChartModel'
import { AnalyticsOutpatientWeeksToBelowCutoffChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsOutpatientWeeksToBelowCutoffChartModel'

/**
 * Analytics Program Report model Interface.
 */
export class AnalyticsProgramMeasureModelOutpatient {
  constructor (measure, programQueryData, programData, currentQueryConfiguration) {
    const outpatientScoreOverTimeParams = this.getOutpatientScoreOverTimeObject(programQueryData.outpatientScoreOverTime, measure)
    if (outpatientScoreOverTimeParams) {
      this.outpatientScoreOverTime = new AnalyticsOutpatientScoreOverTimeChartModel(
        outpatientScoreOverTimeParams,
        programData,
        currentQueryConfiguration,
        true,
        measure
      )
      this.measureUuid = measure.uuid
      this.measureAbbr = measure.abbreviation
    }

    const outpatientWeeksToBelowCutoffParams = this.getOutpatientWeeksToBelowCutoffObject(programQueryData.outpatientWeeksToBelowCutoff, measure)
    if (outpatientWeeksToBelowCutoffParams) {
      this.outpatientWeeksToBelowCutoff = new AnalyticsOutpatientWeeksToBelowCutoffChartModel(
        outpatientWeeksToBelowCutoffParams,
        programData,
        currentQueryConfiguration,
        true,
        measure
      )
      this.measureUuid = measure.uuid
      this.measureAbbr = measure.abbreviation
    }
  }

  shouldShowAverageOverTime (scores) {
    scores = scores ? Object.values(scores) : []
    if (scores.length === 0 || (scores.length === 1 && scores[0].length === 0)) {
      return false
    }
    return true
  }

  getOutpatientScoreOverTimeObject (data, measure) {
    const scoresObject = measure.uuid && data && data[measure.uuid] ? data[measure.uuid] : []
    let scores = null
    try {
      scores = this.shouldShowAverageOverTime(scoresObject) ? scoresObject : null
    } catch (error) {
      console.log(error) // eslint-disable-line no-console
    }
    return scores ? { scores, measure } : null
  }

  getOutpatientWeeksToBelowCutoffObject (data, measure) {
    const scores = measure.uuid && data && data[measure.uuid] ? data[measure.uuid] : []
    return scores ? { scores, measure } : null
  }

  getMeasureData (scores, measureConfig) {
    const measureData = scores ? Object.values(scores) : null
    if (measureData && measureData.length) {
      measureConfig.score_type = measureData[0].score_type ? measureData[0].score_type : 'asd'
      measureConfig.score_label = measureData[0].score_label
    }
    return measureConfig
  }
}
