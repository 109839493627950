import { AnalyticsGenderBreakdownChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsGenderBreakdownChartModel'
import { AnalyticsAgeRangeChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsAgeRangeChartModel'
import { AnalyticsMaxLotChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsMaxLotChartModel'
import { AnalyticsDischargeDispositionChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsDischargeDispositionChartModel'
import { AnalyticsDaysFromAdmissionToDischargeChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsDaysFromAdmissionToDischargeChartModel'
import { AnalyticsProgramMeasureModel } from '@/mixins/analytics/Reports/AnalyticsProgramMeasureModel'
import { AnalyticsProgramMeasureModelOutpatient } from '@/mixins/analytics/Reports/AnalyticsProgramMeasureModelOutpatient'

/**
 * Analytics Program Report model Interface.
 */
export class AnalyticsProgramReportModel {
  constructor (programData, programQueryData, currentQueryConfiguration, measuresWithResults, measureChartSettings, patientType, dateLabel) {
    this.name = programData.name
    this.uuid = programData.uuid
    this.dateLabel = dateLabel
    this.patientType = patientType
    this.queryTimestamp = `${programData.uuid}_${Date.now()}`
    const genderBreakdown = programQueryData.genderBreakdown ? programQueryData.genderBreakdown : []
    const ageRange = programQueryData.ageRange ? programQueryData.ageRange : []
    this.demographics = {
      genderBreakdown: new AnalyticsGenderBreakdownChartModel(
        genderBreakdown,
        programData,
        currentQueryConfiguration,
        true,
        null
      ),
      ageRange: new AnalyticsAgeRangeChartModel(
        ageRange,
        programData,
        currentQueryConfiguration,
        true,
        null
      )
    }

    const dischargeDisposition = programQueryData.dischargeDisposition ? programQueryData.dischargeDisposition : []
    const maxLot = programQueryData.maxLot ? programQueryData.maxLot : []
    this.patientStatus = {
      dischargeDisposition: new AnalyticsDischargeDispositionChartModel(
        dischargeDisposition,
        programData,
        currentQueryConfiguration,
        true,
        null
      ),
      daysFromAdmissionToDischargeAssessment: new AnalyticsDaysFromAdmissionToDischargeChartModel(
        programQueryData.daysFromAdmissionToDischarge,
        programData,
        currentQueryConfiguration,
        true,
        null
      )
    }
    this.patientStatusOutpatient = {
      maxLot: new AnalyticsMaxLotChartModel(
        maxLot,
        programData,
        currentQueryConfiguration,
        true,
        null
      )
    }
    this.outcomes = {}
    this.outcomesOutpatient = {}
    if (this.patientType === 'inpatient') {
      this.outcomes = this.getOutcomesDataGroupedByMeasures(measuresWithResults, programQueryData, programData, currentQueryConfiguration, measureChartSettings)
    } else {
      this.outcomesOutpatient = this.getOutcomesDataGroupedByMeasures(measuresWithResults, programQueryData, programData, currentQueryConfiguration, measureChartSettings)
    }
  }

  getOutcomesDataGroupedByMeasures (measuresWithResults, programQueryData, programData, currentQueryConfiguration, measureChartSettings) {
    return measuresWithResults.map(measure => {
      measure.chartSettings = measureChartSettings[measure.uuid]
      if (this.patientType === 'inpatient') {
        return new AnalyticsProgramMeasureModel(measure, programQueryData, programData, currentQueryConfiguration)
      }
      return new AnalyticsProgramMeasureModelOutpatient(measure, programQueryData, programData, currentQueryConfiguration)
    })
  }

  getConfigurationForExport () {
    const charts = [
      this.demographics.genderBreakdown,
      this.demographics.ageRange,
      this.patientStatus.daysFromAdmissionToDischargeAssessment,
      this.patientStatus.dischargeDisposition,
      this.patientStatusOutpatient.maxLot
    ]

    // Add Measure Charts to the charts array.
    for (let index = 0, length = this.outcomes.length; index < length; index++) {
      charts.push(this.outcomes[index].admitAndDischargeScores)
      charts.push(this.outcomes[index].scoreOverTime)
    }
    for (let index = 0, length = this.outcomesOutpatient.length; index < length; index++) {
      charts.push(this.outcomesOutpatient[index].outpatientScoreOverTime)
      charts.push(this.outcomesOutpatient[index].outpatientWeeksToBelowCutoff)
    }

    // Get Configuration for all the charts that are currently related to this program
    const chartConfiguration = {}
    for (let i = 0, len = charts.length; i < len; i++) {
      if (charts[i]) {
        chartConfiguration[charts[i].id] = charts[i].getConfigurationForExport()
      }
    }

    return chartConfiguration
  }
}
