import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function initialState () {
  return {
    myProgramsCollection: null,
    allProgramsCollection: null,
    justAddedPatientData: null,
    patientsShowingByProgram: []
  }
}

const state = initialState()
export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
