import axios from 'axios'
import getters from './getters'
import {
  SET_PROGRAMS_TABS,
  GET_PROGRAMS,
  GET_PROGRAM_LIST_BY_ROLE,
  GET_PROGRAM_LIST_BY_ROLE_LOADING,
  GET_PROGRAM,
  GET_PROGRAM_LOADING,
  GET_PROGRAMS_ONLY,
  GET_PROGRAM_TYPES,
  GET_PROGRAM_MEASURES,
  SIGN_OUT,
  PROGRAMS_TABS,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  DELETE_PROGRAM_MEASURE,
  PROGRAM_JUST_ADDED,
  RESET_PROGRAM_JUST_ADDED,
  PRE_SELECTED_PROGRAM,
  PROGRAMS_SEARCH,
  GET_SETTINGS_PROGRAMS,
  CREATE_SETTINGS_PROGRAM,
  UPDATE_SETTINGS_PROGRAM,
  SET_SELECTED_PROGRAM,
  ENDPOINTS
} from './constants'
import {
  createNewCancelTokenRequest
} from '@/store/modules/patients/index.js'

function initialState () {
  return {
    programsTab: 'my',
    searchPrograms: '',
    settingPrograms: [],
    programs: [],
    programListByRole: [],
    programListByRoleLoading: false,
    programsNotGroups: [],
    programsJustAdded: [],
    singleProgram: { type: '' },
    singleProgramLoading: false,
    singleProgramMeasures: {},
    programTypes: [],
    patientsShowingByProgram: [],
    preSelectedProgramId: null,
    selectedProgram: []
  }
}

function submitProgram (program, url, method, commit, mutation) {
  program.length = Number(program.length)
  return new Promise((resolve, reject) => {
    axios[method](url, program)
      .then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          if (method === 'post') {
            commit(PROGRAM_JUST_ADDED, response.data.id)
          }
          commit(mutation, response.data)
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// initial state
const state = initialState()

// actions
const actions = {
  CREATE_PROGRAM ({ commit }, program) {
    return submitProgram(program, ENDPOINTS.PROGRAM, 'post', commit, CREATE_SETTINGS_PROGRAM)
  },

  UPDATE_PROGRAM ({ commit }, program) {
    return submitProgram(program, `${ENDPOINTS.PROGRAM}/${program.id}`, 'put', commit, UPDATE_SETTINGS_PROGRAM)
  },

  GET_PROGRAM_TYPES ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_PROGRAM_TYPES)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_PROGRAM_TYPES, response.data)
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  GET_PROGRAM ({ commit }, programId) {
    commit(GET_PROGRAM_LOADING, true)
    if (!programId) {
      return commit(GET_PROGRAM, { length_type: 'days' })
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_PROGRAM.replace('{programId}', programId))
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_PROGRAM, response.data)
            commit(GET_PROGRAM_LOADING, false)
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  GET_PROGRAM_MEASURES ({ commit }, programId) {
    if (!programId) {
      return commit(GET_PROGRAM_MEASURES, {})
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.PROGRAM_MEASURES.replace('{programId}', programId))
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_PROGRAM_MEASURES, response.data)
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  SET_PROGRAMS_TABS ({ commit }, tab) {
    commit(SET_PROGRAMS_TABS, tab)
  },

  SCHEDULE_PROGRAM_MEASURE (actions, schedule) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.PROGRAM_MEASURES.replace('{programId}', schedule.program_id)

      axios.post(url, schedule)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },

  [GET_PROGRAMS] ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PROGRAMS
      const promise = axios.get(url, { params })
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_PROGRAMS, response.data)
          resolve(response)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  [GET_PROGRAM_LIST_BY_ROLE] ({ commit }) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PROGRAM_LIST_BY_ROLE
      const promise = axios.get(url)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          const payload = { data: response.data }
          commit(GET_PROGRAM_LIST_BY_ROLE, payload)
          resolve(response)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  [GET_SETTINGS_PROGRAMS] ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_SETTINGS_PROGRAMS
      const cancelReq = createNewCancelTokenRequest('loadItems')
      const promise = axios.get(url, { params, cancelToken: cancelReq })
      promise.then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_SETTINGS_PROGRAMS, response.data.rows)
          resolve(response)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  GET_PROGRAMS_ONLY ({ commit }) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PROGRAMS_ONLY
      const promise = axios.get(url)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_PROGRAMS_ONLY, response.data)
          resolve()
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },
  CREATE_ASSIGNMENT ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.CREATE_ASSIGNMENT.replace('{programId}', data.program_id)
      const promise = axios.post(url, data)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(CREATE_ASSIGNMENT, response.data)
          resolve(response)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  UPDATE_ASSIGNMENT ({ commit }, data) {
    commit(UPDATE_ASSIGNMENT, data)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.UPDATE_ASSIGNMENT
        .replace('{patientId}', data.patient_id)
        .replace('{accessControlProgramId}', data.access_control_program_id)

      const promise = axios.put(url, data.newData)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(UPDATE_ASSIGNMENT, response.data)
          resolve(response)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  [DELETE_PROGRAM_MEASURE] ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.DELETE_PROGRAM_MEASURE
        .replace('{programId}', data.program_id)
        .replace('{measureId}', data.measure_id)
      const promise = axios.delete(url)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(DELETE_PROGRAM_MEASURE, response.data)
          resolve()
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  DISCHARGE_FROM_PROGRAM (actions, data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.DISCHARGE_FROM_PROGRAM.replace('{accessControlProgramId}', data.access_control_program_id).replace('{patientId}', data.patient_id)
      const promise = axios.post(url, data)
      promise.then((response) => {
        return response.data.error ? reject(response.data.error) : resolve(response.data)
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  RESET_PROGRAM_JUST_ADDED ({ commit }) {
    commit(RESET_PROGRAM_JUST_ADDED)
  }
}

// mutations
const mutations = {
  [SET_PROGRAMS_TABS] (state, tab) {
    if (PROGRAMS_TABS.includes(tab)) {
      state.programsTab = tab
    }
  },

  [GET_PROGRAM] (state, program) {
    program.length_type = 'days'
    if (program.length && program.length % 7 === 0) {
      program.length_type = 'weeks'
      program.length = program.length / 7
    }
    program.program_clinicians = program.program_clinicians ? program.program_clinicians : []
    program.program_clinician_teams = program.program_clinician_teams ? program.program_clinician_teams : []
    state.singleProgram = program
  },

  [GET_PROGRAM_LOADING] (value) {
    state.programLoading = value
  },

  [GET_PROGRAMS] (state, programs) {
    programs.forEach(program => {
      // This will set to null parentId to prevent showing the program name when parent id is 0 (set to 0 by a temporary bug)
      program.parent_id = program.parent_id === '0' ? null : program.parent_id
      program.justAdded = state.programsJustAdded.includes(program.id)
    })
    const justAdded = programs.filter(p => p.justAdded)
    const notJustAdded = programs.filter(p => !p.justAdded)
    notJustAdded.sort((a, b) => a.name.localeCompare(b.name))
    state.programs = justAdded.concat(notJustAdded)
  },

  [GET_PROGRAM_LIST_BY_ROLE] (state, payload) {
    const collection = []
    const programs = payload.data
    Object.entries(programs).forEach(item => {
      if (Array.isArray(item)) {
        const thisObject = {
          uuid: null,
          name: `${item[0]} Programs`,
          isHeader: true
        }
        collection.push(thisObject, ...item[1])
      }
    })
    // if (payload.showUnassigned) {
    //   collection.push({
    //     uuid: null,
    //     name: 'Unassigned Patients',
    //     isHeader: false
    //   })
    // }
    state.programListByRole = collection
  },

  [GET_PROGRAM_LIST_BY_ROLE_LOADING] (value) {
    state.programListByRoleLoading = value
  },

  [GET_PROGRAMS_ONLY] (state, programs) {
    programs.forEach(program => {
      program.justAdded = state.programsJustAdded.includes(program.id)
    })
    const justAdded = programs.filter(p => p.justAdded)
    const notJustAdded = programs.filter(p => !p.justAdded)
    notJustAdded.sort((a, b) => a.name.localeCompare(b.name))
    state.programsNotGroups = justAdded.concat(notJustAdded)
  },

  [GET_PROGRAM_MEASURES] (state, data) {
    state.singleProgramMeasures = data
  },

  [GET_SETTINGS_PROGRAMS] (state, data) {
    state.settingPrograms = data
  },

  [GET_PROGRAM_TYPES] (state, programTypes) {
    state.programTypes = programTypes
  },

  [CREATE_ASSIGNMENT] () {
    // TODO: Add to PatientsByProgram list when we start using real data
  },

  [UPDATE_ASSIGNMENT] (state, data) {
    this.dispatch('UPDATE_SINGLE_PATIENT_PROGRAM', data)
  },

  [DELETE_PROGRAM_MEASURE] () {
  },

  [PROGRAM_JUST_ADDED] (state, id) {
    state.programsJustAdded.push(id)
  },

  [RESET_PROGRAM_JUST_ADDED] (state) {
    state.programsJustAdded = []
  },

  [PRE_SELECTED_PROGRAM] (state, preSelectedProgramId) {
    state.preSelectedProgramId = preSelectedProgramId
  },

  [PROGRAMS_SEARCH] (state, val) {
    state.searchPrograms = val
  },

  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },

  [CREATE_SETTINGS_PROGRAM] (state, program) {
    state.settingPrograms.unshift(program)
  },
  [UPDATE_SETTINGS_PROGRAM] (state, program) {
    const i = state.settingPrograms.findIndex(p => Number(p.id) === Number(program.id))
    if (i !== -1) {
      const oldPrograms = state.settingPrograms[i]
      for (const k in oldPrograms) {
        if (oldPrograms.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          oldPrograms[k] = program[k]
        }
      }
    }
  },

  [SET_SELECTED_PROGRAM] (state, program) {
    state.selectedProgram = program
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
}
