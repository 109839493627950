import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function initialState () {
  return {
    locations: [],
    states: [],
    settingsSearch: '',
    locationHasClinicians: false,
    settingEditingInForm: null
  }
}
const state = initialState()

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
