export const ErrorHandler = {
  methods: {
    $handleApiError (error) {
      let message = ''
      if (typeof error === 'string') {
        message = error
      } else {
        try {
          message = error.response.data.error
        } catch (e) {
          console.log(e) // eslint-disable-line no-console
        }

        if (!message) {
          try {
            message = this.getErrorString(error.response.data.errors)
          } catch (e) {
            console.log(e) // eslint-disable-line no-console
          }
        }

        if (!message) {
          try {
            message = error.response.statusText
          } catch (e) {
            console.log(e) // eslint-disable-line no-console
          }
        }
        if (!message) {
          try {
            message = error.data.message
          } catch (e) {
            console.log(e) // eslint-disable-line no-console
          }
        }
        if (!message) {
          message = this.$t('generalError')
        }
      }

      if (message === 'Invalid access token') {
        message = 'Your session has expired. Please sign in.'
      }

      this.$toast.error({ message })

      if (process.env.NODE_ENV !== 'production') {
        console.log('Error:', error) // eslint-disable-line no-console
      }
    },
    getErrorString (errors) {
      if (errors) {
        const errorsArray = Object.values(errors)
        if (errorsArray.length) {
          const errors = errorsArray.join(', ')
          return `${errors}`.trim()
        }
      }
    }
  }
}
