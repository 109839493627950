import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings'
import security from './modules/security'
import measures from './modules/measures'
import programs from './modules/programs'
import user from './modules/user'
import patients from './modules/patients'
import clinicians from './modules/clinicians'
import lastSession from './modules/lastSession'
import appointments from './modules/appointments'
import respondents from './modules/respondents'
import applicationState from './modules/applicationState'
import locations from './modules/locations'
import clinicalUsers from './modules/clinicalUsers'
import patientUsers from './modules/patientUsers'
import analytics from './modules/analytics'
import programList from './modules/programList'
import exports from './modules/exports'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    settings,
    security,
    measures,
    programs,
    programList,
    user,
    patients,
    lastSession,
    appointments,
    respondents,
    clinicians,
    locations,
    clinicalUsers,
    patientUsers,
    analytics,
    exports,
    applicationState
  },
  strict: debug
})
