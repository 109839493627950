import Roles from '@/data/roles'

export const GET_CLINICAL_USERS = 'locations/GET_CLINICAL_USERS'
export const DELETE_CLINICAL_USER = 'locations/DELETE_CLINICAL_USER'
export const UPDATE_CLINICAL_USER = 'locations/UPDATE_CLINICAL_USER'
export const UPDATE_USER_SUPERIORS_AND_SUBORDINATES = 'locations/UPDATE_USER_SUPERIORS_AND_SUBORDINATES'
export const SAVE_CLINICAL_USER = 'locations/SAVE_CLINICAL_USER'
export const GET_SUPERVISORS = 'locations/GET_SUPERVISORS'
export const GET_ACTIVE_SUPERVISORS = 'locations/GET_ACTIVE_SUPERVISORS'
export const GET_ACTIVE_SUPERVISORS_LOADING = 'locations/GET_ACTIVE_SUPERVISORS_LOADING'
export const CHANGE_STATUS = 'locations/CHANGE_STATUS'
export const SORT_SUPERVISORS_BY_RECENTLY_SELECTED = 'clinicalUsers/SORT_SUPERVISORS_BY_RECENTLY_SELECTED'
export const VALIDATE_PROVIDER_ID = 'clinicalUsers/VALIDATE_PROVIDER_ID'
export const ROLE_CLINICIAN_OBJECT = Roles.find(r => r.id === 'clinician')
export const ROLE_SUPERVOSOR_OBJECT = Roles.find(r => r.id === 'supervisor')

export const ENDPOINTS = {
  GET_CLINICAL_USERS: 'clinical-user',
  UPDATE_CLINICAL_USER: 'clinical-user/{id}',
  DELETE_CLINICAL_USER: 'clinical-user/{id}',
  CHANGE_STATUS: 'clinical-user/change-status/{id}',
  VALIDATE_PROVIDER_ID: 'clinical-user/validate-provider-id-available',
  GET_SUPERVISORS: '/clinical-user/by-role/supervisor',
  SAVE_CLINICAL_USER: 'clinical-user'
}
