// Mutation types
export const SIGN_OUT = 'respondents/SIGN_OUT'
export const GET_RESPONDENTS = 'respondents/GET_RESPONDENTS'
export const SEND_REMINDER = 'respondents/SEND_REMINDER'
export const SEND_INVITATION = 'respondents/SEND_INVITATION'

export const ENDPOINTS = {
  GET_RESPONDENTS: 'respondents',
  SEND_REMINDER: 'respondents/{acId}/send-reminder',
  SEND_INVITATION: 'users/{acId}/send-invitation'
}
