import actions from './actions'
import mutations from './mutations'
import getters from './getters'

function initialState () {
  return {
    clinicians: [],
    cliniciansLoading: false,
    patientMeasures: [],
    patientMeasuresPDF: [],
    patientMeasuresPDFLoading: false,
    teams: [],
    alerts: [],
    alertsCount: 0,
    alertsCountLoading: false,
    deletingScheduleMeasure: null,
    patientSingleMeasure: {},
    patientSingleMeasureLoading: false,
    patientSingleMeasurePDF: {},
    patientSingleMeasurePDFLoading: false,
    patientSingleMeasureCompletedSessions: {},
    clinician_team: null
  }
}

// initial state
const state = initialState()

export default {
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  actions: {
    ...actions
  },
  mutations: {
    ...mutations
  }
}
