<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { setScrollBarWidth } from '@/mixins/ScrollBarWidthHelper'
import { LogoutTimer } from '@/mixins/LogoutTimer'
import { ValidatorRules } from '@/mixins/ValidatorRules'
import { intializeApps, initializeAppUser, reInitializeAppUser } from '@/mixins/ThirdPartyApps'
import '@/components/common/Prototypes'
import packageJson from '../package.json'
import {
  refreshAccessTokenFromAxiosResponse,
  handle401RefreshToken
} from '@/store/modules/security/sessionHandler'

const APP_VERSION = 'app_version'

function checkAppVersion () {
  const currentVersion = localStorage.getItem(APP_VERSION) || ''
  localStorage.setItem(APP_VERSION, packageJson.version)
  if (currentVersion !== '' && packageJson.version !== currentVersion) {
    deleteAllCookies()
    window.location.reload()
  }
}

function deleteAllCookies () {
  const c = document.cookie.split('; ')
  for (const i in c) {
    try {
      const cookieName = /^[^=]+/.exec(c[i])[0]
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    } catch (error) {
      console.log(error) // eslint-disable-line no-console
    }
  }
}

/**
 * Set Request Interceptors.
 *
 * @param VueComponent
 */
function setRequestInterceptors (vueComponent) {
  axios.interceptors.response.use(rsp => {
    const awsErrors = ['Internal server error', 'Endpoint request timed out']
    // Handle case of AWS returning a 200 with an error
    if (rsp.status === 200 && rsp.data && rsp.data.message && awsErrors.includes(rsp.data.message)) {
      return new Promise((resolve, reject) => {
        rsp.data.forceShow = true
        reject(rsp)
      })
    }
    if (vueComponent.$route.name === 'MaintenancePage') {
      vueComponent.$router.push('/')
    }

    // User Access has been revoked, Redirect to Home.
    if (rsp && rsp.data && rsp.data.hasOwnProperty('has_access') && rsp.data.has_access === false) { // eslint-disable-line no-prototype-builtins
      rsp.data.cancelActions = true
    }

    refreshAccessTokenFromAxiosResponse(rsp)
    return rsp
  }, err => {
    if (axios.isCancel(err)) {
      return err
    }

    const isAccountSetupOrPasswordReset = vueComponent.$route.query.hasOwnProperty('next') || vueComponent.$route.query.hasOwnProperty('resetPassword') // eslint-disable-line no-prototype-builtins

    return new Promise((resolve, reject) => {
      if (err.response.status === 404) {
        err.redirect = false
        reject(err)
      }
      if (err.response.status === 401 && err.response.data.error === 'Invalid access token' && !isAccountSetupOrPasswordReset) {
        err.redirect = false
        vueComponent.$store.dispatch('SIGN_OUT_CLIENT_SIDE')
        vueComponent.$router.push({ name: 'Login' })
        reject(err)
      } else if (err.response.status === 401 && !isAccountSetupOrPasswordReset) {
        return handle401RefreshToken(err).then(result => {
          resolve(result)
        }).catch(() => {
          err.redirect = false
          vueComponent.$store.dispatch('SIGN_OUT_CLIENT_SIDE')
          reject(err)
        })
      }
      if (err.response.status === 503) {
        err.redirect = false
        vueComponent.$router.push({ name: 'MaintenancePage' })
        reject(err)
      }
      reject(err)
      throw err
    })
  })
}
export default {
  name: 'App',
  mixins: [ErrorsDictionary, LogoutTimer, ValidatorRules],
  data () {
    return {
      userLoaded: false,
      anonymousUserLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      gSettings: 'generalSettings',
      loggedInUser: 'loggedInUser'
    })
  },
  watch: {
    loggedInUser: {
      /**
       * 4 statuses:
       *  1. user logged in, yes pendo - update pendo
       *  2. user logged in, no pendo - add pendo known
       *  3. user logged out, yes pendo anonymous - update pendo anonymous
       *  4. user logged out, no pendo - update pendo with empty values
       */
      deep: true,
      // immediate: false, do not make true as-is
      handler (newVal) {
        if (newVal.username && this.userLoaded) { // 1
          // update pendo
          this.userLoaded = true
          reInitializeAppUser(newVal, { name: this.gSettings.customer_name })
        } else if (newVal.username && !this.userLoaded && !this.anonymousUserLoaded) {
          // add pendo known user
          this.userLoaded = true
          initializeAppUser(newVal, { name: this.gSettings.customer_name })
        } else if (newVal.username && !this.userLoaded && this.anonymousUserLoaded) {
          // update pendo known user
          this.userLoaded = true
          reInitializeAppUser(newVal, { name: this.gSettings.customer_name })
        } else if (!newVal.username && this.userLoaded) {
          // update with empty vals
          reInitializeAppUser({}, {})
        }
      }
    }
  },
  beforeCreate () {
    setRequestInterceptors(this)
  },
  created () {
    intializeApps()
    this.$validator.localize('en', this.errorsDictionary)
    checkAppVersion(this)
    this.$store.dispatch('SET_CURRENT_BROWSER')
    this.$store.dispatch('GENERAL_SETTINGS').then(() => {
      this.resetTimer()
    }).catch((e) => {
      this.$handleApiError(e)
    })
  },
  mounted () {
    setScrollBarWidth()
  }
}
</script>

<style lang="css">
  /* Create base Tailwind styles */
  @tailwind base;

  /*
    TODO: Important... move to bottom of the file and overwrite/remove Bootstrap's base at a later time.
    The overwrites to Bootstrap base styles and utilities and Tailwind would cause a lot of visual thrashing
    as Bootstrap values are over-written. Get visual diffing in place, then remove Bootstrap entirely!
  */
</style>

<style lang="scss">
  @import "assets/styles/styles.scss";
</style>

<style lang="css">
  /* Create Tailwind utilities */
  @tailwind components;
  @tailwind utilities;
</style>
