export default {
  programsTab: state => state.programsTab,
  getSearchPrograms: state => state.searchPrograms,
  programs: state => state.programs,
  getProgramsByRole: state => state.programListByRole,
  getProgramsByRoleLoading: state => state.programListByRoleLoading,
  getSingleProgram: state => state.singleProgram,
  getSingleProgramLoading: state => state.singleProgramLoading,
  getProgramTypes: state => state.programTypes,
  getSingleProgramMeasures: state => state.singleProgramMeasures,
  programsNotGroups: state => state.programsNotGroups,
  getSettingPrograms: state => state.settingPrograms,
  preSelectedProgramId: state => state.preSelectedProgramId,
  getSelectedProgram: state => state.selectedProgram
}
