// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import i18n from './i18n'
import VueScrollTo from 'vue-scrollto'
import axios from 'axios'
import VeeValidate from 'vee-validate'
import Loading from 'vue-loading-overlay'
import iziToast from 'izitoast'
import More from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import setPageTitle from '@/mixins/SetPageTitle'
import StockInit from 'highcharts/modules/stock'
import Exporting from 'highcharts/modules/exporting.js'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display.js'
import ExportData from 'highcharts/modules/export-data.js'
import VTooltip from 'v-tooltip'
import { ErrorHandler } from '@/mixins/ErrorHandler'
import { setAccessTokensFromLocalStorage, setSessionRequestInterceptor, handleApiKeyHeader } from '@/store/modules/security/sessionHandler'
import { CHANGE_SETUP_DONE_PROMISE_VALUE } from '@/store/modules/applicationState/constants'

/*
* START Fix for vue-good-table when fixed-header is true
* remove when library fix the issue https://github.com/xaksis/vue-good-table/issues/590
*/
window.getComputedStyleNative = window.getComputedStyle
window.getComputedStyle = (element, pseudoElt = null) => {
  if (!element) {
    return { width: 'auto' }
  }
  return window.getComputedStyleNative(element, pseudoElt)
}
/*
* END Fix for vue-good-table when fixed-header is true
*/

StockInit(Highcharts)
Exporting(Highcharts)
ExportData(Highcharts)
NoDataToDisplay(Highcharts)
More(Highcharts)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
const ua = navigator.userAgent.toLowerCase()
const isSafari = ua.indexOf('safari') !== -1 && ua.indexOf('chrome') <= -1
setAccessTokensFromLocalStorage()

Vue.use(VTooltip)
Vue.use(VeeValidate)
iziToast.settings({ position: 'topRight', class: 'default-owl', icon: 'fal', closeOnClick: true, timeout: 4000 })
// Use iziToast as a plugin
Vue.prototype.$toast = iziToast
Vue.use(VueScrollTo)
Vue.use(Loading)
Vue.mixin(ErrorHandler)
Vue.config.productionTip = false

Vue.config.errorHandler = (err) => {
  // TODO: add logic to save the error
  console.error(err) // eslint-disable-line no-console
  // router.replace({name: 'PageNotFound'})
}

setSessionRequestInterceptor()

router.beforeEach((to, from, next) => {
  // Handle special api-key header
  handleApiKeyHeader(to)

  // TODO get lang from store
  const lang = 'en'
  if (i18n.locale !== lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
  }
  // Fix for right swipe on safari, we blur the content for safari to take a screenshoot and prevent sensitive information exposure
  if (isSafari) {
    document.getElementById('app').classList.add('safariFix')
    setTimeout(() => {
      next()
    }, 0)
    // added this here because for some reason its not always going through router.afterEach
    setTimeout(() => {
      document.getElementById('app').classList.remove('safariFix')
    }, 200)
  } else {
    next()
  }
})

router.afterEach((to) => {
  // Fix for safari, we remove the class that blurs the content
  setTimeout(() => {
    if (document.getElementById('app')) {
      document.getElementById('app').classList.remove('safariFix')
    }
  }, 0)

  // Update page title but only after successfully routing
  Vue.nextTick(() => {
    setPageTitle(to.name)
  })
})

/**
 * Check with server user session data and refresh and update UI status.
 * @returns {Promise<any>}
 */
function checkUserSession (store) {
  return new Promise((resolve) => {
    if (!store.getters.isAuthenticated) {
      return resolve(true)
    }
    store.dispatch('GET_SESSION').then(() => {
      return resolve(true)
    }).catch(() => {
      store.dispatch('SIGN_OUT').then(() => {
        return resolve(true)
      })
    })
  })
}

store.commit(CHANGE_SETUP_DONE_PROMISE_VALUE, checkUserSession(store))
store.dispatch('GENERAL_SETTINGS').then(() => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    store,
    router,
    i18n,
    components: { App },
    template: '<App/>'
  })
})
