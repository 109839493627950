import axios from 'axios'
import { ANALYTICS_UNASSIGNED_UUID, ENDPOINTS } from '@/store/modules/analytics/outcomes-explorer/constants'
import { ENDPOINTS as LIBRARY_ENDPOINTS } from '@/store/modules/measures/constants'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Charts that Information is already a collection From analytics.
 *
 * @type {string[]}
 */
const RANGE_BASED_CHART_ALIAS = [
  'ageRange', 'daysFromAdmissionToDischarge', 'maxLot'
]

/**
 * Determines wither to apply 'analytics_graph' filter to measures list
 *
 * @type {string[]}
 */
const GRAPH_FILTER_ALIAS = [
  'outpatientWeeksToBelowCutoff'
]

/**
 * Take the raw response and hydrate the result as needed (like converting null to ANALYTICS_UNASSIGNED_UUID)
 *
 * @param data
 * @param uuidType
 * @returns {Object}
 */
export const hydrateResponse = (data, uuidType) => {
  return {
    ...data,
    results: Array.isArray(data.results)
      ? data.results.map(result => ({
        ...result,
        [uuidType]: result[uuidType] !== null ? result[uuidType] : ANALYTICS_UNASSIGNED_UUID
      }))
      : data.results
  }
}

/**
 * get the proper UUID type for outpatient or inpatient queries
 *
 * @param params
 * @returns {string}
 */
export const getUUIDType = (params) => params.program_or_location_filter === 'locationFilter' ? 'location_uuid' : 'program_uuid'

/**
 * Determine whether we are filtering by inpatient (programs) or outpatient (locations)
 *
 * @param params
 * @returns {string}
 */
export const getPatientType = (params) => params.program_or_location_filter === 'locationFilter' ? 'outpatient' : 'inpatient'

/**
 * Get Available Programs result from analytics
 *
 * @param criteria Default Criteria Object to be send to analytics generated from selected Filters.
 * @param uuidType UUID Type (program or location).
 * @returns {{count: int, results: []}}
 */
export async function getAvailableProgramsOrLocations (criteria, uuidType) {
  const params = { criteria }
  return getAvailableFieldByColumn(params, uuidType)
}
/**
 * Get Available Measures result from analytics
 *
 * @param criteria Default Criteria Object to be send to analytics generated from selected Filters.
 * @returns {{count: int, results: []}}
 */
export async function getAvailableMeasures (criteria) {
  const params = { criteria }
  return getAvailableFieldByColumn(params, 'owl_measure_uuid')
}

/**
 * get Available Field By Column.
 *
 * @param params
 * @param column
 * @returns {Promise<unknown>}
 */
export async function getAvailableFieldByColumn (params, column) {
  params.group_by = [{
    column,
    function: 'count',
    include_column: true
  }]

  return new Promise((resolve, reject) => {
    axios.post(ENDPOINTS.ANALYTICS_GET_RECORDS, params).then((response) => {
      if (response.data.error) {
        return reject(response.data.error)
      }
      const responseData = hydrateResponse(response.data, column)
      resolve(responseData)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * Get Charting information for all the programs or locations with results.
 *
 * @param programOrLocationUUIDS
 * @param criteria
 * @param uuidType
 * @returns {Promise<void>}
 */
export async function getChartData (uuids, criteria, uuidType, measuresWithGraphs) {
  /*
    Here's how the different charts are broken down by service line:
    Outpatient/Locations:
      maxLot
      outpatientScoreOverTime
      outpatientWeeksToBelowCutoff
    Programs:
      dischargeDisposition
      daysFromAdmissionToDischarge
      admitDischargeScores
      averageScoreOverTime
    Both:
      genderBreakdown
      ageRange

    Outpatient and Programs each have their own 'Outcomes' and 'Patient Status' sections, but then the "Demographics' section is shared between the two
*/

  return new Promise(resolve => {
    const promisesOutpatient = () => [
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeMaxLOTQuery, 'maxLot', addResultsByMeasure),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeOutpatientScoreOverTimeQuery, 'outpatientScoreOverTime', addResultsByMeasure),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeOutpatientWeeksToBelowCutoffQuery, 'outpatientWeeksToBelowCutoff', addResultsByMeasure, measuresWithGraphs)
    ]

    const promisesInpatient = () => [
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeDischargeDispositionQuery, 'dischargeDisposition', addProgramResults),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeDaysFromAdmissionToDischargeQuery, 'daysFromAdmissionToDischarge', addProgramResults),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeAdmitDischargeScoresQuery, 'admitDischargeScores', addResultsByMeasure),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeAverageScoreOverTime, 'averageScoreOverTime', addResultsByMeasure)
    ]

    const promisesAll = () => [
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeGenderBreakdownQuery, 'genderBreakdown', addProgramResults),
      getProgramResultWithSingleRequestFlow(uuids, uuidType, criteria, executeAgeRangeQuery, 'ageRange', addProgramResults)
    ]

    const promises = uuidType === 'location_uuid' ? promisesOutpatient().concat(promisesAll()) : promisesInpatient().concat(promisesAll())

    Promise.all(promises).then((chartQueryResults) => {
      const resultsByProgram = {}
      chartQueryResults.forEach((queryResult) => {
        // Need to filter out superfluous response data from pairing-modified query
        if (queryResult.alias === 'dischargeDisposition' && queryResult.results && queryResult.results.find(el => el.response_type)) {
          queryResult.results = queryResult.results.filter(r => r.response_type === 'discharge')
        }
        queryResult.callback(resultsByProgram, queryResult, uuidType)
      })
      resolve(resultsByProgram)
    })
  })
}

/**
 * Add Query Results to Program Map for normal Queries
 *
 * @param programMapObject
 * @param queryResults
 */
function addProgramResults (programMapObject, queryResults, uuidType) {
  if (queryResults.results) {
    queryResults.results.forEach((result) => {
      // Create Key if it does not exists.
      addProgramToProgramMapIfDoesNotExists(programMapObject, result[uuidType])

      // When Range Based all information is grouped
      if (RANGE_BASED_CHART_ALIAS.includes(queryResults.alias)) {
        programMapObject[result[uuidType]][queryResults.alias] = result
        return true
      }

      addResultAliasToProgramMapIfDoesNotExists(programMapObject, result[uuidType], queryResults.alias)
      programMapObject[result[uuidType]][queryResults.alias].push(result)
    })
  }
}

/**
 * Create Key if it does not exists.
 *
 * @param programMapObject
 * @param programUUID
 */
function addProgramToProgramMapIfDoesNotExists (programMapObject, programUUID) {
  if (!programMapObject.hasOwnProperty(programUUID)) { // eslint-disable-line no-prototype-builtins
    programMapObject[programUUID] = {}
  }
}

/**
 * Create Default object if it does not exists and add items to the collection.
 *
 * @param programMapObject
 * @param programUUID
 * @param alias
 */
function addResultAliasToProgramMapIfDoesNotExists (programMapObject, programUUID, alias) {
  if (!programMapObject[programUUID].hasOwnProperty(alias)) { // eslint-disable-line no-prototype-builtins
    programMapObject[programUUID][alias] = []
  }
}

/**
 * Aggregate Score over time results to the Program Map Object
 *
 * @param programMapObject
 * @param queryResults
 */
function addResultsByMeasure (programMapObject, queryResults) {
  if (!queryResults.results) {
    return false
  }

  const objectKeys = Object.keys(queryResults.results)
  for (let i = 0, len = objectKeys.length; i < len; i++) {
    const programUUID = objectKeys[i]
    addProgramToProgramMapIfDoesNotExists(programMapObject, programUUID)
    addResultAliasToProgramMapIfDoesNotExists(programMapObject, programUUID, queryResults.alias)
    programMapObject[programUUID][queryResults.alias] = queryResults.results[programUUID]
  }
}

/**
 * Need to use this flow when can query multiple programs at the same time.
 *
 * @param uuids
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function getProgramResultWithSingleRequestFlow (uuids, uuidType, criteria, callback, alias, aggregationCallback, measuresWithGraphs = null) {
  // Don't believe this is required, but leaving this. It breaks location queries.
  if (uuidType === 'program_uuid') {
    criteria[uuidType] = uuids
  }

  // for cutoff chart, filter out measures where 'analytics_graph' === 0:
  // if we mutate criteria here, it stays mutated on subsequent function calls, so we have to clone it
  const criteriaClone = cloneDeep(criteria)
  if (GRAPH_FILTER_ALIAS.includes(alias)) {
    criteriaClone.measure = measuresWithGraphs || criteriaClone.measure
  }

  return new Promise(resolve => {
    callback(criteriaClone, uuidType).then(result => {
      const resultData = {
        alias,
        callback: aggregationCallback,
        ...hydrateResponse(result, uuidType)
      }

      resolve(resultData)
    })
  })
}

/**
 * Execute Gender Breakdown Query.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeGenderBreakdownQuery (criteria, uuidType) {
  const params = { criteria }
  params.group_by = [{
    column: uuidType,
    function: 'group_only'
  }, {
    column: 'gender',
    function: 'count',
    include_column: true
  }]
  params.aggregate = [{
    column: 'patient_uuid',
    function: 'count',
    distinct: true
  }]
  params.order_by = [
    { column: uuidType }
  ]
  return executeQuery(params, uuidType)
}

/**
 * Prepare and Execute Discharge disposition Query.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeDischargeDispositionQuery (criteria, uuidType) {
  const params = { criteria }
  const isEpisodePaired = params.criteria.results_to_include.column && params.criteria.results_to_include.column === 'episode_id'

  params.criteria.program_status = {
    condition: 'not',
    value: 'admitted'
  }
  params.group_by = [{
    column: 'program_uuid',
    function: 'group_only'
  }, {
    column: 'program_status',
    function: 'group_only',
    include_column: true
  }]
  params.aggregate = {
    column: 'program_assignment_uuid',
    function: 'count',
    distinct: true
  }

  // Alter query for episode-pairing results
  if (isEpisodePaired) {
    params.group_by = [{
      column: 'program_uuid',
      function: 'group_only'
    }, {
      column: 'response_type',
      function: 'group_only',
      include_column: true
    }, {
      column: 'program_status',
      function: 'group_only',
      include_column: true
    }]
  }

  return executeQuery(params, uuidType)
}

/**
 * Execute query to retrieve Data grouped by Age range.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeAgeRangeQuery (criteria, uuidType) {
  const params = { criteria }
  const isEpisodePaired = params.criteria.results_to_include.column && params.criteria.results_to_include.column === 'episode_id'
  const isProgramPaired = params.criteria.results_to_include.column && params.criteria.results_to_include.column === 'program_assignment_id'

  // Alter query for episode-pairing results
  if (isEpisodePaired || isProgramPaired) {
    params.criteria.results_to_include.discharge_only = true
  }
  params.group_by = [{
    column: uuidType,
    function: 'group_only'
  }]
  params.ranges = {
    column: 'patient_uuid',
    function: 'count',
    distinct: true,
    groups: [{
      criteria: { age: { less_than: 17 } },
      alias: 'age_under_18'
    }, {
      criteria: { age: { greater_than: 18, less_than: 25 } },
      alias: 'age_18_25'
    }, {
      criteria: { age: { greater_than: 26, less_than: 30 } },
      alias: 'age_26_30'
    }, {
      criteria: { age: { greater_than: 31, less_than: 35 } },
      alias: 'age_31_35'
    }, {
      criteria: { age: { greater_than: 36, less_than: 40 } },
      alias: 'age_36_40'
    }, {
      criteria: { age: { greater_than: 41, less_than: 45 } },
      alias: 'age_41_45'
    }, {
      criteria: { age: { greater_than: 46, less_than: 50 } },
      alias: 'age_46_50'
    }, {
      criteria: { age: { greater_than: 51, less_than: 55 } },
      alias: 'age_51_55'
    }, {
      criteria: { age: { greater_than: 56, less_than: 60 } },
      alias: 'age_56_60'
    }, {
      criteria: { age: { greater_than: 61, less_than: 65 } },
      alias: 'age_61_65'
    }, {
      criteria: { age: { greater_than: 66, less_than: 70 } },
      alias: 'age_66_70'
    }, {
      criteria: { age: { greater_than: 71 } },
      alias: 'age_over_71'
    }
    ]
  }
  return executeQuery(params, uuidType)
}

/**
 * Execute query to retrieve Data grouped by Max Length of Treatment.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeMaxLOTQuery (criteria, uuidType) {
  const params = { criteria }
  params.group_by = [{
    column: 'location_uuid',
    function: 'group_only'
  }, {
    column: 'patient_uuid',
    function: 'group_only',
    include_column: true
  }]
  params.aggregate = [{
    column: 'length_of_treatment_outpatient',
    function: 'max'
  }]
  params.lot_week_ranges = [
    {
      less_or_equal: 1
    }, {
      greater_or_equal: 2,
      less_or_equal: 4
    }, {
      greater_or_equal: 5,
      less_or_equal: 8
    }, {
      greater_or_equal: 9,
      less_or_equal: 16
    }, {
      greater_or_equal: 17,
      less_or_equal: 24
    }, {
      greater_or_equal: 25,
      less_or_equal: 48
    }, {
      greater_or_equal: 49,
      less_or_equal: 96
    }, {
      greater_or_equal: 97
    }
  ]
  params.query = 'length_of_treatment'

  return executeQuery(params, uuidType)
}

/**
 * Execute query to retrieve Data grouped by Outpatient Score Over Time data query.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeOutpatientScoreOverTimeQuery (criteria, uuidType) {
  const params = { criteria }
  params.query = 'outpatient_scores_over_time'

  return executeQuery(params, uuidType)
}

/**
 * Execute query to retrieve Data grouped by Outpatient Weeks To Below Cutoff data query.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
async function executeOutpatientWeeksToBelowCutoffQuery (criteria, uuidType) {
  const params = { criteria }
  params.query = 'first_drop_below_cutoff'

  return executeQuery(params, uuidType)
}

/**
 * Execute Days From Admission To Discharge Chart data query.
 *
 * @param criteria
 * @returns {Promise<unknown>}
 */
function executeDaysFromAdmissionToDischargeQuery (criteria, uuidType) {
  const params = { criteria }
  params.group_by = [{ column: 'program_uuid', function: 'group_only' }]
  params.ranges = [{
    column: 'patient_uuid',
    function: 'count',
    distinct: true,
    groups: [{
      criteria: { length_of_treatment: { less_than: 2 } },
      alias: 'length_of_treatment_under_2'
    }, {
      criteria: { length_of_treatment: { greater_than: 3, less_than: 5 } },
      alias: 'length_of_treatment_3_5'
    }, {
      criteria: { length_of_treatment: { greater_than: 6, less_than: 7 } },
      alias: 'length_of_treatment_6_7'
    }, {
      criteria: { length_of_treatment: { greater_than: 8, less_than: 10 } },
      alias: 'length_of_treatment_8_10'
    }, {
      criteria: { length_of_treatment: { greater_than: 11, less_than: 14 } },
      alias: 'length_of_treatment_11_14'
    }, {
      criteria: { length_of_treatment: { greater_than: 15, less_than: 20 } },
      alias: 'length_of_treatment_15_20'
    }, {
      criteria: { length_of_treatment: { greater_than: 21, less_than: 28 } },
      alias: 'length_of_treatment_21_28'
    }, {
      criteria: { length_of_treatment: { greater_than: 29 } },
      alias: 'length_of_treatment_over_29'
    }]
  }]

  // OWL-8134 return only patients that have been discharged
  if (params.criteria && params.criteria.program_discharge) {
    params.criteria.program_discharge = Object.assign(params.criteria.program_discharge, {
      condition: 'not',
      value: null
    })
  }

  params.query = 'days_admit_discharge'
  return executeQuery(params, uuidType)
}

async function executeAdmitDischargeScoresQuery (criteria, uuidType) {
  const params = { criteria }
  if (params.criteria.results_to_include === 'all_results') {
    params.criteria.program_discharge = criteria.completed
    delete params.criteria.completed
  }
  params.query = 'admit_discharge'
  return executeQuery(params, uuidType)
}

/**
 * Execute query for Average Scores Over Time Chart.
 *
 * @param criteria
 * @returns {Promise<void>}
 */
async function executeAverageScoreOverTime (criteria, uuidType) {
  const params = { criteria }
  params.query = 'scores_over_time'
  return executeQuery(params, uuidType)
}

/**
 * Get chart settings for measures
 *
 * @param measure_uuids
 * @returns {Promise<unknown>}
 */
export function getMeasureChartSettings (uuids) {
  return new Promise((resolve, reject) => {
    axios.post(LIBRARY_ENDPOINTS.GET_MEASURE_CHART_SETTINGS, { measure_uuids: uuids }).then((response) => {
      if (response.data.error) {
        return reject(response.data.error)
      }
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}
/**
 * Execute Query against analytics.
 *
 * @param params
 * @returns {Promise<unknown>}
 */
export function executeQuery (params, uuidType) {
  return new Promise((resolve, reject) => {
    axios.post(ENDPOINTS.ANALYTICS_GET_RECORDS, params).then((response) => {
      if (response.data.error) {
        return reject(response.data.error)
      }

      const responseData = hydrateResponse(response.data, uuidType)

      resolve(responseData)
    }).catch((error) => {
      reject(error)
    })
  })
}
