export const GET_LOCATIONS = 'locations/GET_LOCATIONS'
export const GET_LOCATIONS_FOR_DATA_TABLE = 'locations/GET_LOCATIONS_FOR_DATA_TABLE'
export const GET_STATES = 'locations/GET_STATES'
export const SET_SEARCHING = 'locations/SET_SEARCHING'
export const EDITING_SETTING = 'settings/EDITING_SETTING'
export const DELETE_LOCATION = 'locations/DELETE_LOCATION'
export const UPDATE_LOCATION = 'locations/UPDATE_LOCATION'
export const SAVE_LOCATION = 'locations/SAVE_LOCATION'
export const GET_HAS_USERS = 'locations/GET_HAS_USERS'
export const LOCATION_RE_ASSIGN = 'locations/LOCATION_RE_ASSIGN'
export const LOCATIONS_LOGOUT = 'locations/LOCATIONS_LOGOUT'

export const ENDPOINTS = {
  GET_STATES: 'state',
  GET_LOCATIONS: 'location',
  UPDATE_LOCATION: 'location/{id}',
  GET_HAS_USERS: 'location/{id}/users',
  LOCATION_RE_ASSIGN: 'location/reassign',
  DELETE_LOCATION: 'location/{id}',
  SAVE_LOCATION: 'location',
  GET_LOCATIONS_FOR_DATA_TABLE: 'location/for-list'
}
