export default {
  getActiveAssignment: state => state.activeAssignment,
  getAssignments: state => state.assignments,
  getAssignmentsLoading: state => state.assignmentsLoading,
  getLoadingGetPatient: state => state.loadingGetPatient,
  getMeasureQuestions: state => state.measureQuestions,
  getMultiMeasureQuestions: state => state.multiMeasureQuestions,
  getMultiMeasureQuestionsLoading: state => state.multiMeasureQuestionsLoading,
  getPatientActiveTab: state => state.activeTab,
  getPatientInsurance: state => state.patientInsurance,
  getPatientInsuranceLoading: state => state.getPatientInsuranceLoading,
  setPatientInsuranceLoading: state => state.setPatientInsuranceLoading,
  getPatientsCount: state => state.patientsCount,
  getPatients: state => state.patients,
  getPatientsByProgram: state => state.patientsByProgram,
  getPatientsLoading: state => state.patientsLoading,
  getPatientsNewResults: state => state.patientsNewResults,
  getPDFStatus: state => state.pdfStatus,
  getScheduledPatientsByDate: state => state.scheduledPatientsByDate,
  getScheduledPatientsByDateLoading: state => state.scheduledPatientsByDateLoading,
  getScheduledPatientsBySingleDate: state => state.scheduledPatientsBySingleDate,
  getSinglePatient: state => state.singlePatient,
  getSinglePatientMeasuresList: state => state.singlePatientMeasuresList,
  getSinglePatientProgram: state => state.singlePatientProgram,
  getSinglePatientPrograms: state => state.singlePatientPrograms,
  getSinglePatientProgramsLoading: state => state.singlePatientProgramsLoading,
  getSinglePatientReferrerRoute: state => state.singlePatientReferrerRoute,
  getTeamId: state => state.teamId,
  getClinicianAssignments: state => state.clinicianAssignments,
  getClinicianAssignmentsLoading: state => state.clinicianAssignmentsLoading
}
