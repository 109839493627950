export const FETCH_ACTIVE_PATIENTS = 'usage/FETCH_ACTIVE_PATIENTS'
export const FETCH_ACTIVE_PATIENTS_TODAY = 'usage/FETCH_ACTIVE_PATIENTS_TODAY'
export const FETCH_CLINICAL_SIGNINS = 'usage/FETCH_CLINICAL_SIGNINS'
export const FETCH_MEASURE_ACTIVITY = 'usage/FETCH_MEASURE_ACTIVITY'
export const FETCH_MEASURE_ACTIVITY_TODAY = 'usage/FETCH_MEASURE_ACTIVITY_TODAY'
export const FETCH_MEASURE_COMPLETION_TODAY = 'usage/FETCH_MEASURE_COMPLETION_TODAY'
export const FETCH_MEASURES = 'usage/FETCH_MEASURES'
export const RESET_USAGE_GLOBAL_PARAMS = 'usage/RESET_USAGE_GLOBAL_PARAMS'
export const RESET_USAGE_TODAY_PARAMS = 'usage/RESET_USAGE_TODAY_PARAMS'
export const SET_ACTIVE_PATIENTS_DATA = 'usage/SET_ACTIVE_PATIENTS_DATA'
export const SET_ACTIVE_PATIENTS_HAS_ERRORS = 'usage/SET_ACTIVE_PATIENTS_HAS_ERRORS'
export const SET_ACTIVE_PATIENTS_LOADING = 'usage/SET_ACTIVE_PATIENTS_LOADING'
export const SET_ACTIVE_PATIENTS_TODAY_DATA = 'usage/SET_ACTIVE_PATIENTS_TODAY_DATA'
export const SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS = 'usage/SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS'
export const SET_ACTIVE_PATIENTS_TODAY_LOADING = 'usage/SET_ACTIVE_PATIENTS_TODAY_LOADING'
export const SET_CLINICAL_SIGNINS_DATA = 'usage/SET_CLINICAL_SIGNINS_DATA'
export const SET_CLINICAL_SIGNINS_HAS_ERRORS = 'usage/SET_CLINICAL_SIGNINS_HAS_ERRORS'
export const SET_CLINICAL_SIGNINS_LOADING = 'usage/SET_CLINICAL_SIGNINS_LOADING'
export const SET_MEASURE_ACTIVITY_DATA = 'usage/SET_MEASURE_ACTIVITY_DATA'
export const SET_MEASURE_ACTIVITY_HAS_ERRORS = 'usage/SET_MEASURE_ACTIVITY_HAS_ERRORS'
export const SET_MEASURE_ACTIVITY_LOADING = 'usage/SET_MEASURE_ACTIVITY_LOADING'
export const SET_MEASURE_ACTIVITY_TODAY_DATA = 'usage/SET_MEASURE_ACTIVITY_TODAY_DATA'
export const SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS = 'usage/SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS'
export const SET_MEASURE_ACTIVITY_TODAY_LOADING = 'usage/SET_MEASURE_ACTIVITY_TODAY_LOADING'
export const SET_MEASURE_COMPLETION_TODAY_DATA = 'usage/SET_MEASURE_COMPLETION_TODAY_DATA'
export const SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS = 'usage/SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS'
export const SET_MEASURE_COMPLETION_TODAY_LOADING = 'usage/SET_MEASURE_COMPLETION_TODAY_LOADING'
export const SET_MEASURES_DATA = 'usage/SET_MEASURES_DATA'
export const SET_MEASURES_HAS_ERRORS = 'usage/SET_MEASURES_HAS_ERRORS'
export const SET_MEASURES_LOADING = 'usage/SET_MEASURES_LOADING'
export const UPDATE_USAGE_GLOBAL_PARAMS = 'usage/UPDATE_USAGE_GLOBAL_PARAMS'
export const UPDATE_USAGE_TODAY_PARAMS = 'usage/UPDATE_USAGE_TODAY_PARAMS'

export const ENDPOINTS = {
  USAGE: 'analytics/usage',
  USAGE_SIGNINS: 'analytics/signin-counts',
  USAGE_RESPONDENTS: 'analytics/respondent-counts',
  USAGE_INVITES: 'analytics/invite-status'
}
