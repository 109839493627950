import i18n from '@/i18n'
import router from '@/router'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'
const RESULT_KEY = 'week_' || 'day_'

/**
 * Handle construction of Outpatient Score Over Time Chart Model.
 */
export class AnalyticsOutpatientScoreOverTimeChartModel extends AnalyticsChartModel {
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    super(queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure)
    this.id = measure.uuid + '_' + this.getDefaultChartAlias()
    this.title = this.getDefaultChartTitle(programData, queryResult, measure)
  }

  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'outpatientScoreOverTime'
  }

  getChartSubdivisionKey () {
    return 'outcomesOutpatient'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program, queryResult, measure) {
    const measureName = measure ? measure.abbreviation : ''
    return `${program.name}: ${router.app.$t('outpatientScoreOverTime', { abbr: measureName })}`
  }

  /**
   * Get Default Chart X Axis.
   */
  getXAxis (queryResult) {
    const categories = this.getXAxisCategories(queryResult.scores, RESULT_KEY, true)
    const labels = this.getXAxisLabels()
    const tickInterval = Object.keys(categories).length > 16 ? 2 : 1
    return {
      categories,
      labels,
      tickInterval
    }
  }

  /**
  * Get Default Chart Y Axis.
  */
  getYAxis (queryResult) {
    const max = this.getYAxisMax(queryResult.scores)
    const measureName = queryResult.measure ? queryResult.measure.abbreviation : ''
    const settings = this.getMeasureChartSettings(queryResult)
    return {
      max: max,
      min: 0,
      tickPositions: this.getYAxisTickPositionsFromMeasureConfig(settings),
      plotBands: this.getYAxisPlotbandsFromMeasureConfig(settings),
      title: {
        text: `${measureName} ${i18n.messages[i18n.locale].totalSeverityScore}`,
        margin: 20,
        style: this.getDefaultStyleObject(16)
      }
    }
  }

  /**
   * Get yAxis secondary config.
   */
  getYSecondaryAxis (queryResult) {
    return this.getYSecondaryAxysForMeasure(queryResult)
  }

  /**
   * Get Default Chart colors.
   */
  getSeriesColors () {
    return ['#36a2ea', '#8097ad', '#485d72', '#0a3560', '#b3c1d1', '#2068ac']
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    const intervalType = Object.hasOwn(queryResult.scores, 'day_0') ? 'days' : 'weeks'
    const name = i18n.messages[i18n.locale][intervalType]
    const data = []
    const colors = this.getSeriesColors()

    for (const key in queryResult.scores) {
      data.push(queryResult.scores[key])
    }

    return [{
      name,
      data,
      marker: {
        lineColor: colors[0],
        fillColor: 'white',
        lineWidth: 3
      }
    }]
  }
}
