export default {
  getConctactInfoDetail: state => state.conctactInfoDetail,
  getPatientUserRowActive: state => state.patientUserRowActive,
  getCustomModalConfig: state => state.modalConfig,
  getCustomModalIsOpened: state => state.customModalIsOpened,
  getCustomModalTemplate: state => state.customModalTemplate,
  getEnhanceAuditionData: state => state.enhanceAuditionModalData,
  getEnhanceAuditionVariables: state => state.enhanceAuditionVariables,
  getSetupDonePromise: state => state.setupDonePromise
}
