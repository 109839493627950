export default {
  analyticsApiQuery: null,
  analyticsTotalRecords: null,
  analyticsTotalRecordsLoading: false,
  analyticsRecordsLoading: false,
  analyticsProgramsFilteredLoading: false,
  analyticsProgramsFiltered: [],
  analyticsRecordsData: [],
  analyticsCurrentQuery: {
    program_or_location_filter: '',
    scorableFilter: true,
    results_to_include: null,
    clinicians: null,
    clinical_teams: null,
    measure: null,
    gender: null,
    age: null,
    completed: null,
    program_discharge: null,
    completedType: null,
    dateFilterMode: 'completed',
    customRangeDate: null
  },
  analyticPrograms: [],
  analyticLocations: [],
  analyticClinicians: [],
  analyticClinicalTeams: [],
  scorableAnalyticMeasures: [],
  noScorableAnalyticMeasures: [],
  analyticSelectedPrograms: [],
  analyticsSavedQueries: [],
  analyticsSavedQueryLoaded: null,
  analyticsSelectedSavedQueryForEdition: null,
  analyticsActiveSavedQueryData: [],
  analyticsSelectedProgramChart: null,
  analyticsSelectedMeasuresChart: null,
  analyticsAvailableServiceLines: [],
  analyticsVisibleChartItems: [],
  analyticsVisibleChartItemsCatchall: [],
  analyticsMeasuresForServiceLine: [],
  analyticsChartCollection: [],
  analyticsChartCollectionLoading: false,
  analyticsCustomDateFilterLabel: null,
  selectedServiceLine: null,
  serviceLinesForChips: [],
  measuresForChips: [],
  totalRecordsForCharts: 0
}
