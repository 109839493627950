import {
  GET_ALL_CLINICIANS,
  GET_ACTIVE_CLINICIANS_LOADING,
  GET_ALL_CLINICIANS_TEAMS,
  GET_CLINICIAN_ALERTS,
  GET_CLINICIAN_ALERTS_COUNT,
  GET_CLINICIAN_ALERTS_COUNT_LOADING,
  GET_PATIENTS_MEASURES,
  GET_PATIENTS_MEASURES_PDF_LOADING,
  GET_PATIENTS_MEASURES_PDF,
  GET_PATIENT_SINGLE_MEASURE_LOADING,
  GET_PATIENT_SINGLE_MEASURE,
  GET_PATIENT_SINGLE_MEASURE_LOADING_PDF,
  GET_PATIENT_SINGLE_MEASURE_PDF,
  CLEAR_PATIENT_SINGLE_MEASURE,
  DELETE_MEASURE_DUE_DATES,
  CHANGE_MEASURE_FREQUENCY,
  REMOVE_MEASURE_FROM_ASSIGNATION,
  DELETE_SCHEDULED_MEASURE,
  DELETING_SCHEDULED_MEASURE,
  LOGIN_AS_RESPONDENT,
  CHANGE_MEASURE_DUE_DATE,
  ADD_CLINICIAN_TEAM,
  UPDATE_CLINICIAN_TEAM,
  GET_CLINICIAN_TEAM,
  ACKNOWLEDGE_ALERT,
  SIGN_OUT,
  HAVE_MEASURES_AVAILABLE,
  SORT_CLINICIANS_BY_RECENTLY_SELECTED
} from '@/store/modules/clinicians/constants.js'
import { sortByRecentlyUpdatedCallback } from '@/mixins/Common/sortingFunctions.js'
import moment from 'moment'

const getCompletedMeasureLatestDate = (measure, fieldIndex, dateIndex) => {
  measure[fieldIndex] = measure[fieldIndex] ? measure[fieldIndex] : []
  return Math.max.apply(null, measure[fieldIndex].map(function (e) {
    return moment(e[dateIndex]).toDate()
  }))
}

const sortMeasures = (measures) => {
  for (let i = 0; i < measures.length; i++) {
    const measure = measures[i]
    measure.is_pre_populate = !!measure.is_pre_populate
    measure.latestCompletedDate = getCompletedMeasureLatestDate(measure, 'sessions_completed', 'measure_date_completed')
    measure.latestScheduledDate = getCompletedMeasureLatestDate(measure, 'next_schedules', 'due_date')
    // We select one chart because we supouse all chart has the same behavior
    const chart = measure.chart[0]
    const isScorable = chart && chart.progress_chart.is_scorable
    if (chart && chart.progress_chart && Object.keys(chart.progress_chart).length) {
      const chartToShow = chart.progress_chart.sessions_progress_results.reduce((total, sessionsProgress) => {
        if (
          isScorable &&
          !sessionsProgress.unanswered_questions &&
          sessionsProgress.yvalues.some(v => !!v) &&
          (parseInt(sessionsProgress.show_chart) === 1 || sessionsProgress.show_chart === 'true')
        ) {
          total++
        }
        return total
      }, 0)
      measure.completedDataWithScores = chartToShow
    }
  }
  return measures.sort((a, b) => {
    const first = a.latestScheduledDate
    const second = b.latestScheduledDate
    if (isFinite(first - second)) {
      return second - first
    } else {
      return isFinite(first) ? -1 : 1
    }
  })
}
const sortMeasuresPDF = (measures) => {
  for (let i = 0; i < measures.length; i++) {
    const measure = measures[i]
    measure.is_pre_populate = !!measure.is_pre_populate
    measure.latestCompletedDate = getCompletedMeasureLatestDate(measure, 'sessions_completed', 'measure_date_completed')
    measure.latestScheduledDate = getCompletedMeasureLatestDate(measure, 'next_schedules', 'due_date')
    // We select one chart because we supouse all chart has the same behavior
    const chart = measure.chart[0]
    const isScorable = chart && chart.progress_chart.is_scorable
    if (chart && chart.progress_chart) {
      if (chart.progress_chart.sessions_progress_results) {
        const chartToShow = chart.progress_chart.sessions_progress_results.reduce((total, sessionsProgress) => {
          if (
            isScorable &&
            !sessionsProgress.unanswered_questions &&
            sessionsProgress.yvalues.some(v => !!v) &&
            (parseInt(sessionsProgress.show_chart) === 1 || sessionsProgress.show_chart === 'true')
          ) {
            total++
          }
          return total
        }, 0)
        measure.completedDataWithScores = chartToShow
      } else {
        measure.completedDataWithScores = null
      }
    }
  }
  return measures.sort((a, b) => {
    const first = a.latestScheduledDate
    const second = b.latestScheduledDate
    if (isFinite(first - second)) {
      return second - first
    } else {
      return isFinite(first) ? -1 : 1
    }
  })
}

export default {
  [GET_ALL_CLINICIANS] (state, data) {
    data.forEach(cu => {
      cu.recentlySelected = false
    })
    state.clinicians = data.sort(sortByRecentlyUpdatedCallback)
  },

  [GET_ACTIVE_CLINICIANS_LOADING] (state, value) {
    state.cliniciansLoading = value
  },

  [ADD_CLINICIAN_TEAM] (state, data) {
    state.teams.unshift(data)
  },

  [UPDATE_CLINICIAN_TEAM] (state, newClinicalTeam) {
    const i = state.teams.findIndex(ct => Number(ct.id) === Number(newClinicalTeam.id))
    if (i !== -1) {
      const oldClinicalTeam = state.teams[i]
      for (const k in oldClinicalTeam) {
        if (oldClinicalTeam.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          oldClinicalTeam[k] = newClinicalTeam[k]
        }
      }
    }
  },

  [GET_ALL_CLINICIANS_TEAMS] (state, data) {
    state.teams = data
  },

  [GET_PATIENTS_MEASURES] (state, data) {
    state.patientMeasures = sortMeasures(data)
  },
  [GET_PATIENTS_MEASURES_PDF] (state, data) {
    const indexBundle = state.patientMeasuresPDF.findIndex(patientMeasuresPDF => patientMeasuresPDF.is_bundle_measure === 1)
    const indexSafetyPlan = state.patientMeasuresPDF.findIndex(patientMeasuresPDF => patientMeasuresPDF.is_safety_plan === true)
    state.patientMeasuresPDF.splice(indexBundle, 1)
    state.patientMeasuresPDF.splice(indexSafetyPlan, 1)
    state.patientMeasuresPDF = sortMeasuresPDF(data)
  },
  [GET_PATIENTS_MEASURES_PDF_LOADING] (state, data) {
    state.patientMeasuresPDFLoading = data
  },
  [GET_PATIENT_SINGLE_MEASURE] (state, data) {
    // We select one chart because we suppose all chart has the same behavior
    const chart = data.measure_report.chart[0]
    if (chart && chart.session_chart) {
      data.measure_report.completedDataWithScores = chart.session_chart.reduce((total, sessionsProgress) => {
        if (
          sessionsProgress.is_scorable &&
          !sessionsProgress.unanswered_questions &&
          sessionsProgress.series.some(s => !!s.yvalue) &&
          (parseInt(sessionsProgress.show_chart) === 1 || sessionsProgress.show_chart === 'true')
        ) {
          total++
        }
        return total
      }, 0)
    }
    state.patientSingleMeasureCompletedSessions = data.completed_sessions
    state.patientSingleMeasure = data.measure_report
  },
  [GET_PATIENT_SINGLE_MEASURE_LOADING] (state, value) {
    state.patientSingleMeasureLoading = value
  },
  [GET_PATIENT_SINGLE_MEASURE_PDF] (state, data) {
    // We select one chart because we suppose all chart has the same behavior
    const chart = data.measure_report.chart[0]
    if (chart && chart.session_chart) {
      data.measure_report.completedDataWithScores = chart.session_chart.reduce((total, sessionsProgress) => {
        if (
          sessionsProgress.is_scorable &&
          !sessionsProgress.unanswered_questions &&
          sessionsProgress.series.some(s => !!s.yvalue) &&
          (parseInt(sessionsProgress.show_chart) === 1 || sessionsProgress.show_chart === 'true')
        ) {
          total++
        }
        return total
      }, 0)
    }
    state.patientSingleMeasureCompletedSessions = data.completed_sessions
    state.patientSingleMeasurePDF = data.measure_report
  },
  [GET_PATIENT_SINGLE_MEASURE_LOADING_PDF] (state, value) {
    state.patientSingleMeasurePDFLoading = value
  },
  [CLEAR_PATIENT_SINGLE_MEASURE] (state) {
    state.patientSingleMeasure = {}
    state.patientSingleMeasureCompletedSessions = {}
  },
  [GET_CLINICIAN_ALERTS] (state, data) {
    state.alerts = data
  },
  [GET_CLINICIAN_ALERTS_COUNT] (state, data) {
    state.alertsCount = data.count
  },
  [GET_CLINICIAN_ALERTS_COUNT_LOADING] (state, value) {
    state.alertsCountLoading = value
  },
  [ACKNOWLEDGE_ALERT] (state, data) {
    state.alerts = state.alerts.map(alert => {
      if (alert.id === data.id) {
        alert.acknowledged_data = data.acknowledged_data
      }
      return alert
    })
  },
  [CHANGE_MEASURE_DUE_DATE] (state, params) {
    const data = params.data
    if (data.measures_updated && Array.isArray(data.measures_updated)) {
      data.measures_updated.forEach((measureId) => {
        state.patientMeasures.forEach((measure) => {
          const scheduled = measure.next_schedules.find(item => Number(item.id) === Number(measureId))
          if (scheduled) {
            scheduled.due_date = data.due_date
          }
        })
      })
    }
  },
  [CHANGE_MEASURE_FREQUENCY] (state, data) {
    if (data.measures_updated && Array.isArray(data.measures_updated)) {
      data.measures_updated.forEach((measureId) => {
        state.patientMeasures.forEach((measure) => {
          const scheduled = measure.next_schedules.find(item => Number(item.id) === Number(measureId))
          if (scheduled) {
            measure.frequency = Number(data.frequency)
            scheduled.frequency = Number(data.frequency)
            scheduled.frequency_information = data.frequency_information
          }
        })
      })
    }
  },
  [DELETE_SCHEDULED_MEASURE] (state, measureId) {
    let scheduleMeasureIndex = null
    if (state.patientMeasures.length) {
      const index = state.patientMeasures.findIndex(m => m.next_schedules.find((ns, index) => {
        if (Number(ns.id) === Number(measureId)) {
          scheduleMeasureIndex = index
          return true
        } else {
          return false
        }
      }))
      // Remove measure if it has no schedules
      state.patientMeasures[index].next_schedules.splice(scheduleMeasureIndex, 1)
      if (state.patientMeasures[index].next_schedules.length === 0 && !state.patientMeasures[index].sessions_completed.length) {
        state.patientMeasures.splice(index, 1)
      }
    }
  },
  [SIGN_OUT] (state) {
    const s = state
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [HAVE_MEASURES_AVAILABLE] () {
    // @todo Implement logic to change Current Selected assignment have_available_measures value
  },
  [LOGIN_AS_RESPONDENT] () {

  },

  [DELETE_MEASURE_DUE_DATES] (state, measureId) {
    const index = state.patientMeasures.findIndex(i => Number(i.measure_id) === Number(measureId))
    if (index !== -1) {
      state.patientMeasures[index].next_schedules = []
    }
  },

  [REMOVE_MEASURE_FROM_ASSIGNATION] (state, data) {
    const index = state.patientMeasures.findIndex(a => Number(a.measure_id) === Number(data.measure_id))
    if (index !== -1) {
      state.patientMeasures.splice(index, 1)
    }
  },

  [DELETING_SCHEDULED_MEASURE] (state, measureId) {
    state.deletingScheduleMeasure = measureId
  },

  [GET_CLINICIAN_TEAM] (state, data) {
    state.clinician_team = data
  },

  [SORT_CLINICIANS_BY_RECENTLY_SELECTED] (state) {
    state.clinicians = state.clinicians.sort(sortByRecentlyUpdatedCallback)
  }

}
