import { Base64 } from 'js-base64'
import moment from 'moment'
import { USER_TYPE_CLINICIAN } from '@/data/permissions'
import { mapGetters } from 'vuex'
import {
  SS_PROGRAMS,
  SS_APPOINTMENT_OPTIONS,
  SS_PROGRAM_OPTIONS,
  SS_OUTPATIENT
} from '@/data/setttingStates'

import {
  getPhoneFromArray,
  getEmailFromArray
} from '@/mixins/Common/userHelperFuncions.js'

const $isMobile = () => {
  return !!($isOnlyMobile() || navigator.userAgent.match(/iPad/i))
}

const $isOnlyMobile = () => {
  return !!(navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i))
}
export default { $isMobile, $isOnlyMobile }
export const Helpers = {
  computed: {
    ...mapGetters({
      settings: 'generalSettings'
    }),
    programsEnabled () {
      return this.settings.programs_enabled
    },
    myPatientsView () {
      return this.settings.setting_state === SS_OUTPATIENT
    },
    programsView () {
      return SS_PROGRAM_OPTIONS.includes(this.settings.setting_state)
    },
    appointmentView () {
      return SS_APPOINTMENT_OPTIONS.includes(this.settings.setting_state)
    },
    showAddMeasure () {
      const programsAssignation = this.patientProgramsAssignation.filter(item => Number(item.discharged) === 0)
      return this.settings.setting_state === SS_PROGRAMS && this.settings.programs_enabled ? programsAssignation.length > 0 : true
    },
    smsEnabled () {
      return this.settings.sms_enabled
    },
    emailEnabled () {
      return this.settings.email_enabled
    },
    notificationsEnabled () {
      return this.smsEnabled || this.emailEnabled
    },
    bothChannelsEnabled () {
      return this.smsEnabled && this.emailEnabled
    },
    bothChannelsDisabled () {
      return !this.smsEnabled && !this.emailEnabled
    },
    someChannelEnabled () {
      return this.smsEnabled || this.emailEnabled
    },
    firstInitial () {
      return this.getUserInitial(0)
    },
    secondInitial () {
      return this.getUserInitial(1)
    }
  },
  methods: {
    getUserInitial (num) {
      try {
        return this.user.initials[num]
      } catch (e) {
        return ''
      }
    },
    userNotificationsEnabled (userData) {
      return userData.is_clinical_user ? true : this.notificationsEnabled
    },
    userEmailEnabled (userData) {
      return userData.is_clinical_user ? true : this.emailEnabled
    },
    userSmsEnabled (userData) {
      return userData.is_clinical_user ? true : this.smsEnabled
    },
    getCleanPhone (phone) {
      return phone ? phone.replace(/\D/g, '') : ''
    },
    getPhoneFromArray (arr) {
      return getPhoneFromArray(arr)
    },
    getEmailFromArray (arr) {
      return getEmailFromArray(arr)
    },
    copyObj (obj) {
      const copy = Array.isArray(obj) ? [] : {}
      for (const key in obj) {
        copy[key] = obj[key] !== null && typeof obj[key] === 'object' ? this.copyObj(obj[key]) : obj[key]
      }
      return copy
    },
    addRespondentFromTable (patient) {
      this.$store.commit('user/SET_COMING_FROM_PROGRAM', patient.program_id)
      this.$store.commit('user/SET_DETAIL_TO_OPEN', patient)
      this.triggerAddRespondent(patient.assignment_id, patient.client_clinic_assignment_user_id)
    },
    addRespondentFromDetail () {
      this.triggerAddRespondent(this.lastSessionDetail.ccaId, this.lastSessionDetail.client_clinic_assignment_user_id)
    },
    triggerAddRespondent (ccaId, ccauId) {
      this.$store.dispatch('GET_ASSIGNMENTS', ccaId).then(() => {
        this.$store.dispatch('SET_ASSIGNMENT_VIEW', parseInt(ccauId))
        this.addRespondentToPatient = ccaId
      }).catch((e) => {
        this.$handleApiError(e)
      })
    },
    $removeByValue: function (array, value) {
      const index = array.indexOf(value)
      if (index >= 0) {
        array.splice(index, 1)
      }
      return array
    },
    $promptBeforeAction (config, callback, rejectCallback) {
      const title = config.title || ''
      const message = config.message || ''
      const cancel = config.cancelButton || this.$t('cancel')
      const ok = config.okButton || this.$t('yes')
      const classname = config.classname || ''

      const options = {
        title: title,
        message: message,
        timeout: 0,
        close: false,
        overlay: true,
        toastOnce: true,
        icon: '',
        id: 'question',
        zindex: 999,
        class: 'owl ' + classname,
        position: 'topCenter',
        closeOnClick: false,
        onClosing: () => {
          if (typeof rejectCallback === 'function') {
            rejectCallback()
          }
        },
        buttons: [
          [`<button class="resume text-uppercase">${cancel}</button>`, (instance, toast) => {
            instance.hide({
              transitionOut: 'fadeOut'
            }, toast, 'button')
            if (typeof rejectCallback === 'function') {
              rejectCallback()
            }
          }],
          [`<button class="exit text-uppercase">${ok}</button>`, (instance, toast) => {
            if (typeof callback === 'function') {
              callback()
            }
            instance.hide({
              transitionOut: 'fadeOut'
            }, toast, 'button')
          }, true]
        ]
      }
      // this is to fix a problem in toast plugin that throws an error if class ends with a space
      options.class = options.class.trim()
      this.$toast.info(options)
    },
    $isIos () {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    },
    $isClinician () {
      return this.$store.getters.loggedInUser.user_type === USER_TYPE_CLINICIAN
    },
    $currentBrowser () {
      let isChrome = navigator.userAgent.indexOf('Chrome') > -1
      const isExplorer = navigator.userAgent.indexOf('MSIE') > -1
      const isFirefox = navigator.userAgent.indexOf('Firefox') > -1
      let isSafari = navigator.userAgent.indexOf('Safari') > -1
      const isOpera = navigator.userAgent.toLowerCase().indexOf('op') > -1
      if ((isChrome) && (isSafari)) {
        isSafari = false
      }
      if ((isChrome) && (isOpera)) {
        isChrome = false
      }
      return { isChrome, isExplorer, isFirefox, isSafari, isOpera }
    },
    $isMobile () {
      return $isMobile()
    },
    $maskedPhone (phone) {
      if (!phone) {
        return
      }
      const zip = phone.substring(0, 3)
      const middle = phone.substring(3, 6)
      const last = phone.substring(6, 10)
      return `(${zip}) ${middle}-${last}`
    },
    $maskedPhoneHyphens (phone) {
      if (!phone) {
        return
      }
      const zip = phone.substring(0, 3)
      const middle = phone.substring(3, 6)
      const last = phone.substring(6, 10)
      return `${zip}-${middle}-${last}`
    },
    $getEncodedId (id) {
      return id ? Base64.encode(id) : ''
    },
    $getDecodedId (hash) {
      return hash ? Base64.decode(hash) : ''
    },
    $getDatesInBetween (startDate, endDate) {
      const dates = []

      const currDate = moment(startDate).add(-1, 'days').startOf('day')
      const lastDate = moment(endDate).startOf('day')
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate())
      }

      return dates
    },
    $getClassValueFromEvent (evt) {
      try {
        return evt.target.className
      } catch (error) {
        return ''
      }
    },
    $numberWithDecimals (number, numbOfDigits = 1) {
      numbOfDigits = numbOfDigits === null ? 1 : numbOfDigits
      number = Number(number)
      if (number % 1 === 0 || isNaN(number)) {
        return number
      }
      return Number(number.toFixed(numbOfDigits))
    },
    $genericDispatch (action, params, noLoader, skipThrowingError) {
      if (action) {
        return new Promise((resolve, reject) => {
          const loader = noLoader ? null : this.$loading.show()
          this.$store.dispatch(action, params).then((response) => {
            if (response && response.status !== 200) {
              this.$handleApiError(response)
              return reject(response)
            }
            resolve(response)
          }).catch((e) => {
            const force = !!(e && e.data && e.data.forceShow)
            if (!skipThrowingError || force) {
              this.$handleApiError(e)
            }
            reject(e)
          }).finally(() => {
            if (loader) {
              loader.hide()
            }
          })
        })
      }
    },
    $arraysEqual (a, b) {
      if (a == null || b == null) return false
      if (a.length !== b.length) return false

      return !Object.keys(a).some(i => `${a[i]}` !== `${b[i]}`)
    },
    $getElementPosition (el) {
      if (!el) { return {} }
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
      }
    },
    capitalizeString (string) {
      if (!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getParamFromQueryAndRemoveIt (param) {
      const query = Object.assign({}, this.$route.query)
      if (!query[param]) {
        return
      }
      const queryParam = query[param]
      delete query[param]
      this.$router.replace({ query })
      return queryParam
    },
    $asyncTimeout (timeToDelay) {
      return new Promise((resolve) => setTimeout(resolve, timeToDelay))
    }
  }
}
