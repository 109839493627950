import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'
import { GENDER_LOCAL_MAP } from '@/mixins/constants/GenderValues'

/**
 * Private - get Total records.
 * @param queryResult
 */
function getTotalRecords (queryResult) {
  let count = 0
  queryResult.forEach(i => { count = count + i.count_patient_uuid })
  return count
}

/**
 * Private Get gender Label locale key.
 * @param genderValue
 * @returns {*}
 */
function getGenderLabel (genderValue) {
  return GENDER_LOCAL_MAP.hasOwnProperty(genderValue) ? translate(GENDER_LOCAL_MAP[genderValue]) : genderValue // eslint-disable-line no-prototype-builtins
}

/**
 * Translate locale key
 *
 * @param key
 */
function translate (key) {
  return `${i18n.messages[i18n.locale][key]}`
}

/**
 * Handle constructions of Analytics Gender Breakdown Chart Model.
 */
export class AnalyticsGenderBreakdownChartModel extends AnalyticsChartModel {
  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'genderBreakdown'
  }

  getChartSubdivisionKey () {
    return 'demographics'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program) {
    return `${program.name} ${i18n.messages[i18n.locale].genderBreakdown}`
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    if (!queryResult || !queryResult.length) {
      return []
    }

    const totalRecords = getTotalRecords(queryResult)
    const data = []
    queryResult.forEach(i => {
      const obj = {
        name: getGenderLabel(i.gender),
        y: this.getPercentage(totalRecords, i.count_patient_uuid)
      }
      data.push(obj)
    })
    return [{ data }]
  }
}
