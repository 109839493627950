import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

/**
 * Handle constructions of Analytics Gender Breakdown Chart Model.
 */
export class AnalyticsAdmitAndDischargeScoresChartModel extends AnalyticsChartModel {
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    super(queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure)
    this.title = this.getChartTitleOrDefault(queryChartConfiguration, programData, queryResult, measure)
  }

  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'admitAndDischargeScores'
  }

  getChartSubdivisionKey () {
    return 'outcomes'
  }

  /**
   * Get Default Chart xAxys.
   */
  getXAxis (queryResult) {
    let categories = []
    let title = {}
    if (queryResult && queryResult.scores) {
      // get months array
      categories = Object.keys(queryResult.scores).map(c => {
        const splittedDate = `${c}`.split('-')
        const month = DateTimeHelper.methods.getMonthAbvFromNumber(splittedDate[1])
        const year = splittedDate[0]
        return splittedDate[1] ? `${month} <br> ${year}` : c
      })
      title = {
        text: i18n.messages[i18n.locale].dischargeMonth,
        y: 20,
        style: {
          marginTop: 10,
          color: this.getColorNavy(),
          fontSize: 14
        },
        reserveSpace: false
      }
    }
    return {
      categories,
      title,
      showLastLabel: categories.length <= 3
    }
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program, queryResult, measure) {
    const measureName = measure ? measure.abbreviation : ''
    return `${program.name} ${measureName} ${i18n.messages[i18n.locale].admitDischargeScores}`
  }

  getLegendAlignment () {
    return 'right'
  }

  /**
   * Get Default Chart yAxys.
   */
  getYAxis (queryResult) {
    return this.getYaxysForMeasure(queryResult)
  }

  /**
   * Get yAxys seconddary config.
   */
  getYSecondaryAxis (queryResult) {
    return this.getYSecondaryAxysForMeasure(queryResult)
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    const admission = queryResult && queryResult.scores ? Object.values(queryResult.scores).map(s => s.admission) : []
    const discharge = queryResult && queryResult.scores ? Object.values(queryResult.scores).map(s => s.discharge) : []

    // Workaround to prevent series overlapping with labels ( inside the chart )
    if (admission.length > 3) {
      admission.push(0)
    }
    if (discharge.length > 3) {
      discharge.push(0)
    }
    return [{
      name: i18n.messages[i18n.locale].admission,
      data: admission
    }, {
      name: i18n.messages[i18n.locale].discharge,
      data: discharge
    }]
  }
}
