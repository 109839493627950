export function setScrollBarWidth () {
  const scrollbox = document.createElement('div')
  // Make box scrollable
  scrollbox.style.overflow = 'scroll'
  // Append box to document
  document.body.appendChild(scrollbox)
  // Measure inner width of box
  document.documentElement.style.setProperty('--scrollbar-width', (scrollbox.offsetWidth - scrollbox.clientWidth) + 'px')
  // Remove box
  document.body.removeChild(scrollbox)
}
