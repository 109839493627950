export default {
  getAnalyticsCurrentQuery: state => state.analyticsCurrentQuery,
  getAnalyticsProgramsFiltered: state => state.analyticsProgramsFiltered,
  getAnalyticsProgramsFilteredLoading: state => state.analyticsProgramsFilteredLoading,
  getAnalyticsApiQuery: state => state.analyticsApiQuery,
  getAnalyticsRecordsData: state => state.analyticsRecordsData,
  getAnalyticsTotalRecords: state => state.analyticsTotalRecords,
  getAnalyticsTotalRecordsLoading: state => state.analyticsTotalRecordsLoading,
  getAnalyticsRecordsLoading: state => state.analyticsRecordsLoading,
  getAnalyticPrograms: state => state.analyticPrograms,
  getAnalyticsProgramsSelected: state => state.analyticSelectedPrograms,
  getAnalyticClinicians: state => state.analyticClinicians,
  getScorableAnalyticMeasures: state => state.scorableAnalyticMeasures,
  getNoScorableAnalyticMeasures: state => state.noScorableAnalyticMeasures,
  getAnalyticClinicalTeams: state => state.analyticClinicalTeams,
  getAnalyticsSavedQueries: state => state.analyticsSavedQueries,
  getAnalyticsSavedQueryLoaded: state => state.analyticsSavedQueryLoaded,
  getAnalyticsSelectedSavedQueryForEdition: state => state.analyticsSelectedSavedQueryForEdition,
  getAnalyticsSelectedProgramChart: state => state.analyticsSelectedProgramChart,
  getAnalyticsSelectedMeasuresChart: state => state.analyticsSelectedMeasuresChart,
  getAnalyticsLocations: state => state.analyticLocations,
  getAnalyticsAvailableServiceLines: state => state.analyticsAvailableServiceLines,
  getAnalyticsVisibleChartItems: state => state.analyticsVisibleChartItems,
  getAnalyticsVisibleChartItemsCatchall: state => state.analyticsVisibleChartItemsCatchall,
  getAnalyticsMeasuresForServiceLine: state => state.analyticsMeasuresForServiceLine,
  getAnalyticsChartCollection: state => state.analyticsChartCollection,
  getAnalyticsChartCollectionLoading: state => state.analyticsChartCollectionLoading,
  getAnalyticsCustomDateFilterLabel: state => state.analyticsCustomDateFilterLabel,
  getSelectedServiceLine: state => state.selectedServiceLine,
  getServiceLinesForChips: state => state.serviceLinesForChips,
  getMeasuresForChips: state => state.measuresForChips,
  getTotalRecordsForCharts: state => state.totalRecordsForCharts
}
