const messages = {
  en: {
    login: {
      passwordExpired: 'Your password has expired. Please select forgot password to reset it.',
      passwordExpiredLink: 'Clicking here'
    },
    measure_list: {
      must_activate_text: 'CALL to activate'
    },
    measure_alert: {
      view_all_responses: 'View All Responses'
    },
    appTitle: 'Owl Outcomes',
    awesome: 'Awesome',
    signIn: 'Sign In',
    sign_In: 'Sign-In',
    areYouNew: 'New to Owl?',
    username: 'USERNAME',
    sendUsername: 'Send username',
    usernameSent: 'Username sent to {value} if a match is found.',
    editUsername: 'EDIT USERNAME',
    forgotUsername: 'Forgot username',
    forgotUsernameTitleBoth: 'Enter the email or mobile number you provided to your clinic below to retrieve your username.',
    forgotUsernameTitleEmail: 'Enter the email you provided to your clinic below to retrieve your username.',
    forgotUsernameTitleMobile: 'Enter the mobile number you provided to your clinic below to retrieve your username.',
    next: 'Next',
    nextPage: 'Next page',
    yesFinalize: 'Yes, Finalize',
    yesExit: 'Yes, Exit',
    yesEdit: 'Yes, Edit',
    yesView: 'Yes, View',
    yes: 'Yes',
    noResume: 'No, Resume',
    resume: 'Resume',
    password: 'PASSWORD',
    usernameAndpassword: 'USERNAME & PASSWORD',
    forgotPassword: 'Forgot password',
    changePassword: 'Change password',
    oldPassword: 'OLD PASSWORD',
    paswordResetSuccsess: 'Password reset',
    paswordChangeSuccsess: 'Your password has been successfully changed',
    generalError: 'Oops, an unexpected error occurred. Try again later',
    passwordReset: 'Password Reset',
    newPassword: 'New Password',
    passwordExplain: {
      fieldName: 'Password',
      description: 'must be at least {length} characters, include a number, an upper and lower case letter, and a symbol.'
    },
    newQuery: 'New Query',
    savedQueries: 'Saved Queries',
    confirmPassword: 'CONFIRM PASSWORD',
    confirmNewPassword: 'CONFIRM NEW PASSWORD',
    continue: 'Continue',
    done: 'Done',
    edit: 'Edit',
    close: 'Close',
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    yesDelete: 'Yes, Delete',
    yesDisable: 'Yes, Disable',
    yesRemove: 'Yes, Remove',
    yesReverse: 'Yes, Reverse',
    start: 'Start',
    noSessionMessage: 'You have no Owl Sessions due. | No Owl Sessions due.',
    expiredResetToken: 'Reset key no longer valid',
    expiredTokenLink: 'Your link has expired. Please try again.',
    error: 'Error',
    toComplete: 'to complete',
    due: 'due',
    duePast: 'that was due',
    on: 'on',
    ok: 'ok',
    no: 'no',
    noUpper: 'No',
    exit: 'exit',
    by: 'by',
    from: 'from',
    for: 'for',
    owlSessionMessage: 'You have an Owl Session',
    greeting: 'Hi',
    questions: 'Question | Questions',
    getAccessCodeHeaderBoth: 'Enter your email or mobile number to receive an Access Code{toAction}.',
    getAccessCodeHeaderEmail: 'Enter your email to receive an Access Code{toAction}.',
    getAccessCodeHeaderMobile: 'Enter your mobile number to receive an Access Code{toAction}.',
    ifFamilyEnterChanel: 'If you are a patient or family member enter the phone number you provided to the clinic. | If you are a patient or family member enter the email you provided to the clinic.',
    getCode: 'Get Code',
    enterCode: 'Enter Code',
    sendAccessCode: 'Send Access Code',
    sendCode: 'Send Code',
    notificationsDisabledByUser: 'You will no longer receive any notifications.',
    notificationsDisabledByAccount: 'SMS notifications are disabled by your clinic. Please use email to receive reminders and notifications.',
    email: 'EMAIL | email',
    phone: 'MOBILE PHONE | mobile phone',
    phoneNumber: 'Mobile phone number',
    emailVerified: 'The email address has been verified',
    phoneVerified: 'The phone number has been verified',
    verifyChannel: 'Verify your {channel}',
    verify: 'Verify',
    change: 'Change',
    changedSuccessfully: '{field} changed successfully',
    activationSent: 'An Activation Code has been sent.',
    emailAdress: 'EMAIL ADDRESS',
    addEmailAdress: 'Add Email Address',
    addPhoneAdress: 'Add Phone Number',
    mobileNumber: 'MOBILE NUMBER | mobile number',
    andResetYourPassword: 'and reset your password',
    enterAccessCode: 'Enter the Access Code sent to',
    activateAccount: 'Activate account',
    whichAccount: 'Which Account?',
    accessMultipleAccount: 'Your contact info is shared with someone on your care team.',
    activate: 'Activate',
    clickToTryAgain: 'Click here to try again',
    needNewCode: 'Didn’t receive a code?',
    send: 'Send',
    EmailSent: 'An email has been sent.',
    SmsSent: 'A text has been sent.',
    reminderSent: 'Reminder was sent to the patient.',
    createUserAndPassword: 'Create a username and password',
    termsAndConds: {
      first: 'By clicking continue you are accepting Owl Insight\'s',
      second: 'Terms & Conditions'
    },
    wrongPasswordLink: {
      first: 'That is not your current password try again or',
      second: 'request access code',
      third: 'to change password.'
    },
    selectAccountMsg: 'The {channel} you provided is tied for more than one account. Select the account you want to activate.',
    sideBar: {
      myAccount: 'My Account',
      signOut: 'Sign Out'
    },
    primary: 'Primary',
    pageNotFound: {
      title: 'WHOOOO\'s sorry? We are',
      message: 'We can\'t seem to process your last action.',
      link: 'Go to home page'
    },
    maintenancePage: {
      title: 'We’ll be back soon!',
      message: 'We are doing a bit of maintenance. You can always reach us at support at owlinsights.com, otherwise we’ll be back online before business hours!'
    },
    reversePatientAdmition: {
      title: 'Are you sure you want to reverse the patient\'s admission into the program?',
      body: 'All record of the patient\'s admission into the program will be deleted'
    },
    contactInfro: 'CONTACT INFO',
    notifications: 'NOTIFICATIONS',
    addPhoneOrEmail: 'Add Phone Number or Email',
    addEmail: 'Add Email',
    editPhoneOrEmail: 'Edit Mobile Phone or Email',
    editEmail: 'Edit Email',
    validateAcountInfo: 'Validate Account Info',
    providerID: 'Provider ID',
    welcomeToOwl: 'Welcome to the Owl',
    welcomeIntroMessagePatient: '{customerName} has partnered with the {clinicName} to ensure you receive high quality care. When you complete these questions, you help your healthcare provider better understand your condition and progress. Your healthcare provider will see your results and share them with you at your next visit.',
    welcomeIntroMessageParents: '{customerName} has partnered with the {clinicName} to ensure your child receives high quality care. When you complete these questions, you help your child’s healthcare provider better understand their condition and progress. Your child’s healthcare provider will see your results.',
    welcomeIntroMessageTeacher: '{customerName} has partnered with the {clinicName} to ensure your student receives high quality care. When you complete these questions, you help your student’s healthcare provider better understand their condition and progress. Your student’s healthcare provider will see your results.',
    welcomeIntroMessageOthers: '{customerName} has partnered with the {clinicName} to ensure your family member receives high quality care. When you complete these questions, you help your family member\'s healthcare provider better understand their condition and progress. Your family member\'s healthcare provider will see your results.',
    welcomeIntroMessageClinician: 'The Owl collects information about your patients that can be used to guide treatment.',
    welcomeIntroMessageClinicianGettingStarted: 'Getting started is easy:',
    welcomeIntroMessageClinicianSub1: 'Add a patient and assign them measures to complete *',
    welcomeIntroMessageClinicianSub2: 'Verify they have completed their measures',
    welcomeIntroMessageClinicianSub3: 'Review the results with them',
    welcomeIntroMessageClinicianDisclaimer: '* NOTE: Step one may not be part of your specific responsibilities',
    accontActivatedSuccess: 'Great job. You have activated your account',
    welcomeIntroMessage: 'Here at {clinicName} we want the people you care for to get the best care possible. With Owl you answer questions about those you care for. Their providers see your answers and can use them to make good decisions about their care.',
    allQuestionsAnswered: 'You have answered all the questions in this section.',
    notAllQuestionsAnswered: 'You have {number} unanswered questions. | You have {number} unanswered question.',
    sectionCompleted: 'You have completed this section',
    clickToSendToClinician: 'Submit the answers to your clinician.',
    finishMeasureNote: 'Note: Submitting your results is final and cannot be undone.',
    resultSentToClinician: 'Results were sent to your clinician',
    atLeast1clinician: 'At least one clinician',
    submit: 'submit',
    submitAnyWay: 'Submit Anyway',
    continueAnyWay: 'Continue Anyway',
    goBack: 'Go Back',
    back: 'Back',
    answersSaved: 'Your answers have been saved and you can resume at any time before your measure is due',
    areYouSureToExit: 'Are you sure you want to exit?',
    areYouSureToDelete: 'Are you sure you want to delete',
    areYouSureToDeleteItem: 'Are you sure you want to delete {item}?',
    areYouSureToDisableScheduling: 'Are you sure you want to disable scheduling?',
    areYouSureToDisableSchedulingStarted: 'Are you sure you want to disable the scheduling for this measure?',
    areYouSureToFinalize: 'Are you sure you want to finalize patient’s discharge?',
    deleteMeasureBody: 'Deleting {item} will remove it from the patient’s list of assigned measures',
    deleteStartedMeasureDialogBody: 'Measure has been partially completed and all answers will be deleted by removing this measure from the patient’s list of assigned measures.',
    deleteDueDateBody: 'Deleting the date when the measure has never been completed will remove it from the patient’s list of assigned measures',
    disableSchedulingBody: 'Deleting the date will disable the measure\'s schedule.',
    disableStartedSchedulingDialogBody: 'Measure has been partially completed and answers will be deleted.',
    measurePartiallyCompleted: 'Measure has been partially completed and answers will be deleted',
    answersWillBeDeleted: 'Measure has been partially completed and answers will be deleted.',
    thisMeasure: 'this measure',
    theMeasure: 'the measure',
    theDueDate: 'the due date',
    dueDate: 'Due date',
    measure: 'Measure',
    thisDueDate: 'this due date',
    today: 'Today',
    pleaseRead: 'Please Read',
    call911: 'Call 911',
    inactive: 'Inactive',
    active: 'Active',
    sessionTimeout: 'You have been inactive and will be signed out in {time} {unit}',
    noChannelEnabled: 'No channel enabled',
    iUnderstand: 'I understand',
    thanksUser: 'Thanks, {name}!',
    sessionFinishedText: 'Your Owl Session is complete. We have received your results and will review them with you at your next appointment.',
    clinicianPatient: {
      seeGraphAbove: 'Please see the {abbreviation} progress graph above for additional information.'
    },
    patientsTable: {
      patient: 'PATIENT',
      clinicians: 'CLINICIAN(S)',
      conditions: 'CONDITION(S)',
      nextSessionDue: 'NEXT DUE',
      lastSessionCompleted: 'LAST COMPLETED',
      nextVisit: 'NEXT VISIT'
    },
    scheduleListTable: {
      time: 'TIME',
      patient: 'PATIENT',
      measureStatus: 'MEASURE SESSION STATUS',
      lastSessionCompleted: 'LAST COMPLETED',
      nextSessionDue: 'NEXT DUE',
      mrn: 'MRN',
      scheduledWith: 'SCHEDULED WITH',
      clinicians: 'CLINICIAN(S)',
      noPatientsFound: 'No patients found.'
    },
    respondentsTable: {
      respondent: 'RESPONDENT',
      contact: 'CONTACT',
      lastActivity: 'LAST ACTIVITY',
      relationship: 'RELATIONSHIP',
      patient: 'PATIENT',
      mrn: 'MRN'
    },
    addToProgram: 'Add to program',
    addProgramGroup: 'Add program group',
    programUpdated: 'Program has been updated',
    addPatient: 'Add patient',
    addPatientToProgram: 'Add patient to program',
    addMeasure: 'Add measure',
    addedMeasures: 'Added measure | Added measures',
    addDeleteMeasure: 'Add/Delete measure',
    searchMeasures: 'Search Measures',
    searchAlerts: 'Search Alerts',
    measureWontBeSaved: 'Measure will not be saved',
    programWontBeAdded: 'Program will not be added',
    itemWontBeSaved: '{item} will not be saved',
    itemWontBeAdded: '{item} will not be added',
    searchPatients: 'Search Patients',
    searchRespondents: 'Search Respondents',
    searchQueries: 'Search Queries',
    noRespondentsFound: 'No respondents found.',
    noPatientsFound: 'No patients found.',
    noPatientsAssigned: 'You are not assigned to any patients.',
    patientWontBeSaved: 'Patient will not be saved.',
    patientWontBeAdded: 'Patient will not be added.',
    patientWontBeAddedToProgam: 'Patient will not be added to program.',
    respondentWontBeSaved: 'Respondent will not be saved.',
    search: 'Search',
    measureLibraryTable: {
      starred: 'Star',
      name: 'MEASURE',
      questions_count: 'Q#',
      condition: 'CONDITION',
      respondent: 'RESPONDENT'
    },
    patientsByProgramTable: {
      patient: 'PATIENT',
      daysIn: 'DAYS IN PROGRAM',
      rate: 'OWL SESSION COMP RATE',
      nextSessionDue: 'NEXT DUE',
      lastSessionCompleted: 'LAST COMPLETED',
      status: 'PROGRAM\'S MEASURE SESSION STATUS'
    },
    clinicianListTable: {
      clinician: 'CLINICIAN',
      teams: 'TEAMS'
    },
    clinicianTeamListTable: {
      team: 'TEAM'
    },
    programsTable: {
      program: 'PROGRAM',
      length: 'LENGTH',
      clinicians: 'CLINICIANS',
      admission: 'ADMISSION',
      termination: 'TERMINATION',
      discharge: 'DISCHARGE'
    },
    searchPrograms: 'Search Programs',
    detail: 'DETAIL',
    noProgramsFound: 'No programs found.',
    noResultsFound: 'No results found.',
    noSessionsMessageLineOne: 'You have no Owl Sessions today.',
    noSessionsMessageLineTwo: 'Remember to sign out now.',
    noSessionsLogOutButtonText: 'Sign Out',
    skip: 'Skip',
    createProgram: 'Add program',
    updateProgram: 'Update program',
    createProgramGroup: 'Add program group',
    updateProgramGroup: 'Update program group',
    programName: 'Program name',
    programGroupName: 'Group name',
    description: 'Description',
    type: 'Type',
    selectType: 'Select type',
    select: 'Select',
    programLength: 'Length  (Desired program length)',
    programClinician: 'Clinician/Clinician team',
    programDetailsUpdated: 'Program details have been updated',
    saveAndExit: 'Save, and exit',
    save: 'Save',
    saveMeasure: 'Save measure',
    saveChanges: 'Save changes',
    saveChangesAnyway: 'Save changes anyway',
    warning: 'Warning',
    noContactInfo: 'Contact info is missing.',
    notificationsOff: 'Notifications are off.',
    alertCantSendNotification: '{text} Invitations and reminders will not be sent.',
    alertCantSendNotificationOnUpdate: 'Invitations will no longer be sent.',
    createPatient: 'Add Patient',
    updatePatient: 'Update Patient',
    patientSumbit: 'Patient has been {action}',
    respondentSumbit: 'Respondent has been {action}',
    mrn: 'MRN',
    mrnInUse: 'MRN already in use',
    teamNameInUse: 'Name already in use',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    genderAtBirth: 'Gender at birth',
    birthdate: 'Birthdate',
    male: 'Male',
    female: 'Female',
    unknown: 'Unknown',
    other: 'Other',
    gender_non_binary: 'Nonbinary',
    patientsContactInfo: 'PATIENT’S CONTACT INFO',
    patientsWillReceiveNotifications: 'Patients with notifications on will receive invitations and reminders.',
    ifNotificationsInvitationsWillBeSent: 'When account access is on invitations to access account will be sent.',
    addMeasures: 'Add Measures',
    addDeleteMeasures: 'Add/Delete Measures',
    measures: 'Measures',
    frequency: 'Frequency',
    nextDueDate: 'Next due date',
    measureScheduled: 'Measure scheduled',
    measureScheduledInvitationSent: 'Measure has been added and {extra} invitation has been sent.',
    invitationTextWhenToEmailAndSms: 'email and sms',
    invitationTextWhenToEmail: 'an email',
    invitationTextWhenToSms: 'a sms',
    measureAdded: 'Measure has been added.',
    measureRemoved: 'Measure has been removed.',
    allMeasuresRemoved: 'All measures have been removed.',
    addAnother: 'Add another',
    addAnotherMeasure: 'Add Another Measure',
    measureAlreadyScheduledWithConfirmation: 'The measure you selected is already scheduled on {date}. Do you want to add it again?',
    measureAlreadyScheduled: 'Measure is already scheduled on {date}',
    chartNotSupported: 'Chart not yet supported',
    noDataFound: 'No data found',
    noDataFoundFor: 'No data found for {name}.',
    none: 'None',
    respondentAlreadyExists: 'Respondent is already on patient team',
    wouldYouLikeToEditRespondent: 'Would you like to edit respondent?',
    addRespondent: 'Add Respondent',
    createRespondent: 'Add respondent for {patient}',
    updateRespondent: 'Edit respondent for {patient}',
    respondentNameOrId: 'ENTER A NEW OR EXISTING RESPONDENT’S NAME OR ID',
    respondentNameOrIdPlaceholder: 'Type respondent’s name or ID…',
    respondentTypeAdult: 'Adult',
    respondentTypeTeacher: 'Teacher',
    respondentTypeParent: 'Caregiver',
    respondentFirstName: 'First Name',
    respondentMiddleName: 'Middle Name',
    respondentLastName: 'Last Name',
    respondentType: 'Respondent Type',
    respondentPatientRelation: 'Relationship to Patient',
    respondentContactInfo: 'RESPONDENT’S CONTACT INFO',
    respondentWillReceiveNotifications: 'Respondent with notifications on will receive invitations and reminders.',
    totalScore: 'Total Score',
    score: 'Score',
    cutoff: 'Clinical Cutoff',
    scale: 'Scale',
    range: 'Range',
    day: 'Day',
    alert: 'Alert',
    time: 'Time',
    acknowledge: 'Acknowledge',
    contact: 'Contact',
    patient: 'Patient',
    gender: 'Gender',
    age: 'Age',
    cellToText: 'CELL PHONE # TO TEXT',
    accEmail: 'ACCOUNT EMAIL',
    acnowledged: 'Acknowledged by {clinician}. on {date}',
    patientTrigeredAlert: '{patient} endorsed the following item on {measure}',
    measureDueDateAdjusted: 'Measure\'s due date has been adjusted',
    measureFrequencyAdjusted: 'Measure\'s frequency has been adjusted',
    noMeasuresAssigned: 'The respondent does not have any measures scheduled. Click here to',
    noMeasuresAssignedWithoutLink: 'The respondent does not have any measures scheduled.',
    addAMeasure: 'add a measure',
    teamAdded: 'Clinician team has been added',
    createClinicianTeam: 'Add clinician team',
    updateClinicianTeam: 'Update clinician team',
    clinicianTeamWontBeSaved: 'Clinician team will not be saved',
    addTeam: 'Add team',
    clinicians: 'Clinicians',
    name: 'Name',
    alertDeleteTitle: 'Are you sure you want to delete this alert?',
    alertDeleteDialog: 'Deleting the alert will remove it from the patient\'s list of alerts.',
    notAvailable: 'Not Available',
    typeOfTreatment: 'Type Of Treatment',
    dispositionStatus: 'Disposition status',
    terminationDate: 'Termination Date',
    intakeDate: 'Intake Date',
    admissionDate: 'Admission Date',
    deleteClinicianFromTeamTitle: 'Are you sure you want to delete the clinician from this team?',
    clinicianTeamSumbit: 'Team has been {action}',
    mrnExistEditPatient: 'A patient with that MRN already exists. Do you want to view their information?',
    confirm: 'Confirm',
    patientAddedToProgram: 'Patient has been added to program',
    addAnotherProgram: 'Add Another Program',
    measureUpdated: 'Measure was updated',
    detailTooltip: 'View latest individual session graph and report detail',
    begin: 'Begin',
    deleteProgramAlertTitle: 'Are you sure you want to remove the patient from the program?',
    deleteProgramAlertBody: 'If you click Yes, Remove, the record of the patient being in the program will be permanently removed. If you aren\'t sure, use program disposition instead.',
    patientDeletedFromProgram: 'The program has been deleted',
    noPatientInPrgram: 'No patients are enrolled in program.',
    searchAllPatients: 'Search all patients?',
    clinicianNoProgramAssigned: 'You are not assigned to any programs.',
    episodeId: 'Episode ID',
    disableScheduling: 'Disable Scheduling',
    show: 'Show',
    hide: 'Hide',
    all: 'all',
    graph: 'Graph',
    viewSessionReport: 'View Session Report',
    sessionReport: 'Session Report',
    editQuestions: 'Edit Questions',
    measureDeleted: 'Measure has been deleted',
    measureScheduleDisabled: "The measure's schedule has been disabled",
    programWontBeUpdated: 'Changes to the program will not be saved',
    measureDisabled: 'Measure has been disabled',
    dischargeFromProgram: 'Discharge From Program',
    finalizeDischarge: 'Finalize Discharge',
    dischargePatientTitle: 'Discharge Patient',
    dischargeDate: 'Discharge Date',
    dischargeMeasuresTitle: 'Discharge Measures',
    dischargeMeasuresMessage: 'Schedule one time at discharge',
    dischargeConfirmTitle: 'Are you sure you want to discharge the patient?',
    dischargeConfirmMessage: 'Discharging the patient will remove them from the program and disable their measure scheduling. Once discharged, clinicians can no longer view the patient’s chart',
    dischargeSuccessMessage: 'Patient has been discharged',
    dischargeFinalizedMessage: 'Patient’s discharge has been finalized',
    dischargePendingMessage: 'Discharge is not final until patient completes the measures and alerts acknowledged',
    yesDischarge: 'Yes, Discharge',
    programGroupWontBeUpdated: 'Changes to the program group will not be saved',
    optional: 'Optional',
    contactInfoNotificationMessage: 'When notifications are on invitations and reminders are sent.',
    measureOverview: 'View Measure Overview',
    patientStatus: 'Patient Status',
    invitationSent: 'The invitation has been sent.',
    programSumbit: 'The program has been {action}',
    ProgramSpecificMeasures: 'Program specific measures',
    overlayCancelAdd: 'If you exit now the {data} will not be saved.',
    overlayCancelEdit: 'If you exit now the data you entered will not be saved.',
    goToProgressGraph: 'View Progress Graph',
    willActivatePatient: '{action} will activate the patient',
    addingRespondent: 'Adding a respondent',
    schedulingMeasure: 'Scheduling a measure',
    addingMeasure: 'Adding a measure',
    addingToaProgram: 'Adding a patient to a program',
    applyToAll: 'Apply to all measures due the same day',
    linkToVisit: 'Link this measure to the {date} visit.',
    wontBeLinkedToVisit: 'Measure will not be linked to a visit.',
    measureLinkedWithAvisitCantReschedule: 'A measure linked with a visit can only be scheduled one time.',
    areSureRemoveMeasure: 'Are you sure you want to remove the measure?',
    program: 'Program',
    firstDueOn: 'First Due On',
    selectMeasure: 'Select Measure',
    selectDate: 'Select Date',
    linkedToAVisit: 'Linked to {date} visit.',
    notLinkedToAVisit: 'Measure is not linked to a visit.',
    graphing: 'Graphing',
    scoring: 'Scoring',
    response: 'Response',
    triggers: 'Triggers',
    dueDateRemoved: 'Due date has been removed.',
    dispositionStatusError: 'Discharge disposition is required when discharging patient.',
    latestOwlSession: 'Latest Owl Session',
    latestMeasureSession: 'Latest Measure Session',
    latestMeasures: 'Latest Measures',
    editResponses: 'Edit Responses',
    updatesNotSaved: 'Updates have not been saved',
    updatesSaved: 'Updates have been saved',
    prePopulateUpdatesSaved: 'Your answers will be saved but will not be reflected on the session report until you select save changes.',
    measureResponsesUpdated: 'Measure’s responses have been updated',
    saveToScore: 'Save changes to score the measure.',
    saveToComplete: 'Save changes to complete the measure.',
    measuresCantBeScored: 'Measure can not be scored.',
    questionRequiredToScore: 'Some questions required to score the measure have been skipped.',
    clickToEditResponses: 'Click here to edit the responses',
    noMeasuresAreDue: 'No measures are due',
    scoringWithNoGraph: {
      title: 'No graph displays for this measure',
      description: 'because this measure does not calculate a score.',
      link: 'View item-level responses'
    },
    scoringBundle: {
      title: 'No graph displays for this measure',
      description: 'because it is only used for scheduling and does not calculate a score.'
    },
    scoringWithGraphButNoData: {
      title: 'No graph displays for this measure',
      description: 'because the portion of the measure resulting in a score was not administered.',
      link: 'View item-level responses'
    },
    admissionUndone: 'Patient’s admission has been undone',
    reverseAdmission: 'Undo Admission',
    patientTeam: 'Patient Team',
    measuresAddedToRespondent: 'Measures have been added for respondent',
    respondentNotMeasuresAssigned: 'Respondent has not been assigned any measures',
    patientNotCompletedMeasures: 'Patient has not completed measures. Once discharged, clinicians can no longer view the patient’s chart',
    patientHasActiveAlerts: 'Patient has active alerts. Once discharged, clinicians can no longer view the patient’s chart',
    completed: 'Completed',
    addPatientChooseProgramTitle: 'Select the program the patient is being admitted into or select skip below if program is not applicable for the patient.',
    noAppointmentsFond: 'No appointments have been found for this day.',
    noPatientFoundDay: 'No patients found for this day.',
    ehrMustBeOn: 'EMR integration must be turned on for appointments to display.',
    needToSelectAValidFrequency: 'Need to select a valid frequency value first.',
    showMore: 'Show more',
    discontinued: 'DISCONTINUED',
    clinician_s: 'CLINICIAN(S)',
    removeRespondent: 'Remove respondent',
    auditionMeasure: 'Preview',
    measuresAssignedTable: {
      due: 'Due',
      completed: 'Completed',
      pdf: '',
      pdfTooltipEnabled: 'Click for a PDF of {patientName}\'s progress graph(s) and session report(s) from this date.',
      pdfTooltipDisabled: 'Your file is being generated.',
      pdfTooltipFailed: 'This file is not available for download. To request a PDF of this file, please click Support on the bottom right corner and provide us the patient or respondent’s OWL System ID, Completion Date, and Measure.',
      pdfTooltipSafetyPlan: 'Click for a PDF of {patientName}\'s safety plan for this date.',
      measure: 'Measure',
      alert: 'Alert',
      frequency: 'Frequency',
      visit: 'Visit',
      type: 'Type',
      program: 'Program(s)'
    },
    viewResults: 'View Results',
    removedFromTeamTag: 'Removed',
    loading: 'Loading...',
    forThisProgram: 'For this Program',
    completeItems: 'missing {count}',
    schedule_type: 'Schedule Type',
    latest_activity_codes: {
      deactivated_by: 'Deactivated by {name}',
      value_1: 'Account Activated',
      value_2: 'Invitation Sent',
      value_3: 'Screen Assigned',
      value_4: 'Login Success',
      value_5: 'Password Reset',
      value_6: 'Reset Acct Request Sent',
      value_7: 'Account Disabled',
      value_8: 'Measure Completed',
      value_9: 'Invitation Sent',
      value_10: 'Invitation Sent',
      value_11: 'Invitation Sent',
      value_12: 'Password Changed',
      value_13: 'Account Disabled',
      value_14: 'Activated by Clinician',
      value_15: 'Inactivated Account',
      value_16: 'Reactivated Account',
      value_17: 'Logged in as Respondent',
      value_18: 'Invitation Canceled',
      value_19: 'Account Created'
    },
    patient_relations: {
      relation_id_1: 'Patient',
      relation_id_2: 'Client',
      relation_id_3: 'Mother',
      relation_id_4: 'Father',
      relation_id_5: 'Guardian',
      relation_id_6: 'Stepfather',
      relation_id_7: 'Stepmother',
      relation_id_8: 'Grandmother',
      relation_id_9: 'Grandfather',
      relation_id_10: 'Uncle',
      relation_id_11: 'Aunt',
      relation_id_12: 'Great Uncle',
      relation_id_13: 'Great Aunt',
      relation_id_14: 'Niece',
      relation_id_15: 'Nephew',
      relation_id_16: 'Cousin',
      relation_id_17: 'Brother',
      relation_id_18: 'Sister',
      relation_id_19: 'Half Brother',
      relation_id_20: 'Half Sister',
      relation_id_21: 'Ward',
      relation_id_22: 'Spouse',
      relation_id_23: 'Partner',
      relation_id_24: 'Girlfriend',
      relation_id_25: 'Boyfriend',
      relation_id_26: 'Teacher',
      relation_id_27: 'Religious Leader',
      relation_id_28: 'Friend',
      relation_id_29: 'Coach',
      relation_id_30: 'Foster Mother',
      relation_id_31: 'Foster Father',
      relation_id_32: 'Mother1',
      relation_id_33: 'Mother2',
      relation_id_34: 'Father1',
      relation_id_35: 'Father2',
      relation_id_36: 'Guardian1',
      relation_id_37: 'Guardian2',
      relation_id_38: 'Self',
      relation_id_39: 'Son',
      relation_id_40: 'Daughter',
      relation_id_41: 'Grandson',
      relation_id_42: 'Granddaughter',
      relation_id_43: 'Employer',
      relation_id_44: 'Other',
      relation_id_45: 'Foster Parent',
      relation_id_46: 'Adult Sibling',
      relation_id_47: 'Extended Family',
      relation_id_48: 'Grandparent',
      relation_id_49: 'Institution',
      relation_id_50: 'Step Parent',
      relation_id_51: 'Aunt or Uncle',
      relation_id_52: 'Unknown',
      relation_id_53: 'Care Giver',
      relation_id_54: 'Child',
      relation_id_55: 'Foster Child',
      relation_id_56: 'Grandchild',
      relation_id_57: 'Parent',
      relation_id_58: 'Stepchild',
      relation_id_59: 'Sibling'
    },
    addAsNew: 'Add as New',
    searchAgain: 'Search Again',
    ofPatient: 'of patient',
    removedFromPatient: 'removed from patient',
    clinician: 'Clinician',
    contactInfoNotFound: 'Contact information not found.',
    enterContactInfo: 'Enter contact information.',
    settings: 'Settings',
    patientUsers: 'Patient Users',
    clinicalUsers: 'Clinical Users',
    clinicalTeams: 'Clinical Teams',
    user: 'User',
    allUsers: 'all users',
    allRoles: 'All roles',
    someRoles: 'Some roles',
    teams: 'Team | Teams',
    programs: 'Program | Programs',
    locations: 'Location | Locations',
    roles: 'Role | Roles',
    location_s: 'Location(s)',
    saveAndClose: 'Save & Close',
    saveAndInvite: 'Save & Invite',
    additionalGraphInfo: 'Additional Graph Information',
    noPatientCompletedMeasures: 'No patients have completed measures in the past 7 days',
    atLeast1Status: 'Select at least one status:',
    alerts: 'alerts',
    viewAllAlerts: 'View all alerts',
    thereAreNo: 'There are no',
    acknowledged: 'Acknowledged',
    settingsLocationsTable: {
      name: 'Location name',
      addrress: 'Address',
      city: 'City',
      state: 'State',
      zip: 'Zip'
    },
    settingsLocationsForm: {
      name: 'Location name',
      addrress1: 'Address line 1',
      addrress2: 'Address line 2',
      city: 'City',
      state: 'State',
      zip: 'Zip'
    },
    locationId: 'Location ID',
    created: 'Created',
    fieldAdded: '{name} added',
    fieldDeleted: '{name} deleted',
    fieldUpdated: '{name} updated',
    deleteLocation: 'Delete Location',
    deleteLocationText: {
      1: 'Clinical users will be retained but no longer associated with this location.',
      2: 'For clinical users who are only assigned to this location, re-assign them to:'
    },
    noLocationsFound: 'No locations found',
    reAssignClinicalUsers: 'Re-assign clinical users to…',
    customMeasureUpdated: 'Measure has been updated and can be completed by patient.',
    settingsPatientsTable: {
      name: 'Name',
      owlId: 'Owl Id',
      status: 'Status',
      roles: 'Role(s)',
      contactInfo: 'Contact info'
    },
    settingsClinicalUsersTable: {
      name: 'Name',
      owlId: 'Owl Id',
      status: 'Status',
      locations: 'Location(s)',
      roles: 'Role(s)'
    },
    settingsClinicalUserForm: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email Address'
    },
    rolesinfo: {
      staff: {
        label: 'Staff',
        text: 'can access all patients and respondents.'
      },
      clinicians: {
        label: 'Clinicians',
        text: 'can access their assigned patients.'
      },
      supervisors: {
        label: 'Supervisors',
        text: 'can access their clinicians’ patients.  '
      },
      admins: {
        label: 'Admins',
        text: 'can manage clinical user accounts, clinical teams, programs, measures, and respondents.'
      }
    },
    noClinicalUsersFound: 'No users found',
    clinicalUserInvited: '{name} was invited',
    clinicalUserAdded: '{name} was added',
    clinicalUserUpdated: '{name}\'s account updated',
    inviteNow: 'Invite now',
    atLeast1LocationAndOrRole: 'At least one {item} {verb} required.',
    locationAndRole: 'location and role',
    role_s: 'Role(s)',
    lastLogin: 'Last login',
    is: 'is',
    are: 'are',
    owlId: 'Owl Id',
    cliniciansSupervised: 'CLINICIAN(S) SUPERVISED',
    selectClinicians: 'Select clinician(s)',
    supervisors: 'SUPERVISOR(S)',
    ifAny: ', IF ANY',
    selectSupervisors: 'Select supervisor(s)',
    patientAgeEmptyError: 'You must complete the patient\'s age.',
    patientAgeInvalidError: 'You must enter a number.',
    measureVariesByPatient: 'This measure varies by patient type.',
    previewFor: 'Preview for',
    yearsOldPatient: 'year-old patients',
    startPreview: 'Start Preview',
    usersReAssigned: 'Users re-assigned.',
    noSupervisorsFound: 'No supervisors found.',
    noCliniciansFound: 'No clinicians found.',
    noCliniciansAssigned: 'Clinician required before measure can be assigned',
    noSessionsDue: 'No Sessions Due',
    providerId: 'Provider Id',
    userIsStatus: 'User is {status}',
    loginErrorCode1: 'Unable to login through single sign-on.',
    resetPasswordButtonText: 'Reset password',
    resetPasswordSucceedToast: '{name} password reset.',
    myPatients: 'My {status} patients',
    allPatients: 'All {status} patients',
    supervisedClinicianPatients: 'My {status} supervised patients',
    myRespondents: 'My {status} respondents',
    allRespondents: 'All {status} respondents',
    supervisedClinicianRespondents: 'My {status} supervised respondents',
    smsDisabledClinic: 'Clinic disabled text messaging',
    emailDisabledClinic: 'Clinic disabled email messaging',
    supervisorsTooltip: 'Often used in training clinics, this optional field lists the clinician’s clinical supervisor(s) who can access their patient records.',
    proceed: 'Proceed',
    areYouSure: 'Are you sure?',
    superiorsWillBeRemoved: 'To add the supervisor role to the user, their current supervisor(s) will be removed because the user can not be supervised and be a supervisor.',
    providerIdInUse: 'Id already in use',
    sendReminder: 'Send reminder',
    completeOnBehalf: 'Complete on behalf',
    sendInvite: 'Send invite',
    sendMessageModalTitle: 'Send {name}’s {action} to:',
    reSendInvite: 'Re-send invitation',
    invitation: 'invitation',
    reminder: 'reminder',
    invitationSuccess: 'Invitation sent to {name} at ',
    reminderSuccess: 'Reminder sent to {name} at ',
    resetPasswordSuccess: '{name}’s password was reset.',
    deleteLocationTooltip: 'Last available Location cannot be deleted.',
    noContactAvailable: 'No contact information provided',
    contactInfoWillBeUpdated: 'We’ll update their contact info and send future notifications to the selected method',
    noMeasuresAvailable: 'No measures available',
    enterEmailAddress: 'Enter email address',
    enterMobile: 'Enter mobile number',
    analytics: 'Analytics',
    owlAnalytics: 'Owl Analytics',
    reports: 'Reports',
    allClinicians: 'All Clinicians',
    someClinicians: 'Some Clinicians',
    noClinicians: 'No Clinicians',
    someMeasures: 'Some Measures',
    allMeasures: 'All Measures',
    allPrograms: 'All Programs',
    allLocations: 'All Locations',
    myPrograms: 'My Programs',
    noMeasures: 'No Measures',
    resultsToInclude: 'Results to include',
    allResults: 'All results',
    resultsToIncludeFilter: {
      allResults: 'Include All Results',
      admissionAndDischargeProgram: 'Admit/Discharge (Program-Paired)',
      admissionAndDischargeEpisodeId: 'Admit/Discharge (Episode-Paired)'
    },
    outcomesExplorer: 'Outcomes Explorer',
    somePatients: 'Some Patients',
    allPatientsLabel: 'All Patients',
    noPatients: 'No Patients',
    date: 'Date',
    to: 'To',
    dates: {
      allTime: 'All time',
      lastMonth: 'Last month',
      lastYear: 'Last year',
      yearToDate: 'Jan - last month',
      custom: 'Custom range',
      lastYearWithDate: 'Jan - Dec {date}',
      yearToDateWithDate: 'Jan - {date}'
    },
    ages: {
      lt18: '< 18',
      '18_25': '18-25',
      '26_35': '26-35',
      '36_45': '36-45',
      '46_55': '46-55',
      '56_65': '56-65',
      gt65: '> 65',
      custom: 'custom'
    },
    activeUsers: 'Active users',
    inactiveUsers: 'Inactive users',
    someActiveUsers: 'Some active users',
    someUsers: 'Some users',
    records: 'Records',
    go: 'Go',
    selectScores: 'Select scores to generate charts',
    selectedContactInvalid: 'Selected contact is invalid',
    inviteAgain: 'Invite again',
    cancelInvite: 'Cancel invite',
    inviteToSelfLogin: 'Invite to self sign-in',
    inviteToSelfLoginAndInvite: 'Activate & invite',
    deactivate: 'Deactivate',
    deactivated: 'Deactivated',
    activated: 'Activated',
    inviteCanceled: 'invite canceled',
    deactivateName: 'Deactivate {name}?',
    userChangedStatus: '{user} {status}',
    activeAndCanSignIn: 'Active & can sign in',
    activeNoSignIn: 'Active, no sign in',
    activeInvited: 'Active & invited',
    tooltipActiveAndCanSignIn: 'Active and can sign in',
    tooltipActiveNoSignIn: 'Active but no self log in',
    tooltipActiveInvited: 'Active and invited {date}',
    tooltipInactive: 'Inactive',
    notInvited: 'Not invited',
    inviteSent: 'Invite sent',
    lastActivity: 'Last activity',
    deactivatePromptMessage: 'Deactivating a user disables their scheduled measures, but retains their past results.',
    patientUserFormNotificationsMessage: 'Account invitations and measure reminders will be sent when notifications are on.',
    update: 'Update',
    settingsPatientUserForm: {
      name_first: 'FIRST NAME',
      name_middle: 'MIDDLE NAME',
      name_last: 'LAST NAME',
      email: 'RESPONDENT\'S EMAIL',
      phone: 'RESPONDENT\'S MOBILE PHONE'
    },
    clinicalAdministeredMessage: 'This is a clinician-administered measure that you can take once you click Confirm.',
    take: 'Take',
    notificationsDisabled: 'Notifications are disabled for all users',
    patientUserUpdated: '{name} updated',
    patientUserAdded: '{name} added',
    lastUpdated: 'Last updated',
    concealPassword: 'Conceal password',
    revealPassword: 'Reveal password',
    createPassword: 'Create password',
    createUsername: 'Create username',
    saveNewPassword: 'Save New Password',
    accountSetup: 'Account setup',
    usernameExplanation: 'Choose a memorable username. You can use your email or cell if you prefer.',
    passworHasBeenReset: 'Your password has been reset.  Please enter a new one',
    measureDiscontinuedTooltip: 'This measure was discontinued {date}',
    measureDiscontinuedTooltipReplacedWith: ', and replaced with {measure}',
    analyticsTableActionButtons: {
      export: 'Export Data',
      csv: 'CSV'
    },
    analyticsSavedQueries: {
      analyticsLoadSavedQueryOr: 'or',
      analyticsLoadSavedQueryLink: 'Load a saved query',
      analyticsSavedQueriesOverlayTitle: 'My Queries',
      analyticsLoadSavedQueryNoEntries: 'You have no saved queries. To save a query, start a new query then select the triangle in the Go button.',
      validationMin: 'Query name is too short. It must be at least 3 characters.',
      validationMax: 'Query name is too long. it cannot exceed 30 characters.',
      validationRequired: 'You must enter a Query Name.'
    },
    analyticsExport: {
      reconnectingMessage: 'Trying to reconnect to update file status...'
    },
    analyticsQueryModals: {
      analyticsDeleteSavedQueryModalText: 'Deleting the query will remove it from the list of saved queries but will not delete past data.',
      analyticsDeleteSavedQueryModalTitle: 'Delete "{queryName}"?',
      analyticsSaveQueryFormTitle: 'Save Query',
      analyticsSaveQueryFormDuplicatedNameReplaceMsg: 'A query with that name already exists. Click “Replace Query” to replace it.',
      analyticsSaveQueryFormDuplicatedNameChooseAnotherError: 'A query with that name already exists. Please choose another name.',
      analyticsSaveQueryFormFieldName: 'QUERY NAME',
      analyticsSaveQuerySavedToastText: '{name} saved.',
      analyticsSaveQueryUpdatedToastText: '{name} updated.',
      analyticsRenameQueryFormTitle: 'Rename Query',
      analyticsReplaceQuery: 'Replace Query'
    },
    analyticsRecordsData: {
      completion_date: 'Completion date',
      completedBy: 'Completed by',
      record_id: 'Record ID',
      episodeId: 'Episode ID',
      mrn: 'MRN',
      first_name: 'First Name',
      last_name: 'Last Name',
      gender: 'Gender',
      age: 'Age',
      dob: 'Date of birth',
      program_name: 'Program',
      program_id: 'Program ID',
      program_status: 'Program status',
      location_name: 'Location',
      admission_date: 'Admit date',
      discharge_date: 'Discharge date',
      clinical_team: 'Clinical team',
      clinician: 'Clinician',
      lot: 'LOT at measure completion (days)',
      lotAtDischarge: 'LOT at discharge (days)',
      taken_at: 'Taken at',
      taken_at_program: 'Taken At (Program)',
      taken_at_episode: 'Taken At (Episode)',
      measure_name: 'Measure',
      measure_id: 'Measure ID',
      measureQuestionCount: 'Item Count',
      due_date: 'Due date',
      completed_on_due_date: 'Completed on due date',
      score_type: 'Score Type',
      score: 'Score',
      primary_insurance_provider: 'Primary Insurance Provider',
      primary_member_id: 'Primary Member ID',
      primary_group_id: 'Primary Group ID',
      secondary_insurance_provider: 'Secondary Insurance Provider',
      secondary_member_id: 'Secondary Member ID',
      secondary_group_id: 'Secondary Group ID'
    },
    analyticsUsage: {
      usageTabHeader: 'Usage',
      patientsTabHeader: 'Patients',
      clinicalTabHeader: 'Clinical',
      measuresTabHeader: 'Measures',
      patients: {
        measureCompletionRate: 'Measure Completion Rate'
      }
    },
    charts: 'Charts',
    table: 'Table',
    programPatientStatusTitle: 'Patient status',
    programDemographicsTitle: 'Demographics',
    programOutcomesTitle: 'Outcomes',
    genderBreakdown: 'Gender Breakdown',
    ageRange: 'Age Range',
    dischargeDisposition: 'Discharge Disposition',
    daysFromAdmissionToDischarge: 'Days from Admission to Discharge Assessment',
    admitDischargeScores: 'Admit/Discharge Scores',
    scoresOvertime: 'Score Over Time',
    average: 'Average',
    contextButtonTitle: 'Click for copy, delete, and reformat tools.',
    optionalCaption: 'Write your caption here (optional)',
    numberOfPatients: 'Number of patients',
    lengthOfTreatment: 'Length of treatment',
    dischargeMonth: 'Discharge month',
    outpatientScoreOverTime: 'Average {abbr} Score Over Time',
    outpatientDaysToBelowCutoff: 'Days to Below Clinical Cutoff',
    outpatientWeeksToBelowCutoff: 'Weeks to Below Clinical Cutoff',
    totalSeverityScore: 'Total Severity Score',
    admission: 'Admission',
    discharge: 'Discharge',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    admitted: 'Admitted',
    dropped_out: 'Dropped Out',
    aged_out: 'Aged Out',
    transferred: 'Transferred',
    discharged_inactivity: 'Discharged due to Inactivity',
    noMatchSelection: 'No records match your selections',
    atLeast1Program: 'Select at least one program',
    selectOnlyOne: 'select only 1',
    noMoreThanXPrograms: 'Select {limit} or fewer programs',
    atLeast1Location: 'Select at least one location',
    noMoreThanXLocations: 'Select {limit} or fewer locations',
    atLeast1ClinicianOrClinicalTeam: 'Select at least one clinician or clinical team',
    atLeast1Measure: 'Select at least one measure',
    atLeast1Gender: 'Select at least one gender',
    atLeast1AgeRange: 'Select at least one age range',
    atLeast1GenderAndAgeRange: 'Select at least one gender and age range',
    atLeast1CustomAgeRange: 'Custom range for ages required',
    only1Measure: 'Select only one measure',
    exportStarted: 'Your CSV file is being generated and will download momentarily. You can continue using the Owl while it generates.',
    allCharts: 'All Charts',
    items: 'Items',
    deleteFile: 'Delete file?',
    exports: 'Exports',
    dataExports: 'Data Exports',
    download: 'Download',
    generating: 'Generating...',
    failed: 'Failed',
    exportsAreRemoved: 'Exports are removed after 7 days.',
    noExport: {
      first: 'To export data, run a query then click “Export Data” in the results’ Table view.',
      second: 'Exports will be saved here for 7 days.'
    },
    analyticsChartNoRecords: '0 records found. Change query for charts to display',
    analyticsTableNoRecords: '0 records found. Change query for records to display',
    clinicianFields: {
      cliniciansHiddenCreate: 'A clinician/team is required.',
      cliniciansHiddenPrograms: 'A clinician/team is required when the patient is in program',
      cliniciansHiddenMeasures: 'A clinician/team is required until all measures assigned to the patient or patient’s team are completed or disabled'
    },
    relationToPatient: 'RELATION TO PATIENT',
    lastCompleted: 'Last completed',
    nextDue: 'Next due',
    fileBeingGenerated: 'Your file is being generated. When it’s done, you can download it from here.',
    recordsBeingGenerated: 'Your results are generating. Please wait.',
    limitedRecordsShown: 'Only the first {shown} alerts (of {total}) are shown.<br>To view more alerts, search by patient name',
    fileGenerationWarning: 'Files exceeding 10,000 records may fail export or download.',
    helpUserSignIn: 'Help {name} sign in...',
    pageMetaTitle: {
      AccountSetup: 'Set-up Account',
      AddClinicalTeam: 'Settings Clinical Teams',
      AuditionMeasureQuestionnaire: 'Preview Measure {ShortName} ({ID})',
      ChangePassword: 'Change password',
      ClinicianAddMeasureToPatient: 'Add Measure',
      ClinicianAddPatientByProgram: 'Add Patient to Program',
      ClinicianAddTeam: 'Add Clinical Team',
      ClinicianAlertList: 'Alerts',
      ClinicianHomepage: '',
      ClinicianHomePageMeasuresAddedOverview: 'Measure Added',
      ClinicianPatientAddMeasure: 'Add Measure',
      ClinicianPatientAddRespondent: 'Add Respondent',
      ClinicianPatientAlerts: 'Patient Chart Alerts',
      ClinicianPatientInformation: 'Patient Chart Info',
      ClinicianPatientMeasureDetail: '{ShortName} ({ID}) Measure Library Detail',
      ClinicianPatientMeasureDetailFromSessionReport: '{ShortName} ({ID}) Measure Session Report',
      ClinicianPatientMeasures: 'Patient Chart Measures',
      ClinicianPatientMeasuresAddedOverview: 'Measure Added',
      ClinicianPatientMeasuresList: 'Patient Chart List',
      ClinicianPatientPrograms: 'Patient Program List',
      ClinicianPatientsList: 'Patients List',
      ClinicianRespondentsList: 'Respondents List',
      ContactInfo: 'Edit Contact Info',
      EditUsername: 'Edit Username',
      ForgotPassword: 'Forgot Password',
      ForgotUsername: 'Forgot Username',
      Login: 'Login',
      MeasuresClinician: 'Measures Library',
      MyAccount: 'My Account',
      PatientsByProgram: 'My Programs',
      SessionDetail: 'Session Report',
      SettingsAddProgram: 'Add Program',
      SettingsClinicalTeam: 'Settings Clinical Teams',
      SettingsClinicalUsers: 'Settings Clinical Users',
      SettingsLocations: 'Settings Locations',
      SettingsPatientUsers: 'Settings Patient Users',
      SettingsPrograms: 'Programs List'
    },
    withNoContactInfo: 'without contact info',
    selected: 'selected',
    scoredMeasuresSelected: 'scored measures selected',
    reportOn: 'Report on',
    allConditions: 'All conditions',
    noConditions: 'No conditions',
    someConditions: 'Some conditions',
    apply: 'Apply',
    applyFilter: 'Apply Filter',
    measureScoreFilter: {
      scorable: 'Scores',
      itemLevel: 'Item-level'
    },
    programFilter: {
      outpatient: 'Outpatient',
      programs: 'Programs'
    },
    skipped: 'Skipped',
    notPresented: 'Not shown'
  },
  es: {
    signIn: 'Iniciar Sesion'
  }
}

module.exports = { messages }
