import { SS_OUTPATIENT } from '@/data/setttingStates'
import store from '@/store'
import {
  getNextRouteWhenGoingHome,
  getNextSettingsHomeRoute
} from '@/mixins/UserManagement/RouteAccess'

import {
  USER_ROLE_RESPONDENT,
  USER_ROLE_CLINICIAN,
  USER_ROLE_SUPERVISOR,
  USER_ROLE_DIRECTOR,
  USER_ROLE_STAFF,
  USER_ROLE_ADMIN
} from '@/mixins/UserManagement/Roles'

import {
  notAuthenticatedRedirect,
  authenticatedRedirect,
  programsEnabledRedirect,
  hasMeasuresInSessionRedirect,
  renewCredentialsRedirect
} from '@/router/routeHelper'

// const ClinicianLayout = () => import('@/components/layout/ClinicianLayout')
const Layout = () => import('@/components/layout/Layout')
const AuthLayout = () => import('@/components/layout/AuthLayout')
const OnlyHeaderLayout = () => import('@/components/layout/OnlyHeaderLayout')

const PageNotFound = () => import('@/components/common/PageNotFound')
const MaintenancePage = () => import('@/components/common/MaintenancePage')
const Welcome = () => import('@/components/Welcome')
const Session = () => import('@/components/measures/Session')
const NoSessions = () => import('@/components/measures/NoSessions')
const SingleMeasure = () => import('@/components/measures/SingleMeasure')
const SessionResultSent = () => import('@/components/measures/SessionResultSent')
const AuditionMeasure = () => import('@/components/measures/audition/AuditionMeasure')
const AuditionMeasureQuestionnaire = () => import('@/components/measures/audition/AuditionMeasureQuestionnaire')
const AuditionMeasureReport = () => import('@/components/measures/audition/AuditionMeasureReport')

const Login = () => import('@/components/security/Login')
const ForgotUsername = () => import('@/components/security/ForgotUsername')
const ForgotPassword = () => import('@/components/security/ForgotPassword')
const ForgotPasswordLoggedUser = () => import('@/components/security/ForgotPasswordLoggedUser')
const ChangePassword = () => import('@/components/security/ChangePassword')
const AccountSetup = () => import('@/components/security/AccountSetup')
const ResetPassword = () => import('@/components/security/ResetPassword')
const ValidateToken = () => import('@/components/security/ValidateToken')

const MyAccountLayout = () => import('@/components/user/MyAccountLayout')
const MyAccount = () => import('@/components/user/MyAccount')
const ContactInfo = () => import('@/components/user/ContactInfo')
const EditUsername = () => import('@/components/user/EditUsername')

const ClinicianHomeView = () => import('@/views/ClinicianHomeView')

const WelcomeClinician = () => import('@/components/WelcomeClinician')

const CliniciansList = () => import('@/components/clinician/list/CliniciansList')
const MeasuresClinician = () => import('@/components/clinician/measures/MeasuresClinician')
const ClinicianAlertList = () => import('@/components/clinician/ClinicianAlertList')
const ClinicianTeamFormWrapper = () => import('@/components/clinician/teams/ClinicianTeamFormWrapper')
const ClinicianHomepage = () => import('@/components/homepage/ClinicianHomepage')

const ClinicianProgramAddMeasure = () => import('@/components/clinician/programs/ClinicianProgramAddMeasure')
const ClinicianProgramFormWrapper = () => import('@/components/clinician/programs/ClinicianProgramFormWrapper')
// Program group feature is disabled for now
// const ClinicianProgramGroupFormWrapper = () => import('@/components/clinician/programs/group/ClinicianProgramGroupFormWrapper')

// Patient Components
const PatientLayout = () => import('@/components/layout/PatientLayout')

const ClinicianPatient = () => import('@/components/clinician/patients/ClinicianPatient')
const ClinicianPatientPDF = () => import('@/components/clinician/patients/ClinicianPatientPDF')
const ClinicianPatientInformation = () => import('@/components/clinician/patients/ClinicianPatientInformation')
const ClinicianPatientMeasuresList = () => import('@/components/clinician/patients/ClinicianPatientMeasuresList')
const ClinicianPatientInsurance = () => import('@/components/clinician/patients/ClinicianPatientInsurance')
const ClinicianPatientPrograms = () => import('@/components/clinician/patients/programs/ClinicianPatientPrograms')
const ClinicianPatientAlerts = () => import('@/components/clinician/patients/ClinicianPatientAlerts')
const ClinicianPatientsListWrapper = () => import('@/components/clinician/patients/ClinicianPatientsListWrapper')
const ClinicianPatientFormWrapper = () => import('@/components/clinician/patients/ClinicianPatientFormWrapper')
const ClinicianRespondent = () => import('@/components/clinician/patients/ClinicianRespondent')
const ClinicianAddPatientToProgram = () => import('@/components/clinician/patients/ClinicianAddPatientToProgram')
const ClinicianDischargeFromProgram = () => import('@/components/clinician/programs/ClinicianDischargeFromProgram')
const AddPatientChooseProgram = () => import('@/components/clinician/patients/AddPatientChooseProgram')

const ClinicianAddMeasureToPatient = () => import('@/components/clinician/patients/ClinicianAddMeasureToPatient')
const ClinicianPatientMeasures = () => import('@/components/clinician/patients/measures/ClinicianPatientMeasures')
const SessionDetail = () => import('@/components/clinician/patients/measures/SessionDetail')
const ClinicianPatientMeasureDetail = () => import('@/components/clinician/patients/measures/ClinicianPatientMeasureDetail')
const ClinicianPatientMeasuresAddedOverview = () => import('@/components/clinician/patients/measures/ClinicianPatientMeasuresAddedOverview')

const SettingsLayout = () => import('@/components/admin/settings/SettingsLayout.vue')
const SettingsPatientUsers = () => import('@/components/admin/settings/SettingsPatientUsers')
const SettingsClinicalUsers = () => import('@/components/admin/settings/SettingsClinicalUsers')
const SettingsClinicalTeam = () => import('@/components/admin/settings/SettingsClinicalTeam')
const SettingsPrograms = () => import('@/components/admin/settings/SettingsPrograms')
const SettingsLocations = () => import('@/components/admin/settings/SettingsLocations')

const AnalyticsLayout = () => import('@/components/analytics/layout/AnalyticsLayout.vue')
const AnalyticsOutcomesExplorer = () => import('@/components/analytics/outcomesExplorer/AnalyticsOutcomesExplorer.vue')
const AnalyticsUsage = () => import('@/components/analytics/usage/AnalyticsUsage.vue')
const AnalyticsUsagePatients = () => import('@/components/analytics/usage/AnalyticsUsagePatients.vue')
const AnalyticsUsageClinical = () => import('@/components/analytics/usage/AnalyticsUsageClinical.vue')
const AnalyticsUsageMeasures = () => import('@/components/analytics/usage/AnalyticsUsageMeasures.vue')

export default [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    beforeEnter: authenticatedRedirect,
    meta: {
      permissions: {
        roles: [USER_ROLE_CLINICIAN, USER_ROLE_RESPONDENT, USER_ROLE_SUPERVISOR]
      }
    },
    redirect: () => {
      const routeName = getNextRouteWhenGoingHome()
      return { name: routeName }
    },
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_STAFF, USER_ROLE_SUPERVISOR, USER_ROLE_CLINICIAN, USER_ROLE_RESPONDENT]
          }
        }
      }, {
        path: '/my-account',
        component: MyAccountLayout,
        beforeEnter: (to, from, next) => store.getters.loggedInUser.isAccountActive ? next() : next('/'),
        children: [{
          path: '/my-account',
          name: 'MyAccount',
          component: MyAccount,
          meta: {
            permissions: {
              roles: [USER_ROLE_RESPONDENT]
            }
          },
          beforeEnter: authenticatedRedirect
        },
        {
          path: '/my-account/contact-info',
          name: 'ContactInfo',
          component: ContactInfo,
          meta: {
            permissions: {
              roles: [USER_ROLE_RESPONDENT]
            }
          },
          beforeEnter: authenticatedRedirect
        },
        {
          path: '/my-account/edit-username',
          name: 'EditUsername',
          component: EditUsername,
          meta: {
            permissions: {
              roles: [USER_ROLE_RESPONDENT]
            }
          },
          beforeEnter: authenticatedRedirect
        },
        {
          path: '/my-account/change-password',
          name: 'ChangePassword',
          component: ChangePassword,
          meta: {
            permissions: {
              roles: [USER_ROLE_RESPONDENT]
            }
          },
          beforeEnter: authenticatedRedirect
        }
        ]
      }, {
        path: '/session',
        name: 'Session',
        component: Session,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      },
      {
        path: '/no-sessions',
        name: 'NoSessions',
        component: NoSessions,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      }, {
        path: '/session/measure/:measureId/page/:pageId',
        name: 'SingleMeasure',
        component: SingleMeasure,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      }, {
        path: '/session/results-sent',
        name: 'SessionResultSent',
        component: SessionResultSent,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      }, {
        path: '/forgot-password',
        name: 'ForgotPasswordLoggedUser',
        component: ForgotPasswordLoggedUser,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      }, {
        path: '/reset-password',
        name: 'ResetPasswordLoggedUser',
        component: ResetPassword,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_RESPONDENT]
          }
        }
      }
    ]
  },
  {
    path: '/security',
    name: 'AuthLayout',
    redirect: { name: 'Login' },
    component: AuthLayout,
    children: [{
      path: 'login',
      name: 'Login',
      component: Login,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'forgot-username',
      name: 'ForgotUsername',
      component: ForgotUsername,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'account-setup',
      name: 'AccountSetup',
      component: AccountSetup,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'validate-token',
      name: 'ValidateToken',
      component: ValidateToken,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      beforeEnter: notAuthenticatedRedirect
    }, {
      path: 'external-login/token/:key/patient/:patientId',
      name: 'ExternalLoginRedirectPatient',
      component: ResetPassword,
      beforeEnter: renewCredentialsRedirect
    }, {
      path: 'external-login/token/:key',
      name: 'ExternalLogin',
      component: ResetPassword,
      beforeEnter: renewCredentialsRedirect
    }]
  },
  {
    path: '/measures',
    name: 'MeasuresLayout',
    redirect: { name: 'AuditionMeasure' },
    component: OnlyHeaderLayout,
    meta: {
      permissions: {
        roles: [USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR, USER_ROLE_STAFF]
      }
    },
    children: [{
      path: 'audition/:measureId',
      name: 'AuditionMeasure',
      component: AuditionMeasure,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR, USER_ROLE_STAFF]
        }
      },
      children: [{
        path: 'questionnaire',
        name: 'AuditionMeasureQuestionnaire',
        component: AuditionMeasureQuestionnaire,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'report',
        name: 'AuditionMeasureReport',
        component: AuditionMeasureReport,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR, USER_ROLE_STAFF]
          }
        }
      }]
    }]
  }, {
    path: '/analytics',
    name: 'AnalyticsLayout',
    redirect: () => store.getters.loggedInUser.roles && store.getters.loggedInUser.roles.roles.includes(USER_ROLE_DIRECTOR) ? { name: 'AnalyticsOutcomesExplorer' } : { name: 'AnalyticsUsagePatients' },
    beforeEnter: authenticatedRedirect,
    component: AnalyticsLayout,
    meta: {
      permissions: {
        roles: [USER_ROLE_DIRECTOR, USER_ROLE_ADMIN]
      }
    },
    children: [{
      path: 'outcomes-explorer',
      name: 'AnalyticsOutcomesExplorer',
      component: AnalyticsOutcomesExplorer,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_DIRECTOR]
        }
      }
    },
    {
      path: 'usage',
      name: 'AnalyticsUsage',
      redirect: { name: 'AnalyticsUsagePatients' },
      component: AnalyticsUsage,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_ADMIN, USER_ROLE_DIRECTOR]
        }
      },
      children: [{
        path: 'patients',
        name: 'AnalyticsUsagePatients',
        component: AnalyticsUsagePatients,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN, USER_ROLE_DIRECTOR]
          }
        }
      },
      {
        path: 'clinical',
        name: 'AnalyticsUsageClinical',
        component: AnalyticsUsageClinical,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN, USER_ROLE_DIRECTOR]
          }
        }
      },
      {
        path: 'measures',
        name: 'AnalyticsUsageMeasures',
        component: AnalyticsUsageMeasures,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN, USER_ROLE_DIRECTOR]
          }
        }
      }]
    }]
  }, {
    path: '/settings',
    name: 'SettingsLayout',
    beforeEnter: authenticatedRedirect,
    redirect: () => ({ name: getNextSettingsHomeRoute() }),
    component: SettingsLayout,
    meta: {
      permissions: {
        roles: [USER_ROLE_ADMIN, USER_ROLE_STAFF]
      }
    },
    children: [{
      path: 'patient-users',
      name: 'SettingsPatientUsers',
      component: SettingsPatientUsers,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_STAFF]
        }
      }
    },
    {
      path: 'clinical-users',
      name: 'SettingsClinicalUsers',
      component: SettingsClinicalUsers,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_ADMIN]
        }
      }
    },
    {
      path: 'clinical-team',
      name: 'SettingsClinicalTeam',
      component: SettingsClinicalTeam,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_ADMIN]
        }
      },
      children: [{
        path: 'add',
        name: 'ClinicianAddTeam',
        component: ClinicianTeamFormWrapper,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN]
          }
        }
      },
      {
        path: ':teamId/edit',
        name: 'ClinicianEditTeam',
        component: ClinicianTeamFormWrapper,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN]
          }
        }
      }]
    }, {
      path: 'programs',
      name: 'SettingsPrograms',
      component: SettingsPrograms,
      beforeEnter: programsEnabledRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_ADMIN]
        }
      },
      children: [{
        path: 'add',
        name: 'SettingsAddProgram',
        component: ClinicianProgramFormWrapper,
        beforeEnter: programsEnabledRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN]
          }
        }
      },
      {
        path: 'program/:programId/edit',
        name: 'SettingsEditProgram',
        component: ClinicianProgramFormWrapper,
        beforeEnter: programsEnabledRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN]
          }
        }
      }, {
        path: 'program/:programId/add-measure',
        name: 'SettingsProgramAddMeasure',
        component: ClinicianProgramAddMeasure,
        beforeEnter: programsEnabledRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_ADMIN]
          }
        }
      }]
    },
    {
      path: 'locations',
      name: 'SettingsLocations',
      component: SettingsLocations,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_ADMIN]
        }
      }
    }]
  },

  /* HOMEPAGE REDESIGN */
  {
    path: '/clinician',
    beforeEnter: authenticatedRedirect,
    redirect: { name: 'ClinicianHomepage' },
    component: ClinicianHomeView,
    meta: {
      permissions: {
        roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
      }
    },
    children: [{
      path: 'welcome',
      name: 'WelcomeClinician',
      component: WelcomeClinician,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR, USER_ROLE_STAFF, USER_ROLE_ADMIN]
        }
      }
    }, {
      path: 'home',
      name: 'ClinicianHomepage',
      component: ClinicianHomepage,
      beforeEnter: (to, from, next) => {
        const isSState1 = store.getters.generalSettings.setting_state === SS_OUTPATIENT
        const isStaff = store.getters.loggedInUser && store.getters.loggedInUser.USER_ROLE === USER_ROLE_STAFF
        if (isSState1 && isStaff && to.name !== 'ClinicianPatientsList') {
          // if setting_state is outpatient and user is staff, default to patient list
          next({ name: 'ClinicianPatientsList' })
        } else {
          next({ query: { homeTab: 'today' } })
        }
        return authenticatedRedirect(to, from, next)
      },
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
        }
      },
      children: [{
        path: 'add',
        name: 'ClinicianAddPatientByProgram',
        component: ClinicianPatientFormWrapper,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'add/choose',
        name: 'AddPatientChooseProgram',
        component: AddPatientChooseProgram,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      },
      {
        path: 'patient/:patientId/add-program',
        name: 'ClinicianAddProgramToPatient',
        component: ClinicianAddPatientToProgram,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      },
      {
        path: 'discharge/:accessControlProgramId/:patientId/:assignmentId',
        name: 'ClinicianDischargeFromProgram',
        component: ClinicianDischargeFromProgram,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'patient/:patientId/:ccaId/:ccauId/add-measures',
        name: 'ClinicianPatientsByProgramAddMeasureToPatient',
        component: ClinicianAddMeasureToPatient,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'patient/:patientId/:ccaId/:ccauId/add-measures/overview',
        name: 'ClinicianPatientsByProgramAddMeasureToPatientOverview',
        component: ClinicianPatientMeasuresAddedOverview,
        beforeEnter: hasMeasuresInSessionRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: ':ccaId/:ccauId/measures/add',
        name: 'ClinicianHomePageAddMeasure',
        component: ClinicianAddMeasureToPatient,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: '/:ccaId/:ccauId/measures-added/overview',
        name: 'ClinicianHomePageMeasuresAddedOverview',
        component: ClinicianPatientMeasuresAddedOverview,
        beforeEnter: hasMeasuresInSessionRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }]
    }, {
      path: 'patients',
      name: 'ClinicianPatientsList',
      component: ClinicianPatientsListWrapper,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
        }
      },
      children: [{
        path: 'add',
        name: 'ClinicianAddPatient',
        component: ClinicianPatientFormWrapper,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      },
      {
        path: 'add/choose',
        name: 'AddPatientChoose',
        component: ClinicianPatientFormWrapper,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'patient/:patientId/edit',
        name: 'ClinicianEditPatient',
        component: ClinicianPatientFormWrapper,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }, {
        path: 'patient/:patientId/add-measure',
        name: 'ClinicianAddMeasureToPatient',
        component: ClinicianAddMeasureToPatient,
        beforeEnter: authenticatedRedirect,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
          }
        }
      }]
    }, {
      path: 'list',
      name: 'ClinicianList',
      component: CliniciansList,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
        }
      }
      //   // }, {
      //   //   path: 'group/add',
      //   //   name: 'ClinicianAddProgramGroup',
      //   //   component: ClinicianProgramGroupFormWrapper,
      //   //   beforeEnter: programsEnabledRedirect,
      //   //   meta: {
      //   //     permissions: {
      //   //       roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
      //   //     }
      //   //   }
      //   // }, {
      //   //   path: 'program-group/:programGroupId/edit',
      //   //   name: 'ClinicianEditProgramGroup',
      //   //   component: ClinicianProgramGroupFormWrapper,
      //   //   beforeEnter: programsEnabledRedirect,
      //   //   meta: {
      //   //     permissions: {
      //   //       roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF]
      //   //     }
      //   //   }
      //   }]
    }, {
      path: 'respondents',
      name: 'ClinicianRespondentsList',
      component: ClinicianPatientsListWrapper,
      beforeEnter: (to, from, next) => {
        document.body.classList.add('wider')
        return authenticatedRedirect(to, from, next)
      },
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
        }
      }
    }, {
      path: 'measures',
      name: 'MeasuresClinician',
      component: MeasuresClinician,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
        }
      }
    },
    {
      path: 'alert-list',
      name: 'ClinicianAlertList',
      component: ClinicianAlertList,
      beforeEnter: authenticatedRedirect,
      meta: {
        permissions: {
          roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
        }
      }
    }]
  },
  /* END HOMEPAGE REDESIGN */

  {
    path: '/assignment/:ccaId/:ccauId/measures/:measureId/pdf',
    name: 'ClinicianPatientMeasurePDF',
    component: ClinicianPatientPDF
  }, {
    path: '/assignment/:ccaId/:ccauId/pdf',
    name: 'ClinicianPatientPDF',
    component: ClinicianPatientPDF
  }, {
    path: '/assignment/:ccauId',
    name: 'PatientLayout',
    redirect: { name: 'ClinicianPatient' },
    component: PatientLayout,
    beforeEnter: authenticatedRedirect,
    children: [{
      path: '/assignment/:ccaId/:ccauId?',
      name: 'ClinicianPatient',
      component: ClinicianPatient,
      redirect: { name: 'ClinicianPatientMeasures' },
      children: [{
        path: '/assignment/:ccaId/:ccauId/measures',
        name: 'ClinicianPatientMeasures',
        component: ClinicianPatientMeasures,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        },
        children: [{
          path: '/assignment/:ccaId/:ccauId/measures/add',
          name: 'ClinicianPatientAddMeasure',
          component: ClinicianAddMeasureToPatient,
          beforeEnter: authenticatedRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }, {
          path: '/assignment/:ccaId/:ccauId/measures/measure/:libraryId/detail',
          name: 'ClinicianPatientMeasureDetail',
          component: ClinicianPatientMeasureDetail,
          beforeEnter: authenticatedRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }, {
          path: '/assignment/:ccaId/:ccauId/measures/overview',
          name: 'ClinicianPatientMeasuresAddedOverview',
          component: ClinicianPatientMeasuresAddedOverview,
          beforeEnter: hasMeasuresInSessionRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }]
      }, {
        path: '/assignment/:ccaId/:ccauId/measures/:measureId',
        name: 'SessionDetail',
        component: SessionDetail,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        },
        children: [{
          path: '/assignment/:ccaId/:ccauId/measures/:measureId/measure/:libraryId/detail',
          name: 'ClinicianPatientMeasureDetailFromSessionReport',
          component: ClinicianPatientMeasureDetail,
          beforeEnter: authenticatedRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }]
      }, {
        path: '/assignment/:ccaId/:ccauId/alerts',
        name: 'ClinicianPatientAlerts',
        component: ClinicianPatientAlerts,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        }
      }, {
        path: '/assignment/:ccaId/:ccauId/information',
        name: 'ClinicianPatientInformation',
        component: ClinicianPatientInformation,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        }
      }, {
        path: '/assignment/:ccaId/:ccauId/measures-list',
        name: 'ClinicianPatientMeasuresList',
        component: ClinicianPatientMeasuresList,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        }
      }, {
        path: '/assignment/:ccaId/:ccauId/insurance',
        name: 'ClinicianPatientInsurance',
        component: ClinicianPatientInsurance,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        }
      }, {
        path: '/assignment/:ccaId/:ccauId/programs',
        name: 'ClinicianPatientPrograms',
        component: ClinicianPatientPrograms,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        },
        children: [{
          path: 'add/:patientId',
          name: 'ClinicianAddPatientToProgram',
          component: ClinicianAddPatientToProgram,
          beforeEnter: authenticatedRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }, {
          path: 'discharge/:accessControlProgramId/:patientId/:assignmentId',
          name: 'ClinicianPatientDischargeFromProgram',
          component: ClinicianDischargeFromProgram,
          beforeEnter: authenticatedRedirect,
          meta: {
            permissions: {
              roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
            }
          }
        }]
      }, {
        path: '/assignment/:ccaId/:ccauId/respondent/add',
        name: 'ClinicianPatientAddRespondent',
        component: ClinicianRespondent,
        meta: {
          permissions: {
            roles: [USER_ROLE_CLINICIAN, USER_ROLE_STAFF, USER_ROLE_SUPERVISOR]
          }
        }
      }]
    }]
  }, {
    path: '/maintenance',
    name: 'MaintenancePage',
    component: MaintenancePage
  }, {
    path: '/force-reset-password',
    name: 'ForceResetPasswordClinicalUser',
    beforeEnter: (to, from, next) => {
      store.dispatch('TOGGLE_MY_ACCOUNT_STATUS')
      next('/')
    }
  }, {
    path: '/404',
    alias: '*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]
