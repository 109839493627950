export const SIGN_OUT = 'lastSession/SIGN_OUT'
export const GET_RESPONDENT_LATEST_SESSION = 'lastSession/GET_RESPONDENT_LATEST_SESSION'
export const GET_RESPONDENT_LATEST_SESSION_LOADING = 'lastSession/GET_RESPONDENT_LATEST_SESSION_LOADING'
export const GET_TEAM_LATEST_SESSION = 'lastSession/GET_TEAM_LATEST_SESSION'
export const UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON = 'lastSession/UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON'
export const SET_LAST_SESSION_DETAIL = 'lastSession/SET_LAST_SESSION_DETAIL'
export const GET_TEAM_MEASURE_SESSION = 'measureSession/GET_TEAM_MEASURE_SESSION'

export const ENDPOINTS = {
  GET_RESPONDENT_LATEST_SESSION: 'latest-session/respondent/{ccauId}/program/{programId}',
  GET_TEAM_LATEST_SESSION: 'latest-session/team/{ccaId}/program/{programId}',
  GET_TEAM_LATEST_SESSION_WITHOUT_PROGRAM: 'latest-session/team/{ccaId}',
  GET_TEAM_MEASURE_SESSION: 'measure-session/team/{ccaId}'
}
