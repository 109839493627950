import axios from 'axios/index'
import { Base64 } from 'js-base64'
import store from '@/store/index'
import {
  LOCAL_STORAGE_USER_KEY_NAME,
  LOCAL_STORAGE_USER_TOKEN,
  LOCAL_STORAGE_USER_TOKEN_REFRESH,
  SIGN_IN
} from './constants'
import { USER_ROLE_STAFF } from '@/mixins/UserManagement/Roles'

const HEADER_AUTHORIZATION = 'Authorization'
const HEADER_REFRESH_TOKEN = 'x-refresh-token'
const HEADER_NEW_ACCESS_TOKEN = 'x-new-access-token'
const HEADER_NEW_REFRESH_TOKEN = 'x-new-refresh-token'
const HEADER_API_KEY = 'api-key'
const KEEP_ALIVE_URL = 'security/keep-alive'
const KEEP_ALIVE_URL_WITH_SLASH = '/security/keep-alive'

/**
 * Remove Access Token Values from app.
 */
export function unsetAccessTokens () {
  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN)
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY_NAME)
  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_REFRESH)
  delete axios.defaults.headers.common[HEADER_AUTHORIZATION]
  delete axios.defaults.headers.common[HEADER_REFRESH_TOKEN]
  delete axios.defaults.headers.common[HEADER_API_KEY]
}

/**
 * Set Access Tokens in app
 * @param accessToken
 * @param refreshToken
 */
export function setAccessTokens (accessToken, refreshToken) {
  localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, accessToken)
  localStorage.setItem(LOCAL_STORAGE_USER_TOKEN_REFRESH, refreshToken)
  setAxiosAuthorizationHeader(accessToken)
  setAxiosRefreshTokenHeader(refreshToken)
}

/**
 * Set Access Token at app initialization after a hard refresh
 */
export function setAccessTokensFromLocalStorage () {
  const token = getAccessTokenFromLocalStorage()
  const refreshToken = getRefreshTokenFromLocalStorage()

  if (token) {
    setAxiosAuthorizationHeader(token)
  }

  if (refreshToken) {
    setAxiosRefreshTokenHeader(refreshToken)
  }
}

/**
 * Refresh Access Token from a response that contains new Access Token and
 * New Refresh token as headers.
 *
 * @param response
 * @returns {*}
 */
export async function refreshAccessTokenFromAxiosResponse (response) {
  const newAccessToken = getHeaderFromAxiosResponse(response, HEADER_NEW_ACCESS_TOKEN)
  const newRefreshToken = getHeaderFromAxiosResponse(response, HEADER_NEW_REFRESH_TOKEN)
  const haveValidTokens = newAccessToken && newRefreshToken
  return haveValidTokens ? setAccessTokens(newAccessToken, newRefreshToken) : null
}

/**
 * Set Request interceptor to be able to Stop request until Token is refreshed.
 *
 */
export function setSessionRequestInterceptor () {
  let renovateSessionStatusPromise = null

  axios.interceptors.request.use(request => {
    if (request.url === KEEP_ALIVE_URL || window.location.pathname.indexOf('/security/') !== -1) {
      return request
    }

    return new Promise((resolve, reject) => {
      if (!renovateSessionStatusPromise) {
        renovateSessionStatusPromise = renovateSessionStatus()
      }

      renovateSessionStatusPromise.then(() => {
        renovateRequestHeaders(request)
        resolve(request)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        renovateSessionStatusPromise = null
      })
    })
  })
}

/**
 * This function is a helper to refresh JWT Token before retrying a 401 request
 * That may have been caused by a token expiration.
 * @param failedRequestError
 * @returns {Promise<unknown>}
 */
let refreshingTokenAfter401 = null
export function handle401RefreshToken (failedRequestError) {
  return new Promise((resolve, reject) => {
    if (failedRequestError.config.url.includes(KEEP_ALIVE_URL_WITH_SLASH)) {
      return reject(failedRequestError)
    }

    if (failedRequestError.config.url.indexOf('logout') !== -1) {
      return reject(failedRequestError)
    }

    if (!refreshingTokenAfter401) {
      refreshingTokenAfter401 = renovateSession()
    }

    refreshingTokenAfter401.then(() => {
      repeatFailedRequest(failedRequestError).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    }).catch(error => {
      reject(error)
    }).finally(() => {
      setTimeout(() => {
        refreshingTokenAfter401 = null
      }, 2000)
    })
  })
}

/**
 * Handle Api-Key Header as a second auth mechanism
 */
export function handleApiKeyHeader (to) {
  if (to.query && to.query.pdfKey) {
    const pdfKey = Base64.decode(to.query.pdfKey)
    setAxiosHeader(HEADER_API_KEY, pdfKey)
    const data = {
      token: pdfKey,
      user: { roles: { roles: [USER_ROLE_STAFF] } },
      refresh_token: null
    }
    store.commit(SIGN_IN, data)
  }
}

/**
 * Repeat a request that has failed from the axios.catch error parameter
 * using the request original configuration (url, method, and params).
 *
 * @param failedRequestError
 * @returns {AxiosPromise}
 */
function repeatFailedRequest (failedRequestError) {
  failedRequestError.config.url = failedRequestError.config.url.replace('/api/', '')
  return axios(failedRequestError.config)
}

function renovateRequestHeaders (request) {
  request.headers[HEADER_AUTHORIZATION] = getBearerToken(getAccessTokenFromLocalStorage())
  request.headers[HEADER_REFRESH_TOKEN] = getBearerToken(getRefreshTokenFromLocalStorage())
  return true
}

/**
 * Renovate a session using refresh tokens if
 *
 * @returns {Promise<void>}
 */
async function renovateSessionStatus () {
  const hasExpired = accessTokenHasExpired()
  return hasExpired ? renovateSession() : true
}

/**
 * Send a Keep-Alive message,
 * This will automatically renovate the credentials upon success.
 *
 * @returns {Promise<any>}
 */
async function renovateSession () {
  return new Promise((resolve, reject) => {
    axios.get(KEEP_ALIVE_URL).then((response) => {
      refreshAccessTokenFromAxiosResponse(response).then(() => resolve(true))
    }).catch((e) => {
      unsetAccessTokens()
      reject(e)
    })
  })
}

/**
 * Determine if the access token being used is expired or not.
 *
 * @returns {boolean}
 */
function accessTokenHasExpired () {
  const accessToken = getAccessTokenFromLocalStorage()
  const accessTokenData = accessToken ? getAccessTokenInformation(accessToken) : null
  return accessTokenData ? (accessTokenData.exp * 1000) < new Date().getTime() : false
}

/**
 * Get Access token value from localstorage.
 *
 * @returns {string | null}
 */
function getAccessTokenFromLocalStorage () {
  return localStorage.getItem(LOCAL_STORAGE_USER_TOKEN)
}

/**
 * Decodes a JWT Token
 *
 * @param token
 * @returns {any}
 */
function getAccessTokenInformation (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

/**
 * Get Refresh token from local storage
 *
 * @returns {string | null}
 */
function getRefreshTokenFromLocalStorage () {
  return localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_REFRESH)
}

/**
 * Get headers from response.
 *
 * @param response
 * @param headerName
 * @returns {null}
 */
function getHeaderFromAxiosResponse (response, headerName) {
  const headerIsPresent = response && response.headers && response.headers[headerName]
  return headerIsPresent ? response.headers[headerName] : null
}

/**
 * Set Header Authorization
 *
 * @param token
 */
function setAxiosAuthorizationHeader (token) {
  setAxiosHeader(HEADER_AUTHORIZATION, getBearerToken(token))
}

function getBearerToken (token) {
  return `Bearer ${token}`
}

/**
 * Set Header Refresh token
 *
 * @param token
 */
function setAxiosRefreshTokenHeader (token) {
  setAxiosHeader(HEADER_REFRESH_TOKEN, getBearerToken(token))
}

/**
 * Set Header by name and value
 *
 * @param headerName
 * @param value
 */
function setAxiosHeader (headerName, value) {
  axios.defaults.headers.common[headerName] = value
}
