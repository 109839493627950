import {
  GET_LOCATIONS,
  GET_STATES,
  EDITING_SETTING,
  LOCATIONS_LOGOUT,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_HAS_USERS,
  SET_SEARCHING,
  SAVE_LOCATION
} from './constants'
import { initialState } from './index'

export default {
  [GET_LOCATIONS]: (state, locations) => {
    state.locations = locations
  },

  [GET_STATES]: (state, states) => {
    state.states = states.sort((a, b) => a.name.localeCompare(b.name))
  },

  [SET_SEARCHING]: (state, search) => {
    state.settingsSearch = search
  },

  [EDITING_SETTING]: (state, data) => {
    state.settingEditingInForm = data
  },

  [UPDATE_LOCATION]: (state, newLocation) => {
    const i = state.locations.findIndex(l => Number(l.id) === Number(newLocation.id))
    if (i !== -1) {
      const oldLocation = state.locations[i]
      for (const k in oldLocation) {
        if (oldLocation.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          oldLocation[k] = newLocation[k]
        }
      }
    }
  },

  [DELETE_LOCATION]: (state, newLocation) => {
    const i = state.locations.findIndex(l => Number(l.id) === Number(newLocation.id))
    if (i !== -1) {
      state.locations.splice(i, 1)
    }
  },

  [SAVE_LOCATION]: (state, location) => {
    state.locations.unshift(location)
  },

  [GET_HAS_USERS]: (state, val) => {
    state.locationHasClinicians = val
  },

  [LOCATIONS_LOGOUT]: (state) => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}
