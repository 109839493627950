import { AnalyticsAdmitAndDischargeScoresChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsAdmitAndDischargeScoresChartModel'
import { AnalyticsAdmitAndDischargeScoreOverTimeChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsAdmitAndDischargeScoreOverTimeChartModel'

/**
 * Analytics Program Report model Interface.
 */
export class AnalyticsProgramMeasureModel {
  constructor (measure, programQueryData, programData, currentQueryConfiguration) {
    const admitDischargeScoresParams = this.getAdmitDischargeScoresObject(programQueryData.admitDischargeScores, measure)
    if (admitDischargeScoresParams) {
      this.admitAndDischargeScores = new AnalyticsAdmitAndDischargeScoresChartModel(
        admitDischargeScoresParams,
        programData,
        currentQueryConfiguration,
        true,
        measure
      )
      this.measureUuid = measure.uuid
      this.measureAbbr = measure.abbreviation
    }
    const scoreOverTimeParams = this.getAverageScoreOverTimeObject(programQueryData.averageScoreOverTime, measure)
    if (scoreOverTimeParams) {
      this.scoreOverTime = new AnalyticsAdmitAndDischargeScoreOverTimeChartModel(
        scoreOverTimeParams,
        programData,
        currentQueryConfiguration,
        true,
        measure
      )
      this.measureUuid = measure.uuid
      this.measureAbbr = measure.abbreviation
    }
  }

  getAdmitDischargeScoresObject (data, measureData) {
    const scoresObject = measureData.uuid && data && data[measureData.uuid] ? data[measureData.uuid] : []
    let scores = null
    if (this.shouldShowAdmitDischargeScores(scoresObject)) {
      scores = this.getTotalScoreByMonth(scoresObject)
    }
    const measure = this.getMeasureData(scores, measureData)
    return scores && Object.keys(scores).length ? { scores, measure } : null
  }

  /**
   * @param {object} months
   * @returns {array} scores
   *
   * This method replaces getFirstScoreTypeAndFirstScore(), which was arbitrarily capturing the first pair
   * of scores for each month, regardless of what TYPE of score it was. Here we are doing some checks:
   *  - if the count of scores is 1, we ignore the type and return it
   *  - otherwise, we look for scores with the label "Total Score" and return those as an array
   * This is a workaround until the PHP export can return more useful score_type value.
   */
  getTotalScoreByMonth (months) {
    const scores = []
    for (const key in months) {
      if (months.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
        const month = months[key]
        try {
          if (month.total_score && month.total_score.length === 1) {
            scores[key] = Object.values(month)[0][0]
          } else {
            const totals = month.total_score.filter((score) => {
              return score.score_label.includes('Total Score')
            })
            if (totals[0]) {
              scores[key] = totals[0]
            }
          }
        } catch (error) {
          console.log(error) // eslint-disable-line no-console
        }
      }
    }
    return scores
  }

  getAverageScoreOverTimeObject (data, measure) {
    const scoresObject = measure.uuid && data && data[measure.uuid] ? data[measure.uuid] : []
    let scores = null
    try {
      // Workaround until we support multiple charts/scores
      scores = this.shouldShowAverageOverTime(scoresObject) ? Object.values(scoresObject)[0][0] : null
      measure.score_type = scores && Object.prototype.hasOwnProperty.call(scores, 'score_type') ? scores.score_type : null
      measure.score_label = scores && Object.prototype.hasOwnProperty.call(scores, 'score_label') ? scores.score_label : null
    } catch (error) {
      console.log(error) // eslint-disable-line no-console
    }
    return scores ? { scores, measure } : null
  }

  shouldShowAdmitDischargeScores (months) {
    if (months) {
      let monthsWithData = 0
      const c = Object.values(months).every((month) => {
        const monthScores = month ? Object.values(month) : []
        if (monthScores.length > 0 || (monthScores.length === 1 && monthScores[0].length > 0)) {
          monthsWithData++
        }
        return monthsWithData
      })
      return c > 0
    }
    return false
  }

  shouldShowAverageOverTime (scores) {
    scores = scores ? Object.values(scores) : []
    if (scores.length === 0 || (scores.length === 1 && scores[0].length === 0)) {
      return false
    }
    return true
  }

  getMeasureData (scores, measureConfig) {
    const measureData = scores ? Object.values(scores) : null
    if (measureData && measureData.length) {
      measureConfig.score_type = measureData[0].score_type ? measureData[0].score_type : 'asd'
      measureConfig.score_label = measureData[0].score_label
    }
    return measureConfig
  }
}
