import axios from 'axios'
import {
  GET_PATIENT_USERS,
  GET_PATIENT_USER,
  SAVE_PATIENT_USER,
  UPDATE_PATIENT_USER,
  ENDPOINTS
} from './constants'
import {
  UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON
} from '@/store/modules/patients/constants.js'
import {
  UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON
} from '@/store/modules/lastSession/constants.js'
import {
  MUTATIONS
} from '@/store/modules/programList/constants.js'
import {
  UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON
} from '@/store/modules/appointments/constants.js'
import { createNewCancelTokenRequest } from '@/store/modules/patients/index.js'

export default {
  [GET_PATIENT_USERS] ({ commit }, params) {
    const uiSettings = this.getters.getUiSettings
    const accountStatusSelector = uiSettings.accountStatusSelector ? uiSettings.accountStatusSelector.selected : null
    const noContactInfoselector = uiSettings.noContactInfoselector ? uiSettings.noContactInfoselector.selected : null
    params.no_contact_information = noContactInfoselector
    if (accountStatusSelector) {
      params.status = accountStatusSelector
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_PATIENT_USERS, {
        params,
        cancelToken: createNewCancelTokenRequest('loadItems')
      }).then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_PATIENT_USERS, response.data.rows)
        resolve(response)
      }).catch((error) => {
        commit(GET_PATIENT_USERS, [])
        reject(error)
      })
    })
  },
  [SAVE_PATIENT_USER] ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.SAVE_PATIENT_USER, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(SAVE_PATIENT_USER, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [UPDATE_PATIENT_USER] ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.UPDATE_PATIENT_USER.replace('{id}', data.id), data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(UPDATE_PATIENT_USER, response.data)
        commit(UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON, response.data)
        commit(UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON, response.data)
        commit(UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON, response.data)
        commit(UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON, response.data)
        commit(MUTATIONS.updateProgramsPatientFromSignInButton, response.data)
        commit(UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON, response.data)
        commit(UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON, response.data)

        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [GET_PATIENT_USER] ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_PATIENT_USER.replace('{id}', id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_PATIENT_USER, response.data)
        resolve(response)
      }).catch((error) => {
        commit(GET_PATIENT_USER, null)
        reject(error)
      })
    })
  }
}
