export const INACTIVE_USER_STRING = 'inactive'
export const ACTIVE_USER_STRING = 'active'
// Mutation types
export const SIGN_OUT = 'user/SIGN_OUT'
export const TOGGLE_SIDEBAR_STATUS = 'user/TOGGLE_SIDEBAR_STATUS'
export const HIDE_SIDEBAR = 'user/HIDE_SIDEBAR'
export const SET_DETAIL_VIEW_OPENED = 'user/SET_DETAIL_VIEW_OPENED'
export const SET_SIDEBAR_ADD_STATUS = 'user/SET_SIDEBAR_ADD_STATUS'
export const TOGGLE_MY_ACCOUNT_STATUS = 'user/TOGGLE_MY_ACCOUNT_STATUS'
export const SET_ACTIVE_FORM_DIRTY = 'user/SET_ACTIVE_FORM_DIRTY'
export const SET_CURRENT_BROWSER = 'user/SET_CURRENT_BROWSER'
export const SET_HOME_TABS = 'user/SET_HOME_TABS'
export const DATE_SELECTOR_DATE = 'user/DATE_SELECTOR_DATE'
export const HOME_TABS = ['today', 'week', 'programs', 'patients', 'new-results']
export const SET_COMING_FROM_PROGRAM = 'user/SET_COMING_FROM_PROGRAM'
export const SET_DETAIL_TO_OPEN = 'user/SET_DETAIL_TO_OPEN'
export const SETTINGS_OPEN = 'user/SETTINGS_OPEN'
export const SET_HOME_TAB_SCROLL_POSITION = 'user/SET_HOME_TAB_SCROLL_POSITION'
export const SET_SCROLL_POSITION_BY_TAB = 'user/SET_SCROLL_POSITION_BY_TAB'
export const SET_PATIENTS_TABLE_PARAMS = 'user/SET_PATIENTS_TABLE_PARAMS'
export const SET_SEARCH_STRING = 'user/SET_SEARCH_STRING'
export const SET_SORT_OPTIONS = 'user/SET_SORT_OPTIONS'
