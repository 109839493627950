
import {
  GET_ANALYTICS_PROGRAMS,
  GET_ANALYTICS_LOCATIONS,
  ANALYTICS_SELECT_PROGRAMS,
  ANALYTICS_GET_TOTAL_RECORDS,
  ANALYTICS_GET_TOTAL_RECORDS_LOADING,
  ANALYTICS_GET_RECORDS,
  ANALYTICS_GET_RECORDS_LOADING,
  ANALYTICS_API_QUERY,
  ANALYTICS_UPDATE_CURRENT_QUERY,
  ANALYTICS_DELETE_PROPERTY_FROM_QUERY,
  SIGN_OUT,
  ANALYTICS_GET_SAVED_QUERIES,
  ANALYTICS_SAVED_QUERY_LOADED,
  ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION,
  ANALYTICS_RESET_SELECTED_QUERY_FOR_EDITION,
  ANALYTICS_DELETE_SAVED_QUERY,
  ANALYTICS_SAVE_QUERY,
  ANALYTICS_UPDATE_SAVED_QUERY, ANALYTICS_LOAD_SAVED_QUERY,
  ANALYTICS_GET_PROGRAMS_FILTERED,
  ANALYTICS_GET_PROGRAMS_FILTERED_LOADING,
  ANALYTICS_CHANGE_CHART_ATTRIBUTE, ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE,
  ANALYTICS_SET_SELECTED_PROGRAM_CHART,
  ANALYTICS_SET_SELECTED_MEASURES_CHART,
  ANALYTICS_UNASSIGNED_UUID,
  ANALYTICS_SET_CHART_COLLECTION,
  ANALYTICS_SET_CHART_COLLECTION_LOADING,
  ANALYTICS_SET_CUSTOM_BUTTON_TEXT,
  ANALYTICS_GET_SELECTED_SERVICE_LINE,
  ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS,
  ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS
} from './constants'
import { initialState } from '../index'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },

  [GET_ANALYTICS_PROGRAMS] (state, data) {
    state.analyticPrograms = data
  },

  [GET_ANALYTICS_LOCATIONS] (state, data) {
    state.analyticLocations = data
  },

  [ANALYTICS_GET_TOTAL_RECORDS] (state, totalRecords) {
    state.analyticsTotalRecords = totalRecords
  },

  [ANALYTICS_GET_TOTAL_RECORDS_LOADING] (state, val) {
    state.analyticsTotalRecordsLoading = val
  },

  [ANALYTICS_GET_RECORDS_LOADING] (state, val) {
    state.analyticsRecordsLoading = val
  },

  [ANALYTICS_GET_SELECTED_SERVICE_LINE] (state, val) {
    state.selectedServiceLine = val
  },

  [ANALYTICS_GET_SERVICE_LINES_FOR_CHIPS] (state, data) {
    state.serviceLinesForChips = data
  },

  [ANALYTICS_GET_TOTAL_RECORDS_FOR_CHARTS] (state, val) {
    state.totalRecordsForCharts = val
  },

  [ANALYTICS_GET_RECORDS] (state, data) {
    if (data && data.length) {
      data.forEach(measure => {
        measure.scores = measure.scores.sort((a) => a.score === 'total_score' ? 1 : -1)
        if (measure.questions) {
          measure.measureQuestionCount = measure.questions.length
        }
        measure.location_name = ''
        measure.locations.forEach(location => {
          measure.location_name += (measure.location_name !== '') ? `, ${location.name}` : location.name
        })
      })
    }
    state.analyticsRecordsData = !data ? [] : data
    state.measuresForChips = state.scorableAnalyticMeasures.filter(item => state.analyticsCurrentQuery.measure.includes(item.uuid))
  },

  [ANALYTICS_UPDATE_CURRENT_QUERY] (state, data) {
    state.analyticsCurrentQuery[data.field] = data.value

    const dateFilterMode = state.analyticsCurrentQuery.dateFilterMode

    if (data.field === 'dateFilterMode') {
      delete state.analyticsCurrentQuery.program_discharge
      delete state.analyticsCurrentQuery.completed

      state.analyticsCurrentQuery[data.value] = state.analyticsCurrentQuery.completedType === 'custom'
        ? DateTimeHelper.methods.getDateRangeFromISONoMoment(state.analyticsCurrentQuery.customRangeDate.greater_than, state.analyticsCurrentQuery.customRangeDate.less_than)
        : DateTimeHelper.methods.getDateRangeFromLabel(state.analyticsCurrentQuery.completedType)
    }

    if (data.field === 'completedType' && (data.value === 'allTime' || data.value === 'custom')) {
      delete state.analyticsCurrentQuery.program_discharge
      delete state.analyticsCurrentQuery.completed
    } else if (data.field === 'completedType') {
      state.analyticsCurrentQuery[dateFilterMode] = DateTimeHelper.methods.getDateRangeFromLabel(data.value)
      state.analyticsCurrentQuery.customRangeDate = null
    }

    if (data.field === 'customRangeDate') {
      state.analyticsCurrentQuery[dateFilterMode] = DateTimeHelper.methods.getDateRangeFromISONoMoment(data.value.greater_than, data.value.less_than)
    }
  },

  [ANALYTICS_DELETE_PROPERTY_FROM_QUERY] (state, field) {
    if (state.analyticsCurrentQuery.hasOwnProperty(field)) { // eslint-disable-line no-prototype-builtins
      delete state.analyticsCurrentQuery[field]
    }
  },

  [ANALYTICS_SELECT_PROGRAMS] (state, data) {
    const selectedFilter = state.analyticsCurrentQuery.program_or_location_filter === 'programFilter' ? state.analyticPrograms : state.analyticLocations
    state.analyticSelectedPrograms = selectedFilter.filter(f => data.includes(f.uuid))
    state.analyticClinicians = getActiveClinicians(state.analyticSelectedPrograms)

    if (state.analyticsCurrentQuery.program_or_location_filter === 'programFilter' && this.getters.generalSettings.programs_enabled) {
      state.analyticClinicalTeams = state.analyticSelectedPrograms.reduce((carry, program) => [...carry, ...program.clinician_teams], []).filter((elem, pos, arr) => arr.findIndex(a => a.id === elem.id) === pos).sort((a, b) => a.name.localeCompare(b.name))
    } else {
      state.analyticClinicalTeams = []
    }

    state.scorableAnalyticMeasures = getSelectedProgramsMeasures(state, true)
    state.noScorableAnalyticMeasures = getSelectedProgramsMeasures(state, false)
  },

  [ANALYTICS_API_QUERY] (state, data) {
    state.analyticsApiQuery = data
  },

  [ANALYTICS_GET_SAVED_QUERIES] (state, data) {
    state.analyticsSavedQueries = sortQueries(normalizeQueries(data))
  },

  [ANALYTICS_RESET_SELECTED_QUERY_FOR_EDITION] (state) {
    state.analyticsSelectedSavedQueryForEdition = null
  },

  [ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION] (state, data) {
    state.analyticsSelectedSavedQueryForEdition = data
  },

  [ANALYTICS_DELETE_SAVED_QUERY] (state, savedQueryId) {
    state.analyticsSavedQueries = state.analyticsSavedQueries.filter(item => item.id !== savedQueryId)
  },

  [ANALYTICS_SAVE_QUERY] (state, data) {
    const query = normalizeQuery(data)
    const currentQueries = state.analyticsSavedQueries

    currentQueries.push(query)

    state.analyticsSavedQueries = sortQueries(currentQueries)
    state.analyticsSavedQueryLoaded = query
  },

  [ANALYTICS_UPDATE_SAVED_QUERY] (state, data) {
    const updatedQueryIndex = state.analyticsSavedQueries.findIndex(item => item.id === data.id)
    const currentQueries = state.analyticsSavedQueries
    currentQueries[updatedQueryIndex] = data
    state.analyticsSavedQueries = sortQueries(currentQueries)
    if (state.analyticsSavedQueryLoaded && state.analyticsSavedQueryLoaded.id === data.id) {
      state.analyticsSavedQueryLoaded = data
    }
  },

  [ANALYTICS_LOAD_SAVED_QUERY] (state, savedQuery) {
    state.analyticsCurrentQuery = savedQuery.criteria
  },

  [ANALYTICS_SAVED_QUERY_LOADED] (state, query) {
    state.analyticsSavedQueryLoaded = query
  },

  [ANALYTICS_GET_PROGRAMS_FILTERED] (state, data) {
    state.analyticsProgramsFiltered = data
  },

  [ANALYTICS_GET_PROGRAMS_FILTERED_LOADING] (state, value) {
    state.analyticsProgramsFilteredLoading = value
  },

  [ANALYTICS_CHANGE_CHART_ATTRIBUTE] (state, data) {
    const program = state.analyticsProgramsFiltered.find(e => {
      return e.uuid === data.programUUID
    })

    if (program) {
      const chart = getProgramChart(program, data)
      chart[data.attribute] = data.newValue
    }
  },

  [ANALYTICS_CHANGE_SET_OBJECT_ATTRIBUTE] (state, data) {
    state[data.objectName][data.attribute] = data.value
  },

  [ANALYTICS_SET_SELECTED_PROGRAM_CHART] (state, selectedProgramReference) {
    state.analyticsSelectedProgramChart = selectedProgramReference
  },

  [ANALYTICS_SET_SELECTED_MEASURES_CHART] (state, selectedMeasuresReference) {
    state.analyticsSelectedMeasuresChart = selectedMeasuresReference
  },

  [ANALYTICS_SET_CHART_COLLECTION] (state, data) {
    state.analyticsChartCollection = data
  },

  [ANALYTICS_SET_CHART_COLLECTION_LOADING] (state, value) {
    state.analyticsChartCollectionLoading = value
  },

  [ANALYTICS_SET_CUSTOM_BUTTON_TEXT] (state, value) {
    state.analyticsCustomDateFilterLabel = value
  }
}

/**
 * Sort Queries.
 *
 * @param queries
 * @returns {*}
 */
function sortQueries (queries) {
  return queries.sort((a, b) => {
    if (a.favorite && !b.favorite) {
      return -1
    }

    if (!a.favorite && b.favorite) {
      return 1
    }

    return a.name.localeCompare(b.name)
  })
}

/**
 * Normalize Query - PHP backend often returns 1 or 0 for Boolean and "" for NULL
 *
 * @param query
 * @returns {Object}
 */
function normalizeQuery (query) {
  const index = query.query.clinicians.findIndex(c => c === '')

  if (index !== -1) {
    query.query.clinicians[index] = ANALYTICS_UNASSIGNED_UUID
  }

  return {
    ...query,
    favorite: (query.favorite === '1' || query.favorite === true)
  }
}

/**
 * Normalize Queries.
 *
 * @param queries
 * @returns {Array}
 */
function normalizeQueries (queries) {
  return queries.map(query => normalizeQuery(query))
}

/**
 * Get Active Clinicians and include an Unassigned option for UI interactions
 * @param {Array} selectedPrograms
 * @returns {Array}
 */
function getActiveClinicians (selectedPrograms) {
  return [
    {
      full_name: 'Unassigned',
      id: 800000000000,
      name_first: '',
      name_last: '',
      name_middle: '',
      uuid: ANALYTICS_UNASSIGNED_UUID
    },
    ...selectedPrograms.reduce((carry, program) => [...carry, ...program.clinicians], [])
      .filter((elem, pos, arr) => arr.findIndex(a => a.id === elem.id) === pos)
      .sort((a, b) => a.full_name.localeCompare(b.full_name))
  ]
}

/**
 * Used to get chart that we need to mutate when changing caption and title.
 *
 * @param program
 * @param data
 * @returns {null|*}
 */
function getProgramChart (program, data) {
  if (data.chartGroup !== 'demographics') {
    let targetChart = null
    const chartGroup = program[data.chartGroup]

    Object.keys(chartGroup).find(key => {
      if ((key === data.alias || chartGroup[key].alias === data.alias) && chartGroup[key].id === data.id) {
        targetChart = chartGroup[key]
      } else if (chartGroup[key][data.alias] && chartGroup[key][data.alias].alias === data.alias && chartGroup[key][data.alias].id === data.id) {
        targetChart = chartGroup[key][data.alias]
      }
      return targetChart !== null
    })
    return targetChart
  }
  return program[data.chartGroup][data.alias]
}

/**
 * Get measures asociated with selected programs and filtered by scorable value
 *
 * @param state
 * @returns {Array}
 */
function getSelectedProgramsMeasures (state, filterByScorable) {
  // Get all selected programs measures
  const selectedProgramsMeasures = state.analyticSelectedPrograms.reduce((carry, program) => [...carry, ...program.measures], []).filter((elem, pos, arr) => arr.findIndex(a => a.id === elem.id) === pos)
  // Filter by scorable filter value and sort
  return selectedProgramsMeasures.filter(m => m.auto_complete !== '1' && (!filterByScorable || (filterByScorable && m.is_scorable === true))).sort((a, b) => a.name.localeCompare(b.name))
}
