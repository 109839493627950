// User types
export const USER_TYPE_CLIENT = 'client'
export const USER_TYPE_CLINICIAN = 'therapist'
export const USER_TYPE_SUPERVISOR = 'supervisor'
export const USER_TYPE_STAFF = 'staff'

// User Roles
export const USER_ROLE_NOTIFICATIONS = 'notifications'
export const USER_ROLE_DIRECTOR_SCREEN = 'director_screen'
export const USER_ROLE_LIBRARY = 'library_manager'
