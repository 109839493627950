import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    modalConfig: {},
    customModalIsOpened: false,
    customModalTemplate: null,
    conctactInfoDetail: null,
    enhanceAuditionModalData: null,
    patientUserRowActive: null,
    enhanceAuditionVariables: null,
    setupDonePromise: null
  },
  actions,
  getters,
  mutations
}
