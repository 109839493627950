export const USER_ROLE_RESPONDENT = 'respondent'
export const USER_ROLE_SUPERVISOR = 'supervisor'
export const USER_ROLE_CLINICIAN = 'clinician'
export const USER_ROLE_STAFF = 'staff'
export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_DIRECTOR = 'director'

export const ROLE_COMBO_STAFF_OR_CLINICIAN = [
  USER_ROLE_STAFF,
  USER_ROLE_CLINICIAN
]

export const ROLE_COMBO_CLINICIAN_OR_SUPERVISOR = [
  USER_ROLE_CLINICIAN,
  USER_ROLE_SUPERVISOR
]

export const ROLE_COMBO_STAFF_OR_CLINICIAN_OR_SUPERVISOR = [
  USER_ROLE_STAFF,
  USER_ROLE_CLINICIAN,
  USER_ROLE_SUPERVISOR
]

export const ROLE_COMBO_ANY_THAN_RESPONDENT = [
  USER_ROLE_STAFF,
  USER_ROLE_CLINICIAN,
  USER_ROLE_SUPERVISOR,
  USER_ROLE_DIRECTOR,
  USER_ROLE_ADMIN
]
