
import { GET_RESPONDENTS, SIGN_OUT } from './constants'
import { initialState } from './index'

export default {
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [GET_RESPONDENTS] (state, data) {
    const respondents = []
    for (let i = 0, len = data.length; i < len; i++) {
      const item = data[i]
      // prevent double spaces in full name
      item.name = item.name.replace('  ', ' ')
      if (item.contact_information) {
        item.contact = item.contact_information.split(',')
      }
      item.patients = createPatientsObject(item)
      item.patients_removed = createPatientsObject(item, true)
      respondents.push(item)
    }
    state.respondents = respondents
  }
}

const createPatientsObject = (itemData, removed = false) => {
  let suffix = ''
  if (removed) {
    suffix = '_removed'
  }

  if (!itemData[`patient_full_names${suffix}`]) {
    return false
  }

  const patientsNames = itemData[`patient_full_names${suffix}`] ? itemData[`patient_full_names${suffix}`].split('$&$@') : []
  const patientMrn = itemData[`patients_mrn${suffix}`] ? itemData[`patients_mrn${suffix}`].split('$&$@') : []
  const patientsCcaIds = itemData.patients_client_clinic_assignment_ids ? itemData.patients_client_clinic_assignment_ids.split('$&$@') : []
  const patientCcauIds = itemData.patients_client_clinic_assignment_user_ids ? itemData.patients_client_clinic_assignment_user_ids.split('$&$@') : []
  const respondentCcauIds = itemData.respondents_client_clinic_assignment_user_ids ? itemData.respondents_client_clinic_assignment_user_ids.split('$&$@') : []
  const patientsRelations = itemData[`patients_relations${suffix}`] ? itemData[`patients_relations${suffix}`].split('$&$@') : []

  const patients = []
  if (patientCcauIds) {
    for (let i = 0, len = patientCcauIds.length; i < len; i++) {
      if (!patientsRelations[i]) {
        continue
      }
      const patient = {
        ccaId: patientsCcaIds[i],
        ccauId: patientCcauIds[i],
        respondent_ccauId: respondentCcauIds[i],
        full_name: patientsNames[i],
        mrn: patientMrn[i],
        patient_relation: patientsRelations[i]
      }
      patients.push(patient)
    }
  }

  return patients
}
