import {
  ACTION_OPEN_AUDITION_ENHANCE_MODAL,
  ACTION_CLOSE_AUDITION_ENHANCE_MODAL,
  MUTATION_SET_MODAL_OPENED,
  MUTATION_SET_MODAL_TEMPLATE,
  MUTATION_SET_ENHANCE_AUDITION_DATA

} from './constants'

export default {
  [ACTION_OPEN_AUDITION_ENHANCE_MODAL]: async ({ commit }, data) => {
    commit(MUTATION_SET_MODAL_OPENED, true)
    commit(MUTATION_SET_MODAL_TEMPLATE, 'EnhanceAuditionModalContent')
    commit(MUTATION_SET_ENHANCE_AUDITION_DATA, data)
  },
  [ACTION_CLOSE_AUDITION_ENHANCE_MODAL]: async ({ commit }) => {
    commit(MUTATION_SET_MODAL_OPENED, false)
    commit(MUTATION_SET_MODAL_TEMPLATE, null)
    commit(MUTATION_SET_ENHANCE_AUDITION_DATA)
  }
}
