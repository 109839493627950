import moment from 'moment-timezone'

const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_DATE_API = 'YYYYMMDD'
const UNIT_DAY = 'day'
const UNIT_ISO_WEEK = 'isoWeek'

export const DateTimeHelper = {
  methods: {
    $getOnlyDateFromJsDate (date, format) {
      if (!date) { return }
      const dateFormat = !format ? FORMAT_DATE : format
      return moment([date.getFullYear(), date.getMonth(), date.getDate()]).format(dateFormat)
    },
    $getJsDateFromTimeString (date) {
      if (!date) { return }
      return moment(date).toDate()
    },
    $fromLocalToUTCAndGetUnix (date) {
      if (date) {
        return moment.utc(new Date(date * 1000)).unix()
      }
      return date
    },
    $unixToUtc (unix) {
      if (unix) {
        const dateObj = new Date(unix * 1000)
        return dateObj.toUTCString()
      }
      return ''
    },
    $toUTC (dateString, format) {
      const date = moment(dateString).toDate()
      const dateFormat = format || FORMAT_DATE
      return moment.utc(date).format(dateFormat)
    },

    // Transform UTC date from Server, to Local Time
    $toLocal (dateString, format) {
      if (!dateString) {
        return ''
      }
      const date = moment.utc(dateString)
      if (!this.$store) {
        return moment(date).local().format(format)
      }

      const timezone = this.$store.getters.getClientTimezone
      const dateFormat = format || FORMAT_DATE
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.tz(timezone).format(dateFormat) : ''
    },
    $fromCalendarToLocal (dateString, format) {
      const dateFormat = format || FORMAT_DATE
      return moment(dateString).local().format(dateFormat)
    },
    $isBeforeToday (dateString) {
      const dates = this.getTodayAndDate(dateString)
      return dates.date.isBefore(dates.today, UNIT_DAY)
    },
    $isAfteroday (dateString) {
      const dates = this.getTodayAndDate(dateString)
      return dates.date.isAfter(dates.today, UNIT_DAY)
    },
    $isToday (dateString) {
      const dates = this.getTodayAndDate(dateString)
      return dates.date.isSame(dates.today, UNIT_DAY)
    },
    $isSameDay (a, b) {
      const aDate = this.getTodayAndDate(a)
      const bDate = this.getTodayAndDate(b)
      return aDate.date.isSame(bDate.date, UNIT_DAY)
    },
    getTodayAndDate (dateString) {
      let date = moment.utc(dateString).toDate()
      const timezone = this.$store.getters.getClientTimezone
      date = moment(date).tz(timezone).endOf(UNIT_DAY)
      const today = moment().tz(timezone).endOf(UNIT_DAY)
      return { today, date }
    },
    getEarliestFromArray (array, field) {
      return this.getExtremeOfArray(array, field, false)
    },
    getLatestFromArray (array, field) {
      return this.getExtremeOfArray(array, field, true)
    },
    getExtremeOfArray (array, field, getLatestPosition = false) {
      return array.reduce((pre, cur) => {
        const isGreater = moment(pre[field]).valueOf() > moment(cur[field]).valueOf()
        return isGreater ? getLatestPosition ? pre : cur : getLatestPosition ? cur : pre
      })
    },
    getFullWeek (date) {
      const startOfWeek = moment(date).startOf(UNIT_ISO_WEEK)
      const endOfWeek = moment(date).endOf(UNIT_ISO_WEEK)
      const days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push(day.toDate())
        day = day.clone().add(1, UNIT_DAY)
      }
      return days
    },
    getAppointmentStartDate (date, mode) {
      return this.$toUTC(
        moment(date).startOf(mode).startOf(UNIT_DAY).format(FORMAT_DATE_API),
        FORMAT_DATE_API
      )
    },
    getAppointmentEndDate (date, mode) {
      return this.$toUTC(
        moment(date).endOf(mode).endOf(UNIT_DAY).format(FORMAT_DATE_API),
        FORMAT_DATE_API
      )
    },
    getStartOfLastMonth () {
      return moment().add(-1, 'month').startOf('month').unix()
    },
    getEndOfLastMonth () {
      return moment().add(-1, 'month').endOf('month').unix()
    },
    getStartOfYearOfLastMonth () {
      return moment().add(-1, 'month').startOf('year').unix()
    },
    getStartOfLastYear () {
      return moment().add(-1, 'year').startOf('year').unix()
    },
    getEndOfLastYear () {
      return moment().add(-1, 'year').endOf('year').unix()
    },
    getStartOfThisYear () {
      return moment().startOf('year').unix()
    },
    getNow () {
      return moment().unix()
    },
    getCurrentBrowserDate (format) {
      return moment().format(format)
    },

    getDateFromString (string) {
      return string ? moment(string, 'YYYY-MM-DD hh:mm:ss').toDate() : null
    },

    getMonthAbvFromNumber (number) {
      if (number) {
        return moment(number, 'MM').format('MMM')
      }
    },

    /**
     * Calculate the Person Age for client time zone base on the Date of birth
     * @param dateOfBirth
     * @return null|number
     */
    getDifferenceInYearsBetweenDateStringAndTodayDate (dateOfBirth) {
      if (!dateOfBirth) {
        return null
      }

      const dateObject = moment(dateOfBirth, 'YYYY-MM-DD')
      return moment().diff(dateObject, 'y')
    },
    getDateRangeFromISO (from, to) {
      return {
        greater_than: moment(from).startOf('day').unix(),
        less_than: moment(to).endOf('day').unix()
      }
    },
    getDateRangeFromISONoMoment (from, to) {
      // avoiding the use of momentjs here again as it was causing unwanted conversions
      const rangeFrom = from.setHours(0, 0, 0, 0)
      const rangeTo = to.setHours(23, 59, 59, 999)

      return {
        // setHours() gives us milliseconds but the API only wants seconds
        // Math.floor(date / 1e3) converts ms to s by stripping the last 3 digits
        greater_than: Math.floor(rangeFrom / 1e3),
        less_than: Math.floor(rangeTo / 1e3)
      }
    },
    getDateRangeFromLabel (label) {
      let dateRange

      switch (label) {
        case 'lastMonth':
          dateRange = {
            greater_than: this.getStartOfLastMonth(),
            less_than: this.getEndOfLastMonth()
          }
          break

        case 'yearToDate':
          dateRange = {
            greater_than: this.getStartOfYearOfLastMonth(),
            less_than: this.getEndOfLastMonth()
          }
          break

        case 'lastYear':
          dateRange = {
            greater_than: this.getStartOfLastYear(),
            less_than: this.getEndOfLastYear()
          }
          break

        default:
          dateRange = null
          break
      }

      return dateRange
    },
    getTZDiff () {
      /**
       * Overcooked way of getting around the fact that moment is set to client time by default.
       * We're using local time with the datepicker so that the user isn't confused when they pick
       * today and it appears like it's yesterday because the client TZ is different from their local.
       *
       * Here we're using vanilla JS to get the difference between the client time and local time
       * TZ offsets between and sending that to the query
       */

      // get the local/browser tz
      const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone

      // set an arbitrary date for comparison
      const date = new Date(Date.UTC(1970, 0, 1, 0, 0, 0))

      // get local time
      const LocalTime = new Date(date.toLocaleString('en-US', {
        timeZone: localTz
      }))

      // get client time
      const clientTime = new Date(date.toLocaleString('en-US', {
        timeZone: this.clientTime
      }))

      // get the difference
      const offsetLocal = date.getTime() - LocalTime.getTime()
      const offsetClient = date.getTime() - clientTime.getTime()
      const diff = offsetClient - offsetLocal

      // divide by 1000: milliseconds to seconds
      return Math.floor(diff / 1000)
    }
  }
}
