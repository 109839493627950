export const ErrorsDictionary = {
  data () {
    return {
      errorsDictionary: {
        custom: {
          password: {
            min: () => 'Invalid password.',
            max: () => 'Invalid password.',
            regex: () => 'Invalid password.'
          },
          confirmPassword: {
            confirmed: () => 'Those passwords don\'t match.'
          },
          mobile: {
            min: () => 'That mobile number is too short. It must be 10 digits.',
            max: () => 'That mobile number is too long. It must be 10 digits.'
          },
          phone: {
            min: () => 'That mobile number is too short. It must be 10 digits.',
            max: () => 'That mobile number is too long. It must be 10 digits.'
          },
          first_name: {
            required: () => 'First name is required'
          },
          last_name: {
            required: () => 'Last name is required'
          },
          genderAtBirth: {
            required: () => 'Gender at birth is required'
          },
          role: {
            required: () => 'Respondent type is required'
          },
          patient_relation: {
            required: () => 'Relation to patient is required'
          },
          birthDate: {
            required: () => 'Birthdate is required',
            date_format: () => 'Birthdate is invalid',
            date_between: () => 'Birthdate is invalid'
          },
          email: {
            email: () => 'Invalid email'
          },
          clinicians: {
            min: () => 'Clinician is required'
          },
          cliniciansHiddenCreate: {
            min_value: () => this.$t('clinicianFields.cliniciansHiddenCreate')
          },
          cliniciansHiddenPrograms: {
            min_value: () => this.$t('clinicianFields.cliniciansHiddenPrograms')
          },
          cliniciansHiddenMeasures: {
            min_value: () => this.$t('clinicianFields.cliniciansHiddenMeasures')
          },
          disposition_status: {
            is_not: this.$t('dispositionStatusError')
          },
          queryName: {
            required: this.$t('analyticsSavedQueries.validationRequired'),
            min: this.$t('analyticsSavedQueries.validationMin'),
            max: this.$t('analyticsSavedQueries.validationMax')
          }
        },
        messages: {
          required: (field) => `${field} is required`,
          alpha_num: (field) => `The ${field} may only contain alpha-numeric characters and no spaces.`,
          email: (field) => `${field} is not valid`
        }
      },
      passwordRegex: /(?=^[\S.]{8,999}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])^.*/
    }
  }
}
