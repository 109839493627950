import axios from 'axios'
import {
  GET_LOCATIONS,
  SAVE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_HAS_USERS,
  LOCATION_RE_ASSIGN,
  GET_STATES,
  GET_LOCATIONS_FOR_DATA_TABLE,
  ENDPOINTS
} from './constants'
import { createNewCancelTokenRequest } from '@/store/modules/patients/index.js'

export default {
  [GET_LOCATIONS_FOR_DATA_TABLE]: async ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_LOCATIONS_FOR_DATA_TABLE, {
        params,
        cancelToken: createNewCancelTokenRequest('loadItems')
      }).then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_LOCATIONS, response.data.rows)
        resolve(response)
      }).catch((error) => {
        commit(GET_LOCATIONS, [])
        reject(error)
      })
    })
  },
  [GET_LOCATIONS]: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_LOCATIONS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_LOCATIONS, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_LOCATIONS, [])
        reject(error)
      })
    })
  },

  [GET_STATES]: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_STATES).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_STATES, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_STATES, [])
        reject(error)
      })
    })
  },

  [GET_HAS_USERS]: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_HAS_USERS.replace('{id}', id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(GET_HAS_USERS, response.data.length)
        resolve(response)
      }).catch((error) => {
        commit(GET_HAS_USERS, false)
        reject(error)
      })
    })
  },

  [SAVE_LOCATION]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.SAVE_LOCATION, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(SAVE_LOCATION, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [UPDATE_LOCATION]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.UPDATE_LOCATION.replace('{id}', data.id), data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(UPDATE_LOCATION, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [LOCATION_RE_ASSIGN]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.LOCATION_RE_ASSIGN, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(LOCATION_RE_ASSIGN, response.data, data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [DELETE_LOCATION]: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.delete(ENDPOINTS.DELETE_LOCATION.replace('{id}', data.id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        commit(DELETE_LOCATION, response.data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
