/**
 * Execute Query against analytics.
 *
 * @param url
 * @param filename
 * @returns {Promise<unknown>}
 */
export function downloadFile (url, filename) {
  return new Promise((resolve) => {
    // get the focused element in order to keep the click local to the previous interaction (keeps from clicking outside and closing modals)
    const currentlyFocusedElement = document.activeElement

    // create an anchor element and click it to simulate user interaction
    const a = document.createElement('a')

    // Check if the browser does not support the "download" attribute (IE 11 ಠ_ಠ)
    if (typeof a.download === 'undefined') {
      window.open(url)
    } else {
      // use the download attrib to set a filename and ensure download behavior
      a.setAttribute('download', filename)
      a.style.display = 'none'
      a.href = url

      // add to DOM and click it
      currentlyFocusedElement.parentNode.appendChild(a)
      a.click()

      // cleanup
      window.URL.revokeObjectURL(a.href)
      currentlyFocusedElement.parentNode.removeChild(a)
      currentlyFocusedElement.blur()
    }

    resolve(true)
  })
}
