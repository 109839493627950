import { Base64 } from 'js-base64'
import iziToast from 'izitoast'
import store from '@/store/index'
import {
  getNextRouteWhenForceResetPassword,
  getNextRouteWhenAuthenticated,
  userHasAccessToRoute
} from '@/mixins/UserManagement/RouteAccess'
import { STATUS_PASSWORD_RESET } from '@/data/userStatusValues'
import {
  URL_TO_REDIRECT_AFTER_LOGIN
} from '@/store/modules/security/constants.js'

const PATIENT_NOT_FOUND_ALIAS = 'notFound'

/**
 * Callback to be used on Security endpoints to redirect user to home page if a session exists.
 */
export function notAuthenticatedRedirect (to, from, next) {
  const promise = store.getters.getSetupDonePromise
  promise.then(() => {
    const authenticated = store.getters.isAuthenticated
    return authenticated ? redirectToDefaultAuthenticated(next) : redirectToDefaultNotAuthenticated(next)
  })
}

/**
 * callback to be used on endpoints that need to check user permissions before enter.
 */
export function authenticatedRedirect (to, from, next) {
  const promise = store.getters.getSetupDonePromise
  promise.then(() => {
    const authenticated = store.getters.isAuthenticated
    return authenticated ? redirectToProposedActionOr404(to, next) : redirectToLoginPage(to, from, next)
  })
}

/**
 * Callback to be used for routes that requires programs to be enabled.
 */
export function programsEnabledRedirect (to, from, next) {
  const enabled = store.getters.generalSettings.programs_enabled
  return enabled ? authenticatedRedirect(to, from, next) : redirectTo404(next)
}

/**
 * Callback to be executed to determine where the user should be directed
 * to the respondent overview or the measures added page.
 */
export function hasMeasuresInSessionRedirect (to, from, next) {
  const hasMeasures = store.getters.getMeasureAssignedDuringSession.length
  return hasMeasures ? authenticatedRedirect(to, from, next) : next(to.path.replace('/overview', ''))
}

/**
 * Callback to be used when doing sso
 */
export function renewCredentialsRedirect (to, from, next) {
  const token = to.params.key
  const patientId = to.params.patientId || to.query.patientId
  const mrn = to.params.mrn || to.query.mrn
  const redirect = to.params.redirect || to.query.redirect || '/'
  logout().then(() => {
    renewCredentialsByToken(token, next, patientId, mrn, redirect)
  })
}

/**
 * Do Logout if necessary, return a promise.
 *
 * @returns {Promise<any>}
 */
function logout () {
  return new Promise((resolve) => {
    if (!store.getters.isAuthenticated) {
      resolve(true)
      return true
    }

    store.dispatch('SIGN_OUT').then(() => {
      resolve(true)
    })
  })
}

/**
 * Refresh Access token Mechanism.
 */
function renewCredentialsByToken (token, next, patientId, mrn, redirect) {
  return store.dispatch('RENEW_TOKEN', token).then((response) => {
    if (response && response.status !== 200 && response.data.errors && response.data.errors.password_expired) {
      iziToast.error({
        message: '',
        title: 'Password reset required'
      })
      return next({ name: 'Login' })
    }
    return patientId ? redirectToPatientOverview(next, patientId, mrn) : next(redirect)
  })
}

/**
 * Try to redirect to the patient Overview, otherwise Redirect to patient list and add mrn as filter.
 */
function redirectToPatientOverview (next, patientId, mrn) {
  const decodedPatientId = Base64.decode(patientId)
  if (decodedPatientId === PATIENT_NOT_FOUND_ALIAS) {
    return redirectToPatientList(next, mrn)
  }

  store.dispatch('GET_PATIENT', decodedPatientId).then(response => {
    let url = '/assignment/:ccaId/:ccauId/measures/overview'
    url = url.replace(':ccaId', Base64.encode(response.data.assignment_id))
    url = url.replace(':ccauId', Base64.encode(response.data.client_clinic_assignment_user_id))
    return next(url)
  }).catch(() => {
    return redirectToPatientList(next, mrn)
  })
}

/**
 * Redirect to patient list.
 */
function redirectToPatientList (next, mrn) {
  next({ name: 'ClinicianPatientsList', query: { search: mrn } })
  iziToast.error({
    message: 'The patient does not exist or you do not have access to view it.',
    title: 'Patient not found'
  })
}

/**
 * Get Settings from Store (memory) or load them from api.
 *
 * @param next
 * @returns {*}
 */
function getSettingsAndContinue (next) {
  if (!Object.values(store.getters.getUiSettings).length) {
    return store.dispatch('GET_UI_SETTINGS').then(() => {
      next()
    })
  }
  next()
}

/**
 * Redirects to Login Page.
 */
function redirectToLoginPage (to, from, next) {
  if (to && to.name !== 'Session' && from.name !== 'ResetPassword') {
    localStorage.setItem(URL_TO_REDIRECT_AFTER_LOGIN, JSON.stringify(to))
  }
  return next('/security/login')
}

/**
 * Determine if there is a logged person and the logged person can access the route.
 */
function canAccessRoute (to) {
  return to.meta.permissions ? userHasAccessToRoute(to.meta.permissions) : false
}

/**
 *  Redirect to 404.
 */
function redirectTo404 (next, to) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Permission denied', to) // eslint-disable-line no-console
  }
  return next('/404')
}

/**
 * Try to grant access to certain action but if it is no possible the user is redirected to 404 page.
 */
function redirectToProposedActionOr404 (to, next) {
  const flaggedReset = Number(store.getters.loggedInUser.status) === Number(STATUS_PASSWORD_RESET)
  const loggedInAsRespondent = store.getters.loggedInUser.loggedAsRespondent
  const forceReset = flaggedReset && !loggedInAsRespondent
  return canAccessRoute(to) ? forceReset ? redirectToResetPassword(next, to) : getSettingsAndContinue(next) : redirectTo404(next, to)
}

/**
 * Default when No authenticated and going to secure action.
 * @param next
 */
function redirectToResetPassword (next, to) {
  return ['ChangePassword'].includes(to.name) ? next() : next(getNextRouteWhenForceResetPassword())
}

/**
 * Default when No authenticated and going to secure action.
 * @param next
 */
function redirectToDefaultNotAuthenticated (next) {
  return next()
}

/**
 * Default when user is authenticated and entering a public/security route.
 */
function redirectToDefaultAuthenticated (next) {
  return next(getNextRouteWhenAuthenticated())
}
