import axios from 'axios'
import moment from 'moment'
import store from '@/store/'
import { LOCAL_STORAGE_USER_KEY_NAME } from '@/store/modules/security/constants.js'
import {
  ACTIONS,
  MUTATIONS,
  ENDPOINTS,
  PATIENTS_NO_PROGRAM_ID,
  LOCAL_STORAGE_USER_PROGRAM_OPENED,
  LOCAL_STORAGE_CLINICIAN_MY_PROGRAMS,
  LOCAL_STORAGE_CLINICIAN_ALL_PROGRAMS
} from './constants'
import { decorateParamsWithPatientSelector } from '@/mixins/PatientSelector/PatientSelectorHelper'
import { createNewCancelTokenRequest } from '@/store/modules/patients/index.js'
import { getDefaultPatientSelector } from '@/mixins/UserManagement/UserRoles'

const CancelToken = axios.CancelToken
let cancelRequestObjects = []

export default {
  async [ACTIONS.getMyPrograms] ({ commit, rootState, state }) {
    commit(MUTATIONS.setPatientsBatchToLoad, rootState.user.currentBrowser.isExplorer)
    return getPrograms(
      commit,
      ENDPOINTS.getMyPrograms,
      MUTATIONS.setMyPrograms,
      state.patientsBatchToLoad,
      LOCAL_STORAGE_CLINICIAN_MY_PROGRAMS
    )
  },
  async [ACTIONS.getAllPrograms] ({ commit, rootState, state }) {
    commit(MUTATIONS.setPatientsBatchToLoad, rootState.user.currentBrowser.isExplorer)
    return getPrograms(
      commit,
      ENDPOINTS.getAllPrograms,
      MUTATIONS.setAllPrograms,
      state.patientsBatchToLoad,
      LOCAL_STORAGE_CLINICIAN_ALL_PROGRAMS
    )
  },
  // TODO cleanup endpoint action
  // Create new pattern for action
  async [ACTIONS.getPatientsByProgram] ({ commit }) {
    return getPrograms(
      commit,
      ENDPOINTS.getPatientsByProgram,
      MUTATIONS.getPatientsByProgram
    )
  },
  async [ACTIONS.getProgramActivePatients] ({ commit, state }, params) {
    const stateIndex = store.getters.getHomeTab === 'my' ? 'myProgramsCollection' : 'allProgramsCollection'
    const highlighted = await getHighlightedPatient(state[stateIndex])
    const program = state[stateIndex] ? state[stateIndex].find((program) => program.id === params.programId) : null
    if (!program) {
      return false
    }

    commit(MUTATIONS.markProgramAsLoading, { program: program, value: true })
    const url = ENDPOINTS.getActiveProgramPatients.replace('{id}', program.id)
    decorateParamsWithPatientSelector(params)

    // No need to fetch program data If program is closed and we do not have any search term (reset search)
    if (!program.isOpen && (!params.searchTerm || !params.searchTerm.length)) {
      commit(MUTATIONS.markProgramAsLoading, { program: program, value: true })
      return false
    }

    //  if program is closed we only need the total records
    params.countOnly = !program.isOpen
    const cancelFunction = new CancelToken(function executor (c) {
      cancelRequestObjects.push(c)
    })

    return axios.get(url, { params, cancelToken: cancelFunction }).then((response) => {
      if (axios.isCancel(response)) {
        return false
      }

      const serverPatients = response.data.rows ? response.data.rows : response.data
      for (let i = 0, len = serverPatients.length; i < len; i++) {
        serverPatients[i].highlighted = false
        highlightPatient(highlighted, serverPatients[i], program.id)
      }

      // Add just added patient at the top
      const justAddedQueryString = window.location.search.indexOf('justAdded=1')
      const exists = state.justAddedPatientData && state.justAddedPatientData.program_id === program.id
      const add = exists && state.justAddedPatientData.hasOwnProperty('addedToProgram') && state.justAddedPatientData.addedToProgram // eslint-disable-line no-prototype-builtins
      if (add && justAddedQueryString !== -1) {
        const index = serverPatients.findIndex(patient => patient.access_control_id === state.justAddedPatientData.access_control_id)
        if (index !== -1) {
          serverPatients.splice(index, 1)
          commit(MUTATIONS.changeObjectAttributes, {
            object: 'justAddedPatient',
            attribute: 'addedToProgram',
            value: true
          })
        }
        serverPatients.unshift(state.justAddedPatientData)
      }

      commit(MUTATIONS.setProgramActivePatients, {
        stateIndex: stateIndex,
        totalRecords: response.data.totalRecords,
        programId: program.id,
        patients: serverPatients
      })
      return response
    })
  },
  async [ACTIONS.toggleProgramAccordion] ({ commit }, data) {
    commit(MUTATIONS.toggleProgramAccordion, data)
  },
  async [ACTIONS.abortPendingRequest] () {
    for (let i = 0, len = cancelRequestObjects.length; i < len; i++) {
      cancelRequestObjects[i]()
    }
    cancelRequestObjects = []
  },
  async [ACTIONS.getUpdatedPatientData] ({ commit }, data) {
    const defaultPatientSelector = getDefaultPatientSelector()
    const uiSettings = store.getters.getUiSettings
    const patientSelectorFilter = uiSettings.patientSelector ? uiSettings.patientSelector.selected : null
    const params = {
      patient_id: data.patientId,
      patient_selector: patientSelectorFilter || defaultPatientSelector
    }

    const url = ENDPOINTS.getActiveProgramPatients.replace('{id}', data.programId)
    return new Promise((resolve) => {
      axios.get(url, { params }).then((httpResponse) => {
        const hasData = httpResponse.data && httpResponse.data.rows && httpResponse.data.rows.length
        const patientData = hasData ? httpResponse.data.rows[0] : null
        if (data.justAdded) {
          commit(MUTATIONS.setJustAddedPatientData, patientData)
        }
        resolve(patientData)
      })
    })
  }
}

/**
 * Get Programs from server and decorate them.
 *
 * @param commit
 * @param endpoint
 * @param mutation
 * @param patientsBatchToLoad
 * @returns {Promise<unknown>}
 */
async function getPrograms (commit, endpoint, mutation, patientsBatchToLoad, storageIndex) {
  return new Promise((resolve, reject) => {
    axios.get(endpoint, {
      cancelToken: createNewCancelTokenRequest('programList')
    }).then(async ({ data }) => {
      const programs = await decorateProgramCollection(data, patientsBatchToLoad, storageIndex)
      commit(mutation, programs)
      resolve(programs)
    }).catch((error) => {
      reject(error, {})
      commit(MUTATIONS.setMyPrograms)
    })
  })
}

/**
 *
 * @param programsFromServer
 * @param patientsBatchToLoad
 * @param storageIndex
 * @returns Promise{}
 */
async function decorateProgramCollection (programsFromServer, patientsBatchToLoad, storageIndex) {
  const programsCollection = [{
    full_name: 'Patients - No Program',
    patients: [],
    force_collapsed: true,
    isOpen: false,
    loadPatients: false,
    id: PATIENTS_NO_PROGRAM_ID,
    patientsShowing: patientsBatchToLoad,
    loading: false,
    loaded: false
  }]

  // Decorate Programs
  for (let i = 0, len = programsFromServer.length; i < len; i++) {
    const program = programsFromServer[i]
    program.parent_id = program.parent_id === '0' ? null : program.parent_id
    program.modified = program.modified ? moment(program.modified).format('ddd hh:mmA').toUpperCase() : ''
    program.isOpen = await getProgramIsOpen(program, storageIndex, false)
    program.patients = []
    program.loaded = false
    program.loading = false
    program.loadPatients = !!program.isOpen
    program.patientsShowing = patientsBatchToLoad
    programsCollection.push(program)
  }

  return programsCollection
}

/**
 * Determine if the program was previously expanded by the logged in user.
 *
 * @param program
 * @param stateIndex
 * @param opened
 * @returns {Promise<*>}
 */
async function getProgramIsOpen (program, stateIndex, opened) {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY_NAME))
  const storagePrograms = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_PROGRAM_OPENED))
  const hasDataInLocalStorage = storagePrograms && storagePrograms[user.id] && storagePrograms[user.id][stateIndex]
  const storageData = hasDataInLocalStorage ? storagePrograms[user.id][stateIndex] : []
  const programFromPrevState = storageData.find((p) => p.id === program.id)
  return programFromPrevState ? programFromPrevState.isOpen : !opened && !program.force_collapsed && program.patients && program.patients.length
}

async function getHighlightedPatient (data) {
  const highlightedPatientData = {}
  if (!data || !data.length) {
    return highlightedPatientData
  }

  for (let i = 0, len = data.length; i < len; i++) {
    const result = await getHighlightedPatientInProgram(data[i])
    if (result.programId) {
      break
    }
  }

  return highlightedPatientData
}

/**
 *
 * @param program
 * @returns {Promise<{}>}
 */
async function getHighlightedPatientInProgram (program) {
  if (!program.patients.length) {
    return {}
  }
  let result = {}
  for (let i = 0, len = program.patients.length; i < len; i++) {
    result = await patientIsHighlighted(program.patients[i])
  }
  return result
}

/**
 *
 * @param patient
 * @returns {Promise<{}>}
 */
async function patientIsHighlighted (patient) {
  const response = {}
  if (patient.highlighted) {
    response.programId = patient.program_id
    response.patientId = patient.access_control_id
  }
  return response
}

/**
 *
 * @param highlighted
 * @param patient
 * @param programId
 * @returns {Promise<void>}
 */
async function highlightPatient (highlighted, patient, programId) {
  patient.highlighted = Number(programId) === Number(highlighted.programId) && Number(patient.access_control_id) === Number(highlighted.patientId)
}
