import axios from 'axios'
import moment from 'moment-timezone'
import {
  GENERAL_SETTINGS,
  GET_UI_SETTINGS,
  UI_SETTINGS_LOADED,
  UPDATE_PATIENT_LIST_VIEW,
  TOGGLE_MEASURE_LIST_ITEM_OPEN,
  SIGN_OUT,
  UPDATE_UI_SETTING_VALUE,
  ENDPOINTS
} from './constants'

function initialState () {
  return {
    general: {},
    uiSettings: {},
    uiSettingsLoaded: false
  }
}

const initDeepKeysForJson = (settings, keys) => {
  let temp = {}
  const obj = temp = settings
  for (let i = 0; i < keys.length; i++) {
    if (!temp[keys[i]]) {
      temp[keys[i]] = {}
    }
    temp = temp[keys[i]]
  }
  return obj
}
/**
 * Set Session Timeout configuration values.
 * @param state
 * @param data
 */
const setSessionTimeout = (state, data) => {
  // If no valid values provided from API (Corner Case), Add default Timeout Values.
  if (!validSessionTimeoutConfiguration(data)) {
    data.session_configuration = { timeout: 7200, warningThreshhold: 7140 }
    return setSessionTimeout(state, data)
  }
  // Create
  const session = data.session_configuration
  session.secondsToShow = session.timeout - (session.timeout - session.warningThreshhold)
  session.warningThreshhold = (session.timeout - session.warningThreshhold) * 1000
  session.timeout = session.timeout * 1000
  data.session_configuration = session
  data.sms_enabled = !!Number(data.sms_enabled)
  data.email_enabled = !!Number(data.email_enabled)
  data.programs_enabled = !!Number(data.programs_enabled)
  data.include_appointments_feature = !!Number(data.include_appointments_feature)
  data.ehr_integration_available = !!Number(data.ehr_integration_available)
  data.setting_state = parseInt(data.setting_state) || 1
  state.general = data
  return true
}

/**
 * Validate Session Setting Value.
 * @param data
 * @returns {boolean}
 */
const validSessionTimeoutConfiguration = (data) => {
  if (typeof data.session_configuration !== 'object') {
    return false
  }
  if (typeof data.session_configuration.timeout === 'undefined') {
    return false
  }
  if (typeof data.session_configuration.warningThreshhold === 'undefined') {
    return false
  }
  return true
}

// initial state
const state = initialState()
// actions
const actions = {
  GENERAL_SETTINGS ({ commit }) {
    return axios.get(ENDPOINTS.GENERAL_SETTINGS).then((response) => commit(GENERAL_SETTINGS, response.data)).catch((error) => {
      commit(GENERAL_SETTINGS, {})
      return error.response
    })
  },

  GET_UI_SETTINGS ({ commit }) {
    commit(UI_SETTINGS_LOADED, false)
    return axios.get(ENDPOINTS.UI_SETTINGS)
      .then((response) => commit(GET_UI_SETTINGS, response.data))
      .catch((error) => {
        commit(GET_UI_SETTINGS, {})
        return error.response
      }).finally(() => {
        commit(UI_SETTINGS_LOADED, true)
      })
  },

  UPDATE_UI_SETTINGS ({ commit }, settings) {
    return axios.post(ENDPOINTS.UI_SETTINGS, settings)
      .then((response) => commit(GET_UI_SETTINGS, response.data))
      .catch((error) => {
        return error.response
      })
  },

  UPDATE_UI_SETTING_VALUE ({ commit }, data) {
    commit(UPDATE_UI_SETTING_VALUE, data)
  },

  UPDATE_PATIENT_LIST_VIEW ({ commit }, view) {
    commit(UPDATE_PATIENT_LIST_VIEW, view)
  },

  TOGGLE_MEASURE_LIST_ITEM_OPEN ({ commit }, data) {
    commit(TOGGLE_MEASURE_LIST_ITEM_OPEN, data)
  }
}

// getters
const getters = {
  generalSettings: state => state.general,
  getUiSettings: state => state.uiSettings,
  getUiSettingsLoaded: state => state.uiSettingsLoaded,
  getClientTimezone: state => state.general.client_time_zone
}

// mutations
const mutations = {
  [GENERAL_SETTINGS] (state, data) {
    setSessionTimeout(state, data)

    if (data.client_time_zone) {
      moment.tz.setDefault(data.client_time_zone)
    }
  },

  [GET_UI_SETTINGS] (state, data) {
    state.uiSettings = data
  },

  [UI_SETTINGS_LOADED] (state, value) {
    state.uiSettingsLoaded = value
  },

  [UPDATE_UI_SETTING_VALUE] (state, data) {
    state.uiSettings[data.settingKey] = data.settingValue
  },

  [UPDATE_PATIENT_LIST_VIEW] (state, view) {
    if (!state.uiSettings.patientsList) {
      state.uiSettings.patientsList = {}
    }
    state.uiSettings.patientsList.view = view
  },

  [TOGGLE_MEASURE_LIST_ITEM_OPEN] (state, data) {
    const ccauId = data.client_clinic_assignment_user_id
    const id = data.id
    state.uiSettings = initDeepKeysForJson(state.uiSettings, ['patients', ccauId, 'measureOverview', id])
    const prevVal = state.uiSettings.patients[ccauId].measureOverview[id].open
    state.uiSettings.patients[ccauId].measureOverview[id].open = !(prevVal === undefined || prevVal === true)
  },

  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      if (key !== 'general') {
        state[key] = s[key]
      }
    })
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
}
