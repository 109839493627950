export const GET_ALL_CLINICIANS = 'patients/GET_ALL_CLINICIANS'
export const GET_ACTIVE_CLINICIANS = 'patients/GET_ACTIVE_CLINICIANS'
export const GET_ACTIVE_CLINICIANS_LOADING = 'patients/GET_ACTIVE_CLINICIANS_LOADING'
export const GET_PATIENTS_MEASURES = 'patients/GET_PATIENTS_MEASURES'
export const GET_PATIENTS_MEASURES_PDF = 'patients/GET_PATIENTS_MEASURES_PDF'
export const GET_PATIENTS_MEASURES_PDF_LOADING = 'patients/GET_PATIENTS_MEASURES_PDF_LOADING'
export const GET_PATIENT_SINGLE_MEASURE = 'patients/GET_PATIENT_SINGLE_MEASURE'
export const GET_PATIENT_SINGLE_MEASURE_LOADING = 'patients/GET_PATIENT_SINGLE_MEASURE_LOADING'
export const GET_PATIENT_SINGLE_MEASURE_PDF = 'patients/GET_PATIENT_SINGLE_MEASURE_PDF'
export const GET_PATIENT_SINGLE_MEASURE_LOADING_PDF = 'patients/GET_PATIENT_SINGLE_MEASURE_LOADING_PDF'
export const GET_CLINICIAN_ALERTS = 'patients/GET_CLINICIAN_ALERTS'
export const GET_CLINICIAN_ALERTS_COUNT = 'patients/GET_CLINICIAN_ALERTS_COUNT'
export const GET_CLINICIAN_ALERTS_COUNT_LOADING = 'patients/GET_CLINICIAN_ALERTS_COUNT_LOADING'
export const ACKNOWLEDGE_ALERT = 'patients/ACKNOWLEDGE_ALERT'
export const CHANGE_MEASURE_DUE_DATE = 'patients/CHANGE_MEASURE_DUE_DATE'
export const SIGN_OUT = 'patients/SIGN_OUT'
export const HAVE_MEASURES_AVAILABLE = 'patients/HAVE_MEASURE_AVAILABLE'
export const LOGIN_AS_RESPONDENT = 'patients/LOGIN_AS_RESPONDENT'
export const GET_ALL_CLINICIANS_TEAMS = 'patients/GET_ALL_CLINICIANS_TEAMS'
export const ADD_CLINICIAN_TEAM = 'patients/ADD_CLINICIAN_TEAM'
export const UPDATE_CLINICIAN_TEAM = 'patients/UPDATE_CLINICIAN_TEAM'
export const CLEAR_PATIENT_SINGLE_MEASURE = 'patients/CLEAR_PATIENT_SINGLE_MEASURE'
export const GET_CLINICIAN_TEAM = 'patients/GET_CLINICIAN_TEAM'
export const CHANGE_MEASURE_FREQUENCY = 'patients/CHANGE_MEASURE_FREQUENCY'
export const DELETE_SCHEDULED_MEASURE = 'measures/DELETE_SCHEDULED_MEASURE'
export const REMOVE_MEASURE_FROM_ASSIGNATION = 'measures/REMOVE_MEASURE_FROM_ASSIGNATION'
export const DELETING_SCHEDULED_MEASURE = 'measures/DELETING_SCHEDULED_MEASURE'
export const DELETE_MEASURE_DUE_DATES = 'patients/DELETE_MEASURE_DUE_DATES'
export const SORT_CLINICIANS_BY_RECENTLY_SELECTED = 'clinicalUsers/SORT_CLINICIANS_BY_RECENTLY_SELECTED'

export const ENDPOINTS = {
  CLINICIANS: '/clinical-user/by-role/clinician',
  GET_PATIENTS_MEASURES: '/overview/measures/{ccauId}',
  GET_PATIENTS_MEASURES_PDF: '/respondents/{ccauId}/progress-report',
  CHANGE_MEASURE_FREQUENCY: '/scheduling/change-frequency',
  DELETE_SCHEDULED_MEASURE: '/scheduling/{id}',
  REMOVE_MEASURE_FROM_ASSIGNATION: '/scheduling/user-assignment/{ccauId}/remove-measure/{measureId}',
  CHANGE_MEASURE_DUE_DATE: '/scheduling/{clientMeasureId}/change-scheduling-date',
  HAVE_MEASURES_AVAILABLE: '/questionnaires/have-available-questionnaires/{ccauId}',
  LOGIN_AS_RESPONDENT: '/security/login-as/{ccauId}',
  CLINICIANS_TEAMS: '/clinician-team',
  GET_CLINICIAN_ALERTS: '/measure-alerts',
  ACKNOWLEDGE_ALERT: '/measure-alerts/acknowledge/{id}',
  GET_CLINICIAN_ALERTS_COUNT: '/measure-alerts/active-alerts-count',
  GET_PATIENT_SINGLE_MEASURE: '/overview/measures/{ccauId}/{measureId}',
  GET_PATIENT_SINGLE_MEASURE_PDF: '/measure-session/{measureId}/session-report',
  CLINICIAN_TEAM: '/clinician-team/{id}',
  VALIDATE_TEAM_NAME: '/clinician-team/check-name'
}
