import store from '@/store'
import {
  PATIENT_SELECTOR_ALL,
  PATIENT_SELECTOR_MY_PATIENTS,
  PATIENT_SELECTOR_SUPERVISED_PATIENTS
} from '@/data/patientSelectorOptions'
import {
  loggedUserIsOnlyStaff,
  loggedUserIsOnlyClinician,
  loggedUserIsClinician,
  loggedUserIsStaff,
  loggedUserIsSupervisor
} from '@/mixins/UserManagement/UserRoles'

/**
 * Determine if the user is admin
 *
 * @returns {boolean}
 */
export function decorateParamsWithPatientSelector (params) {
  const uiSettings = store.getters.getUiSettings
  const patientActiveStatusFilter = uiSettings.patientsStatusSelector ? uiSettings.patientsStatusSelector.selected : getDefaultActiveStatusFilter()
  // If only staff or only clinician force default ( Only load saved when has multiple roles)
  const patientSelectorFilter = uiSettings.patientSelector && !loggedUserIsOnlyStaff() && !loggedUserIsOnlyClinician() ? getShortSelector(uiSettings) : getDefaultPatientSelectorFilter()
  params.activeStatus = patientActiveStatusFilter
  params.patientSelector = patientSelectorFilter
  return params
}

function getDefaultActiveStatusFilter () {
  return 'active'
}

function getDefaultPatientSelectorFilter () {
  // If clinician has user role
  if (loggedUserIsClinician()) {
    return PATIENT_SELECTOR_MY_PATIENTS
  }

  if (loggedUserIsStaff()) {
    return PATIENT_SELECTOR_ALL
  }

  if (loggedUserIsSupervisor()) {
    return PATIENT_SELECTOR_SUPERVISED_PATIENTS
  }
}

function getShortSelector (uiSettings) {
  const input = uiSettings.patientSelector.selected ? uiSettings.patientSelector.selected : 'all'
  const output = input.includes('supervised') ? 'supervised' : input.includes('my') ? 'my' : 'all'
  return output
}
