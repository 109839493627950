const PRODUCTION = process.env.NODE_ENV === 'production'

const hasRole = (user, role) => user.roles.roles.some(i => i === role)

const getVisitorType = (user) => {
  return user.is_patient || user.roles.roles.some(i => i === 'respondent') ? 'Patient' : 'ClinicalUser'
}

const addZendDesk = () => {
  const key = '0e2a2f7b-3342-4e55-a90f-75d78df3e3e1'
  const zendDesk = document.createElement('script', { type: 'text/javascript' })

  window.zESettings = {
    webWidget: {
      launcher: {
        label: {
          '*': 'Support'
        }
      }
    }
  }

  zendDesk.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${key}`)
  zendDesk.setAttribute('id', 'ze-snippet')
  document.head.appendChild(zendDesk)
}

const pendoApiKey = '3f2ffd38-2cbd-48fe-6f20-81dec7ee0219'

const setPendoObject = () => {
  const pendo = document.getElementById('pendo') ? document.getElementById('pendo') : document.createElement('script', { type: 'text/javascript', id: 'pendo' })
  return pendo
}

const getPendoSnippet = () => {
  return `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })('${pendoApiKey}');`
}

const initializePendoAnonymous = (customer, action) => {
  const pendo = setPendoObject()

  pendo.innerHTML = getPendoSnippet() + `
  pendo.${action}({
    visitor: {
      id: null,
      role: 'AnonymousUser'
    },

    account: {
      id: '${customer.name}',
      name: '${customer.name}'
    }
  })`

  document.head.appendChild(pendo)
}

const addPendoLoggedIn = (user, customer, action) => {
  const pendo = setPendoObject()
  const visitorType = getVisitorType(user)

  const roles = {
    admin: hasRole(user, 'admin'),
    clinician: hasRole(user, 'clinician'),
    director: hasRole(user, 'director'),
    staff: hasRole(user, 'staff'),
    supervisor: hasRole(user, 'supervisor')
  }

  pendo.innerHTML = getPendoSnippet() + `

      pendo.${action}({
        visitor: {
          id: '${user.uuid}',
          role: '${visitorType}',
          admin: ${roles.admin},
          clinician: ${roles.clinician},
          director: ${roles.director},
          staff: ${roles.staff},
          supervisor: ${roles.supervisor}
        },

        account: {
          id: '${customer.name}',
          name: '${customer.name}'
        }
      })`

  document.head.appendChild(pendo)
}

const updatePendo = (user, customer) => {
  const pendo = setPendoObject()

  if (user.id) {
    addPendoLoggedIn(user, customer, 'updateOptions')
  } else {
    pendo.innerHTML = getPendoSnippet() + `
      pendo.updateOptions({
        visitor: {
          id: null,
          role: '',
          admin: '',
          clinician: '',
          director: '',
          staff: '',
          supervisor: ''
        },

        account: {
          id: null,
          name: ''
        }
      })`
  }

  document.head.appendChild(pendo)
}

export function intializeApps () {
  addZendDesk()
}

export function initializeAppUser (user, customer) {
  if (PRODUCTION) {
    addPendoLoggedIn(user, customer, 'initialize')
  }
  // todo - move (pendo) api key(s) to a proper env file
  // maybe move api keys to PHP side and get them from settings call
  // then declare them in the index.html with server side rewrite ¯\_(ツ)_/¯
}

export function initializeAnonymousAppUser (customer, action) {
  if (PRODUCTION) {
    initializePendoAnonymous(customer, action)
  }
}

export function reInitializeAppUser (user, customer) {
  if (PRODUCTION) {
    updatePendo(user, customer)
  }
}
