export const GET_PATIENT_USERS = 'patientUsers/GET_PATIENT_USERS'
export const GET_PATIENT_USER = 'patientUsers/GET_PATIENT_USER'
export const PATIENT_USERS_LOGOUT = 'patientUsers/PATIENT_USERS_LOGOUT'
export const CHANGE_PATIENT_USER_STATUS = 'patientUsers/CHANGE_PATIENT_USER_STATUS'
export const SAVE_PATIENT_USER = 'patientUsers/SAVE_PATIENT_USER'
export const UPDATE_PATIENT_USER = 'patientUsers/UPDATE_PATIENT_USER'

export const ENDPOINTS = {
  GET_PATIENT_USERS: 'patient-user/respondents',
  GET_PATIENT_USER: 'patient-user/respondents/{id}',
  SAVE_PATIENT_USER: 'patient-user/respondents',
  UPDATE_PATIENT_USER: 'patient-user/respondents/{id}'
}
