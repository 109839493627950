import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'
const KEY = 'age_'
/**
 * Handle constructions of Analytics Gender Breakdown Chart Model.
 */
export class AnalyticsAgeRangeChartModel extends AnalyticsChartModel {
  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'ageRange'
  }

  getChartSubdivisionKey () {
    return 'demographics'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program) {
    return `${program.name} ${i18n.messages[i18n.locale].ageRange}`
  }

  /**
   * Get Default Chart yAxys.
   */
  getYAxis (queryResult) {
    const max = this.getYAxisMax(queryResult)
    return {
      max: max,
      min: 0,
      tickPositions: this.getYAxisTickPositions(max),
      title: {
        text: i18n.messages[i18n.locale].numberOfPatients,
        margin: 20,
        style: this.getDefaultStyleObject()
      }
    }
  }

  /**
   * Get Default Chart yAxys.
   */
  getXAxisLabels () {
    return {
      rotation: -45,
      style: this.getDefaultStyleObject()
    }
  }

  /**
   * Get Default Chart yAxys.
   */
  getXAxis (queryResult) {
    const categories = this.getXAxisCategories(queryResult, KEY)
    const labels = this.getXAxisLabels()
    return { categories, labels }
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    const name = i18n.messages[i18n.locale].age
    const data = []
    for (const key in queryResult) {
      if (queryResult.hasOwnProperty(key) && key.includes(KEY)) { // eslint-disable-line no-prototype-builtins
        data.push(queryResult[key])
      }
    }
    return [{ name, data }]
  }
}
