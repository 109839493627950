import axios from 'axios'
import {
  GET_RESPONDENTS,
  ENDPOINTS,
  SEND_REMINDER,
  SEND_INVITATION
} from './constants'
import { UPDATE_ASSIGNMENT_CONTACT_INFO } from '@/store/modules/patients/constants.js'

export default {
  [GET_RESPONDENTS] ({ commit }, params) {
    if (!params.hasOwnProperty('ignorePatientSelector') || !params.ignorePatientSelector) { // eslint-disable-line no-prototype-builtins
      const uiSettings = this.getters.getUiSettings
      const patientSelectorFilter = uiSettings.patientSelector ? uiSettings.patientSelector.selected : null
      const patientActiveStatusFilter = uiSettings.respondentsStatusSelector ? uiSettings.respondentsStatusSelector.selected : null
      params.activeStatus = patientActiveStatusFilter
      params.patientSelector = patientSelectorFilter
    }
    if (params.forceAllPatients) {
      params.patientSelector = 'all'
    }
    return new Promise((resolve, reject) => {
      const wasPaginated = !params.hasOwnProperty('paginate') || params.paginate === true // eslint-disable-line no-prototype-builtins
      axios.get(ENDPOINTS.GET_RESPONDENTS, { params: params }).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_RESPONDENTS, wasPaginated ? response.data.rows : response.data)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [SEND_REMINDER]: async (actions, params) => {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.SEND_REMINDER.replace('{acId}', params.id)
      axios.post(url, params).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [SEND_INVITATION]: async ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.SEND_INVITATION.replace('{acId}', params.id)
      axios.post(url, params).then((response) => {
        if (response.data.error || !response.data.result) {
          reject(response.data.error)
          return false
        }
        commit(UPDATE_ASSIGNMENT_CONTACT_INFO, params)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
