import {
  SET_PATIENT_INSURANCE,
  GET_PATIENT_INSURANCE_LOADING,
  SET_PATIENT_INSURANCE_LOADING,
  GET_PATIENTS_COUNT,
  GET_PATIENTS,
  SET_PATIENTS_NEW_RESULTS,
  GET_PATIENTS_BY_PROGRAM,
  GET_PATIENTS_LOADING,
  GET_PATIENT,
  LOADING_GET_PATIENT,
  RESET_PATIENT,
  UPDATE_PATIENTS_LIST,
  UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON,
  SIGN_OUT,
  SET_PATIENT_ACTIVE_TAB,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_LOADING,
  SET_ASSIGNMENT_VIEW,
  UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON,
  SET_TEAM_VIEW,
  UPDATE_ASSIGNMENTS_LIST,
  PATIENT_VIEW_TABS,
  GET_MEASURE_QUESTIONS,
  GET_MULTI_MEASURE_QUESTIONS,
  GET_MULTI_MEASURE_QUESTIONS_LOADING,
  GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE,
  GET_SCHEDULED_PATIENTS_BY_DATE,
  SET_SCHEDULED_PATIENTS_BY_DATE_LOADING,
  GET_SINGLE_PATIENT_PROGRAMS,
  SET_SINGLE_PATIENT_PROGRAMS_LOADING,
  DELETE_PROGRAM_ASSIGNATION,
  GET_SINGLE_PATIENT_PROGRAM,
  UPDATE_SINGLE_PATIENT_PROGRAM,
  SET_SINGLE_PATIENT_REFERRER_ROUTE,
  GET_RESPONDENT_ASSIGNED_MEASURES_LIST,
  REMOVE_RESPONDENT_FROM_TEAM,
  GET_AUDITION_ANSWERS_REPORT,
  SET_PATIENT_LIST,
  UPDATE_ASSIGNMENT_CONTACT_INFO,
  SET_PDF_STATUS,
  GET_CLINICIAN_ASSIGNMENTS,
  GET_CLINICIAN_ASSIGNMENTS_LOADING
} from './constants'
import { initialState } from './index.js'
import { sortByFieldAndFullName } from '@/mixins/Common/sortingFunctions'
import { updateUserProfile } from '@/mixins/Common/userHelperFuncions.js'

export default {
  [GET_PATIENT_INSURANCE_LOADING] (state, value) {
    state.getPatientInsuranceLoading = value
  },

  [SET_PATIENT_INSURANCE_LOADING] (state, value) {
    state.setPatientInsuranceLoading = value
  },

  [SET_PATIENT_INSURANCE] (state, data) {
    state.patientInsurance = data
  },

  [SET_PATIENT_LIST] (state, data) {
    state.patients = data
  },
  [GET_PATIENTS_COUNT] (state, value) {
    state.patientsCount = value
  },
  [GET_PATIENTS] (state, obj) {
    const { data, params } = obj
    if (params && params.sort && params.sort.field === 'latest_completed_session_date') {
      data.sort((a, b) => sortByFieldAndFullName(a, b, 'latest_completed_session_completed_date', params.sort.type))
    }
    state.patients = data
  },
  [SET_PATIENTS_NEW_RESULTS] (state, obj) {
    const { data, params } = obj
    if (params && params.sort && params.sort.field === 'latest_completed_session_date') {
      data.sort((a, b) => sortByFieldAndFullName(a, b, 'latest_completed_session_completed_date', params.sort.type))
    }
    state.patientsNewResults = data
  },

  [GET_PATIENTS_BY_PROGRAM] (state, data) {
    state.patientsByProgram = data
  },
  [GET_PATIENTS_LOADING] (state, val) {
    state.patientsLoading = val
  },
  [GET_PATIENT] (state, data) {
    data.email_notification = !!Number(data.email_notification)
    data.mobile_notification = !!Number(data.mobile_notification)
    data.mobile_number = data.mobile_number === '0' ? null : data.mobile_number
    state.singlePatient = data
  },

  [RESET_PATIENT] (state) {
    state.singlePatient = []
  },

  [LOADING_GET_PATIENT] (state, val) {
    state.loadingGetPatient = val
  },

  [GET_ASSIGNMENTS] (state, data) {
    state.assignments = data
  },

  [GET_ASSIGNMENTS_LOADING] (state, value) {
    state.assignmentsLoading = value
  },

  [GET_CLINICIAN_ASSIGNMENTS] (state, val) {
    state.clinicianAssignments = val
  },

  [GET_CLINICIAN_ASSIGNMENTS_LOADING] (state, val) {
    state.clinicianAssignmentsLoading = val
  },

  [GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE] (state, data) {
    const days = Object.keys(data.days)
    state.scheduledPatientsBySingleDate = data.days[days[0]]
  },

  [GET_SCHEDULED_PATIENTS_BY_DATE] (state, data) {
    state.scheduledPatientsByDate = data
  },

  [SET_SCHEDULED_PATIENTS_BY_DATE_LOADING] (state, value) {
    state.scheduledPatientsByDateLoading = value
  },

  [GET_SINGLE_PATIENT_PROGRAMS] (state, data) {
    state.singlePatientPrograms = data
  },

  [SET_SINGLE_PATIENT_PROGRAMS_LOADING] (state, value) {
    state.singlePatientProgramsLoading = value
  },

  [GET_SINGLE_PATIENT_PROGRAM] (state, data) {
    data.clinicians = data.clinicians ? data.clinicians : []
    data.clinicians_teams = data.clinicians_teams ? data.clinicians_teams : []
    state.singlePatientProgram = data
  },

  [UPDATE_SINGLE_PATIENT_PROGRAM] (state, data) {
    const index = state.singlePatientPrograms.findIndex(p => Number(p.id) === Number(data.id))
    if (index > -1) {
      const updateItem = state.singlePatientPrograms[index]
      for (const k in data) {
        if (data.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          updateItem[k] = data[k]
        }
      }
      state.singlePatientPrograms[index] = updateItem
    }
  },

  [GET_MEASURE_QUESTIONS] (state, data) {
    state.measureQuestions = data
  },

  [GET_MULTI_MEASURE_QUESTIONS] (state, data) {
    state.multiMeasureQuestions = data
  },

  [GET_MULTI_MEASURE_QUESTIONS_LOADING] (state, data) {
    state.multiMeasureQuestionsLoading = data
  },

  [SET_SINGLE_PATIENT_REFERRER_ROUTE] (state, data) {
    if (data !== 'Login') {
      state.singlePatientReferrerRoute = data
    }
  },

  [SET_PATIENT_ACTIVE_TAB] (state, tab) {
    if (PATIENT_VIEW_TABS.includes(tab)) {
      state.activeTab = tab
    }
  },

  [SET_ASSIGNMENT_VIEW] (state, id) {
    state.activeAssignment = state.assignments.find(element => {
      return element.ccauId === id
    })
  },

  [UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON] (state, data) {
    if (state.assignments && state.assignments.length) {
      const index = state.assignments.findIndex(a => parseInt(a.user_id) === parseInt(data.id))
      if (index > -1) {
        updateUserProfile(state.assignments[index], data, UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON)
        state.activeAssignment = state.assignments[index]
      }
    }
  },

  [SET_TEAM_VIEW] (state, id) {
    state.teamId = id
  },

  [UPDATE_ASSIGNMENT_CONTACT_INFO] (state, data) {
    if (parseInt(state.singlePatient.id) === parseInt(data.id)) {
      const newVal = data.new_channel_value
      if (data.channel === '1') {
        state.singlePatient.email = !newVal ? state.singlePatient.email : newVal
        state.singlePatient.email_notification = true
      } else {
        state.singlePatient.mobile_notification = true
        state.singlePatient.mobile_number = !newVal ? state.singlePatient.mobile_number : newVal
      }
    }
  },

  [UPDATE_PATIENTS_LIST] (state, data) {
    if (data.clinician_logged && data.clinicians.indexOf(data.clinician_logged.toString()) === -1) {
      return
    }
    const index = state.patients.findIndex((patient) => patient.id === data.id)
    if (index > -1) {
      state.patients.splice(index, 1)
    }
    data.justAdded = true
    state.patients.unshift(data)
  },

  [UPDATE_ASSIGNMENTS_LIST] (state, data) {
    data.justAdded = true
    data.ccauId = data.ccau_id

    state.assignments.push(data)
  },

  [DELETE_PROGRAM_ASSIGNATION] (state, id) {
    state.singlePatientPrograms = state.singlePatientPrograms.filter(program => Number(program.id) !== Number(id))
  },

  [GET_RESPONDENT_ASSIGNED_MEASURES_LIST] (state, measures) {
    state.singlePatientMeasuresList = measures
  },

  [SET_PDF_STATUS] (state, pdfStatus) {
    const results = pdfStatus.results
    for (const uuid in results) {
      state.pdfStatus[uuid] = results[uuid]
    }
  },

  [REMOVE_RESPONDENT_FROM_TEAM] (state, parameters) {
    const data = parameters.result
    const rootState = parameters.rootState

    // Respondent is in a state in which is removed from the team bust must be shown.
    if (data.hasMeasures) {
      // Mark respondent as deleted.
      state.activeAssignment.deleted = true
      const existing = state.assignments.find(a => parseInt(a.ccauId) === parseInt(data.assignation.id))
      if (existing) {
        existing.deleted = true
      }

      // Remove Instances of measures that have not been ever completed.
      const measures = rootState.clinicians.patientMeasures
      const measuresToMaintain = []
      for (let i = 0, len = measures.length; i < len; i++) {
        if (measures[i].sessions_completed.length) {
          measuresToMaintain.push(measures[i])
        }
      }
      rootState.clinicians.patientMeasures = measuresToMaintain
      return true
    }

    // If it doesn't have measures the respondent is removed from the team
    const index = state.assignments.findIndex(a => parseInt(a.ccauId) === parseInt(data.assignation.id))
    if (index > -1) {
      state.assignments.splice(index, 1)
    }
  },

  [GET_AUDITION_ANSWERS_REPORT] (state, data) {
    state.auditionAnswersReport = data
  },

  [UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON] (state, data) {
    if (state.patients && state.patients.length) {
      const index = state.patients.findIndex(p => parseInt(p.access_control_id) === parseInt(data.id))
      if (index > -1) {
        updateUserProfile(state.patients[index], data, UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON)
      }
    }
  },

  [UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON] (state, data) {
    updateUserProfile(state.singlePatient, data, UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON)
  },
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}
