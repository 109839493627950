// Mutation types
export const GET_PATIENTS_COUNT = 'patients/GET_PATIENTS_COUNT'
export const GET_PATIENTS = 'patients/GET_PATIENTS'
export const GET_PATIENTS_NEW_RESULTS = 'patients/GET_PATIENTS_NEW_RESULTS'
export const SET_PATIENTS_NEW_RESULTS = 'patients/SET_PATIENTS_NEW_RESULTS'
export const GET_PATIENTS_BY_PROGRAM = 'patients/GET_PATIENTS_BY_PROGRAM'
export const GET_PATIENTS_LOADING = 'patients/GET_PATIENTS_LOADING'
export const GET_PATIENT = 'patients/GET_PATIENT'
export const LOADING_GET_PATIENT = 'patients/LOADING_GET_PATIENT'
export const GET_PATIENT_INSURANCE = 'patients/GET_PATIENT_INSURANCE'
export const GET_PATIENT_INSURANCE_LOADING = 'patients/GET_PATIENT_INSURANCE_LOADING'
export const SET_PATIENT_INSURANCE = 'patients/SET_PATIENT_INSURANCE'
export const SET_PATIENT_INSURANCE_LOADING = 'patients/SET_PATIENT_INSURANCE_LOADING'
export const RESET_PATIENT = 'patients/RESET_PATIENT'
export const UPDATE_PATIENTS_LIST = 'patients/UPDATE_PATIENTS_LIST'
export const SIGN_OUT = 'patients/SIGN_OUT'
export const SET_PATIENT_ACTIVE_TAB = 'patient/SET_PATIENT_ACTIVE_TAB'
export const GET_ASSIGNMENTS = 'patient/GET_ASSIGNMENTS'
export const GET_ASSIGNMENTS_LOADING = 'patient/GET_ASSIGNMENTS_LOADING'
export const SET_ASSIGNMENT_VIEW = 'patient/SET_ASSIGNMENT_VIEW'
export const SET_TEAM_VIEW = 'patient/SET_TEAM_VIEW'
export const UPDATE_ASSIGNMENTS_LIST = 'respondents/UPDATE_ASSIGNMENTS_LIST'
export const PATIENT_VIEW_TABS = ['measures', 'information', 'alerts', 'list']
export const GET_MEASURE_QUESTIONS = 'patient/GET_MEASURE_QUESTIONS'
export const GET_MULTI_MEASURE_QUESTIONS = 'patient/GET_MULTI_MEASURE_QUESTIONS'
export const GET_MULTI_MEASURE_QUESTIONS_LOADING = 'patient/GET_MULTI_MEASURE_QUESTIONS_LOADING'
export const GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE = 'patients/GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE'
export const GET_SCHEDULED_PATIENTS_BY_DATE = 'patients/GET_SCHEDULED_PATIENTS_BY_DATE'
export const SET_SCHEDULED_PATIENTS_BY_DATE_LOADING = 'patients/SET_SCHEDULED_PATIENTS_BY_DATE_LOADING'
export const GET_SINGLE_PATIENT_PROGRAMS = 'patient/GET_SINGLE_PATIENT_PROGRAMS'
export const SET_SINGLE_PATIENT_PROGRAMS_LOADING = 'patient/SET_SINGLE_PATIENT_PROGRAMS_LOADING'
export const DELETE_PROGRAM_ASSIGNATION = 'patient/DELETE_PROGRAM_ASSIGNATION'
export const GET_SINGLE_PATIENT_PROGRAM = 'patient/GET_SINGLE_PATIENT_PROGRAM'
export const UPDATE_SINGLE_PATIENT_PROGRAM = 'patient/UPDATE_SINGLE_PATIENT_PROGRAM'
export const SET_SINGLE_PATIENT_REFERRER_ROUTE = 'patients/SET_SINGLE_PATIENT_REFERRER_ROUTE'
export const GET_RESPONDENT_ASSIGNED_MEASURES_LIST = 'respondents/GET_RESPONDENT_ASSIGNED_MEASURES_LIST'
export const REMOVE_RESPONDENT_FROM_TEAM = 'respondents/REMOVE_RESPONDENT_FROM_TEAM'
export const GET_AUDITION_ANSWERS_REPORT = 'measures/GET_AUDITION_ANSWERS_REPORT'
export const SET_PATIENT_LIST = 'patients/SET_PATIENT_LIST'
export const UPDATE_ASSIGNMENT_CONTACT_INFO = 'patients/UPDATE_ASSIGNMENT_CONTACT_INFO'
export const UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON = 'patients/UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON'
export const UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON = 'patients/UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON'
export const UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON = 'patients/UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON'
export const SET_PDF_STATUS = 'patients/SET_PDF_STATUS'
export const GET_CLINICIAN_ASSIGNMENTS = 'patients/GET_CLINICIAN_ASSIGNMENTS'
export const GET_CLINICIAN_ASSIGNMENTS_LOADING = 'patients/GET_CLINICIAN_ASSIGNMENTS_LOADING'

export const ENDPOINTS = {
  PATIENTS: '/patients',
  PATIENT: '/patients/{patientId}',
  GET_PATIENTS_BY_PROGRAM: '/programs/{uuid}/patients',
  GET_SCHEDULED_PATIENTS_BY_DATE: '/patients/from/{startDate}/to/{endDate}/scheduled',
  GET_SINGLE_PATIENT_PROGRAMS: '/patients/{patientId}/programs',
  GET_SINGLE_PATIENT_PROGRAM: '/patients/{patientId}/programs/{accessControlProgramId}',
  GET_PATIENTS_NEW_RESULTS: '/patients/from/{startDate}/to/{endDate}/completed',
  VALIDATE_MRN: '/patients/check-mrn',
  GET_ASSIGNMENTS: '/assignments/{ccau_id}',
  RESPONDENTS: '/respondents',
  REMOVE_RESPONDENT_FROM_TEAM: '/teams/{ccauId}',
  RESPONDENT: '/respondents/{respondentId}',
  GET_MEASURE_QUESTIONS: '/overview/answers-report/{client_measure_id}',
  GET_MULTI_MEASURE_QUESTIONS: '/overview/answers-report',
  DELETE_PROGRAM_ASSIGNATION: 'patients/{accessControlId}/programs/{accessControlProgramId}',
  GET_RESPONDENT_ASSIGNED_MEASURES_LIST: 'respondents/{ccauId}/measures',
  GET_AUDITION_ANSWERS_REPORT: '/questionnaires-audition/answers-report/{measureId}',
  VALIDATE_UNIC_RESPONDENT: '/respondents/exist',
  POST_PDF_STATUS: '/documents/status',
  GET_PDF_URL: '/documents/download/{pdf_uuid}',
  GET_PATIENT_INSURANCE: '/v2/patients/{patient_user_id}/insurance',
  PUT_PATIENT_INSURANCE: '/v2/patients/{patient_user_id}/insurance'
}
