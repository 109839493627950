export const STATUS_INACTIVE = 1
export const STATUS_ACTIVE = 2
export const STATUS_NOT_INVITED = 3
export const STATUS_INVITATION_SENT = 4
export const STATUS_PASSWORD_RESET = 5

const statusIcons = []
statusIcons[STATUS_INACTIVE] = 'inactive'
statusIcons[STATUS_ACTIVE] = 'active'
statusIcons[STATUS_NOT_INVITED] = 'not_invited'
statusIcons[STATUS_INVITATION_SENT] = 'invited'
statusIcons[STATUS_PASSWORD_RESET] = 'password_reset'
export const STATUS_ICONS = statusIcons

// Todo: Move to translations keys instead.
const statusStrings = []
statusStrings[STATUS_INACTIVE] = 'Inactive'
statusStrings[STATUS_ACTIVE] = 'Active'
statusStrings[STATUS_NOT_INVITED] = 'Not Invited'
statusStrings[STATUS_INVITATION_SENT] = 'Invited'
statusStrings[STATUS_PASSWORD_RESET] = 'Password Reset'
export const STATUS_STRINGS = statusStrings
