import axios from 'axios'
import store from '@/store'
import moment from 'moment'
import {
  GET_ALL_CLINICIANS,
  GET_ACTIVE_CLINICIANS,
  GET_ACTIVE_CLINICIANS_LOADING,
  GET_ALL_CLINICIANS_TEAMS,
  GET_CLINICIAN_ALERTS,
  GET_CLINICIAN_ALERTS_COUNT,
  GET_CLINICIAN_ALERTS_COUNT_LOADING,
  GET_PATIENTS_MEASURES,
  GET_PATIENTS_MEASURES_PDF_LOADING,
  GET_PATIENTS_MEASURES_PDF,
  GET_PATIENT_SINGLE_MEASURE_LOADING,
  GET_PATIENT_SINGLE_MEASURE,
  GET_PATIENT_SINGLE_MEASURE_LOADING_PDF,
  GET_PATIENT_SINGLE_MEASURE_PDF,
  CLEAR_PATIENT_SINGLE_MEASURE,
  DELETE_MEASURE_DUE_DATES,
  CHANGE_MEASURE_FREQUENCY,
  REMOVE_MEASURE_FROM_ASSIGNATION,
  DELETE_SCHEDULED_MEASURE,
  DELETING_SCHEDULED_MEASURE,
  LOGIN_AS_RESPONDENT,
  CHANGE_MEASURE_DUE_DATE,
  ADD_CLINICIAN_TEAM,
  UPDATE_CLINICIAN_TEAM,
  GET_CLINICIAN_TEAM,
  ACKNOWLEDGE_ALERT,
  ENDPOINTS
} from '@/store/modules/clinicians/constants.js'
import { createNewCancelTokenRequest } from '@/store/modules/patients/index.js'
import { getDefaultPatientSelector } from '@/mixins/UserManagement/UserRoles.js'

export default {
  VALIDATE_TEAM_NAME (actions, team) {
    const data = { name: team.name }
    if (team.id) {
      data.id = team.id
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.VALIDATE_TEAM_NAME, { params: data })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [GET_ALL_CLINICIANS] ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.CLINICIANS).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_ALL_CLINICIANS, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [GET_ACTIVE_CLINICIANS] ({ commit }) {
    commit(GET_ACTIVE_CLINICIANS_LOADING, true)
    return new Promise((resolve, reject) => {
      axios.get(`${ENDPOINTS.CLINICIANS}?onlyActive=1`).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_ALL_CLINICIANS, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(GET_ACTIVE_CLINICIANS_LOADING, false)
      })
    })
  },
  [GET_ALL_CLINICIANS_TEAMS] ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.CLINICIANS_TEAMS, {
        params,
        cancelToken: createNewCancelTokenRequest('loadItems')
      }).then((response) => {
        if (axios.isCancel(response)) {
          return false
        }
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_ALL_CLINICIANS_TEAMS, response.data.rows ? response.data.rows : response.data)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [GET_CLINICIAN_ALERTS] ({ commit }, params) {
    const defaultPatientSelector = getDefaultPatientSelector()
    const uiSettings = store.getters.getUiSettings
    const patientSelectorFilter = uiSettings.patientSelector ? uiSettings.patientSelector.selected : null

    params.patientSelector = patientSelectorFilter || defaultPatientSelector
    params.includeActiveAlerts = uiSettings.alertsStatusSelector && uiSettings.alertsStatusSelector.selected && ['all', 'active'].includes(uiSettings.alertsStatusSelector.selected)
    params.includeAcknowledgedAlerts = uiSettings.alertsStatusSelector && uiSettings.alertsStatusSelector.selected && ['all', 'acknowledged'].includes(uiSettings.alertsStatusSelector.selected)

    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_CLINICIAN_ALERTS, { params: params }).then((response) => {
        if (response.data.error) {
          return reject(response.data.error)
        }
        const alerts = response.data && response.data.rows ? response.data.rows : response.data
        commit(GET_CLINICIAN_ALERTS, !alerts ? [] : alerts)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  GET_CLINICIAN_PATIENT_ALERTS ({ commit }, ccauId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ENDPOINTS.GET_CLINICIAN_ALERTS}/${ccauId}`).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_CLINICIAN_ALERTS, response.data.rows ? response.data.rows : response.data)
          resolve(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  GET_CLINICIAN_ALERTS_COUNT ({ commit }) {
    commit(GET_CLINICIAN_ALERTS_COUNT_LOADING, true)
    const uiSettings = this.getters.getUiSettings
    const patientSelectorFilter = uiSettings.patientSelector ? uiSettings.patientSelector.selected : null
    const params = { patientSelector: patientSelectorFilter }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_CLINICIAN_ALERTS_COUNT, { params: params }).then((response) => {
        commit(GET_CLINICIAN_ALERTS_COUNT, response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(GET_CLINICIAN_ALERTS_COUNT_LOADING, false)
      })
    })
  },

  GET_PATIENTS_MEASURES ({ commit }, ccauId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PATIENTS_MEASURES.replace('{ccauId}', ccauId)
      axios.get(url).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_PATIENTS_MEASURES, response.data)
          resolve()
        }
      }).catch((error) => {
        commit(GET_PATIENTS_MEASURES, [])
        reject(error)
      })
    })
  },
  GET_PATIENTS_MEASURES_PDF ({ commit }, ccauId) {
    commit(GET_PATIENTS_MEASURES_PDF_LOADING, true)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PATIENTS_MEASURES_PDF.replace('{ccauId}', ccauId)
      axios.get(url).then((response) => {
        commit(GET_PATIENTS_MEASURES_PDF_LOADING, false)
        if (response.data.error) {
          reject(response.data.error)
        } else {
          const filterMeasures = response.data.filter(item => {
            return item.is_bundle_measure === 0 && Array.isArray(item.sessions_completed) && item.sessions_completed.length
          })
          commit(GET_PATIENTS_MEASURES_PDF, filterMeasures)
          const measureIds = response.data.map(item => item.id)
          if (measureIds.length) {
            return this.dispatch('GET_MULTI_MEASURE_QUESTIONS', { clientMeasureIds: measureIds }).then((response) => {
              resolve()
            })
          }
          return {}
        }
      }).catch((error) => {
        commit(GET_PATIENTS_MEASURES_PDF, [])
        reject(error)
      })
    })
  },
  GET_PATIENT_SINGLE_MEASURE ({ commit }, params) {
    commit(GET_PATIENT_SINGLE_MEASURE_LOADING, true)
    const url = ENDPOINTS.GET_PATIENT_SINGLE_MEASURE
      .replace('{ccauId}', params.ccauId)
      .replace('{measureId}', params.measureId)

    return new Promise((resolve, reject) => {
      axios.get(url).then((response) => {
        commit(GET_PATIENT_SINGLE_MEASURE_LOADING, false)
        commit(GET_PATIENT_SINGLE_MEASURE, response.data)
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_PATIENT_SINGLE_MEASURE_PDF ({ commit }, params) {
    commit(GET_PATIENT_SINGLE_MEASURE_LOADING_PDF, true)
    const url = ENDPOINTS.GET_PATIENT_SINGLE_MEASURE_PDF
      .replace('{measureId}', params.measureId)

    return new Promise((resolve, reject) => {
      axios.get(url).then((response) => {
        commit(GET_PATIENT_SINGLE_MEASURE_LOADING_PDF, false)
        commit(GET_PATIENT_SINGLE_MEASURE_PDF, response.data)
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  CLEAR_PATIENT_SINGLE_MEASURE ({ commit }) {
    commit(CLEAR_PATIENT_SINGLE_MEASURE)
  },
  DELETE_MEASURE_DUE_DATES ({ commit }, measureId) {
    commit(DELETE_MEASURE_DUE_DATES, measureId)
  },
  CHANGE_MEASURE_FREQUENCY ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.CHANGE_MEASURE_FREQUENCY, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(CHANGE_MEASURE_FREQUENCY, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  REMOVE_MEASURE_FROM_ASSIGNATION ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.REMOVE_MEASURE_FROM_ASSIGNATION.replace('{ccauId}', data.client_clinic_assignment_user_id).replace('{measureId}', data.measure_id)
      axios.delete(url).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(REMOVE_MEASURE_FROM_ASSIGNATION, data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [DELETE_SCHEDULED_MEASURE] ({ commit }, params) {
    if (!this.getters.getDeletingScheduleMeasure) {
      commit(DELETING_SCHEDULED_MEASURE, params.clientMeasureId)
      return new Promise((resolve, reject) => {
        const url = ENDPOINTS.DELETE_SCHEDULED_MEASURE.replace('{id}', params.clientMeasureId)
        axios.delete(url, { data: params })
          .then(() => {
            commit(DELETE_SCHEDULED_MEASURE, params.clientMeasureId)
            commit('measures/REMOVE_MEASURE_ASSIGNED_DURING_SESSION', params.clientMeasureId)
            commit('measures/REMOVE_SCHEDULED_MEASURE', params.clientMeasureId)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit(DELETING_SCHEDULED_MEASURE, null)
          })
      })
    }
  },

  HAVE_MEASURES_AVAILABLE (actions, ccauId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.HAVE_MEASURES_AVAILABLE.replace('{ccauId}', ccauId)
      axios.get(url).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  LOGIN_AS_RESPONDENT ({ commit }, ccauId) {
    const url = ENDPOINTS.LOGIN_AS_RESPONDENT.replace('{ccauId}', ccauId)
    return new Promise((resolve, reject) => {
      axios.post(url, {}).then((response) => {
        commit(LOGIN_AS_RESPONDENT, {})
        commit('security/SIGN_OUT')
        commit('security/LOGIN_AS', response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  CHANGE_MEASURE_DUE_DATE ({ commit }, data) {
    data.due_date = moment(data.due_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
    const url = ENDPOINTS.CHANGE_MEASURE_DUE_DATE.replace('{clientMeasureId}', data.client_measure_id)
    return new Promise((resolve, reject) => {
      axios.post(url, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(CHANGE_MEASURE_DUE_DATE, { data: response.data, appointment: !!data.appointment_id })
          resolve(response.data)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  ADD_CLINICIAN_TEAM ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(ENDPOINTS.CLINICIANS_TEAMS, data).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(ADD_CLINICIAN_TEAM, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  UPDATE_CLINICIAN_TEAM ({ commit }, team) {
    return new Promise((resolve, reject) => {
      axios.put(ENDPOINTS.CLINICIAN_TEAM.replace('{id}', team.id), team).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(UPDATE_CLINICIAN_TEAM, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  GET_CLINICIAN_TEAM ({ commit }, id) {
    if (!id) {
      commit(GET_CLINICIAN_TEAM, null)
      return
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.CLINICIAN_TEAM.replace('{id}', id)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_CLINICIAN_TEAM, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  ACKNOWLEDGE_ALERT ({ commit }, alert) {
    const url = ENDPOINTS.ACKNOWLEDGE_ALERT.replace('{id}', alert.id)
    return new Promise((resolve, reject) => {
      axios.post(url, { global: false }).then((response) => {
        commit(ACKNOWLEDGE_ALERT, response.data)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
