export const GENDER_MALE = 'm'
export const GENDER_FEMALE = 'f'
export const GENDER_UNKNOWN = 'u'
export const GENDER_NON_BINARY = 'n'
export const GENDER_OTHER = 'o'
export const GENDER_LOCALE_MALE = 'male'
export const GENDER_LOCALE_FEMALE = 'female'
export const GENDER_LOCALE_UNKNOWN = 'unknown'
export const GENDER_LOCALE_NON_BINARY = 'gender_non_binary'
export const GENDER_LOCALE_OTHER = 'other'
export const GENDER_LOCAL_MAP = {
  m: GENDER_LOCALE_MALE,
  f: GENDER_LOCALE_FEMALE,
  u: GENDER_LOCALE_UNKNOWN,
  n: GENDER_LOCALE_NON_BINARY,
  o: GENDER_LOCALE_OTHER
}
