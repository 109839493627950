
import axios from 'axios'
import moment from 'moment'
import state from '@/store/'
import {
  GET_PATIENTS_COUNT,
  GET_PATIENTS,
  SET_PATIENTS_NEW_RESULTS,
  GET_PATIENTS_BY_PROGRAM,
  GET_PATIENTS_LOADING,
  GET_PATIENT,
  GET_PATIENT_INSURANCE_LOADING,
  SET_PATIENT_INSURANCE,
  SET_PATIENT_INSURANCE_LOADING,
  LOADING_GET_PATIENT,
  RESET_PATIENT,
  SET_PATIENT_ACTIVE_TAB,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_LOADING,
  SET_ASSIGNMENT_VIEW,
  SET_TEAM_VIEW,
  GET_MEASURE_QUESTIONS,
  GET_MULTI_MEASURE_QUESTIONS,
  GET_MULTI_MEASURE_QUESTIONS_LOADING,
  GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE,
  GET_SCHEDULED_PATIENTS_BY_DATE,
  SET_SCHEDULED_PATIENTS_BY_DATE_LOADING,
  GET_SINGLE_PATIENT_PROGRAMS,
  SET_SINGLE_PATIENT_PROGRAMS_LOADING,
  DELETE_PROGRAM_ASSIGNATION,
  GET_SINGLE_PATIENT_PROGRAM,
  UPDATE_PATIENTS_LIST,
  UPDATE_SINGLE_PATIENT_PROGRAM,
  UPDATE_ASSIGNMENTS_LIST,
  SET_SINGLE_PATIENT_REFERRER_ROUTE,
  GET_RESPONDENT_ASSIGNED_MEASURES_LIST,
  REMOVE_RESPONDENT_FROM_TEAM,
  SET_PDF_STATUS,
  GET_CLINICIAN_ASSIGNMENTS,
  ENDPOINTS
} from './constants'
import { createNewCancelTokenRequest } from './index.js'
import { decorateParamsWithPatientSelector } from '@/mixins/PatientSelector/PatientSelectorHelper'
import { UPDATE_CONTACT_INFO_DETAIL } from '@/store/modules/applicationState/constants.js'

function submitPatient (patient, url, method, commit) {
  if (patient.email_notification) {
    patient.email_notification = !!Number(patient.email_notification)
  }
  if (patient.mobile_notification) {
    patient.mobile_notification = !!Number(patient.mobile_notification)
  }
  if (patient.mobile_number) {
    patient.mobile_number = patient.mobile_number.replace(/\D/g, '')
  }
  if (patient.birth_date) {
    patient.birth_date = moment(patient.birth_date).utc().format('YYYY-MM-DD')
  }

  return new Promise((resolve, reject) => {
    axios[method](url, patient)
      .then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          response.data.clinician_logged = patient.clinician_logged || null
          commit(UPDATE_PATIENTS_LIST, response.data)
          if (!patient.isAutoActivation || (patient.isAutoActivation && patient.ccauId === response.data.client_clinic_assignment_user_id)) {
            commit(UPDATE_CONTACT_INFO_DETAIL, response.data)
            commit(GET_PATIENT, response.data)
            const arraysToCheck = JSON.parse(JSON.stringify([response.data.clinicians, response.data.clinician_teams, response.data.clinician_teams_programs]))
            const notEmpty = (element) => element.length > 0
            const result = arraysToCheck.some(notEmpty)
            commit(GET_CLINICIAN_ASSIGNMENTS, result)
          }
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function submitRespondent (patient, url, method, commit) {
  patient.email_notification = !!Number(patient.email_notification)
  patient.mobile_notification = !!Number(patient.mobile_notification)
  patient.mobile_number = patient.mobile_number.replace(/\D/g, '')
  if (patient.birth_date) {
    patient.birth_date = moment(patient.birth_date).utc().format('YYYY-MM-DD')
  }

  return new Promise((resolve, reject) => {
    axios[method](url, patient)
      .then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          if (method.toLowerCase() !== 'put') {
            commit(UPDATE_ASSIGNMENTS_LIST, response.data)
          }
          resolve(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

let cancelRequestObjects = []
export default {
  CANCEL_AXIOS_REQUEST (actions, object) {
    if (cancelRequestObjects && cancelRequestObjects[object.requestIdentifier] && typeof cancelRequestObjects[object.requestIdentifier] === 'function') {
      cancelRequestObjects[object.requestIdentifier]()
      cancelRequestObjects = []
    }
  },
  SET_PATIENT_ACTIVE_TAB ({ commit }, tab) {
    commit(SET_PATIENT_ACTIVE_TAB, tab)
  },
  SET_ASSIGNMENT_VIEW ({ commit }, id) {
    commit(SET_ASSIGNMENT_VIEW, id)
  },
  SET_TEAM_VIEW ({ commit }, id) {
    commit(SET_TEAM_VIEW, id)
  },
  GET_MEASURE_QUESTIONS ({ commit }, id) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_MEASURE_QUESTIONS
        .replace('{client_measure_id}', id)
      axios.get(url)
        .then((response) => {
          commit(GET_MEASURE_QUESTIONS, response.data)
          resolve()
        })
        .catch((error) => {
          commit(GET_MEASURE_QUESTIONS, {})
          reject(error.response)
        })
    })
  },
  GET_MULTI_MEASURE_QUESTIONS ({ commit }, params) {
    commit(GET_MULTI_MEASURE_QUESTIONS_LOADING, true)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_MULTI_MEASURE_QUESTIONS
      axios.post(url, params)
        .then((response) => {
          commit(GET_MULTI_MEASURE_QUESTIONS, response.data)
          commit(GET_MULTI_MEASURE_QUESTIONS_LOADING, false)
          resolve(response)
        })
        .catch((error) => {
          commit(GET_MULTI_MEASURE_QUESTIONS, {})
          reject(error.response)
        })
    })
  },
  GET_PATIENTS ({ commit }, tableParams) {
    return new Promise((resolve, reject) => {
      const uiSettings = this.getters.getUiSettings
      const patientActiveStatusFilter = uiSettings.patientsStatusSelector ? uiSettings.patientsStatusSelector.selected : null
      const patientSelectorFilter = uiSettings.patientSelector ? uiSettings.patientSelector.selected : null
      tableParams.activeStatus = patientActiveStatusFilter
      tableParams.patientSelector = patientSelectorFilter

      axios.get(ENDPOINTS.PATIENTS, {
        params: tableParams,
        cancelToken: createNewCancelTokenRequest('patientListData')
      }).then((response) => {
        if (!response.status) { return }
        if (response.data.error) {
          return reject(response.data.error)
        }
        const r = {
          data: response.data.rows,
          params: tableParams
        }
        commit(GET_PATIENTS, r)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_PATIENTS_BY_PROGRAM ({ commit }, params) {
    commit(GET_PATIENTS_LOADING, true)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_PATIENTS_BY_PROGRAM.replace('{uuid}', params.uuid)
      axios.get((url), {
        params: { paginate: params.paginate },
        cancelToken: createNewCancelTokenRequest('patientListData')
      }).then((response) => {
        if (!response.status) { return }
        if (response.data.error) {
          return reject(response.data.error)
        }
        response.data.program_uuid = params.uuid
        commit(GET_PATIENTS_BY_PROGRAM, response.data)
        commit(GET_PATIENTS_LOADING, false)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  GET_PATIENTS_NEW_RESULTS ({ commit }, params) {
    return new Promise((resolve, reject) => {
      params = decorateParamsWithPatientSelector(params)
      params.activeStatus = null
      const dateFormat = 'YYYYMMDD'
      const dateFrom = moment().startOf('day').add(-7, 'days').utc().format(dateFormat)
      const dateTo = moment().endOf('day').utc().format(dateFormat)
      const computedEndpoint = ENDPOINTS.GET_PATIENTS_NEW_RESULTS.replace('{startDate}', dateFrom).replace('{endDate}', dateTo)
      axios.get(computedEndpoint, {
        params: params,
        cancelToken: createNewCancelTokenRequest('patientListData')
      }).then((response) => {
        if (response.data.error) {
          return reject(response.data.error)
        }
        const r = {
          data: response.data.patients,
          params
        }
        const count = response.data.count
        commit(GET_PATIENTS_COUNT, count)
        commit(SET_PATIENTS_NEW_RESULTS, r)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  GET_PATIENT ({ commit }, patientId) {
    if (patientId) {
      commit(LOADING_GET_PATIENT, true)
      return new Promise((resolve, reject) => {
        if (state.singlePatient && state.singlePatient.id === parseInt(patientId)) {
          resolve()
        }
        axios.get(ENDPOINTS.PATIENT.replace('{patientId}', patientId)).then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            response.data.middle_name = response.data.middle_name.trim().length === 0 ? '' : response.data.middle_name
            const arraysToCheck = JSON.parse(JSON.stringify([response.data.clinicians, response.data.clinician_teams, response.data.clinician_teams_programs]))
            const notEmpty = (element) => element.length > 0
            const result = arraysToCheck.some(notEmpty)
            commit(GET_PATIENT, response.data)
            commit(LOADING_GET_PATIENT, false)
            commit(GET_CLINICIAN_ASSIGNMENTS, result)
            resolve(response)
          }
        }).catch((error) => {
          reject(error)
          commit(GET_PATIENT, { gender: '' })
        })
      })
    }
    return commit(GET_PATIENT, { gender: '' })
  },

  GET_ASSIGNMENTS ({ commit }, id) {
    commit(GET_ASSIGNMENTS_LOADING, true)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_ASSIGNMENTS
        .replace('{ccau_id}', id)
      axios.get(url)
        .then((response) => {
          commit(GET_ASSIGNMENTS, response.data)
          commit(GET_ASSIGNMENTS_LOADING, false)
          resolve()
        })
        .catch((error) => {
          commit(GET_ASSIGNMENTS, {})
          reject(error.response)
        })
    })
  },

  GET_PATIENT_INSURANCE ({ commit }, patientId) {
    return new Promise((resolve, reject) => {
      commit(GET_PATIENT_INSURANCE_LOADING, true)
      axios.get(ENDPOINTS.GET_PATIENT_INSURANCE.replace('{patient_user_id}', patientId)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(SET_PATIENT_INSURANCE, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(GET_PATIENT_INSURANCE_LOADING, false)
      })
    })
  },

  SET_PATIENT_INSURANCE ({ commit }, params) {
    commit(SET_PATIENT_INSURANCE_LOADING, true)
    const url = ENDPOINTS.PUT_PATIENT_INSURANCE.replace('{patient_user_id}', params.patient_user_id)
    delete params.patient_user_id
    return axios.put(url, params).then((response) => {
      commit(SET_PATIENT_INSURANCE, response.data)
      return response
    }).catch((error) => {
      return error.response
    }).finally(() => {
      commit(SET_PATIENT_INSURANCE_LOADING, false)
    })
  },

  RESET_PATIENT ({ commit }) {
    commit(RESET_PATIENT)
  },

  GET_SCHEDULED_PATIENTS_BY_DATE ({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(SET_SCHEDULED_PATIENTS_BY_DATE_LOADING, true)
      const url = ENDPOINTS.GET_SCHEDULED_PATIENTS_BY_DATE.replace('{startDate}', params.startDate).replace('{endDate}', params.endDate)
      delete params.startDate
      delete params.endDate
      axios.get(url, { params: params }).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_SCHEDULED_PATIENTS_BY_DATE, response.data)
          commit(GET_SCHEDULED_PATIENTS_BY_SINGLE_DATE, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_SCHEDULED_PATIENTS_BY_DATE_LOADING, false)
      })
    })
  },
  GET_SINGLE_PATIENT_PROGRAMS ({ commit }, patientId) {
    return new Promise((resolve, reject) => {
      commit(SET_SINGLE_PATIENT_PROGRAMS_LOADING, true)
      axios.get(ENDPOINTS.GET_SINGLE_PATIENT_PROGRAMS.replace('{patientId}', patientId)).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_SINGLE_PATIENT_PROGRAMS, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_SINGLE_PATIENT_PROGRAMS_LOADING, false)
      })
    })
  },

  GET_SINGLE_PATIENT_PROGRAM ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_SINGLE_PATIENT_PROGRAM.replace('{patientId}', params.patientId).replace('{accessControlProgramId}', params.accessControlProgramId)
      axios.get(url).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(GET_SINGLE_PATIENT_PROGRAM, response.data)
          resolve()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  UPDATE_SINGLE_PATIENT_PROGRAM ({ commit }, data) {
    commit(UPDATE_SINGLE_PATIENT_PROGRAM, data)
  },

  SET_SINGLE_PATIENT_REFERRER_ROUTE ({ commit }, data) {
    commit(SET_SINGLE_PATIENT_REFERRER_ROUTE, data)
  },

  CREATE_PATIENT ({ commit }, patient) {
    return submitPatient(patient, ENDPOINTS.PATIENTS, 'post', commit)
  },

  UPDATE_PATIENT ({ commit }, patient) {
    const updatePatient = { ...patient }
    updatePatient.status = updatePatient.status === 'active'
    return submitPatient(updatePatient, ENDPOINTS.PATIENT.replace('{patientId}', updatePatient.id), 'put', commit)
  },

  VALIDATE_MRN (actions, patient) {
    const data = { mrn: patient.mrn }
    if (patient.id) {
      data.patient_id = patient.id
    }
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.VALIDATE_MRN, { params: data })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  VALIDATE_UNIC_RESPONDENT (actions, data) {
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.VALIDATE_UNIC_RESPONDENT, { params: data })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  CREATE_RESPONDENT ({ commit }, respondent) {
    return submitRespondent(respondent, ENDPOINTS.RESPONDENTS, 'post', commit)
  },

  UPDATE_RESPONDENT ({ commit }, respondent) {
    return submitRespondent(respondent, ENDPOINTS.RESPONDENT.replace('{respondentId}', respondent.id), 'put', commit)
  },

  DELETE_PROGRAM_ASSIGNATION ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.DELETE_PROGRAM_ASSIGNATION
        .replace('{accessControlId}', data.access_control_id)
        .replace('{accessControlProgramId}', data.access_control_program_id)
      const promise = axios.delete(url)
      promise.then((response) => {
        if (response.data.error) {
          reject(response.data.error)
        } else {
          commit(DELETE_PROGRAM_ASSIGNATION, data.access_control_program_id)
          resolve(response.data)
        }
      })
      promise.catch((error) => {
        reject(error)
      })
    })
  },

  GET_RESPONDENT_ASSIGNED_MEASURES_LIST ({ commit }, ccauId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_RESPONDENT_ASSIGNED_MEASURES_LIST
        .replace('{ccauId}', ccauId)
      axios.get(url).then((response) => {
        if (response.data.error) {
          commit(GET_RESPONDENT_ASSIGNED_MEASURES_LIST, [])
          reject(response.data.error)
        } else {
          commit(GET_RESPONDENT_ASSIGNED_MEASURES_LIST, response.data)
          resolve()
        }
      }).catch((error) => {
        commit(GET_RESPONDENT_ASSIGNED_MEASURES_LIST, [])
        reject(error)
      })
    })
  },

  REMOVE_RESPONDENT_FROM_TEAM ({ commit, rootState }, ccauId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.REMOVE_RESPONDENT_FROM_TEAM.replace('{ccauId}', ccauId)
      axios.delete(url).then((response) => {
        if (response.data.error || !response.data.result) {
          return reject(response.data.error)
        }

        const data = {
          result: response.data.result,
          rootState: rootState
        }
        commit(REMOVE_RESPONDENT_FROM_TEAM, data)
        resolve(response.data.result.hasMeasures)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_AUDITION_ANSWERS_REPORT ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_AUDITION_ANSWERS_REPORT.replace('{measureId}', params.measureId)
      axios.post(url, params).then((response) => {
        commit(GET_MEASURE_QUESTIONS, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_MEASURE_QUESTIONS, null)
        reject(error.response)
      })
    })
  },

  POST_PDF_STATUS ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.POST_PDF_STATUS
      axios.post(url, params).then((response) => {
        commit(SET_PDF_STATUS, response.data)
        resolve()
      })
        .catch((error) => {
          reject(error.response)
        })
    })
  }

}
