import outcomesActions from './outcomes-explorer/actions'
import outcomesMutations from './outcomes-explorer/mutations'
import outcomesGetters from './outcomes-explorer/getters'
import outcomesState from './outcomes-explorer'
import usageActions from './usage/actions'
import usageMutations from './usage/mutations'
import usageGetters from './usage/getters'
import usageState from './usage'

export function initialState () {
  return {
    ...outcomesState,
    ...usageState
  }
}

// initial state
const state = initialState()

export default {
  state: {
    ...state
  },
  actions: {
    ...outcomesActions,
    ...usageActions
  },
  getters: {
    ...outcomesGetters,
    ...usageGetters
  },
  mutations: {
    ...outcomesMutations,
    ...usageMutations
  }
}
