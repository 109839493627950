export const GET_EXPORTS = 'exports/GET_EXPORTS'
export const GET_EXPORTS_LOADING = 'exports/GET_EXPORTS_LOADING'
export const CREATE_EXPORT = 'exports/CREATE_EXPORT'
export const CREATE_EXPORT_LOADING = 'exports/CREATE_EXPORT_LOADING'
export const DELETE_EXPORT = 'exports/DELETE_EXPORT'
export const DOWNLOAD_EXPORT = 'exports/DOWNLOAD_EXPORT'
export const SHOW_EXPORT_TOOLTIP = 'exports/SHOW_EXPORT_TOOLTIP'
export const EXPORTS_LOGOUT = 'exports/EXPORTS_LOGOUT'

export const EXPORTS_STATUSES = {
  pending: 'in_process',
  received: 'received',
  successful: 'completed',
  failed: 'error'
}

export const ENDPOINTS = {
  GET_EXPORTS: 'analytics/export/user-status',
  DOWNLOAD_EXPORT: 'analytics/export/download/{id}',
  CREATE_EXPORT: 'analytics/generate-export-file',
  DELETE_EXPORT: 'analytics/export/status/{id}'
}
