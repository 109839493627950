import axios from 'axios'
import { MEASURE_STATUS_SCHEDULED, MEASURE_STATUS_INCOMPLETE } from '@/data/clientMeasureStatus'
import {
  SIGN_OUT,
  GET_RESPONDENT_LATEST_SESSION,
  GET_RESPONDENT_LATEST_SESSION_LOADING,
  GET_TEAM_LATEST_SESSION,
  UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON,
  SET_LAST_SESSION_DETAIL,
  GET_TEAM_MEASURE_SESSION,
  ENDPOINTS
} from './constants'
import {
  updateUserProfile
} from '@/mixins/Common/userHelperFuncions.js'

function initialState () {
  return {
    respondentLatestSession: {},
    respondentLatestSessionLoading: false,
    teamLatestSession: {},
    lastSessionDetail: null
  }
}

function populateTeamDetailMeasures (data) {
  const r = sortByField(data, 'patient_relation_id', 1)
  for (let i = 0; i < r.length; i++) {
    const u = r[i]
    u.pending_sessions.sort((a, b) => !a.date ? -1 : a.date.localeCompare(b.date))
    u.have_available_measures = !u.pending_sessions || !u.pending_sessions.length ? false : u.pending_sessions.some(s => s.available_to_login && s.measures.some((m) => [MEASURE_STATUS_SCHEDULED, MEASURE_STATUS_INCOMPLETE].indexOf(m.status) > -1))
    u.latest_complete.measures = u.latest_complete.measures && typeof u.latest_complete.measures === 'string' ? JSON.parse(u.latest_complete.measures) : u.latest_complete.measures
  }
  return r
}
function sortByField (array, field, compareValue) {
  return [...array].sort((a, b) => a[field] == compareValue ? -1 : b[field] == compareValue ? 1 : 0) // eslint-disable-line eqeqeq
}

// initial state
const state = initialState()
// actions
const actions = {
  GET_RESPONDENT_LATEST_SESSION ({ commit }, params) {
    commit(GET_RESPONDENT_LATEST_SESSION_LOADING, true)
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.GET_RESPONDENT_LATEST_SESSION.replace('{ccauId}', params.ccauId).replace('{programId}', 0)
      axios.get(url)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_RESPONDENT_LATEST_SESSION, response.data)
            resolve(response.data)
          }
          commit(GET_RESPONDENT_LATEST_SESSION_LOADING, false)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  GET_TEAM_LATEST_SESSION ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const programId = params.programId || 0
      params.cca_id = params.ccaId
      const endpoint = params.programId ? ENDPOINTS.GET_TEAM_LATEST_SESSION : ENDPOINTS.GET_TEAM_LATEST_SESSION_WITHOUT_PROGRAM
      const url = endpoint.replace('{ccaId}', params.ccaId).replace('{programId}', programId)
      axios.get(url)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_TEAM_LATEST_SESSION, response.data)
            resolve(response.data)
          }
        }).catch((error) => {
          reject(error)
        })
    })
  },
  GET_TEAM_MEASURE_SESSION ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      const requestParameters = {
        session_due_date: parameters.sessionDueDate
      }

      const url = ENDPOINTS.GET_TEAM_MEASURE_SESSION.replace('{ccaId}', parameters.assignmentId)
      axios.get(url, { params: requestParameters }).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return void 0 // eslint-disable-line no-void
        }

        commit(GET_TEAM_MEASURE_SESSION, response.data)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  RESET_RESPONDENT_LAST_SESSION ({ commit }) {
    commit(GET_RESPONDENT_LATEST_SESSION, null)
  },
  RESET_LAST_SESSION ({ commit }) {
    commit(GET_TEAM_LATEST_SESSION, null)
    commit(SET_LAST_SESSION_DETAIL, null)
  }
}

// getters
const getters = {
  getRespondentLatestSession: state => state.respondentLatestSession,
  getRespondentLatestSessionLoading: state => state.respondentLatestSessionLoading,
  getTeamLatestSession: state => state.teamLatestSession,
  getTeamMeasureSession: state => state.teamLatestSession,
  getLastSessionDetail: state => state.lastSessionDetail
}

// mutations
const mutations = {
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [GET_RESPONDENT_LATEST_SESSION] (state, data) {
    state.respondentLatestSession = data
  },
  [GET_RESPONDENT_LATEST_SESSION_LOADING] (state, data) {
    state.respondentLatestSessionLoading = data
  },
  [GET_TEAM_LATEST_SESSION] (state, data) {
    state.teamLatestSession = data ? populateTeamDetailMeasures(data) : data
  },
  [UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON] (state, data) {
    if (state.teamLatestSession && state.teamLatestSession.length) {
      const index = state.teamLatestSession.findIndex(ls => parseInt(ls.user_id) === parseInt(data.id))
      if (index > -1) {
        updateUserProfile(state.teamLatestSession[index], data, UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON)
      }
    }
  },
  [GET_TEAM_MEASURE_SESSION] (state, data) {
    state.teamLatestSession = data ? sortByField(data, 'patient_relation_id', 1) : data
  },
  [SET_LAST_SESSION_DETAIL] (state, data) {
    state.lastSessionDetail = data
  }
}

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
