import {
  MUTATIONS,
  LOCAL_STORAGE_USER_PROGRAM_OPENED,
  LOCAL_STORAGE_CLINICIAN_ALL_PROGRAMS,
  LOCAL_STORAGE_CLINICIAN_MY_PROGRAMS
} from './constants'
import { initialState } from './index'
import { LOCAL_STORAGE_USER_KEY_NAME } from '@/store/modules/security/constants'
import {
  updateUserProfile
} from '@/mixins/Common/userHelperFuncions.js'
const STATES_TO_CHANGE = ['myProgramsCollection', 'allProgramsCollection']

export default {
  [MUTATIONS.resetState] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [MUTATIONS.setMyPrograms] (state, data) {
    state.myProgramsCollection = data
  },
  [MUTATIONS.setAllPrograms] (state, data) {
    state.allProgramsCollection = data
  },
  [MUTATIONS.setProgramActivePatients] (state, data) {
    const program = state[data.stateIndex] ? state[data.stateIndex].find((p) => p.id === data.programId) : false
    if (program) {
      program.patients = data.patients
      program.patientTotalRecords = data.totalRecords
      program.loading = false
      program.loaded = true
    }
  },
  [MUTATIONS.setPatientsBatchToLoad] (state, isExplorer) {
    state.patientsBatchToLoad = isExplorer ? 10 : 20
  },
  [MUTATIONS.increasePatientsToShow] (state, program) {
    program.patientsShowing += state.patientsBatchToLoad
    state.patientsShowingByProgram[program.id] = program.patientsShowing
  },
  [MUTATIONS.highlightPatient] (state, params) {
    const tab = params.programsTab ? params.programsTab : state.programsTab
    const programs = tab === 'my' ? state.myProgramsCollection : state.allProgramsCollection
    if (programs && programs.length) {
      programs.forEach(program => {
        program.patients.forEach(patient => {
          patient.highlighted = parseInt(params.programId) === parseInt(program.id) && parseInt(patient.access_control_id) === parseInt(params.patient.access_control_id)
        })
      })
    }
  },
  [MUTATIONS.markProgramAsLoading] (state, params) {
    params.program.loading = params.value
  },
  async [MUTATIONS.toggleProgramAccordion] (state, data) {
    const isAll = data.tab === 'all'
    const indexReference = isAll ? LOCAL_STORAGE_CLINICIAN_ALL_PROGRAMS : LOCAL_STORAGE_CLINICIAN_MY_PROGRAMS
    const collection = isAll ? state.allProgramsCollection : state.myProgramsCollection
    data.program.isOpen = !data.program.isOpen
    data.program.loadPatients = data.program.isOpen
    await updateClinicianProgramsOpened(collection, indexReference)
    return true
  },
  [MUTATIONS.setJustAddedPatientData] (state, data) {
    state.justAddedPatientData = data
  },
  [MUTATIONS.addJustAddedToActiveProgram] (state, patientData) {
    modifyProgramPatients(state, patientData, 3)
  },
  [MUTATIONS.updatePatientInProgram] (state, patientData) {
    modifyProgramPatients(state, patientData, 1)
  },
  [MUTATIONS.removePatientFromProgram] (state, params) {
    modifyProgramPatients(state, params, 2)
  },
  [MUTATIONS.changeObjectAttributes] (state, params) {
    if (state.hasOwnProperty(params.object) && state[params.object]) { // eslint-disable-line no-prototype-builtins
      state[params.object][params.attribute] = params.value
    }
  },

  [MUTATIONS.updateProgramsPatientFromSignInButton] (state, data) {
    for (let i = 0, len = STATES_TO_CHANGE.length; i < len; i++) {
      if (state[STATES_TO_CHANGE[i]]) {
        updateProgramPatientDataFromSignInButtonUpdate(state[STATES_TO_CHANGE[i]], data)
      }
    }
  },
  // Todo: We need to expose the RemoteTable logic to generate params to avoid doing this
  // hack using the watcher of patientsByProgram table, and instead
  // Call directly the action ACTIONS.getProgramActivePatients
  [MUTATIONS.reloadPatientsInAllPrograms] (state, params) {
    for (let i = 0, len = STATES_TO_CHANGE.length; i < len; i++) {
      if (state[STATES_TO_CHANGE[i]] && state[STATES_TO_CHANGE[i]].length) {
        state[STATES_TO_CHANGE[i]].forEach((program) => {
          if (program.isOpen) {
            program.loadPatients = params.value
          }
        })
      }
    }
  }
}

/**
 * Update or Remove an entry on the Store Data for program List
 * used after discharge actions.
 *
 * @param state
 * @param params
 * @param action
 */
function modifyProgramPatients (state, params, action) {
  for (let i = 0, len = STATES_TO_CHANGE.length; i < len; i++) {
    updateStateIndexForPatientsByProgramList(state, params, action, STATES_TO_CHANGE[i])
  }
}

/**
 * Update/Delete entries in the Program List based on State Index based on given action
 *
 * @param state
 * @param params
 * @param action
 * @param stateIndex
 */
function updateStateIndexForPatientsByProgramList (state, params, action, stateIndex) {
  if (!state[stateIndex]) {
    return false
  }

  const programs = state[stateIndex]
  const changedProgramIndex = state[stateIndex].findIndex((program) => parseInt(program.id) === parseInt(params.program_id))
  if (changedProgramIndex !== -1 && programs[changedProgramIndex].isOpen) {
    // Create entry.
    if (action === 3) {
      programs[changedProgramIndex].patients.unshift(params)
    } else {
      const index = programs[changedProgramIndex].patients.findIndex((patient) => {
        return parseInt(params.access_control_id) === parseInt(patient.access_control_id)
      })

      // Update entry
      if (index && action === 1) {
        const keys = Object.keys(state[stateIndex][changedProgramIndex].patients[index])
        for (let i = 0, len = keys.length; i < len; i++) {
          const attribute = keys[i]
          if (params.hasOwnProperty(attribute)) { // eslint-disable-line no-prototype-builtins
            state[stateIndex][changedProgramIndex].patients[index][attribute] = params[attribute]
          }
        }
      }

      // Delete entry
      if (index && action === 2) {
        programs[changedProgramIndex].patients.splice(index, 1)
      }
    }
  }
}
function updateProgramPatientDataFromSignInButtonUpdate (programs, patientUserData) {
  programs.forEach(program => {
    const index = program.patients.findIndex(p => parseInt(p.access_control_id) === parseInt(patientUserData.id))
    if (index > -1) {
      updateUserProfile(program.patients[index], patientUserData, MUTATIONS.updateProgramsPatientFromSignInButton)
    }
  })
}
/**
 * Save in the local storage the elements that the current user has opened
 * on the Program List.
 *
 * @param patientsByPrograms
 * @param stateIndex
 * @returns {Promise<void>}
 */
async function updateClinicianProgramsOpened (patientsByPrograms, stateIndex) {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY_NAME))
  if (!user) {
    return
  }

  let storagePrograms = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_PROGRAM_OPENED))
  const programs = Object.values(patientsByPrograms).map(p => {
    return {
      id: p.id,
      isOpen: p.isOpen
    }
  })

  if (!storagePrograms) {
    storagePrograms = {}
  }
  if (!storagePrograms[user.id]) {
    storagePrograms[user.id] = {}
  }
  if (!storagePrograms[user.id][stateIndex]) {
    storagePrograms[user.id][stateIndex] = {}
  }
  storagePrograms[user.id][stateIndex] = programs
  localStorage.setItem(LOCAL_STORAGE_USER_PROGRAM_OPENED, JSON.stringify(storagePrograms))
}
