// Mutation types
export const SET_PROGRAMS_TABS = 'programs/SET_PROGRAMS_TABS'
export const GET_PROGRAMS = 'programs/GET_PROGRAMS'
export const GET_PROGRAM_LIST_BY_ROLE = 'programs/GET_PROGRAM_LIST_BY_ROLE'
export const GET_PROGRAM_LIST_BY_ROLE_LOADING = 'programs/GET_PROGRAM_LIST_BY_ROLE_LOADING'
export const GET_PROGRAM = 'programs/GET_PROGRAM'
export const GET_PROGRAM_LOADING = 'programs/GET_PROGRAM_LOADING'
export const GET_PROGRAMS_ONLY = 'programs/GET_PROGRAMS_ONLY'
export const GET_PROGRAM_TYPES = 'programs/GET_PROGRAM_TYPES'
export const GET_PROGRAM_MEASURES = 'programs/GET_PROGRAM_MEASURES'
export const SIGN_OUT = 'programs/SIGN_OUT'
export const PROGRAMS_TABS = ['my', 'all', 'programs']
export const CREATE_ASSIGNMENT = 'programs/CREATE_ASSIGNMENT'
export const UPDATE_ASSIGNMENT = 'programs/UPDATE_ASSIGNMENT'
export const DELETE_PROGRAM_MEASURE = 'programs/DELETE_PROGRAM_MEASURE'
export const PROGRAM_JUST_ADDED = 'programs/PROGRAM_JUST_ADDED'
export const RESET_PROGRAM_JUST_ADDED = 'programs/RESET_PROGRAM_JUST_ADDED'
export const PRE_SELECTED_PROGRAM = 'programs/PRE_SELECTED_PROGRAM'
export const PROGRAMS_SEARCH = 'programs/PROGRAMS_SEARCH'
export const GET_SETTINGS_PROGRAMS = 'programs/GET_SETTINGS_PROGRAMS'
export const CREATE_SETTINGS_PROGRAM = 'programs/CREATE_SETTINGS_PROGRAM'
export const UPDATE_SETTINGS_PROGRAM = 'programs/UPDATE_SETTINGS_PROGRAM'
export const PATIENTS_NOPROGRAM_ID = 'patientsnoprogram'
export const GET_SELECTED_PROGRAM = 'programs/GET_SELECTED_PROGRAM'
export const SET_SELECTED_PROGRAM = 'programs/SET_SELECTED_PROGRAM'

export const ENDPOINTS = {
  // Program group feature is disabled for now
  GET_SETTINGS_PROGRAMS: 'programs',
  GET_PROGRAMS: 'programs/get-all-programs?excludeGroups=true',
  GET_PROGRAM_LIST_BY_ROLE: 'programs/by-role',
  GET_PROGRAMS_ONLY: 'programs/get-all-programs?excludeGroups=true',
  GET_PROGRAM_TYPES: '/program-types',
  GET_PROGRAM: 'programs/{programId}',
  GET_PATIENT_NO_PROGRAM: 'programs/patients/no-program',
  PROGRAM: 'programs',
  PROGRAM_MEASURES: 'programs/{programId}/measures',
  CREATE_ASSIGNMENT: 'programs/{programId}/create-assignment',
  UPDATE_ASSIGNMENT: 'patients/{patientId}/programs/{accessControlProgramId}',
  DELETE_PROGRAM_MEASURE: 'programs/{programId}/measures/{measureId}',
  DISCHARGE_FROM_PROGRAM: 'patients/{patientId}/programs/{accessControlProgramId}/discharge'
}
