import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'

/**
 * Handle construction of Analytics Max LOT Chart Model.
 */
export class AnalyticsMaxLotChartModel extends AnalyticsChartModel {
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    super(queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure)
    this.title = this.getChartTitleOrDefault(queryChartConfiguration, programData, queryResult, measure)
  }

  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'maxLot'
  }

  getChartSubdivisionKey () {
    return 'patientStatusOutpatient'
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program) {
    return `${program.name} ${i18n.messages[i18n.locale].lengthOfTreatment}`
  }

  /**
   * Get Default Chart X Axis.
   */
  getXAxis (queryResult) {
    const categories = this.getXAxisCategoriesNoPrefix(queryResult)
    const labels = this.getXAxisLabels()
    return {
      categories,
      labels
    }
  }

  /**
  * Get Default Chart Y Axis.
  */
  getYAxis (queryResult) {
    const max = this.getYAxisMax(queryResult)
    return {
      max: max,
      min: 0,
      tickPositions: this.getYAxisTickPositions(max),
      title: {
        text: i18n.messages[i18n.locale].numberOfPatients,
        margin: 20,
        style: this.getDefaultStyleObject(14)
      }
    }
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    const name = i18n.messages[i18n.locale].weeks
    const data = []

    for (const key in queryResult) {
      data.push(queryResult[key])
    }
    return [{ name, data }]
  }
}
