import { sortByRecentlyUpdatedCallback } from '@/mixins/Common/sortingFunctions'
import Roles from '@/data/roles'
import {
  GET_CLINICAL_USERS,
  GET_ACTIVE_SUPERVISORS_LOADING,
  GET_SUPERVISORS,
  DELETE_CLINICAL_USER,
  UPDATE_CLINICAL_USER,
  UPDATE_USER_SUPERIORS_AND_SUBORDINATES,
  CHANGE_STATUS,
  SAVE_CLINICAL_USER,
  SORT_SUPERVISORS_BY_RECENTLY_SELECTED
} from './constants'
import { CHANNEL_EMAIL } from '@/data/communicationChannels.js'

export default {
  [GET_CLINICAL_USERS]: (state, clinicalUsers) => {
    state.clinicalUsers = clinicalUsers ? clinicalUsers.map(cu => standarizeClinicalUser(cu)) : []
  },

  [GET_ACTIVE_SUPERVISORS_LOADING]: (state, value) => {
    state.supervisorsLoading = value
  },

  [GET_SUPERVISORS]: (state, supervisors) => {
    supervisors.forEach(su => {
      su.recentlySelected = false
    })
    state.supervisors = supervisors.sort(sortByRecentlyUpdatedCallback)
  },

  [UPDATE_CLINICAL_USER]: (state, clinicalUser) => {
    clinicalUser = standarizeClinicalUser(clinicalUser)
    const i = state.clinicalUsers.findIndex(l => Number(l.id) === Number(clinicalUser.id))
    if (i !== -1) {
      const oldClinicalUser = state.clinicalUsers[i]
      for (const k in oldClinicalUser) {
        if (oldClinicalUser.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
          oldClinicalUser[k] = clinicalUser[k]
        }
      }
    }
  },

  [UPDATE_USER_SUPERIORS_AND_SUBORDINATES]: (state, clinicalUser) => {
    const clinicalUserId = `${clinicalUser.id}`
    for (let i = 0; i < state.clinicalUsers.length; i++) {
      const user = state.clinicalUsers[i]
      const userIsNewSubordinate = clinicalUser.subordinates.includes(user.id) && !user.superiors.includes(clinicalUserId)
      const userIsNewSuperior = clinicalUser.superiors.includes(user.id) && !user.subordinates.includes(clinicalUserId)
      const userWasSubordinate = !clinicalUser.subordinates.includes(user.id) && user.superiors.includes(clinicalUserId)
      const userWasSuperior = !clinicalUser.superiors.includes(user.id) && user.subordinates.includes(clinicalUserId)

      if (userIsNewSubordinate) {
        user.superiors.push(clinicalUserId)
      }
      if (userIsNewSuperior) {
        user.subordinates.push(clinicalUserId)
      }
      if (userWasSubordinate) {
        const i = user.superiors.findIndex(u => Number(u.id) === Number(clinicalUserId))
        user.superiors.splice(i, 1)
      }
      if (userWasSuperior) {
        const i = user.subordinates.findIndex(u => Number(u.id) === Number(clinicalUserId))
        user.subordinates.splice(i, 1)
      }
    }
  },

  [CHANGE_STATUS]: (state, userData) => {
    const i = state.clinicalUsers.findIndex(l => Number(l.id) === Number(userData.id))
    if (i !== -1) {
      state.clinicalUsers[i].status = userData.status
      state.clinicalUsers[i].account_information = userData.account_information
    }
  },

  [DELETE_CLINICAL_USER]: (state, clinicalUser) => {
    const i = state.clinicalUsers.findIndex(l => Number(l.id) === Number(clinicalUser.id))
    if (i !== -1) {
      state.clinicalUsers.splice(i, 1)
    }
  },

  [SAVE_CLINICAL_USER]: (state, clinicalUser) => {
    clinicalUser = standarizeClinicalUser(clinicalUser)
    state.clinicalUsers.unshift(clinicalUser)
  },

  [SORT_SUPERVISORS_BY_RECENTLY_SELECTED]: (state) => {
    state.supervisors = state.supervisors.sort(sortByRecentlyUpdatedCallback)
  }
}

const standarizeClinicalUser = (user) => {
  user.invited = Number(!!user.invited)
  if (!user.contact_information) {
    user.contact_information = [{
      channel: CHANNEL_EMAIL,
      value: user.email
    }]
  }
  if (!user.person_information) {
    user.person_information = {
      access_control_id: user.id,
      full_name: user.name,
      name_first: user.first_name,
      name_last: user.last_name,
      user_name: user.user_name
    }
  }
  user.roles = Roles.filter(r => user.roles.includes(`${r.id}`)).map(r => r.id)
  return user
}
