import {
  MUTATION_SET_MODAL_OPENED,
  PATIENT_ROW_ACTIVE,
  MUTATION_SET_MODAL_TEMPLATE,
  MUTATION_SET_ENHANCE_AUDITION_DATA,
  MUTATION_SET_MODAL_CONFIG,
  CONTACT_INFO_DETAIL,
  UPDATE_CONTACT_INFO_DETAIL,
  MUTATION_SET_ENHANCE_AUDITION_VARIABLES,
  UPDATE_RECENTLY_SELECTED,
  UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA,
  HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT,
  CHANGE_SETUP_DONE_PROMISE_VALUE
} from './constants'

export default {
  [PATIENT_ROW_ACTIVE]: (state, value) => {
    state.patientUserRowActive = value
  },

  [MUTATION_SET_MODAL_OPENED]: (state, value) => {
    state.customModalIsOpened = value
  },
  [MUTATION_SET_MODAL_TEMPLATE]: (state, template) => {
    state.customModalTemplate = template
  },
  [MUTATION_SET_ENHANCE_AUDITION_DATA]: (state, data) => {
    state.enhanceAuditionModalData = data
  },
  [MUTATION_SET_MODAL_CONFIG]: (state, config) => {
    state.modalConfig = config
  },
  [UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA]: (state, data) => {
    const user = state.modalConfig && state.modalConfig.user ? state.modalConfig.user : null
    if (data.channel === 1 && user && user.contact_email && user.contact_email.value && data.value) {
      state.modalConfig.user.contact_email.value = data.value
      return true
    }

    if (data.channel === 2 && user && user.contact_phone && user.contact_phone.value && data.value) {
      state.modalConfig.user.contact_phone.value = data.value
      return true
    }
  },
  [MUTATION_SET_ENHANCE_AUDITION_VARIABLES]: (state, data) => {
    state.enhanceAuditionVariables = data
  },
  [CONTACT_INFO_DETAIL]: (state, data) => {
    state.conctactInfoDetail = data
  },
  [UPDATE_CONTACT_INFO_DETAIL]: (state, data) => {
    if (state.conctactInfoDetail && parseInt(state.conctactInfoDetail.id) === parseInt(data.id)) {
      const email = state.conctactInfoDetail.contact_information.find(i => parseInt(i.channel) === 1)
      const phone = state.conctactInfoDetail.contact_information.find(i => parseInt(i.channel) === 2)
      phone.value = data.mobile_number
      email.value = data.email
    }
  },
  [UPDATE_RECENTLY_SELECTED] (state, data) {
    data.model.recentlySelected = data.value
  },

  [HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT] (state, data) {
    if (state.conctactInfoDetail && parseInt(data.alertId) === parseInt(state.conctactInfoDetail.alertId)) {
      state.conctactInfoDetail = null
    }
  },
  [CHANGE_SETUP_DONE_PROMISE_VALUE] (state, promise) {
    state.setupDonePromise = promise
  }
}
