import axios from 'axios'
import { decorateParamsWithPatientSelector } from '@/mixins/PatientSelector/PatientSelectorHelper'
import { sortByLastNameAndFirstName } from '@/mixins/Common/sortingFunctions'
import {
  SIGN_OUT,
  GET_APPOINTMENTS_BY_DATE,
  GET_PATIENTS_BY_DUE_DATE,
  UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON,
  ENDPOINTS
} from './constants.js'
import {
  updateUserProfile
} from '@/mixins/Common/userHelperFuncions.js'

function filterAndGetRemaining (array, filterField) {
  // Extract patients with alert and sort
  const filtered = array.filter(pi => parseInt(pi[filterField])).sort(sortByLastNameAndFirstName)
  // grab the remaining patients
  const filteredIds = filtered.map(pi => pi.access_control_id)
  const remaining = array.filter(pi => !filteredIds.includes(pi.access_control_id))
  return { remaining, filtered }
}

function initialState () {
  return {
    clinicianAppointmentsByDate: [],
    patientsByDueDate: []
  }
}

// initial state
const state = initialState()

// actions
const actions = {
  GET_APPOINTMENTS_BY_DATE ({ commit }, params) {
    params = decorateParamsWithPatientSelector(params)
    params.activeStatus = null
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_APPOINTMENTS_BY_DATE, { params: params })
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            commit(GET_APPOINTMENTS_BY_DATE, response.data)
            resolve()
          }
        }).catch((error) => {
          commit(GET_APPOINTMENTS_BY_DATE, [])
          reject(error)
        })
    })
  },
  GET_PATIENTS_BY_DUE_DATE ({ commit }, parameters) {
    parameters = decorateParamsWithPatientSelector(parameters)
    parameters.activeStatus = null
    return new Promise((resolve, reject) => {
      axios.get(ENDPOINTS.GET_PATIENTS_BY_DUE_DATE, { params: parameters }).then((response) => {
        if (response.data.error) {
          reject(response.data.error)
          return false
        }

        commit(GET_PATIENTS_BY_DUE_DATE, response.data)
        resolve()
      }).catch((error) => {
        commit(GET_PATIENTS_BY_DUE_DATE, [])
        reject(error)
      })
    })
  }
}

// getters
const getters = {
  getClinicianAppointmentsByDate: state => state.clinicianAppointmentsByDate,
  getPatientsByDueDate: state => state.patientsByDueDate
}

// mutations
const mutations = {
  [GET_APPOINTMENTS_BY_DATE] (state, appointments) {
    state.clinicianAppointmentsByDate = appointments.sort((a, b) => a.visit_date_time.localeCompare(b.visit_date_time))
  },
  [GET_PATIENTS_BY_DUE_DATE] (state, patientInformation) {
    const patientsWithAlerts = filterAndGetRemaining(patientInformation, 'alert_count')
    patientInformation = patientsWithAlerts.remaining

    const patientsWithIncompleteMEasures = filterAndGetRemaining(patientInformation, 'uncomplete_measures')
    patientInformation = patientsWithIncompleteMEasures.remaining

    state.patientsByDueDate = [].concat(patientsWithAlerts.filtered, patientsWithIncompleteMEasures.filtered, patientInformation)
  },

  [UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON] (state, data) {
    const index = state.patientsByDueDate.findIndex(p => parseInt(p.access_control_id) === parseInt(data.id))
    if (index > -1) {
      updateUserProfile(state.patientsByDueDate[index], data, UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON)
    }
  },

  [UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON] (state, data) {
    const index = state.clinicianAppointmentsByDate.findIndex(a => parseInt(a.patient.id) === parseInt(data.id))
    if (index > -1) {
      updateUserProfile(state.clinicianAppointmentsByDate[index], data, UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON)
    }
  },
  [SIGN_OUT] (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
