import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export function initialState () {
  return {
    respondents: []
  }
}

// initial state
const state = initialState()

export default {
  state: {
    ...state
  },
  actions,
  getters,
  mutations
}
