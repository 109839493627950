import { VALIDATE_PROVIDER_ID } from '@/store/modules/clinicalUsers/constants.js'
export const ValidatorRules = {
  beforeMount () {
    const uniqueProviderId = (value, params) => {
      const clinicianId = params[0]
      return new Promise(resolve => {
        if (value) {
          this.$store.dispatch(VALIDATE_PROVIDER_ID, { value, clinicianId }).then((data) => {
            if (!data.available) {
              return resolve({
                valid: false,
                data: { message: this.$t('providerIdInUse') }
              })
            }
            return resolve({ valid: true })
          }).catch(() => {
            return resolve({
              valid: false,
              data: {
                message: this.$t('generalError')
              }
            })
          })
        } else {
          // no value then validity is true
          return resolve({ valid: true })
        }
      })
    }

    this.$validator.extend('uniqueProviderId', {
      validate: uniqueProviderId,
      getMessage: (field, params, data) => data.message
    })
  }
}
