import i18n from '@/i18n'
import { AnalyticsChartModel } from '@/mixins/analytics/Reports/ChartModels/AnalyticsChartModel'

/**
 * Handle constructions of Analytics Gender Breakdown Chart Model.
 */
export class AnalyticsAdmitAndDischargeScoreOverTimeChartModel extends AnalyticsChartModel {
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    super(queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure)
    this.title = this.getChartTitleOrDefault(queryChartConfiguration, programData, queryResult, measure)
  }

  /**
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    return 'scoreOverTime'
  }

  getChartSubdivisionKey () {
    return 'outcomes'
  }

  /**
   * Get Default Chart xAxys.
   */
  getXAxis (queryResult) {
    const categories = queryResult.scores.over_time ? Object.keys(queryResult.scores.over_time).map(v => `${i18n.messages[i18n.locale].week} ${Number(v) + 1}`) : []
    categories.unshift(i18n.messages[i18n.locale].admission)
    categories.push(i18n.messages[i18n.locale].discharge)
    return { categories }
  }

  /**
   * Get Default Chart Title.
   */
  getDefaultChartTitle (program, queryResult, measure) {
    const measureName = measure ? measure.abbreviation : ''
    return `${program.name} ${i18n.messages[i18n.locale].average}  ${measureName} ${i18n.messages[i18n.locale].scoresOvertime}`
  }

  /**
   * Get yAxys seconddary config.
   */
  getYSecondaryAxis (queryResult) {
    return this.getYSecondaryAxysForMeasure(queryResult)
  }

  /**
   * Get Default Chart yAxys.
   */
  getYAxis (queryResult) {
    return this.getYaxysForMeasure(queryResult)
  }

  /**
   * Get Default Chart colors.
   */
  getSeriesColors () {
    return ['#36a2ea', '#8097ad', '#485d72', '#0a3560', '#b3c1d1', '#2068ac']
  }

  /**
   * Transform Analytics Data into more friendly object for charts.
   *
   * @param queryResult
   */
  transformAnalyticsDataIntoChartSeries (queryResult) {
    const scores = queryResult.scores
    const name = ''
    const data = scores && scores.over_time ? Object.values(scores.over_time).map(v => v) : []
    data.unshift(scores.admission)
    data.push(scores.discharge)
    const colors = this.getSeriesColors()
    return [{
      name,
      data,
      marker: {
        lineColor: colors[0],
        fillColor: 'white',
        lineWidth: 3
      }
    }]
  }
}
