import {
  CHANNEL_EMAIL,
  CHANNEL_PHONE
} from '@/data/communicationChannels.js'
import {
  UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON
} from '@/store/modules/patients/constants.js'
import {
  UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON
} from '@/store/modules/lastSession/constants.js'
import {
  MUTATIONS
} from '@/store/modules/programList/constants.js'
import {
  UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON,
  UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON
} from '@/store/modules/appointments/constants.js'

function getChannelFromArray (arr, channel) {
  return arr ? Object.values(arr).find(ci => ci.channel === channel) : {}
}
export function getPhoneFromArray (arr) {
  return getChannelFromArray(arr, CHANNEL_PHONE)
}
export function getEmailFromArray (arr) {
  return getChannelFromArray(arr, CHANNEL_EMAIL)
}
// TODO when we standarize user profile this should change to be a single line replacement of the profile
export function updateUserProfile (user, data, key) {
  switch (key) {
    case UPDATE_ASSIGNMENTS_LIST_FROM_SIGN_IN_BUTTON:
      user.contact_phone = getPhoneFromArray(data.contact_information)
      user.contact_email = getEmailFromArray(data.contact_information)
      updateUserProfileFullName(user, data, true, false, true)
      break
    case UPDATE_PATIENT_INFORMATION_FROM_SIGN_IN_BUTTON:
      const phoneData = getPhoneFromArray(data.contact_information) // eslint-disable-line no-case-declarations
      const emailData = getEmailFromArray(data.contact_information) // eslint-disable-line no-case-declarations
      user.mobile_number = phoneData ? phoneData.value : ''
      user.email = emailData ? emailData.value : ''
      user.mobile_notification = phoneData ? !!parseInt(phoneData.enabled) : false
      user.email_notification = emailData ? !!parseInt(emailData.enabled) : false

      updateUserProfileFullName(user, data, true, true, false)
      break
    case UPDATE_PATIENTS_LIST_FROM_SIGN_IN_BUTTON:
    case UPDATE_PATIENTS_BY_DUE_DATE_FROM_SIGN_IN_BUTTON:
      updateUserProfileFullName(user, data, false, true, false)
      break
    case UPDATE_TEAM_LATEST_SESSION_FROM_SIGN_IN_BUTTON:
      updateUserProfileFullName(user, data, true, true, false)
      user.contact_phone = getPhoneFromArray(data.contact_information)
      user.contact_email = getEmailFromArray(data.contact_information)
      break
    case MUTATIONS.updateProgramsPatientFromSignInButton:
      updateUserProfileFullName(user, data, true, true, false)
      break
    case UPDATE_PATIENTS_BY_APPOINTMENT_FROM_SIGN_IN_BUTTON:
      updateUserProfileFullName(user.patient, data, false, false, true)
      user.patient.initials = `${data.person_information.first_name.substring(0, 1)}${data.person_information.last_name.substring(0, 1)}`
      break

    default:
      break
  }
}

function updateUserProfileFullName (user, data, updateMiddleName, updateName, updateFullName) {
  user.first_name = data.person_information.first_name
  user.last_name = data.person_information.last_name
  if (updateMiddleName) {
    user.middle_name = data.person_information.middle_name
  }
  if (updateName) {
    user.name = data.person_information.full_name
  }
  if (updateFullName) {
    user.full_name = data.person_information.full_name
  }
}

export function waitForEventToFinish (validationFunction) {
  return new Promise((resolve) => {
    if (typeof validationFunction !== 'function') resolve()
    const checkFinishLoading = setInterval(() => {
      const result = validationFunction()
      if (result) {
        clearInterval(checkFinishLoading)
        resolve()
      }
    }, 100)
  })
}
