import i18n from '@/i18n'
import { format } from 'date-fns'
import store from '@/store'
export class AnalyticsChartModel {
  /**
   *
   * @param queryResult
   * @param queryChartConfiguration
   * @param additionalData
   */
  constructor (queryResult, programData, queryChartConfiguration, setVariablesFromQuery, measure) {
    if (this.constructor === AnalyticsChartModel) {
      throw new Error(this.constructor.name + ' Is an abstract class and cannot be instantiated')
    }
    this.alias = this.getDefaultChartAlias()
    this.programUUID = this.getProgramUUID(programData)
    this.measureUUID = this.getMeasureUUID(measure)
    this.id = this.programUUID + '_' + this.measureUUID + '_' + this.getDefaultChartAlias()
    this.chartGroup = this.getChartSubdivisionKey()
    this.series = this.transformAnalyticsDataIntoChartSeries(queryResult, programData)
    this.yAxis = [Object.assign(this.getBasicYaxis(), this.getYAxis(queryResult)), Object.assign(this.getBasicYaxis(), this.getYSecondaryAxis(queryResult))]
    this.xAxis = Object.assign(this.getBasicXaxis(), this.getXAxis(queryResult))
    this.legend = this.getBasicLegend(queryResult)
    this.colors = this.getSeriesColors()
    this.noDataMessage = this.getNoDataMessage()
    this.noData = this.getNoData()

    if (setVariablesFromQuery) {
      this.title = this.getChartTitleOrDefault(queryChartConfiguration, programData, queryResult, measure)
      this.subtitle = this.getDefaultChartSubTitle()
      this.caption = this.getChartCaptionOrDefault(queryChartConfiguration)
    }
  }

  /**
   * Get Chart Identifier add program sufix if there is one available.
   *
   * @param programData
   * @returns string
   */
  getProgramUUID (programData) {
    return programData && programData.hasOwnProperty('uuid') ? programData.uuid : '' // eslint-disable-line no-prototype-builtins
  }

  /**
   * Get Chart Identifier add measure sufix if there is one available.
   *
   * @param measure
   * @returns string
   */
  getMeasureUUID (measure) {
    return measure && measure.hasOwnProperty('uuid') ? measure.uuid : '' // eslint-disable-line no-prototype-builtins
  }

  /**
   * Private - Try to gets stored chart configuration from saved query.
   * Get Default Caption for this chart object if it is not found.
   *
   * @returns string
   */
  getChartCaptionOrDefault (queryChartConfiguration) {
    const chartConfiguration = this.getStoredChartConfiguration(queryChartConfiguration)
    if (!chartConfiguration) {
      return this.caption
    }

    const hasCaption = chartConfiguration.hasOwnProperty('caption') // eslint-disable-line no-prototype-builtins
    return hasCaption ? chartConfiguration.caption : this.caption
  }

  /**
   * Private - Try to gets stored chart configuration from saved query.
   * Get Default Caption for this chart object if it is not found.
   *
   * @returns string
   */
  getChartTitleOrDefault (config, programData, queryResult, measure = null) {
    const chartConfiguration = this.getStoredChartConfiguration(config)
    if (!chartConfiguration) {
      return this.getDefaultChartTitle(programData, queryResult, measure)
    }

    const hasTitle = chartConfiguration.hasOwnProperty('title') // eslint-disable-line no-prototype-builtins
    return hasTitle ? chartConfiguration.title : this.title
  }

  /**
   * Private - Check if the stored chart configuration from saved query
   * contains information about this chart and it configuration.
   *
   * @param config
   * @returns {boolean}
   */
  getStoredChartConfiguration (config) {
    if (!config || typeof config !== 'object' || !config.hasOwnProperty('chart_info')) { // eslint-disable-line no-prototype-builtins
      return false
    }

    if (!config.chart_info || !config.chart_info.hasOwnProperty(this.programUUID)) { // eslint-disable-line no-prototype-builtins
      return false
    }

    if (!config.chart_info[this.programUUID].hasOwnProperty(this.id)) { // eslint-disable-line no-prototype-builtins
      return false
    }

    return config.chart_info[this.programUUID][this.id]
  }

  /**
   * Protected - Applies transformation for te given data from Analytics
   * API and convert the data in actual series.
   */
  transformAnalyticsDataIntoChartSeries () {
    throw new Error('Method "transformAnalyticsDataIntoChartSeries()" must be implemented.')
  }

  /**
   * Protected - gets the noData property for the lang object
   * @returns String
   */
  getNoDataMessage () {
    return 'noDataFound'
  }

  /**
   * Protected - gets noData and the noData.style object
   * @returns Object
   */
  getNoData () {
    return {
      style: {
        fontFamily: 'MuseoSans_300',
        fontSize: '18px',
        fontWeight: 300,
        color: '#8097AD'
      }
    }
  }

  /**
   * Protected -  gets chart y axis config
   */
  getYAxis () {
    return {}
  }

  /**
   * Protected -  gets chart y axis config
   */
  getYSecondaryAxis () {
    return {
      title: {
        text: ''
      }
    }
  }

  /**
   * Protected - gets chart x axis config
   */
  getXAxis () {
    return {}
  }

  getLegendAlignment () {
    return 'center'
  }

  /**
   * Protected - gets chart legend
   */
  getBasicLegend () {
    return {
      align: this.getLegendAlignment(),
      itemStyle: this.getDefaultStyleObject()
    }
  }

  /**
   * Protected - gets chart legend
   */
  getBasicYaxis () {
    return {
      labels: {
        style: this.getDefaultStyleObject()
      }
    }
  }

  /**
   * Protected - gets chart legend
   */
  getBasicXaxis () {
    return {
      labels: {
        style: this.getDefaultStyleObject(10, 300)
      }
    }
  }

  /**
   * Protected - Need to be override by class child.
   * Get Default Chart Alias.
   */
  getDefaultChartAlias () {
    throw new Error('Method "getDefaultChartAlias()" must be implemented.')
  }

  getChartSubdivisionKey () {
    throw new Error('Method "getChartSubdivisionKey()" must be implemented.')
  }

  /**
   * Protected - Need to be override by class child.
   * Get Default Chart Title.
   */
  getDefaultChartTitle () {
    throw new Error('Method "getDefaultChartAlias()" must be implemented.')
  }

  /**
   * Get Default Chart color pallet
   */

  getSeriesColors () {
    return ['#8097ad', '#485d72', '#0a3560', '#b3c1d1', '#2068ac']
  }

  /**
   * Get Default Chart subtitle
   */
  getDefaultChartSubTitle () {
    const text = store.state.analytics.analyticsCustomDateFilterLabel
    const formattedDate = format(new Date(), 'MMM dd, yyyy')
    const today = `All Time as of ${formattedDate}`
    return text.toLowerCase() === 'all time' ? today : text
  }

  /**
   * Get Percentage
   *
   * @param total
   * @param value
   * @returns {number}
   */
  getPercentage (total, value) {
    return (value * 100) / total
  }

  /**
   * Public - Get Object to be send as part of into chart_info of a Analytics Query.
   *
   * @returns {}
   */
  getConfigurationForExport () {
    return {
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      caption: this.caption
    }
  }

  /**
  * Get Default Chart yAxys.
  */
  getCategoryName (string, key, strip) {
    const underKey = 'under-'
    const overKey = 'over-'
    const lotUnderKey = 'length_of_treatment_under_'
    const opWeek = 'week-'
    const opDay = 'day-'
    const noKey = '-'

    let categoryName = string.replace(key, '').replace('_', '-')

    // show min as <, special case for LOT "under" case
    if (string.includes(lotUnderKey)) {
      categoryName = `<${parseInt(categoryName.replace(underKey, '')) + 1}`
    } else if (categoryName.includes(underKey)) {
      categoryName = categoryName.replace(underKey, '<')
    } else if (categoryName.includes(opWeek)) {
      categoryName = categoryName.replace(opWeek, 'Week ')
    } else if (categoryName.includes(opDay)) {
      categoryName = categoryName.replace(opDay, 'Day ')
    } else if (strip && categoryName.includes(noKey)) {
      categoryName = categoryName.replace(noKey, '')
    }

    // show max as > (x - 1)
    if (categoryName.includes(overKey)) {
      // BE returns "29 and over" as _over_29, so _over_29 is actually 28 or greater
      categoryName = `>${parseInt(categoryName.replace(overKey, '')) - 1}`
    }
    return categoryName
  }

  /**
  * Get Default Chart Y Axis.
  */
  getXAxisLabels () {
    return {
      style: this.getDefaultStyleObject()
    }
  }

  /**
   * Get Default Chart Y Axis categories.
   */
  getXAxisCategories (queryResult, suffix, strip) {
    const categories = []
    for (const key in queryResult) {
      if (queryResult.hasOwnProperty(key) && key.includes(suffix)) { // eslint-disable-line no-prototype-builtins
        categories.push(this.getCategoryName(key, suffix, strip))
      }
    }
    return categories
  }

  /**
   * Get Default Chart X Axis categories where no prefix exists
   */
  getXAxisCategoriesNoPrefix (queryResult) {
    const categories = []
    for (const key in queryResult) {
      if (queryResult.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
        categories.push(this.getCategoryName(key))
      }
    }
    return categories
  }

  getColorNavy () {
    return '#0a3560'
  }

  getDefaultStyleObject (fSize = 14, fWeight = 200) {
    return {
      color: this.getColorNavy(),
      fontSize: `${fSize}px`,
      fontWeight: fWeight
    }
  }

  /**
  * Get Default Chart Y Axis max value.
  */
  getYAxisMax (queryResult) {
    if (queryResult) {
      let max = Math.max.apply(Math, Object.values(queryResult).map(v => isNaN(v) ? 0 : v))
      max = Math.ceil(max / 10) * 10
      return Math.ceil(max / 4) * 4
    }
    return null
  }

  /**
   * Get Default Chart yAxys tickPositions.
  */
  getYAxisTickPositions (max) {
    const increment = max / 4
    const tickPositions = []
    for (let i = 0; i < 4; i++) {
      tickPositions.push(increment * i)
    }
    tickPositions.push(max)
    return tickPositions
  }

  getYAxisCutOffFromMeasureConfig (setting) {
    if (Object.hasOwn(setting, 'series')) {
      const start = setting.series[0] ? setting.series[0].ycutoff : null
      return setting.series.reduce((carry, current) => {
        return carry === current.ycutoff ? current.ycutoff : null
      }, start)
    }
    return null
  }

  getYAxisPlotbandsFromMeasureConfig (setting) {
    if (setting) {
      const plotbands = setting.categories.map(c => {
        return {
          from: c.ymin,
          to: c.ymax,
          label: {
            align: 'right',
            verticalAlign: 'bottom',
            text: c.label,
            style: this.getDefaultStyleObject(),
            x: -5,
            y: c.label.indexOf('<br>') > -1 ? -20 : -5
          },
          color: 'white'
        }
      })
      const cutoff = this.getYAxisCutOffFromMeasureConfig(setting)
      if (cutoff) {
        plotbands.push({
          color: '#f5f8fb',
          from: parseFloat(Math.min.apply(Math, setting.categories.map(v => v.ymin))),
          to: parseFloat(cutoff)
        })
      }
      return plotbands
    }
    return []
  }

  getYAxisTickPositionsFromMeasureConfig (setting) {
    if (setting) {
      const tickPositions = setting.categories.map(c => c.ymin)

      const min = Math.min.apply(Math, setting.categories.map(v => v.ymin))
      tickPositions.unshift(min)

      const max = Math.max.apply(Math, setting.categories.map(v => v.ymax))
      tickPositions.push(max)
      return tickPositions
    }
    return undefined
  }

  getYSecondaryAxysForMeasure (queryResult) {
    const setting = this.getMeasureChartSettings(queryResult)
    const style = this.getDefaultStyleObject(16)
    style.textTransform = 'uppercase'
    return {
      opposite: true,
      title: {
        text: setting && setting.y_right_label ? setting.y_right_label : '',
        style
      }
    }
  }

  getMeasureChartSettings (queryResult) {
    const scoreType = queryResult && queryResult.measure ? queryResult.measure.score_type : ''
    const totalScore = queryResult && queryResult.measure ? queryResult.measure.chartSettings.total_score : ''
    if (scoreType && queryResult.measure.chartSettings && queryResult.measure.chartSettings[scoreType]) {
      return queryResult.measure.chartSettings[scoreType]
    } else if (totalScore) {
      return totalScore
    }
    return null
  }

  getYaxysLabelFromScoreType (queryResult) {
    let title = i18n.messages[i18n.locale].totalScore
    let measureAbb = ''
    if (queryResult && queryResult.measure) {
      title = queryResult.measure.score_label ? queryResult.measure.score_label : title
      measureAbb = queryResult.measure.abbreviation
    }
    return `${measureAbb} ${title}`
  }

  getYaxysForMeasure (queryResult) {
    const settings = this.getMeasureChartSettings(queryResult)
    return {
      tickPositions: this.getYAxisTickPositionsFromMeasureConfig(settings),
      plotBands: this.getYAxisPlotbandsFromMeasureConfig(settings),
      title: {
        text: this.getYaxysLabelFromScoreType(queryResult),
        margin: 20,
        style: this.getDefaultStyleObject(16)
      }
    }
  }
}
