
import {
  UPDATE_USAGE_GLOBAL_PARAMS,
  RESET_USAGE_GLOBAL_PARAMS,
  UPDATE_USAGE_TODAY_PARAMS,
  RESET_USAGE_TODAY_PARAMS,
  SET_ACTIVE_PATIENTS_DATA,
  SET_ACTIVE_PATIENTS_LOADING,
  SET_ACTIVE_PATIENTS_HAS_ERRORS,
  SET_ACTIVE_PATIENTS_TODAY_DATA,
  SET_ACTIVE_PATIENTS_TODAY_LOADING,
  SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS,
  SET_MEASURE_ACTIVITY_DATA,
  SET_MEASURE_ACTIVITY_LOADING,
  SET_MEASURE_ACTIVITY_HAS_ERRORS,
  SET_MEASURE_ACTIVITY_TODAY_DATA,
  SET_MEASURE_ACTIVITY_TODAY_LOADING,
  SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS,
  SET_MEASURE_COMPLETION_TODAY_DATA,
  SET_MEASURE_COMPLETION_TODAY_LOADING,
  SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS,
  SET_CLINICAL_SIGNINS_DATA,
  SET_CLINICAL_SIGNINS_LOADING,
  SET_CLINICAL_SIGNINS_HAS_ERRORS,
  SET_MEASURES_DATA,
  SET_MEASURES_LOADING,
  SET_MEASURES_HAS_ERRORS
} from './constants'

import {
  usageGlobalParamsDefault,
  usageTodayParamsDefault
} from './index'

export default {
  [UPDATE_USAGE_GLOBAL_PARAMS] (state, data) {
    state.usageGlobalParams = data
  },

  [RESET_USAGE_GLOBAL_PARAMS] (state) {
    state.usageGlobalParams = usageGlobalParamsDefault
  },

  [UPDATE_USAGE_TODAY_PARAMS] (state, data) {
    state.usageTodayParams = data
  },

  [RESET_USAGE_TODAY_PARAMS] (state) {
    state.usageTodayParams = usageTodayParamsDefault
  },

  [SET_ACTIVE_PATIENTS_DATA] (state, data) {
    state.activePatients.data = data
  },

  [SET_ACTIVE_PATIENTS_HAS_ERRORS] (state, data) {
    state.activePatients.hasErrors = data
  },

  [SET_ACTIVE_PATIENTS_LOADING] (state, data) {
    if (data) {
      state.activePatients.data = []
    }
    state.activePatients.loading = data
  },

  [SET_ACTIVE_PATIENTS_TODAY_DATA] (state, data) {
    state.activePatientsToday.data = data
  },

  [SET_ACTIVE_PATIENTS_TODAY_HAS_ERRORS] (state, data) {
    state.activePatientsToday.hasErrors = data
  },

  [SET_ACTIVE_PATIENTS_TODAY_LOADING] (state, data) {
    if (data) {
      state.activePatientsToday.data = []
    }
    state.activePatientsToday.loading = data
  },

  [SET_MEASURE_ACTIVITY_DATA] (state, data) {
    state.measureActivity.data = data
  },

  [SET_MEASURE_ACTIVITY_HAS_ERRORS] (state, data) {
    state.measureActivity.hasErrors = data
  },

  [SET_MEASURE_ACTIVITY_LOADING] (state, data) {
    if (data) {
      state.measureActivity.data = []
    }
    state.measureActivity.loading = data
  },

  [SET_MEASURE_ACTIVITY_TODAY_DATA] (state, data) {
    state.measureActivityToday.data = data
  },

  [SET_MEASURE_ACTIVITY_TODAY_HAS_ERRORS] (state, data) {
    state.measureActivityToday.hasErrors = data
  },

  [SET_MEASURE_ACTIVITY_TODAY_LOADING] (state, data) {
    if (data) {
      state.measureActivityToday.data = []
    }
    state.measureActivityToday.loading = data
  },

  [SET_MEASURE_COMPLETION_TODAY_DATA] (state, data) {
    state.measureCompletionToday.data = data
  },

  [SET_MEASURE_COMPLETION_TODAY_HAS_ERRORS] (state, data) {
    state.measureCompletionToday.hasErrors = data
  },

  [SET_MEASURE_COMPLETION_TODAY_LOADING] (state, data) {
    if (data) {
      state.measureCompletionToday.data = []
    }
    state.measureCompletionToday.loading = data
  },

  [SET_CLINICAL_SIGNINS_DATA] (state, data) {
    state.clinicalSignIns.data = data
  },

  [SET_CLINICAL_SIGNINS_HAS_ERRORS] (state, data) {
    state.clinicalSignIns.hasErrors = data
  },

  [SET_CLINICAL_SIGNINS_LOADING] (state, data) {
    if (data) {
      state.clinicalSignIns.data = []
    }
    state.clinicalSignIns.loading = data
  },

  [SET_MEASURES_DATA] (state, data) {
    state.measures.data = data
  },

  [SET_MEASURES_HAS_ERRORS] (state, data) {
    state.measures.hasErrors = data
  },

  [SET_MEASURES_LOADING] (state, data) {
    if (data) {
      state.measures.data = []
    }
    state.measures.loading = data
  }
}
